import { Component, Input, OnInit } from '@angular/core';
import { Event_class } from 'src/app/class/Event_class';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';

@Component({
  selector: 'app-user-seasson-ticket',
  templateUrl: './user-seasson-ticket.component.html',
  styleUrls: ['./user-seasson-ticket.component.css']
})
export class UserSeassonTicketComponent implements OnInit {
  @Input() event: Event_class;
  id_user_seasson_ticket: string;
  mode: string;
  constructor() {
    this.id_user_seasson_ticket = ' ';
    this.mode = ' ';
  }

  ngOnInit(): void {
  }

  getUserSeassonTicket(){
    let result: User_season_ticket_class;
    this.event.userSeassonTickets.forEach(userSeassonTicket =>{
      if(userSeassonTicket.id==this.id_user_seasson_ticket){
        result = userSeassonTicket;
      }
    });
    return result;
  }

}
