export class Event_summary_price_block_class{
  tot_inscrits: number;
  tot_pagats: number;
  tot_pagats_plataforma: number;
  preu_total_pagats: number;
  preu_total_pagats_plataforma: number;
  commission: number;
  total_commissions: number;
  price: number;
  constructor(){
    this.tot_inscrits = 0;
    this.tot_pagats = 0;
    this.tot_pagats_plataforma = 0;
    this.preu_total_pagats = 0;
    this.preu_total_pagats_plataforma = 0;
    this.commission = 0;
    this.total_commissions = 0;
  }
}
