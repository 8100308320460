export class Subzone_class{
    id: string;
    name: string;
    num_seats: number;
    constructor()
    constructor(id: string, name: string, num_seats: number)
    constructor(id?: string, name?: string, num_seats?: number)
    {
        this.id = id;
        this.name = name;
        this.num_seats = num_seats;
    }
}