<header class="bg-white shadow">
    <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl tracking-tight text-stone-900">Inscrits de l'esdeveniment<br><strong>{{event.event_name}}</strong></h1>
      
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
              </svg> {{dateFormat(event.date)}}
        </span>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg> {{timeFormat(event.date)}}
        </span><br>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
              
            {{ event.address }}
        </span>
    
    </div>
    <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-2">
            <div class="">
                <strong>Total inscrits</strong>
    
            <ng-container *ngIf="attendants!=null">
            
                {{countAttendants()}} / <strong>{{ event.has_sessions?event.sum_sessions_tickets:event.num_tickets }}</strong> - 
                <span [ngClass]="{
                    'text-red-300': (getAttendantP()<50),
                    'text-yellow-300': (getAttendantP()>=50 && getAttendantP()<75),
                    'text-lime-300': (getAttendantP()>=75)
                    }" style="font-weight: 500; font-style: normal;">
                    {{ getAttendantP() | number : '1.0-0' }}%
                </span></ng-container>
            </div>
        
            <div class="">
                <strong>Total assistents</strong>
                <ng-container *ngIf="attendants!=null">
                {{countAttAttendants()}} / <strong>{{countAttendants()}}</strong> - 
                <span [ngClass]="{
                    'text-red-300': (getAttendedAttendantP()<50),
                    'text-yellow-300': (getAttendedAttendantP()>=50 && getAttendedAttendantP()<75),
                    'text-lime-300': (getAttendedAttendantP()>=75)
                    }" style="font-weight: 500; font-style: normal;">
                    {{ getAttendedAttendantP() | number : '1.0-0' }}%
                </span></ng-container>
            </div>
        </div>
    </div>
  </header>
  <div class="mx-auto text-center border-t bg-white text-stone-900 p-4">
    <button type="button" class="bg-violet-200 hover:bg-violet-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="update()">Refresca les dades</button>
    <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2"  (click)="transformRes()">Capturar dades <ng-container *ngIf="!acabat && iniciCaptura"><svg aria-hidden="true" class="inline w-4 h-4 text-white animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg></ng-container></button>
    <button type="button" class="bg-yellow-200 hover:bg-yellow-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="getExcel()">Excel Individuals</button>
    <button type="button" class="bg-yellow-200 hover:bg-yellow-300 text-stone-900 text-xs py-2 px-4 rounded mr-8" (click)="getExcelM()">Excel Múltiples</button>
    <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="manualRegister()">Inscripció QR</button>
    <button type="button" class="bg-yellow-200 hover:bg-yellow-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="inPersonRegister()">Inscripció Guixeta</button>
  </div>


  
<ng-container *ngIf="!attendants"><div role="status" class="text-center w-full p-10">
    <svg aria-hidden="true" class="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-lime-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="text-sm text-stone-900">Carregant...</span>
</div></ng-container>

<div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8"  *ngIf="attendants!=null">
        <span style="font-size: 28px; font-weight: 500;">Inscrits individuals <span *ngIf="!event.has_sessions">- {{getTotalAttendants()}}</span></span>
</div>  
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-4 mb-4"  id="excel-table">
        <thead>
            <tr>
                <th class="px-3 py-2 w-56">Eines
                    <!--Fent clic a aquest botó, et permet amagar aquest esdeveniment de la pàgina principal i passar-lo a la pestanya d'Esdeveniments arxivats, visible a la part superior esquerra d'aquesta mateixa graella. Serveix per mantenir ordenat i fàcil de gestionar el llistat d'esdeveniments actius."-->
                </th>
                
                <th class="px-3 py-2">Nom</th>
                <th class="px-3 py-2">Cognoms</th>
                <th class="px-3 py-2">DNI</th>
                <th class="px-3 py-2">Correu</th>
                <th class="px-3 py-2">Telèfon</th>
                <th class="px-3 py-2">Info Regsitre</th>
                <th class="px-3 py-2" *ngIf="event.have_alternative_price">Tipus d'inscripció</th>
                <ng-container class="" *ngIf="event.questions.length>0"><!--<button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [routerLink]="['/vresp/'+attendant.id]" routerLinkActive="router-link-active" title="Respostes"><fa-icon [icon]="faList"></fa-icon></button>-->
                   <th *ngFor="let eventq of event.questions; index as i" title="{{eventq.text}}">{{acortar(eventq.text)}} ...</th>
                </ng-container>
                
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let attendant of attendants;index as i">
                <tr *ngIf="attendant.familiar_unit_id==undefined"  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td>
                        <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                (click)="open(content,attendant)"
                                title="Esborrar">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                  </svg>
                                  
                        </button>
                        
                        <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                [ngStyle]="{'background-color': attendant.has_attended=='Y'?'#d9f99d':'#fca5a5'}"
                                (click)="markAsAttended(attendant.id)" title="Validar">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                  </svg>
                                  
                        </button>
                        <button type="button" class="bg-yellow-200 hover:bg-yellow-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                (click)="open2(content2,attendant)"
                                title="Enviar entrades">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                  </svg>
                                  
                        </button>
                        <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1" *ngIf="event.paid"
                                (click)="view_payment_status(viewPaymentStatus,attendant)"
                                [ngStyle]="{'background-color': attendant.payment_status=='C'?'#d9f99d':attendant.payment_status=='P'?'rgb(255,196,56)':'#fca5a5'}" >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                  
                        </button>
                        <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1" title="{{attendant.id}}" (click)="clipboard_copy(attendant.id)"
                                placement="top" style="cursor: pointer;"
                                ngbPopover="" popoverTitle="Codi copiat">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                              </svg>
                        </button>
                    
                    </td>
                    <td>
                        <ng-container *ngIf="!attendant.edit_name">
                            {{attendant.name}}
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_name = true; saved_name = attendant.name" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                              </svg>
                        </ng-container>
                        <div class="input-group" *ngIf="attendant.edit_name">
                            <div class="input-group-prepend">
                                <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_name = false; attendant.name = saved_name"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                            </div>
                            <input type="text" class="form-control" aria-describedby="name" [(ngModel)]="attendant.name">
                            <div class="input-group-append">
                                <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveName(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                            </div>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="!attendant.edit_surname">
                            {{attendant.surname}}
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_surname = true; saved_surname = attendant.surname" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                              </svg>
                        </ng-container>
                        <div class="input-group" *ngIf="attendant.edit_surname">
                            <div class="input-group-prepend">
                                <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_surname = false; attendant.surname = saved_surname"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                            </div>
                            <input type="text" class="form-control" aria-describedby="surname" [(ngModel)]="attendant.surname">
                            <div class="input-group-append">
                                <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveSurname(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                            </div>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="!attendant.edit_dni">
                            {{attendant.dni}}
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_dni = true; saved_dni = attendant.dni" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                              </svg>
                            
                        </ng-container>
                        <div class="input-group" *ngIf="attendant.edit_dni">
                            <div class="input-group-prepend">
                                <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_dni = false; attendant.dni = saved_dni"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                            </div>
                            <input type="text" class="form-control" aria-describedby="dni" [(ngModel)]="attendant.dni">
                            <div class="input-group-append">
                                <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveDni(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                            </div>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="!attendant.edit_mail">
                            {{attendant.mail}}
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                              </svg>
                        </ng-container>
                        <div class="input-group" *ngIf="attendant.edit_mail">
                            <div class="input-group-prepend">
                                <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                            </div>
                            <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                            <div class="input-group-append">
                                <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                            </div>
                        </div>
                    </td>
                    <td>
                      <ng-container *ngIf="!attendant.edit_phone">
                        {{attendant.phone}}
                        <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_phone = true; saved_phone = attendant.phone" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                      </ng-container>
                      <div class="input-group" *ngIf="attendant.edit_phone">
                          <div class="input-group-prepend">
                              <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_phone = false; attendant.phone = saved_phone"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                          </div>
                          <input type="text" class="form-control" aria-describedby="phone" [(ngModel)]="attendant.phone">
                          <div class="input-group-append">
                              <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="savePhone(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                          </div>
                      </div>
                    </td> 
                    <td>
                        <p>{{attendant.time_register | date:'dd-MM-y HH:mm'}}</p>
                    </td>                  
                    <td class="d-print-none" *ngIf="event.have_alternative_price">
                        <p>{{transformPrice(attendant.id_event_alt_price)}}</p>
                    </td>
                    <ng-container *ngIf="acabat">
                        <td *ngFor="let question of event.questions; index as j" title="">
                            <div *ngIf="questions[i] && questions[i][j]">
                                <div *ngIf="questions[i][j].type != 'T'">
                                    <div *ngFor="let response of questions[i][j].response; index as o">
                                        <div id="res" *ngIf="questions[i][j].response[o]['MARK'] == 'Y'" style="height: unset;">
                                            {{questions[i][j].response[o].text}}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="questions[i][j].type == 'T'">
                                    <div id="resposta">{{ questions[i][j].text_response ? questions[i][j].text_response : '-' }}</div>
                                </div>
                            </div>
                            <div *ngIf="!questions[i] || !questions[i][j]">
                                <div id="resposta">-</div>
                            </div>
                        </td>
                    </ng-container>

                    
                    

                </tr>
            </ng-container>
        </tbody>
    </table>
    <ng-container *ngIf="hasFUAttendants">
        <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <span style="font-size: 28px; font-weight: 500;">Inscrits múltiples <span *ngIf="!event.has_sessions">- {{getTotalAttendantsFU()}}</span></span>
    </div> 
        <!-- <div class="text-center">
            <h3 style="margin-top: 60px;">Reserves per unitat familiar <span *ngIf="!event.has_sessions">- {{getTotalAttendantsFU()}}</span></h3>
            <ng-container *ngIf="!event.has_sessions">
              <p>Les següents pastilles indiquen la quantitat de unitats familiars de cada tipus.</p>
              <ng-container *ngFor="let ele of summary; index as i">
                  <ng-container *ngIf="i!=0&&ele">
                      <div class="btn-group mr-3 mb-3" style="border: 4px solid black" role="group" aria-label="Basic example">
                          <button type="button" class="btn btn-white"
                                  style="border: none; border-right:4px solid black;">Grups de <strong>{{i}}</strong></button>
                          <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" style="border:none; ">{{ele}}</button>
                      </div>
                  </ng-container>
              </ng-container>
            </ng-container>
        </div>-->
        <ng-container *ngIf="event.has_sessions">
            <ng-container *ngFor="let session of event.sessions">
                <ng-container *ngIf="session_has_fu(session.id)">
                    <h4>{{session.name}} - {{getTotalFUAttendantsSession(session.id)}}/{{session.tickets}} -
                      <span [ngClass]="{
                        'color-red': (getTotalFUAttendantsSessionP(session)<50),
                        'color-yellow': (getTotalFUAttendantsSessionP(session)>=50 && getTotalFUAttendantsSessionP(session)<75),
                        'color-green': (getTotalFUAttendantsSessionP(session)>75)
                        }" style="font-weight: 500; font-style: normal;">
                        {{ getTotalFUAttendantsSessionP(session) | number : '1.0-0' }}%
                      </span>
                    </h4>
                    <p>Les següents pastilles indiquen la quantitat de unitats familiars de cada tipus.</p>
                    <ng-container *ngFor="let ele of session.summary; index as i">
                        <ng-container *ngIf="i!=0&&ele">
                            <div class="btn-group mr-3 mb-3" style="border: 4px solid black" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-white"
                                        style="border: none; border-right:4px solid black;">Grups de <strong>{{i}}</strong></button>
                                <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" style="border:none; ">{{ele}}</button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <table class="table mt-3 table-borderless" id="excel-table">
                        <thead>
                            <tr>
                                <th class="px-3 py-2 w-56">Eines
                                    <!--Fent clic a aquest botó, et permet amagar aquest esdeveniment de la pàgina principal i passar-lo a la pestanya d'Esdeveniments arxivats, visible a la part superior esquerra d'aquesta mateixa graella. Serveix per mantenir ordenat i fàcil de gestionar el llistat d'esdeveniments actius."-->
                                </th>
                                
                                <th class="px-3 py-2">Nom</th>
                                <th class="px-3 py-2">Cognoms</th>
                                <th class="px-3 py-2">DNI</th>
                                <th class="px-3 py-2">Correu</th>
                                <th class="px-3 py-2">Telèfon</th>
                                <th class="px-3 py-2">Info Regsitre</th>                                
                                <th class="px-3 py-2" *ngIf="event.have_alternative_price">Tipus d'inscripció</th>
                                <ng-container class="" *ngIf="event.questions.length>0"><!--<button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [routerLink]="['/vresp/'+attendant.id]" routerLinkActive="router-link-active" title="Respostes"><fa-icon [icon]="faList"></fa-icon></button>-->
                                   <th *ngFor="let eventq of event.questions; index as i" title="{{eventq.text}}">{{acortar(eventq.text)}} ...</th>
                                </ng-container>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let attendant of attendants; index as i">
                                <ng-container *ngIf="attendant.id_session == session.id">
                                    <tr *ngIf="attendant.familiar_unit_id!=undefined"   [ngClass]="{'d-print-none': event.paid&&attendant.payment_status!='C'}" [style]="'background-color:' + attendant.color " class="border-b dark:bg-gray-800 dark:border-gray-700">

                                        <td>
                                            <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                                    (click)="open(content,attendant)"
                                                    title="Esborrar">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                      </svg>
                                                      
                                            </button>
                                            
                                            <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                                    [ngStyle]="{'background-color': attendant.has_attended=='Y'?'#d9f99d':'#fca5a5'}"
                                                    (click)="markAsAttended(attendant.id)" title="Validar">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                      </svg>
                                                      
                                            </button>
                                            <button type="button" class="bg-yellow-200 hover:bg-yellow-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                                    (click)="open2(content2,attendant)"
                                                    title="Enviar entrades">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                      </svg>
                                                      
                                            </button>
                                            <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1" *ngIf="event.paid"
                                                    (click)="view_payment_status(viewPaymentStatus,attendant)"
                                                    [ngStyle]="{'background-color': attendant.payment_status=='C'?'#d9f99d':attendant.payment_status=='P'?'rgb(255,196,56)':'#fca5a5'}" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                      </svg>
                                                      
                                            </button>
                                            <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1" (click)="clipboard_copy(attendant.id)"
                                            placement="top" style="cursor: pointer;"
                                            ngbPopover="" popoverTitle="Codi copiat">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                  </svg>
                                            </button>
                                        
                                        </td>
                                        
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_name">
                                                {{attendant.name}}
                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_name = true; saved_name = attendant.name" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                  </svg>
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_name">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_name = false; attendant.name = saved_name"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="name" [(ngModel)]="attendant.name">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveName(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_surname">
                                                {{attendant.surname}}
                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_surname = true; saved_surname = attendant.surname" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                  </svg>
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_surname">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_surname = false; attendant.surname = saved_surname"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="surname" [(ngModel)]="attendant.surname">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveSurname(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_dni">
                                                {{attendant.dni}}
                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_dni = true; saved_dni = attendant.dni" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                  </svg>
                                                
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_dni">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_dni = false; attendant.dni = saved_dni"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="dni" [(ngModel)]="attendant.dni">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveDni(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_mail">
                                                {{attendant.mail}}
                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                  </svg>
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_mail">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                          <ng-container *ngIf="!attendant.edit_phone">
                                            {{attendant.phone}}
                                            <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_phone = true; saved_phone = attendant.phone" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                              </svg>
                                          </ng-container>
                                          <div class="input-group" *ngIf="attendant.edit_phone">
                                              <div class="input-group-prepend">
                                                  <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_phone = false; attendant.phone = saved_phone"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                              </div>
                                              <input type="text" class="form-control" aria-describedby="phone" [(ngModel)]="attendant.phone">
                                              <div class="input-group-append">
                                                  <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="savePhone(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                              </div>
                                          </div>
                                        </td>    
                                        <td>
                                            <p>{{attendant.time_register | date:'dd-MM-y HH:mm'}}</p>
                                        </td>                
                                        <td class="d-print-none" *ngIf="event.have_alternative_price">
                                            <p>{{transformPrice(attendant.id_event_alt_price)}}</p>
                                        </td>
                                        <ng-container *ngIf="acabat">
                                            <td *ngFor="let question of event.questions; index as j" title="">
                                                <div *ngIf="questions[i] && questions[i][j]">
                                                    <div *ngIf="questions[i][j].type != 'T'">
                                                        <div *ngFor="let response of questions[i][j].response; index as o">
                                                            <div id="res" *ngIf="questions[i][j].response[o]['MARK'] == 'Y'" style="height: unset;">
                                                                {{questions[i][j].response[o].text}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="questions[i][j].type == 'T'">
                                                        <div id="resposta">{{ questions[i][j].text_response ? questions[i][j].text_response : '-' }}</div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!questions[i] || !questions[i][j]">
                                                    <div id="resposta">-</div>
                                                </div>
                                            </td>
                                        </ng-container>
                    
                                        
                                        
                                        <!-- <td *ngIf="attendant.first_fu" [attr.rowspan]="count_fu(attendant.familiar_unit_id)" style="vertical-align: middle;">{{attendant.number_fu}}</td>
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_name">
                                                {{attendant.name}}<fa-icon [icon]="faEdit" (click)="attendant.edit_name = true; saved_name = attendant.name" class="ml-1"></fa-icon>
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_name">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_name = false; attendant.name = saved_name"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="name" [(ngModel)]="attendant.name">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveName(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_surname">
                                                {{attendant.surname}}<fa-icon [icon]="faEdit" (click)="attendant.edit_surname = true; saved_surname = attendant.surname" class="ml-1"></fa-icon>
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_surname">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_surname = false; attendant.surname = saved_surname"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="surname" [(ngModel)]="attendant.surname">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveSurname(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!attendant.edit_dni">
                                                {{attendant.dni}}<fa-icon [icon]="faEdit" (click)="attendant.edit_dni = true; saved_dni = attendant.dni" class="ml-1"></fa-icon>
                                            </ng-container>
                                            <div class="input-group" *ngIf="attendant.edit_dni">
                                                <div class="input-group-prepend">
                                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_dni = false; attendant.dni = saved_dni"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="dni" [(ngModel)]="attendant.dni">
                                                <div class="input-group-append">
                                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveDni(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                          <ng-container *ngIf="!attendant.edit_mail">
                                              {{attendant.mail}}<fa-icon [icon]="faEdit" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" class="ml-1"></fa-icon>
                                          </ng-container>
                                          <div class="input-group" *ngIf="attendant.edit_mail">
                                              <div class="input-group-prepend">
                                                  <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                              </div>
                                              <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                                              <div class="input-group-append">
                                                  <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                              </div>
                                          </div>
                                        </td>
                                        <td>
                                          <ng-container *ngIf="!attendant.edit_phone">
                                            {{attendant.phone}}<fa-icon [icon]="faEdit" (click)="attendant.edit_phone = true; saved_phone = attendant.phone" class="ml-1"></fa-icon>
                                          </ng-container>
                                          <div class="input-group" *ngIf="attendant.edit_phone">
                                              <div class="input-group-prepend">
                                                  <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_phone = false; attendant.phone = saved_phone"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                              </div>
                                              <input type="text" class="form-control" aria-describedby="phone" [(ngModel)]="attendant.phone">
                                              <div class="input-group-append">
                                                  <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="savePhone(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                              </div>
                                          </div>
                                        </td>
                                        <ng-container *ngIf="!event.multiple">
                                          <td *ngIf="attendant.has_attended=='Y'">{{formatDate(attendant.time_attendance)}}</td>
                                          <td *ngIf="attendant.has_attended!='Y'"></td>
                                          <td *ngIf="attendant.has_attended=='Y'">{{attendant.door_keeper}}</td>
                                          <td *ngIf="attendant.has_attended!='Y'"></td>
                                        </ng-container>
                                        <td class="d-print-none" *ngIf="event.questions.length>0"><button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [routerLink]="['/vresp/'+attendant.id]" routerLinkActive="router-link-active" title="Respostes"><fa-icon [icon]="faList"></fa-icon></button></td>
                                        <td class="d-print-none">
                                            <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [ngStyle]="{'background-color': event.accent_color}"
                                                (click)="open(content,attendant)" title="Esborrar"><fa-icon [icon]="faTrashAlt"></fa-icon></button>
                                        </td>
                                        <td class="d-print-none" *ngIf="event.multiple">
                                          <button type="button" class="btn"
                                              [ngClass]="{'btn-succes':attendant.has_key_reads,'btn-danger':!attendant.has_key_reads}"
                                              (click)="viewKeyReads(viewAttendantRead,attendant)" title="Validar">
                                              <fa-icon [icon]="attendant.has_key_reads?faUserCheck:faUserTimes"></fa-icon></button>
                                        </td>
                                        <td class="d-print-none" *ngIf="!event.multiple">
                                          <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s"
                                              [ngStyle]="{'background-color': attendant.has_attended=='Y'?'#d9f99d':'#fca5a5'}"
                                              (click)="markAsAttended(attendant.id)" title="Validar">
                                              <fa-icon [icon]="attendant.has_attended=='Y'?faUserCheck:faUserTimes"></fa-icon></button>
                                        </td>
                                        <td class="d-print-none">
                                            <button type="button" class="btn btn-warning" (click)="open2(content2,attendant)" title="Enviar entrades"><fa-icon [icon]="faEnvelope"></fa-icon></button>
                                        </td>
                                        <td class="d-print-none" *ngIf="event.paid">
                                            <button type="button" class="btn"
                                                style="color:white"
                                                (click)="view_payment_status(viewPaymentStatus,attendant)"
                                                [ngStyle]="{'background-color': attendant.payment_status=='C'?'#d9f99d':attendant.payment_status=='P'?'rgb(255,196,56)':'#fca5a5'}" >
                                                <fa-icon [icon]="faEuroSign"></fa-icon></button>
                                        </td>-->
                                    </tr>
                                </ng-container>

                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>
        </ng-container>

        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-4 mb-4" *ngIf="!event.has_sessions" id="excel-table-m">
            <thead>
                <tr>
                    <th class="px-3 py-2 w-56">Eines
                        <!--Fent clic a aquest botó, et permet amagar aquest esdeveniment de la pàgina principal i passar-lo a la pestanya d'Esdeveniments arxivats, visible a la part superior esquerra d'aquesta mateixa graella. Serveix per mantenir ordenat i fàcil de gestionar el llistat d'esdeveniments actius."-->
                    </th>
                    <th class="px-3 py-2">Nom</th>
                    <th class="px-3 py-2">Cognoms</th>
                    <th class="px-3 py-2">DNI</th>
                    <th class="px-3 py-2">Correu</th>
                    <th class="px-3 py-2">Telèfon</th>
                    <th class="px-3 py-2">Info Regsitre</th>
                    <th class="px-3 py-2" *ngIf="event.have_alternative_price">Tipus d'inscripció</th>
                    <ng-container class="" *ngIf="event.questions.length>0"><!--<button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [routerLink]="['/vresp/'+attendant.id]" routerLinkActive="router-link-active" title="Respostes"><fa-icon [icon]="faList"></fa-icon></button>-->
                       <th *ngFor="let eventq of event.questions; index as i" title="{{eventq.text}}">{{acortar(eventq.text)}} ...</th>
                    </ng-container>
                    
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let attendant of attendants; index as i">
                    <tr *ngIf="attendant.familiar_unit_id!=undefined" [style]="'background-color:' + attendant.color " [ngClass]="{'d-print-none': event.paid&&attendant.payment_status!='C'}" class="border-b dark:bg-gray-800 dark:border-gray-700">
                        <td>
                            <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                    (click)="open(content,attendant)"
                                    title="Esborrar">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                      </svg>
                                      
                            </button>
                            
                            <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                    [ngStyle]="{'background-color': attendant.has_attended=='Y'?'#d9f99d':'#fca5a5'}"
                                    (click)="markAsAttended(attendant.id)" title="Validar">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                      </svg>
                                      
                            </button>
                            <button type="button" class="bg-yellow-200 hover:bg-yellow-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1"
                                    (click)="open2(content2,attendant)"
                                    title="Enviar entrades">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                      </svg>
                                      
                            </button>
                            <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1" *ngIf="event.paid"
                                    (click)="view_payment_status(viewPaymentStatus,attendant)"
                                    [ngStyle]="{'background-color': attendant.payment_status=='C'?'#d9f99d':attendant.payment_status=='P'?'rgb(255,196,56)':'#fca5a5'}" >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>
                                      
                            </button>
                            <button type="button" class="bg-gray-200 hover:bg-gray-300 text-stone-900 text-xs py-1 px-2 rounded mr-2 my-1" title="{{attendant.id}}" (click)="clipboard_copy(attendant.id)"
                            placement="top" style="cursor: pointer;"
                            ngbPopover="" popoverTitle="Codi copiat">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                  </svg>
                            </button>
                        
                        </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_name">
                                {{attendant.name}}
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_name = true; saved_name = attendant.name" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_name">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_name = false; attendant.name = saved_name"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="text" class="form-control" aria-describedby="name" [(ngModel)]="attendant.name">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveName(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_surname">
                                {{attendant.surname}}
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_surname = true; saved_surname = attendant.surname" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_surname">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_surname = false; attendant.surname = saved_surname"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="text" class="form-control" aria-describedby="surname" [(ngModel)]="attendant.surname">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveSurname(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_dni">
                                {{attendant.dni}}
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_dni = true; saved_dni = attendant.dni" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                                
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_dni">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_dni = false; attendant.dni = saved_dni"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="text" class="form-control" aria-describedby="dni" [(ngModel)]="attendant.dni">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveDni(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_mail">
                                {{attendant.mail}}
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_mail">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td>
                          <ng-container *ngIf="!attendant.edit_phone">
                            {{attendant.phone}}
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="attendant.edit_phone = true; saved_phone = attendant.phone" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                              </svg>
                          </ng-container>
                          <div class="input-group" *ngIf="attendant.edit_phone">
                              <div class="input-group-prepend">
                                  <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_phone = false; attendant.phone = saved_phone"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                              </div>
                              <input type="text" class="form-control" aria-describedby="phone" [(ngModel)]="attendant.phone">
                              <div class="input-group-append">
                                  <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="savePhone(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                              </div>
                          </div>
                        </td> 
                        <td>
                            <p>{{attendant.time_register | date:'dd-MM-y HH:mm'}}</p>
                        </td>                   
                        <td class="d-print-none" *ngIf="event.have_alternative_price">
                            <p>{{transformPrice(attendant.id_event_alt_price)}}</p>
                        </td>
                        <ng-container *ngIf="acabat">
                            <td *ngFor="let question of event.questions; index as j" title="">
                                <div *ngIf="questions[i] && questions[i][j]">
                                    <div *ngIf="questions[i][j].type != 'T'">
                                        <div *ngFor="let response of questions[i][j].response; index as o">
                                            <div id="res" *ngIf="questions[i][j].response[o]['MARK'] == 'Y'" style="height: unset;">
                                                {{questions[i][j].response[o].text}}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="questions[i][j].type == 'T'">
                                        <div id="resposta">{{ questions[i][j].text_response ? questions[i][j].text_response : '-' }}</div>
                                    </div>
                                </div>
                                <div *ngIf="!questions[i] || !questions[i][j]">
                                    <div id="resposta">-</div>
                                </div>
                            </td>
                        </ng-container>
    
                        
                        
                        <!-- <td  *ngIf="attendant.first_fu" [attr.rowspan]="count_fu(attendant.familiar_unit_id)" style="vertical-align: middle;">{{attendant.number_fu}} </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_name">
                                {{attendant.name}}<fa-icon [icon]="faEdit" (click)="attendant.edit_name = true; saved_name = attendant.name" class="ml-1"></fa-icon>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_name">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_name = false; attendant.name = saved_name"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="text" class="form-control" aria-describedby="name" [(ngModel)]="attendant.name">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveName(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_surname">
                                {{attendant.surname}}<fa-icon [icon]="faEdit" (click)="attendant.edit_surname = true; saved_surname = attendant.surname" class="ml-1"></fa-icon>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_surname">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_surname = false; attendant.surname = saved_surname"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="text" class="form-control" aria-describedby="surname" [(ngModel)]="attendant.surname">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveSurname(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ng-container *ngIf="!attendant.edit_dni">
                                {{attendant.dni}}<fa-icon [icon]="faEdit" (click)="attendant.edit_dni = true; saved_dni = attendant.dni" class="ml-1"></fa-icon>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_dni">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_dni = false; attendant.dni = saved_dni"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="text" class="form-control" aria-describedby="dni" [(ngModel)]="attendant.dni">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveDni(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="attendant.first_fu" [attr.rowspan]="count_fu(attendant.familiar_unit_id)" style="vertical-align: middle;">
                            <ng-container *ngIf="!attendant.edit_mail">
                                {{attendant.mail}}<fa-icon [icon]="faEdit" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" class="ml-1"></fa-icon>
                            </ng-container>
                            <div class="input-group" *ngIf="attendant.edit_mail">
                                <div class="input-group-prepend">
                                    <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                                </div>
                                <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                                <div class="input-group-append">
                                    <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="attendant.first_fu" [attr.rowspan]="count_fu(attendant.familiar_unit_id)" style="vertical-align: middle;">
                          <ng-container *ngIf="!attendant.edit_phone">
                            {{attendant.phone}}<fa-icon [icon]="faEdit" (click)="attendant.edit_phone = true; saved_phone = attendant.phone" class="ml-1"></fa-icon>
                          </ng-container>
                          <div class="input-group" *ngIf="attendant.edit_phone">
                              <div class="input-group-prepend">
                                  <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_phone = false; attendant.phone = saved_phone"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                              </div>
                              <input type="text" class="form-control" aria-describedby="phone" [(ngModel)]="attendant.phone">
                              <div class="input-group-append">
                                  <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="savePhone(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                              </div>
                          </div>
                        </td>
                        <ng-container *ngIf="!event.multiple">
                          <td *ngIf="attendant.has_attended=='Y'">{{formatDate(attendant.time_attendance)}}</td>
                          <td *ngIf="attendant.has_attended!='Y'"></td>
                          <td *ngIf="attendant.has_attended=='Y'">{{attendant.door_keeper}}</td>
                          <td *ngIf="attendant.has_attended!='Y'"></td>
                        </ng-container>
                        <td class="d-print-none" *ngIf="event.questions.length>0"><button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [routerLink]="['/vresp/'+attendant.id]" routerLinkActive="router-link-active" title="Respostes"><fa-icon [icon]="faList"></fa-icon></button></td>
                        <td class="d-print-none">
                            <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" [ngStyle]="{'background-color': event.accent_color}"
                                (click)="open(content,attendant)" title="Esborrar"><fa-icon [icon]="faTrashAlt"></fa-icon></button>
                        </td>
                        <td class="d-print-none" *ngIf="event.multiple">
                          <button type="button" class="btn"
                              [ngClass]="{'btn-succes':attendant.has_key_reads,'btn-danger':!attendant.has_key_reads}"
                              (click)="viewKeyReads(viewAttendantRead,attendant)" title="Validar">
                              <fa-icon [icon]="attendant.has_key_reads?faUserCheck:faUserTimes"></fa-icon></button>
                        </td>
                        <td class="d-print-none" *ngIf="!event.multiple">
                          <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s"
                              [ngStyle]="{'background-color': attendant.has_attended=='Y'?'#d9f99d':'#fca5a5'}"
                              (click)="markAsAttended(attendant.id)" title="Validar">
                              <fa-icon [icon]="attendant.has_attended=='Y'?faUserCheck:faUserTimes"></fa-icon></button>
                        </td>
                        <td class="d-print-none" *ngIf="attendant.first_fu" [attr.rowspan]="count_fu(attendant.familiar_unit_id)" style="vertical-align: middle;">
                            <button type="button" class="btn btn-warning" (click)="open2(content2,attendant)" title="Enviar entrades"><fa-icon [icon]="faEnvelope"></fa-icon></button>
                        </td>
                        <td class="d-print-none" *ngIf="event.paid&&attendant.first_fu" [attr.rowspan]="count_fu(attendant.familiar_unit_id)" style="vertical-align: middle;">
                            <button type="button" class="btn"
                                style="color:white"
                                (click)="view_payment_status(viewPaymentStatus,attendant)"
                                [ngStyle]="{'background-color': attendant.payment_status=='C'?'#d9f99d':attendant.payment_status=='P'?'rgb(255,196,56)':'#fca5a5'}" >
                                <fa-icon [icon]="faEuroSign"></fa-icon></button>
                        </td>-->
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>

<h2 *ngIf="userSeassonAttendants.length > 0">Abonats</h2>
<ng-container *ngIf="userSeassonAttendants.length > 0">
  <button type="button" class="btn btn-warning ml-3 d-print-none"
                (click)="sortSurnameUst()">
      <fa-icon [icon]="faList"></fa-icon>
      &nbsp;Ordenar per cognom
  </button>
  <br>
</ng-container>
<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-4 mb-4" *ngIf="userSeassonAttendants.length > 0">
  <thead>
      <tr>
          <th class="px-3 py-2">#</th>
          <th class="px-3 py-2">Nom</th>
          <th class="px-3 py-2">Cognoms</th>
          <th class="px-3 py-2">DNI</th>
          <th class="px-3 py-2">Correu</th>
          <th class="px-3 py-2">Telèfon</th>
          <th class="px-3 py-2">Info Regsitre</th>
          <th class="d-print-none"></th>
      </tr>
  </thead>
  <tbody>
      <ng-container *ngFor="let attendant of userSeassonAttendants;index as i">
          <tr>
              <td>{{i+1}}</td>
              <td>{{attendant.name}}</td>
              <td>{{attendant.surname}}</td>
              <td>{{attendant.dni}}</td>
              <td>
                  <ng-container *ngIf="!attendant.edit_mail">
                      {{attendant.mail}}<!--<fa-icon [icon]="faEdit" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" class="ml-1"></fa-icon>-->
                  </ng-container>
                  <!--
                  <div class="input-group" *ngIf="attendant.edit_mail">
                      <div class="input-group-prepend">
                          <button class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                      </div>
                      <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                      <div class="input-group-append">
                          <button class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2s" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                      </div>
                  </div>-->
              </td>
              <td>{{attendant.phone}}</td>
              <td class="d-print-none">
                  <button type="button" class="btn"
                      style="color:white"
                      [ngStyle]="{'background-color': attendant.payment_status=='C'?'#d9f99d':
                        (attendant.payment_status=='P'?'rgb(255,196,56)':'#fca5a5')}" >
                      <fa-icon [icon]="faEuroSign"></fa-icon>
                  </button>
              </td>
          </tr>
      </ng-container>
  </tbody>
</table>
<br><br>


<ng-template #content let-modal>
    <div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div class="relative w-full grid place-items-center h-screen">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
    <h4 class="modal-title" id="modal-basic-title">Eliminar reserva</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="p-6">
    Eliminar la reserva a nom de <b>{{delete_attendant_name}}?</b>
  </div>
  <div class="p-6 border-t">
    <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.dismiss()">Cancel·lar</button>
    <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.close('DELETE')">Eliminar</button>
  </div></div></div></div>
</ng-template>


<ng-template #content2 let-modal><div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full grid place-items-center h-screen">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
      <h4 class="modal-title" id="modal-basic-title">Reenviament entrades</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="p-6">
      Enviar entrades de nou a <b>{{delete_attendant_name}}?</b>
    </div>
    <div class="p-6 border-t">
      <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.dismiss()">Cancel·lar</button>
      <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.close('SEND')">Enviar</button>
    </div></div></div></div>
  </ng-template>

  <ng-template #confirmMarkAsPaied let-modal><div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full grid place-items-center h-screen">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
      <h4 class="modal-title" id="modal-basic-title">Estat del pagament</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="p-6">
      Canviar situació de pagament de <b>{{delete_attendant_name}}?</b>
    </div>
    <div class="p-6 border-t">
      <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.dismiss()">Cancel·lar</button>
      <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.close('SEND')">Enviar</button>
    </div></div></div></div>
  </ng-template>

  <ng-template #confirmMarkAsAttendedF let-modal><div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full grid place-items-center h-screen">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
      <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="p-6">
      Marcar <ng-container *ngIf="selected_attendant.last_read_type=='I'">la sortida</ng-container>
      <ng-container *ngIf="selected_attendant.last_read_type=='O'">l'entrada</ng-container>
      &nbsp; de <b>{{delete_attendant_name}}?</b>
    </div>
    <div class="p-6 border-t">
      <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.dismiss()">Cancel·lar</button>
      <button type="button" class="bg-red-200 hover:bg-red-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.close('SEND')">Enviar</button>
    </div></div></div></div>
  </ng-template>

  <ng-template #viewPaymentStatus let-modal>
    <div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div class="relative w-full grid place-items-center h-screen">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
                <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                    <h4 class="modal-title" id="modal-basic-title">Estat del pagament: <ng-container *ngIf="selected_attendant.payment_status!='C'"><span class="text-red-400">No pagat</span></ng-container><ng-container  *ngIf="selected_attendant.payment_status=='C'"><span class="text-lime-400">Pagat</span></ng-container></h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
           
                <div class="p-6">
                    <td class="d-print-none" *ngIf="event.paid">
                        <button type="button" class="text-stone-900 text-xs py-2 px-4 rounded"
                            (click)="confirm_mark_as_paied(confirmMarkAsPaied,selected_attendant)"
                            [ngStyle]="{'background-color': selected_attendant.payment_status=='C'?'rgb(255,196,56)':selected_attendant.payment_status=='P'?'rgb(217, 249, 157)':'#fca5a5'}" >
                            <ng-container *ngIf="selected_attendant.payment_status!='C'">Marcar com pagat</ng-container>
                            <ng-container *ngIf="selected_attendant.payment_status=='C'">Marcar com no pagat</ng-container>
                        </button>
                    </td>
                <app-payment-status [payment_status_list]="selected_attendant.payment_status_list"></app-payment-status>
                </div>
                <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button type="button" class="bg-violet-200 hover:bg-violet-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.dismiss()">Tancar</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

  <app-back-button link="/events-list"></app-back-button>

  <ng-template #viewAttendantRead let-modal><div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full grid place-items-center h-screen">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
      <h4 class="modal-title" id="modal-basic-title">Lectures</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="p-6">
        <td class="d-print-none">
            <button type="button" class="btn"
                style="color:white"
                (click)="confirMarkAsAttended(confirmMarkAsAttendedF,selected_attendant)"
                [ngStyle]="{'background-color': selected_attendant.last_read_type=='I'?'#d9f99d':(selected_attendant.last_read_type=='O'?'rgb(255,196,56)':'#fca5a5')}" >
                <ng-container *ngIf="selected_attendant.last_read_type=='O'">Marcar entrada</ng-container>
                <ng-container *ngIf="selected_attendant.last_read_type=='I'">Marcar sortida</ng-container>
            </button>
        </td>
      <app-attendant-reads [keyReads]="selected_attendant.key_reads"></app-attendant-reads>
    </div>
    <div class="p-6 border-t">
      <button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded mr-2" (click)="modal.dismiss()">Tancar</button>
    </div></div></div></div>
  </ng-template>

  <app-back-button link="/events-list"></app-back-button>
