import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

export class Event_session_class{
    id: number;
    id_event: string;
    name: string;
    description: string;
    tickets: number;
    limit_by_fu: boolean;
    max_fu: number;
    reserved_tickets: number;
    reserved_fu: number;
    open_date: number;
    open_date_d: Date;
    close_date: number;
    close_date_d: Date;
    door_code: string;
    mer_assist: boolean = false;
    selectedAltPrice: string = ' ';
    summary: Array<number>;
    constructor(){
        this.description = '';
        this.name = '';
        this.limit_by_fu = false;
    }
}
