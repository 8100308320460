import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import * as Constants from 'src/app/modules/constants/constants.module';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {
  idAttendant: string;
  checkAttendantP: boolean;
  mode: string;
  rsVersion: string;
  rsParams: string;
  rsSignature: string;
  rsLink: string;
  idAttendantP: string;
  paymentDone: boolean;
  supp_mail: string;
  faExclamationCircle = faExclamationCircle;

  constructor(public route: ActivatedRoute,
              public backend: BackendServiceService) {
    this.idAttendant = '';
    this.checkAttendantP = false;
    this.mode = '';
    this.paymentDone = false;
    this.supp_mail = Constants.SUPP_MAIL;
   }

  ngOnInit(): void {
    this.idAttendant = this.route.snapshot.paramMap.get("id_attendant");
    this.mode = this.route.snapshot.paramMap.get("mode");
    this.checkAttendantP = false;
    if(this.mode == 'a')
      this.getPaymentInfo(this.idAttendant);
    else if(this.mode == 'fu')
      this.getPaymentInfoFU(this.idAttendant);
  }

  getPaymentInfoFU(idFU){
    //console.log(this.attendant);
    this.backend.getPaymentInfoFU(idFU).subscribe(data => {
      //console.log(data);
      if(data){
        if(data['payment_done']){
          this.paymentDone = true;
        } else {
          let rs = data['payment_info'];
          this.rsVersion = rs['rsVersion'];
          this.rsParams = rs['rsParams'];
          this.rsSignature = rs['rsSignature'];
          this.rsLink = rs['rsLink'];
          this.pay(this.rsVersion,this.rsParams,this.rsSignature,this.rsLink);
          this.idAttendantP = data['id'];
          this.checkAttendantP = true;
        }
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getPaymentInfo(idAttendant){
    //console.log(this.attendant);
    this.backend.getPaymentInfo(idAttendant).subscribe(data => {
      //console.log(data);
      if(data){
        if(data['payment_done']){
          this.paymentDone = true;
        } else {
          let rs = data['payment_info'];
          this.rsVersion = rs['rsVersion'];
          this.rsParams = rs['rsParams'];
          this.rsSignature = rs['rsSignature'];
          this.rsLink = rs['rsLink'];
          this.pay(this.rsVersion,this.rsParams,this.rsSignature,this.rsLink);
          this.idAttendantP = data['id'];
          this.checkAttendantP = true;
        }
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  pay(rsVersion,rsParams,rsSignature,rsLink) {
    var mapForm = document.createElement("form");
    //mapForm.target = "_blank";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = rsLink;

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_SignatureVersion';
    mapInput.setAttribute("value", rsVersion);
    mapForm.appendChild(mapInput);

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_MerchantParameters';
    mapInput.setAttribute("value", rsParams);
    mapForm.appendChild(mapInput);

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_Signature';
    mapInput.setAttribute("value", rsSignature);
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();
  }


}
