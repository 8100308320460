import { Injectable } from '@angular/core';
import { User_season_ticket_class } from '../class/User_season_ticket_class';

@Injectable({
  providedIn: 'root'
})
export class UserSeasonTicketContainerService {
  userSeasonTicket: User_season_ticket_class;
  constructor() { }
}
