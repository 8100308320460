import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';

/* PANEL */
import { LoginComponent } from './components/panel/login/login.component';
import { CreateModifyEventComponent } from './components/panel/create-modify-event/create-modify-event.component';
import { HeaderComponent } from './components/panel/header/header.component';
import { EventsListComponent } from './components/panel/events-list/events-list.component';
import { ConfigurationComponent } from './components/panel/configuration/configuration.component';
import { EventAdminComponent } from './components/panel/event-admin/event-admin.component';
import { HeaderAdminComponent } from './components/panel/header-admin/header-admin.component';
import { ViewResponsesComponent } from './components/panel/view-responses/view-responses.component';
import { CreateSubzoneComponent } from './components/panel/create-subzone/create-subzone.component';
import { CreateZoneComponent } from './components/panel/create-zone/create-zone.component';
import { FormAttendantComponent } from './components/view-register/register/forms/form-attendant/form-attendant.component';
import { ZoneListComponent } from './components/panel/zone-list/zone-list.component';
import { CreateUserComponent } from './components/panel/create-user/create-user.component';
import { UserListComponent } from './components/panel/user-list/user-list.component';
import { ViewTicketsComponent } from './components/panel/view-tickets/view-tickets.component';
import { DeleteAttendantComponent } from './components/panel/delete-attendant/delete-attendant.component';
import { BackButtonComponent } from './components/panel/back-button/back-button.component';
import { NewsletterComponent } from './components/panel/newsletter/newsletter.component';
import { EventSessionComponent } from './components/panel/event-session/event-session.component';
import { EventSessionFormComponent } from './components/panel/event-session-form/event-session-form.component';
import { PaymentStatusComponent } from './components/panel/payment-status/payment-status.component';
import { StatisticsComponent } from './components/panel/statistics/statistics.component';

/* Event view */
import { ViewEventComponent } from './components/view-register/view/view-event/view-event.component';
import { RegisterAttendantComponent } from './components/view-register/register/ticket/register-attendant/register-attendant.component';
import { PaymentWaitComponent } from './components/view-register/view/payment-wait/payment-wait.component';
import { PayComponent } from './components/view-register/pay/pay.component';
import { LegalAdviceComponent } from './components/view-register/view/legal-advice/legal-advice.component';
import { CookiePoliticComponent } from './components/view-register/view/cookie-politic/cookie-politic.component';
import { CookieBannerComponent } from './components/view-register/view/cookie-banner/cookie-banner.component';
import { PrivacyPolicyComponent } from './components/view-register/view/privacy-policy/privacy-policy.component';
import { CookieConfigComponent } from './components/view-register/view/cookie-config/cookie-config.component';
import { SelectAttendantTypeComponent } from './components/view-register/register/ticket/select-attendant-type/select-attendant-type.component';
import { RegisterZoneAttendantsComponent } from './components/view-register/register/ticket/register-zone-attendants/register-zone-attendants.component';
import { RegisterFamiliarUnionAttendantsComponent } from './components/view-register/register/ticket/register-familiar-union-attendants/register-familiar-union-attendants.component';
import { ListEventsFromUserComponent } from './components/view-register/list-events-from-user/list-events-from-user.component';
import { SessionsComponent } from './components/panel/sessions/sessions.component';
import { CreateModifyUserSeasonTicketComponent } from './components/panel/create-modify-user-season-ticket/create-modify-user-season-ticket.component';
import { ListUserSeasonTicketComponent } from './components/panel/list-user-season-ticket/list-user-season-ticket.component';
import { UserSeassonTicketComponent } from './components/view-register/register/user-seasson-ticket/user-seasson-ticket/user-seasson-ticket.component';
import { UserSeassonTicketSelectComponent } from './components/view-register/register/user-seasson-ticket/user-seasson-ticket-select/user-seasson-ticket-select.component';
import { ListUserSeassonTicketAttendantsComponent } from './components/panel/list-user-seasson-ticket-attendants/list-user-seasson-ticket-attendants.component';
import { FormEventAlternativePriceComponent } from './components/panel/form-event-alternative-price/form-event-alternative-price.component';
import { AttendantReadsComponent } from './components/panel/attendant-reads/attendant-reads.component';
import { SummaryComponent } from './components/panel/summary/summary.component';
import { SessionSelectComponent } from './components/panel/session-select/session-select.component';
import { MeFamiliarUnionComponent } from './components/view-register/register/multi-event/me-familiar-union/me-familiar-union.component';
import { MeAttendantComponent } from './components/view-register/register/multi-event/me-attendant/me-attendant.component';
import { MeRegisterComponent } from './components/view-register/register/multi-event/me-register/me-register.component';
import { HelpPageComponent } from './components/view-register/view/help-page/help-page.component';
import { ViewTicketsMeComponent } from './components/panel/view-tickets-me/view-tickets-me.component';
import { ViewTicketsMeIComponent } from './components/panel/view-tickets-me-i/view-tickets-me-i.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TransformQuestionsPipe } from './pipes/transform-questions.pipe';
import { FooterComponent } from './components/panel/footer/footer.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable() export class DefaultIntl extends OwlDateTimeIntl {
  firstDayOfWeek=1;
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel= 'Afegir segon';

  /** A label for the down second button (used by screen readers).  */
  downSecondLabel= 'Restar segons';

  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel= 'Afegir minut';

  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel= 'Restar minut';

  /** A label for the up hour button (used by screen readers).  */
  upHourLabel= 'Afegir hora';

  /** A label for the down hour button (used by screen readers).  */
  downHourLabel= 'Restar hora';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel= 'Previous month';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel= 'Next month';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel= 'Previous year';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel= 'Next year';

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel= 'Previous 21 years';

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel= 'Next 21 years';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel= 'Change to month view';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel= 'Choose month and year';

  /** A label for the cancel button */
  cancelBtnLabel= 'Cancel·lar';

  /** A label for the set button */
  setBtnLabel= 'Acceptar';

  /** A label for the range 'from' in picker info */
  rangeFromLabel= 'From';

  /** A label for the range 'to' in picker info */
  rangeToLabel= 'To';

  /** A label for the hour12 button (AM) */
  hour12AMLabel= 'AM';

  /** A label for the hour12 button (PM) */
  hour12PMLabel= 'PM';
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CreateModifyEventComponent,
    ViewEventComponent,
    RegisterAttendantComponent,
    HeaderComponent,
    EventsListComponent,
    ConfigurationComponent,
    EventAdminComponent,
    HeaderAdminComponent,
    ViewResponsesComponent,
    CreateSubzoneComponent,
    CreateZoneComponent,
    FormAttendantComponent,
    SelectAttendantTypeComponent,
    RegisterZoneAttendantsComponent,
    RegisterFamiliarUnionAttendantsComponent,
    ZoneListComponent,
    CreateUserComponent,
    UserListComponent,
    ViewTicketsComponent,
    DeleteAttendantComponent,
    BackButtonComponent,
    PaymentWaitComponent,
    PayComponent,
    LegalAdviceComponent,
    CookiePoliticComponent,
    CookieBannerComponent,
    PrivacyPolicyComponent,
    CookieConfigComponent,
    StatisticsComponent,
    NewsletterComponent,
    EventSessionComponent,
    EventSessionFormComponent,
    PaymentStatusComponent,
    BlockCopyPasteDirective,
    ListEventsFromUserComponent,
    SessionsComponent,
    CreateModifyUserSeasonTicketComponent,
    ListUserSeasonTicketComponent,
    UserSeassonTicketComponent,
    UserSeassonTicketSelectComponent,
    ListUserSeassonTicketAttendantsComponent,
    FormEventAlternativePriceComponent,
    AttendantReadsComponent,
    SummaryComponent,
    SessionSelectComponent,
    MeFamiliarUnionComponent,
    MeAttendantComponent,
    MeRegisterComponent,
    HelpPageComponent,
    ViewTicketsMeComponent,
    ViewTicketsMeIComponent,
    TransformQuestionsPipe,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CKEditorModule,
    ColorPickerModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage:'ca'
  })
  ],
  providers: [{provide: OwlDateTimeIntl, useClass: DefaultIntl},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
