<div class="grid grid-cols-2 gap-4">
    
    <div class="col-span-2 font-bold text-lg">{{"Les teves dades" | translate}}</div>
    <div class="col-span-2 lg:col-span-1">
    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">{{'Nom' | translate}}</label>
    <!--<input type="text" class="form-control" [(ngModel)]="attendant.name" required #name="ngModel">-->
    <input class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" type="text" [(ngModel)]="attendant.name">
    <!--<div *ngIf="name.invalid && (name.dirty || name.touched)"
            class="text-red-500 text-xs">

        <div *ngIf="name.errors.required">
            Name is required.
        </div>
    </div>--><div class="text-red-500 text-xs" role="alert" *ngFor="let error of attendant_errors.name">
    {{error | translate}}
</div>
</div>

<div class="col-span-2 lg:col-span-1">
    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">{{'Cognoms' | translate}}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="attendant.surname" required #surname="ngModel">
    <div class="text-red-500 text-xs" role="alert" *ngFor="let error of attendant_errors.surname">
        {{error | translate}}
    </div>
</div>

<div class="col-span-2 lg:col-span-1" *ngIf="fields.isEnabled('dni')">
    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">DNI / NIE</label>
    <input type="text" *ngIf="!attendant.nodni" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="attendant.dni" required #dni="ngModel">
    <div class="input-group mt-1">
        <input id="nodni" class="w-4 h-4 text-lime-600 bg-stone-100 border-stone-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-stone-800 focus:ring-2 dark:bg-stone-700 dark:border-stone-600" type="checkbox" name="nodni" [(ngModel)]="attendant.nodni">
            
        <label for="nodni" class="ml-2 text-xs font-medium text-stone-900 dark:text-stone-300">{{'No té DNI / NIE' | translate}}</label>
    </div>
    <div class="text-red-500 text-xs" role="alert" *ngFor="let error of attendant_errors.dni">
        {{error | translate}}
    </div>
</div>

<div class="col-span-2 lg:col-span-1" *ngIf="fields.isEnabled('phone')&&mode!='F'">
    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">{{'Telèfon' | translate}}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="attendant.phone" required #phone="ngModel">
    <div class="text-red-500 text-xs" role="alert" *ngFor="let error of attendant_errors.phone">
        {{error | translate}}
    </div>
</div>

<div class="col-span-2 lg:col-span-1" *ngIf="mode!='F'">
    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">{{'Correu electrònic' | translate}}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="attendant.mail" required #mail="ngModel" appBlockCopyPaste>
    <div class="text-red-500 text-xs" role="alert" *ngFor="let error of attendant_errors.mail">
        {{error | translate}}
    </div>
    <div class="text-red-500 text-xs" *ngIf="attendant_errors.mail.length>0" role="alert">
        <fa-icon [icon]="faInfoCircle" class="mr-1"></fa-icon> <small>{{'Si el teu correu és correcte i et continua sortint el missatge d\'error, si us plau obre el link de l\'esdeveniment en un altre navegador.' | translate}}</small>
    </div>
</div>
<div class="col-span-2 lg:col-span-1" *ngIf="mode!='F'">
    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">{{'Confirma el correu electrònic' | translate}}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="attendant.mail2" required #mail="ngModel" appBlockCopyPaste>
    
</div>


<div class="col-span-2 my-3"><hr></div>
<ng-container *ngIf="seassonTicket&&userSeassonTicket.sections.length>0">
    
  <div class="form-check">
    <input class="form-check-input" [value]="' '" type="radio" [name]="'sectionRadio'+index" [(ngModel)]="attendant.selectedSection">
    <label class="form-check-label">
      {{'Sense descompte' | translate}}
    </label>
  </div>
  <ng-container *ngFor="let section of userSeassonTicket.sections">
    <div class="form-check">
      <input class="form-check-input" type="radio" [value]="section.id" [name]="'sectionRadio'+index" [(ngModel)]="attendant.selectedSection">
      <label class="form-check-label">
        {{section.description}}
      </label>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="!seassonTicket&&altPrices.length>0">
   
    <div class="col-span-2 font-bold text-lg">{{"Tipus d'entrada" | translate}}</div>
  <ng-container *ngFor="let altPrice of altPrices">
    
    <div class="col-span-2 lg:col-span-1 my-3">
      <input class="w-4 h-4 text-lime-600 bg-stone-100 border-stone-300 focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-stone-800 focus:ring-2 dark:bg-stone-700 dark:border-stone-600" type="radio" [value]="altPrice.id" [name]="'altPriceRadio'+index" [(ngModel)]="attendant.selectedAltPrice">
      <label class="">
        {{altPrice.description}}
      </label>
    </div>
  </ng-container>
  
</ng-container>
<ng-container *ngIf="attendant.questions?.length>0">
    <div *ngIf="!seassonTicket&&altPrices.length>0" class="col-span-2 my-3"><hr></div>
    <div class="col-span-2 font-bold text-lg">{{"Altres" | translate}}</div>
    <div class="col-span-2 lg:col-span-1" *ngFor="let question of attendant.questions;index as i">
    
        <div class="col-12">
            <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">{{i+1}}. {{question.text}}</label>
        </div>
        <div class="col-12" *ngIf="question.type!='T'">
            <div *ngFor="let response of question.response;index as o">
                <div class="input-group mt-1">
                    
                    <input class="w-4 h-4  text-lime-600 bg-stone-100 border-stone-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-stone-800 focus:ring-2 dark:bg-stone-700 dark:border-stone-600" type="checkbox" *ngIf="question.type=='C'" [name]="'response'+i" [(ngModel)]="question.response[o].value">
                    <input class="w-4 h-4  text-lime-600 bg-stone-100 border-stone-300 focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-stone-800 focus:ring-2 dark:bg-stone-700 dark:border-stone-600" type="radio" *ngIf="question.type=='R'" [name]="'response'+i" [(ngModel)]="question.response_id" [value]="response.id">
                    
                    <label class="mb-2 ml-2 text-sm font-medium text-stone-400 dark:text-white" style="height: unset;">{{question.response[o].text}}</label>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="question.type=='T'">
            <textarea rows="4" class="block p-2.5 w-full text-sm text-stone-900 bg-stone-50 rounded-lg border border-stone-300 focus:ring-lime-500 focus:border-lime-500 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="question.text_response" required #text_response="ngModel"></textarea>
        </div>
    
    </div>
    
</ng-container>

<div class="col-span-2 my-3"><hr></div>
</div>