<div class="container-fluid">
    <div class="row">
        <div class="col-6 offset-3">
            <h1>Configuració</h1>
            <div class="form-group">
                <label>color</label>
                <input type="text" class="form-control" [(ngModel)]="config.accent_color">
            </div>
            <div class="form-group">
                <label>foto fons</label>
                <input #filechooser type="file" class="form-control-file">
            </div>
            <div class="form-group">
                <label for="event_nameInput">condicions generals</label>
                <ckeditor [editor]="Editor"
                        [config]="Editor_config"
                        [(ngModel)]="config.conditions"></ckeditor>
            </div>
            <h1>Correu</h1>
            <div class="form-group">
                <label>Temps reserva (hores)</label>
                <input type="number" class="form-control" [(ngModel)]="gconfig.RES_TI">
            </div>
            <h1>Redsys</h1>
            <div class="form-group">
                <label>Temps pagament (minuts)</label>
                <input type="number" class="form-control" [(ngModel)]="gconfig.RS_MIN">
            </div>
            <div class="form-group">
                <label>Version</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_VER">
            </div>
            <div class="form-group">
                <label>Key</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_KEY">
            </div>
            <div class="form-group">
                <label>Fuc</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_FUC">
            </div>
            <div class="form-group">
                <label>Terminal</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_TER">
            </div>
            <div class="form-group">
                <label>Link servidor</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_LNK">
            </div>
            <div class="form-group">
                <label>Link OK</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_LOK">
            </div>
            <div class="form-group">
                <label>Link KO</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_LKO">
            </div>
            <div class="form-group">
                <label>Divisa</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_DIV">
            </div>
            <div class="form-group">
                <label>Trans</label>
                <input type="text" class="form-control" [(ngModel)]="gconfig.RS_TRA">
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary mt-3" style="width: 100%;" (click)="save()">Guardar</button>
            </div>
        </div>
    </div>
</div>
