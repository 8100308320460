import { Component, OnInit } from '@angular/core';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';
import { User_season_ticket_error_class } from 'src/app/class/User_season_ticket_error_class';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { UserSeasonTicketContainerService } from 'src/app/services/user-season-ticket-container.service';
import { User_season_ticket_section_class } from 'src/app/class/User_season_ticket_section_class';
import { User_season_ticket_section_error_class } from 'src/app/class/User_season_ticket_section_error_class';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-create-modify-user-season-ticket',
  templateUrl: './create-modify-user-season-ticket.component.html',
  styleUrls: ['./create-modify-user-season-ticket.component.css']
})
export class CreateModifyUserSeasonTicketComponent implements OnInit {
  userSeasonTicket: User_season_ticket_class;
  userSeasonTicketError: User_season_ticket_error_class;
  mode: string;
  start_date: Date;
  end_date: Date;
  start_sell: Date;
  end_sell: Date;
  allowfui: string;
  sections: Array<User_season_ticket_section_class>;
  sections_error: Array<User_season_ticket_section_error_class>;
  faTimesCircle = faTimesCircle;
  public Editor_config = {
    fontSize: {
      options: [
          9,
          11,
          13,
          'default',
          17,
          19,
          21
      ]
    },
    fontColor: {
      colors: [
          {
              color: 'hsl(0, 0%, 0%)',
              label: 'Black'
          },
          {
              color: 'hsl(0, 0%, 30%)',
              label: 'Dim grey'
          },
          {
              color: 'hsl(0, 0%, 60%)',
              label: 'Grey'
          },
          {
              color: 'hsl(0, 0%, 90%)',
              label: 'Light grey'
          },
          {
              color: 'hsl(0, 0%, 100%)',
              label: 'White',
              hasBorder: true
          },

          // ...
      ]
    },
    toolbar: [ 'fontSize', 'fontColor', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'mediaEmbed' ]
  };
  public Editor = ClassicEditor;
  constructor(public backend: BackendServiceService,
    private localLogin: LocalLoginService,
    public userSeasonTicketContainer: UserSeasonTicketContainerService,
    public router: Router) {
      if(this.backend.apikey==undefined){
        if(this.localLogin.existsData()){
          this.localLogin.restore_to_service();
        } else {
          this.router.navigateByUrl("/");
        }
      }
      const userSeasonTicketFrom = this.userSeasonTicketContainer.userSeasonTicket;
      //console.log(userSeasonTicketFrom);
      if(userSeasonTicketFrom!=undefined && userSeasonTicketFrom.name!=undefined
          && userSeasonTicketFrom.name!=''){
        this.mode = 'E';
        this.userSeasonTicket = userSeasonTicketFrom;
        this.userSeasonTicketError = new User_season_ticket_error_class();
        this.start_date = new Date(userSeasonTicketFrom.start_date * 1000);
        this.end_date = new Date(userSeasonTicketFrom.end_date * 1000);
        this.start_sell = new Date(userSeasonTicketFrom.start_sell * 1000);
        this.end_sell = new Date(userSeasonTicketFrom.end_sell * 1000);
        this.sections = this.userSeasonTicket.sections;
        this.sections_error = new Array<User_season_ticket_section_error_class>();
        this.sections.forEach(e=>{this.sections_error.push(new User_season_ticket_section_error_class());})
        this.allowfui = this.userSeasonTicket.allow_i?'I':'FU';
        //console.log(this.user);
      } else {
        this.mode = 'C';
        this.userSeasonTicket = new User_season_ticket_class();
        this.userSeasonTicketError = new User_season_ticket_error_class();
        this.sections = new Array<User_season_ticket_section_class>();
        this.sections_error = new Array<User_season_ticket_section_error_class>();
        this.allowfui = 'I';
      }
    }

  ngOnInit(): void {
  }

  validate(): boolean{
    let result = true;
    this.userSeasonTicketError = new User_season_ticket_error_class();
    if(this.isVoid(this.userSeasonTicket.name)){
      this.userSeasonTicketError.name.push("El camp nom es obligatori");
      result = false;
    }
    if(this.userSeasonTicket.type=='U'){
      if(this.userSeasonTicket.allow_i_unique){
        if(this.isVoid(this.userSeasonTicket.price)){
          this.userSeasonTicketError.price.push("El camp preu es obligatori");
          result = false;
        }
      }
      if(this.userSeasonTicket.allow_fu_unique){
        if(this.isVoid(this.userSeasonTicket.price_fu)){
          this.userSeasonTicketError.price_fu.push("El camp preu es obligatori");
          result = false;
        }
      }
    }
    if(this.userSeasonTicket.type=='U'){
      if(this.userSeasonTicket.allow_couple){
        if(this.isVoid(this.userSeasonTicket.price_couple)){
          this.userSeasonTicketError.price_couple.push("El camp preu es obligatori");
          result = false;
        }
        if(this.isVoid(this.userSeasonTicket.price_individual)){
          this.userSeasonTicketError.price_individual.push("El camp preu es obligatori");
          result = false;
        }
      }
    }
    if(this.isVoid(this.userSeasonTicket.start_date)){
      this.userSeasonTicketError.start_date.push("El camp data inici validesa es obligatori");
      result = false;
    }
    if(this.isVoid(this.userSeasonTicket.end_date)){
      this.userSeasonTicketError.end_date.push("El camp data fi validesa es obligatori");
      result = false;
    }
    return result;
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  save(){
    if(this.mode == 'C'){
      this.userSeasonTicket.sections = this.sections;
    }
    //console.log(this.userSeasonTicket)
    if(!this.isVoid(this.start_date))
      this.userSeasonTicket.start_date = Math.round(this.start_date.getTime()/1000);
    else
      this.userSeasonTicket.start_date = undefined;

    if(!this.isVoid(this.end_date))
      this.userSeasonTicket.end_date = Math.round(this.end_date.getTime()/1000);
    else
      this.userSeasonTicket.end_date = undefined;

    if(!this.isVoid(this.start_sell))
      this.userSeasonTicket.start_sell = Math.round(this.start_sell.getTime()/1000);
    else
      this.userSeasonTicket.start_sell = undefined;
    if(!this.isVoid(this.end_sell))
      this.userSeasonTicket.end_sell = Math.round(this.end_sell.getTime()/1000);
    else
      this.userSeasonTicket.end_sell = undefined;
      
    if(this.allowfui == 'I'){
      this.userSeasonTicket.allow_i = true;
      this.userSeasonTicket.allow_fu = false;
    } else {
      this.userSeasonTicket.allow_i = false;
      this.userSeasonTicket.allow_fu = true;
    }


    if(this.validate())
      if(this.mode=='C'){
        //console.log(this.user);
        this.backend.saveUserSeasonTicket(this.userSeasonTicket).subscribe(data => {
          console.log(data);
          if(data['error']=='login_error'){
              this.backend.logout().subscribe(data=> {
              this.localLogin.remove(true);
              this.router.navigateByUrl("/");
            })
          }

          if(data){
              this.router.navigateByUrl("user-season-ticket-list");
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
      } else {
        // console.log("UPDATE USER SEASON TICKET")
        // console.log(this.userSeasonTicket);
        this.backend.modifyUserSeasonTicket(this.userSeasonTicket).subscribe(data => {
          console.log(data);
          if(data['error']=='login_error'){
            this.backend.logout().subscribe(data=> {
            this.localLogin.remove(true);
            this.router.navigateByUrl("/");
            })
          }

          if(data){
              this.router.navigateByUrl("user-season-ticket-list");
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
      }
  }

  addSection(){
    this.sections.push(new User_season_ticket_section_class());
    this.sections_error.push(new User_season_ticket_section_error_class());
  }

  getComision(price: number){
    if(price <= 0)
      return 0;
    if(price <5)
      return this.backend.commission_low;
    else
      return this.backend.commission_high;
  }

  getRPrice(price: number){
    const commission = this.getComision(price);
    return price - commission;
  }

  removeSection(index: number){
    this.sections.splice(index,1);
  }

}
