export class Attendant_error_class{
    name: Array<string>;
    surname: Array<string>;
    dni: Array<string>;
    mail: Array<string>;
    phone: Array<string>;
    condition: Array<string>;
    general_condition: Array<string>;
    general_error: Array<string>;
    pConditions: Array<string>;
    constructor(){
        this.name = new Array<string>();
        this.surname = new Array<string>();
        this.dni = new Array<string>();
        this.mail = new Array<string>();
        this.phone = new Array<string>();
        this.condition = new Array<string>();
        this.general_condition = new Array<string>();
        this.general_error = new Array<string>();
        this.pConditions = new Array<string>();
    }
}