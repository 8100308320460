import { User_season_ticket_section_class } from "./User_season_ticket_section_class";

export class User_season_ticket_class{
  id: string;
  name: string;
  description: string;
  allow_i: boolean;
  price: number;
  start_date: number;
  end_date: number;
  start_sell: number;
  end_sell: number;
  allow_fu: boolean;
  price_fu: number;
  allow_i_unique: boolean;
  allow_fu_unique: boolean;
  allow_couple: boolean;
  price_individual: number;
  price_couple: number;
  max_fu: number
  type: string;
  sections: Array<User_season_ticket_section_class>;
  apikey: string;
  constructor(){
    this.description = '';
    this.name = '';
    this.price = 0;
    this.price_fu = 0;
    this.price_individual = 0;
    this.price_couple = 0;
    this.allow_i = false;
    this.allow_fu = false;
    this.allow_i_unique = false;
    this.allow_fu_unique = false;
    this.allow_couple = false;
    this.type = 'U';
    this.max_fu = 0;
    this.sections = new Array<User_season_ticket_section_class>();
  }
}
