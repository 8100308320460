export class Event_session_error_class{
    name: Array<string>;
    description: Array<string>;
    tickets: Array<string>;
    max_fu: Array<string>;
    open_date: Array<string>;
    close_date: Array<string>;
    constructor(){
        this.name = new Array<string>();
        this.description = new Array<string>();
        this.tickets = new Array<string>();
        this.max_fu = new Array<string>();
        this.open_date = new Array<string>();
        this.close_date = new Array<string>();
    }
}