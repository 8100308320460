<ng-container *ngIf="idAttendant!=''">
    <ng-container *ngIf="payment_status!='C'&&payment_status!='F'">
        <div class="full-screen text-center pl-3 pr-3">
            <h4>{{'Esperant pagament...' | translate }}</h4>
            <img class="img-fluid" style="height: 40px;" src="/assets/loader.gif" />
            <div class="col-sm-6 offset-sm-3">
                <p>{{'Si no ha pogut completar el pagament, en uns minuts rebrà un correu electrònic amb un enllaç per completar-lo' | translate }}</p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="payment_status=='C'">
        <div class="full-screen text-center pl-3 pr-3">
            <fa-icon [icon]="faCheckCircle" style="font-size: 40px; color: #15D790;"></fa-icon>
            <h4>{{'Pagament rebut, en uns minuts rebrà un correu electrònic amb les entrades' | translate }}</h4>
        </div>  
    </ng-container>
    <ng-container *ngIf="payment_status=='F'">
        <div class="full-screen text-center pl-3 pr-3">
            <fa-icon [icon]="faTimesCircle" style="font-size: 40px; color: #f5535e;"></fa-icon>
            <h4>{{'Error en el pagament' | translate }}</h4>
            <div class="col-sm-6 offset-sm-3">
                <p>{{'Si no ha pogut completar el pagament, en uns minuts rebrà un correu electrònic amb un enllaç per completar-lo' | translate }}</p>
            </div>
        </div>  
    </ng-container>
</ng-container>