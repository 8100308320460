import { Injectable } from '@angular/core';
import { Event_class } from '../class/Event_class';

@Injectable({
  providedIn: 'root'
})
export class EventContainerService {
  event: Event_class
  constructor() { }

  get(){
    return this.event;
  }

  set(event){
    this.event = event;
  }
}
