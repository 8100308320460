<div class="col-12 pr-3 pl-3 mt-3" style="margin-top: 60px!important;margin-bottom: 60px;">
  <button type="button" class="btn btn-warning ml-3 d-print-none"
                (click)="sortSurname()">
      <fa-icon [icon]="faList"></fa-icon>
      &nbsp;Ordenar per cognom
  </button>
  <br>
  <table class="table table-striped table-borderless" *ngIf="!hasFUAttendants">
    <thead>
        <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Cognoms</th>
            <th>DNI</th>
            <th>Correu</th>
            <th>Telèfon</th>
            <th class="d-print-none"></th>
            <th class="d-print-none"></th>
            <th class="d-print-none"></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let attendant of userSeassonAttendants;index as i">
            <tr *ngIf="attendant.familiar_unit_id==''">
                <td>{{attendant.num}}</td>
                <td>{{attendant.name}}</td>
                <td>{{attendant.surname}}</td>
                <td>{{attendant.dni}}</td>
                <td>
                    <ng-container *ngIf="!attendant.edit_mail">
                        {{attendant.mail}}<fa-icon [icon]="faEdit" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" class="ml-1"></fa-icon>
                    </ng-container>
                    <div class="input-group" *ngIf="attendant.edit_mail">
                        <div class="input-group-prepend">
                            <button class="btn btn-danger" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                        </div>
                        <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                        <div class="input-group-append">
                            <button class="btn btn-success" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                        </div>
                    </div>
                </td>
                <td>{{attendant.phone}}</td>
                <td class="d-print-none" >
                  <button type="button" class="btn btn-danger"
                    (click)="showDeleteForm(deleteModal,attendant)"
                    title="Enviar entrades">
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </td>
                <td class="d-print-none" >
                    <button type="button" class="btn btn-warning"
                      (click)="sendMail(sendMailModal,attendant)"
                      title="Enviar entrades">
                      <fa-icon [icon]="faEnvelope"></fa-icon>
                    </button>
                </td>
                <td class="d-print-none">
                    <button type="button" class="btn"
                        style="color:white"
                        (click)="viewPaymentStatus(viewPaymentStatusT,attendant)"
                        [ngStyle]="{'background-color': attendant.payment_status=='C'?'#15D790':
                          (attendant.payment_status=='P'?'rgb(255,196,56)':'rgb(208,77,45)')}" >
                          {{attendant.amount_paid*1}}€
                    </button>
                </td>
            </tr>
        </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="hasFUAttendants">
    <table class="table mt-3 table-borderless">
      <thead>
          <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Cognoms</th>
              <th>DNI</th>
              <th class="d-print-none"></th>
              <th>Correu</th>
              <th>Telèfon</th>
              <th class="d-print-none"></th>
              <th class="d-print-none"></th>
              <!--<th class="d-print-none"></th>-->
          </tr>
      </thead>
      <tbody>
          <ng-container *ngFor="let attendant of userSeassonAttendants; index as i">
              <tr *ngIf="attendant.familiar_unit_id!=''"
                [style]="'background-color:' + attendant.color ">
                  <td  *ngIf="attendant.first_fu"
                    [attr.rowspan]="count_fu(attendant.familiar_unit_id)"
                    style="vertical-align: middle;">{{attendant.number_fu}} </td>
                  <td>{{attendant.name}}</td>
                  <td>{{attendant.surname}}</td>
                  <td>{{attendant.dni}}</td>
                  <td class="d-print-none" >
                    <button type="button" class="btn btn-danger"
                      (click)="showDeleteForm(deleteModal,attendant)"
                      title="Enviar entrades">
                      <fa-icon [icon]="faTrashAlt"></fa-icon>
                    </button>
                  </td>
                  <td *ngIf="attendant.first_fu"
                    [attr.rowspan]="count_fu(attendant.familiar_unit_id)"
                    style="vertical-align: middle;">
                      <ng-container *ngIf="!attendant.edit_mail">
                          {{attendant.mail}}<fa-icon [icon]="faEdit" (click)="attendant.edit_mail = true; saved_mail = attendant.mail" class="ml-1"></fa-icon>
                      </ng-container>
                      <div class="input-group" *ngIf="attendant.edit_mail">
                          <div class="input-group-prepend">
                              <button class="btn btn-danger" type="button" (click)="attendant.edit_mail = false; attendant.mail = saved_mail"><fa-icon [icon]="faTimes" class="ml-1"></fa-icon></button>
                          </div>
                          <input type="email" class="form-control" aria-describedby="email" [(ngModel)]="attendant.mail">
                          <div class="input-group-append">
                              <button class="btn btn-success" type="button" (click)="saveMail(attendant)"><fa-icon [icon]="faCheck" class="ml-1"></fa-icon></button>
                          </div>
                      </div>
                  </td>
                  <td *ngIf="attendant.first_fu"
                    [attr.rowspan]="count_fu(attendant.familiar_unit_id)"
                    style="vertical-align: middle;">{{attendant.phone}}</td>
                  <td *ngIf="attendant.has_attended=='Y'">
                    {{formatDate(attendant.time_attendance)}}
                  </td>
                  <td *ngIf="attendant.has_attended!='Y'"></td>
                  <td *ngIf="attendant.has_attended=='Y'">{{attendant.door_keeper}}</td>
                  <td *ngIf="attendant.has_attended!='Y'"></td>
                  <td class="d-print-none" *ngIf="attendant.first_fu"
                    [attr.rowspan]="count_fu(attendant.familiar_unit_id)"
                    style="vertical-align: middle;">
                      <button type="button" class="btn btn-warning"
                        (click)="sendMail(sendMailModal,attendant)"
                        title="Enviar entrades">
                        <fa-icon [icon]="faEnvelope"></fa-icon>
                      </button>
                  </td>
                  <td class="d-print-none" *ngIf="attendant.first_fu"
                    [attr.rowspan]="count_fu(attendant.familiar_unit_id)"
                    style="vertical-align: middle;">
                      <button type="button" class="btn"
                          style="color:white"
                          (click)="viewPaymentStatus(viewPaymentStatusT,attendant)"
                          [ngStyle]="{'background-color':
                            attendant.payment_status=='C'?'#15D790':
                            (attendant.payment_status=='P'?'rgb(255,196,56)':
                              'rgb(208,77,45)')}" >
                          {{attendant.amount_paid}}€</button>
                  </td>
              </tr>
          </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>

<ng-template #sendMailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Enviar entrades <b>{{attendant_name}}?</b>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Cancel·lar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('SEND')">Enviar</button>
  </div>
</ng-template>

<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Eliminar inscrit <b>{{attendant_name}}?</b>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Cancel·lar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('SEND')">Eliminar</button>
  </div>
</ng-template>

<ng-template #confirmMarkAsPaiedT let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Canviar situació de pagament de <b>{{attendant_name}}?</b>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Cancel·lar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('SEND')">Enviar</button>
  </div>
</ng-template>

<ng-template #viewPaymentStatusT let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Estat del pagament</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <td class="d-print-none">
          <button type="button" class="btn"
              style="color:white"
              (click)="confirmMarkAsPaied(confirmMarkAsPaiedT,selected_attendant)"
              [ngStyle]="{'background-color': selected_attendant.payment_status=='C'?'#15D790':selected_attendant.payment_status=='P'?'rgb(255,196,56)':'rgb(208,77,45)'}" >
              <ng-container *ngIf="selected_attendant.payment_status!='C'">Marcar com pagat</ng-container>
              <ng-container *ngIf="selected_attendant.payment_status=='C'">Marcar com no pagat</ng-container>
          </button>
      </td>
    <app-payment-status [payment_status_list]="selected_attendant.payment_status_list"></app-payment-status>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Tancar</button>
  </div>
</ng-template>

<app-back-button link="/user-season-ticket-list"></app-back-button>
