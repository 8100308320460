import { Component, Input, OnInit } from '@angular/core';
import { Event_session_error_class } from 'src/app/class/Event_session_error_class';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Event_session_class } from 'src/app/class/Event_session_class';

@Component({
  selector: 'app-event-session',
  templateUrl: './event-session.component.html',
  styleUrls: ['./event-session.component.css']
})
export class EventSessionComponent implements OnInit {
  @Input() sessions: Array<Event_session_class>;
  @Input() sessions_errors: Array<Event_session_error_class>
  faTimesCircle = faTimesCircle;
  constructor() { 
    this.sessions = new Array<Event_session_class>();
    this.sessions_errors = new Array<Event_session_error_class>();
  }

  ngOnInit(): void {
  }

  removeSession(list,i){
    list = this.sessions.splice(i,1);
  }

  addSession(){
    this.sessions.push(new Event_session_class());
    this.sessions_errors.push(new Event_session_error_class());
  }

}
