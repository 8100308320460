<header class="bg-white shadow">
    <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl tracking-tight text-stone-900">Nou esdeveniment:<br><strong>{{obj_event.event_name}}</strong></h1>
    </div>
  </header>

  <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <div class="block p-6 bg-lime-50 border border-stone-200 rounded shadow dark:bg-stone-800 dark:border-stone-700 dark:hover:bg-stone-700 mb-8">
            <div class="grid grid-cols-2 gap-4">
                <div class="col-span-2 font-bold"><h2>Informació descriptiva de l'esdeveniment</h2></div>
                <div class="form-group col-span-2">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Nom de l'esdeveniment</label>
                    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.event_name" >
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.event_name">
                        {{error | translate}}
                    </div>
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> Títol de l'acte. També apareix al correu electrònic de les entrades.</p>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Organitzador</label>
                    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.organizers" >
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> Nom de l'organitzador de l'acte. També apareix al correu electrònic de les entrades i al peu de la pàgina de l'esdeveniment.</p>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="event_nameInput">Link organitzador</label>
                    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.organizer_link">
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> Enllaç cap on dirigeix el text 'Organitzador'.</p>
                </div>
                <div class="form-group col-span-2">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="event_nameInput">Notes del registre</label>
                    <textarea class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full h-36 p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.register_notes"></textarea>
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> Les notes del registre apareixen com una informació extra tant davall del preu com al correu electrònic. Acostuma a fer-se anar per indicar si l'entrada inclou alguna cosa extra.</p>
                </div>
                <div class="form-group col-span-2 ">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="event_nameInput">Descripció de l'esdeveniment</label>
                    <ckeditor [editor]="Editor"
                            [config]="Editor_config"
                            [(ngModel)]="obj_event.event_description" class="h-96" ></ckeditor>
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                            </svg> Descripció de l'esdeveniment. Aquest text acompanya la foto de l'acte i serveix per detallar en què consisteix l'esdeveniment. No apareix al correu electrònic de les entrades.</p>
                </div>
                
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="event_nameInput">Localització</label>
                    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.address">
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> Localització de l'esdeveniment. L'emplaçament on tindrà lloc l'esdeveniment.</p>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="event_nameInput">Enllaç de Google Maps</label>
                    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.map_link">
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> Enllaç cap a la localització de l'esdeveniment. Vés a <a class="text-lime-800" target="_blank" href="https://www.google.com/maps/search/">Google Maps</a> per trobar l'enllaç de la localització per ajudar als assistents a arribar.</p>
                </div>

                <div class="form-group col-span-2">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Imatge de portada</label>
                    <input #filechooser type="file" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300-file">
                    <p class="mt-2 text-xs text-stone-400 dark:text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> La Imatge de portada que acompanya l'enllaç públic de l'esdeveniment i que també es present en el correu electrònic de les entrades QR. Recomanem un arxiu JPG o PNG. +acces s'encarrega de baixar la qualitat de la imatge per evitar càrregues innecessàries al servidor</p>
                </div>
            </div>
        </div>
        
        <div class="block p-6 bg-yellow-50 border border-stone-200 rounded shadow dark:bg-stone-800 dark:border-stone-700 dark:hover:bg-stone-700 mb-8">
            <div class="grid grid-cols-2 gap-4">
                
                <div class="col-span-2 font-bold">
                    <h2>Informació sobre les dates i l'aforament</h2>
                </div>
                <div> 
                    <input class="form-check-input" type="checkbox" [(ngModel)]="obj_event.allow_individual_reg"  class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">
                        Permetre Registres individuals
                    </label>
                </div>
                <div class="form-group">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="obj_event.allow_fu_reg">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">
                        Permetre Registres múltiples
                    </label>
                </div>
                <div class="form-group" *ngIf="show_capacity">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Aforament màxim</label>
                    <input type="number" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.capacity">
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.capacity">
                        {{error | translate}}
                    </div>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Nombre màxim d'entrades</label>
                    <input type="number" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.num_tickets">
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.num_tickets">
                        {{error | translate}}
                    </div>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Nombre màxim de persones que formen un registre múltiple</label>
                    <input type="number" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.max_fu_attendants">
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.max_fu_attendants">
                        {{error | translate}}
                    </div>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Dia i hora de l'esdeveniment</label>
                    <input class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" [(ngModel)]="event_date">
                    <owl-date-time #dt></owl-date-time>
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.date">
                        {{error | translate}}
                    </div>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="datepicker2">Dia i hora d'inici de registres</label>
                    <input class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [(ngModel)]="open_date">
                    <owl-date-time #dt1></owl-date-time>
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.open_date">
                        {{error | translate}}
                    </div>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="datepicker3">Dia i hora de fi de registres</label>
                    <input class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [(ngModel)]="close_date">
                    <owl-date-time #dt2></owl-date-time>
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.close_date">
                        {{error | translate}}
                    </div>
                </div>
                <div class="form-group">
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white" for="datepicker4">Dia i hora d'obertura de portes</label>
                    <input class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" [(ngModel)]="door_open_date">
                    <owl-date-time #dt3></owl-date-time>
                    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_error.open_date">
                        {{error | translate}}
                    </div>
                </div>
            </div>
        </div> 

        <div class="block p-6 bg-violet-50 border border-stone-200 rounded shadow dark:bg-stone-800 dark:border-stone-700 dark:hover:bg-stone-700 mb-8">
            <div class="grid grid-cols-2 gap-4">
                <!--
            <div class="col-12">
              <div class="form-group">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="enableZone">
                  <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">
                      Recinte
                  </label>
              </div>
            </div>
            <div class="form-group" *ngIf="enableZone">
                <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Recinte</label>
                <select class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.id_zone">
                    <option value=''>Tria un recinte</option>
                    <option *ngFor="let zone of zones" [value]="zone.id">{{zone.name}}</option>
                </select>
            </div>
            -->
            
            <div class="col-span-2 font-bold">
                <h2>Formulari de preguntes</h2>
            </div>
            <div class="form-group">
                <input class="form-check-input" type="checkbox" [(ngModel)]="obj_event.questions"  class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label class="mb-2 text-sm font-medium text-stone-900 dark:text-white">
                    El formulari ha de contenir preguntes alternatives
                </label>
            </div>
            <ng-container *ngIf="obj_event.questions">
                <div class="col-span-2 grid grid-cols-2 gap-4 rounded border bg-white mb-2 p-4" *ngFor="let question of obj_event.questions;index as i">
                    <div>
                        <div class="mb-4 font-bold">
                            <h4 class="inline-block">Pregunta #{{i}}</h4>
                            
                            <button type="button" class="p-1 rounded text-xs font-normal inline-block ml-4 bg-red-300 hover:bg-red-400" (click)="removeQuestion(obj_event.questions,i)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                              </svg>
                              </button>
                              <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Enunciat de la pregunta:</label>
                              <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="question.text">
                            <br><span class="text-normal">Tipus de pregunta</span>
                        </div>
                        <div class="">
                            <input class="form-check-input" type="radio" value="R" [name]="'question-type'+i" [(ngModel)]="question.type">
                            <label class="inline-block mb-2 ml-2 text-sm font-medium text-stone-900 dark:text-white">
                                Selecció única
                            </label>
                        </div>
                        <div class="">
                            <input class="form-check-input" type="radio" value="C" [name]="'question-type'+i" [(ngModel)]="question.type">
                            <label class="inline-block mb-2 ml-2 text-sm font-medium text-stone-900 dark:text-white">
                                Selecció múltiple
                            </label>
                        </div>
                        <div class="">
                            <input class="form-check-input" type="radio" value="T" [name]="'question-type'+i" [(ngModel)]="question.type">
                            <label class="inline-block mb-2 ml-2 text-sm font-medium text-stone-900 dark:text-white">
                                Quadre de text
                            </label>
                        </div>
                    </div>
                    
                    <div class="col-12" *ngIf="question.type!='T'">
                        <div *ngFor="let response of question.response;index as o;trackBy:trackByFn">
                            <div class="flex mb-2">
                                <div class="flex-none p-1">
                                    <div class="input-group-text">
                                        <input type="checkbox" *ngIf="question.type=='C'" [name]="'response'+i">
                                        <input type="radio" *ngIf="question.type=='R'" [name]="'response'+i">
                                    </div>
                                </div>
                                <input type="text" class="flex-auto bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="question.response[o].text">
                                <div class="flex-none">
                                    
                                    <button type="button" class="p-1 rounded text-xs font-normal inline-block ml-4 bg-red-300 hover:bg-red-400" (click)="removeResponse(question.response,o)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                      </svg>
                                      </button>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="rounded py-2 px-4 bg-violet-200 text-stone-800 text-sm" (click)="addResponse(question.response)">Afegeix selecció</button>
                    </div>
                
                
                </div>
                <button class="rounded py-2 px-4 bg-violet-200 text-stone-800 text-sm w-fit"  (click)="addQuestion()">Afegir una altra pregunta</button>
                </ng-container> 
                </div>
            </div>


            <div class="block p-6 bg-blue-50 border border-stone-200 rounded shadow dark:bg-stone-800 dark:border-stone-700 dark:hover:bg-stone-700 mb-8">
                <div class="grid grid-cols-2 gap-4">
                    <div class="col-span-2 font-bold">
                        <h2>Sessions</h2>
                    </div>
                    <div class="form-group">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="obj_event.has_sessions"  class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label class="mb-2 text-sm font-medium text-stone-900 dark:text-white">
                            L'esdeveniment té sessions
                        </label>
                    </div>
              
            </div>
            <app-event-session *ngIf="obj_event.has_sessions" [sessions]="obj_event.sessions" [sessions_errors]="sessions_errors"></app-event-session>
                    <div class="col-12 text-center" *ngIf="multiple_creator">
                    <div class="form-group">
                        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
                        </ngb-datepicker>
                        <ng-template #t let-date let-focused="focused">
                        <span class="custom-day"
                                [class.focused]="focused"
                                [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                        </ng-template>
                    </div>

                    </div>
                
            
            
        </div>
        <div class="block p-6 bg-red-50 border border-stone-200 rounded shadow dark:bg-stone-800 dark:border-stone-700 dark:hover:bg-stone-700 mb-8">
            <div class="grid grid-cols-2 gap-4">

                <div class="col-span-2 font-bold">
                    <h2>Informació sobre el pagament</h2>
                </div>
                <div class="col-span-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="obj_event.paid"  class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label class="mb-2 text-sm font-medium text-stone-900 dark:text-white">
                        El registre a l'esdeveniment és de pagament
                    </label>
                </div>
            <ng-container *ngIf="obj_event.paid">
            
                <div class="form-group" >
                    <label class="block mb-2 text-sm font-medium text-stone-900 dark:text-white">Preu estàndard (€)</label>
                    <div class="input-group mb-3">
                        <input type="number" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded focus:ring-lime-300 focus:border-lime-300 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-300 dark:focus:border-lime-300" [(ngModel)]="obj_event.price">
                        <div class="input-group-append">
                            
                        </div>
                    </div>
                    <small>Cost de gestió: {{getComision()}}€  |  Preu net per l'organitzador: {{obj_event.price - getComision()}}€</small>
                </div>
                
                <div class="col-span-2" *ngIf="obj_event.paid">
                    <input class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" [(ngModel)]="obj_event.have_alternative_price">
                    <label class="mb-2 text-sm font-medium text-stone-900 dark:text-white">
                        L'esdeveniment té preus alternatius
                    </label>
                </div>
                
                
                <ng-container *ngIf="obj_event.have_alternative_price">
                    <span class="font-bold col-span-2">{{"NOTA: Si s'activen els preus alternatius, no es tindrà en compte el preu estàndard"}}</span>
                    <div class="rounded border bg-white mb-2 p-4">
                    <ng-container *ngFor="let altPrice of obj_event.altPrices; index as i">
                    <app-form-event-alternative-price
                        [altPrice]="altPrice" [altPriceError]="altPricesErrors[i]">
                    </app-form-event-alternative-price>
                    <button type="button" class="py-2 px-4 mr-2 rounded text-xs mt-1 font-normal inline-block  bg-red-300 hover:bg-red-400" (click)="removeFromList(obj_event.altPrices,i)" *ngIf="i>0"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg></button>
                    </ng-container>
                    <button type="button" class="rounded py-2 px-4 bg-violet-200 text-stone-800 text-sm w-fit" (click)="addAltPrice()">Afegir un preu alternatiu</button>
                    </div>
                </ng-container>
                
                <div class="col-span-2" *ngIf="obj_event.paid">
                <div class="form-group">
                    <input class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" [(ngModel)]="obj_event.show_commission" >
                    <label class="mb-2 text-sm font-medium text-stone-900 dark:text-white">
                        Mostrar el cost de gestió en el procés de compra
                    </label>
                </div>
                </div>
            </ng-container>
            </div>
        </div>
        <div class="flex flex-row-reverse">      
             <button type="button" class="bg-lime-200 text-stone-900 px-3 py-2 rounded-md text-sm font-medium hover:bg-lime-300" (click)="save()">Guardar i sortir</button><button type="button" class="bg-violet-200 text-stone-900 px-3 py-2 rounded-md text-sm font-medium hover:bg-violet-300 mr-4" (click)="saveStay()">Guardar</button>
        </div>
</div>
 <app-back-button></app-back-button>

 