
export class User_class{
    apikey: string;
    user_name: string;
    name: string;
    password: string;
    role: string;
    sat_of: string;
    conditions: string;
    information: string;
    multiple_creator: boolean;
    logo: string;
    custom_color: string;
    background: string;
    archived: string;
    tot_events: string;
    tot_users: string;
    creator_role: string;
    creator_user: string;
    custom_url: string;
    commission_free: number;
    commission_low: number;
    commission_high: number;
    has_tpv: boolean;
    RS_VER: string;
    RS_KEY: string;
    RS_FUC: string;
    RS_TER: string;
    RS_URL: string;
    RS_DIV: string;
    RS_TRA: string;
    user_id: string;
    constructor(){
        this.name = '';
        this.user_name = '';
        this.password = '';
        this.conditions = '';
        this.information = '';
        this.commission_free = 0.1;
        this.commission_low = 0.4;
        this.commission_high = 0.7;
        this.has_tpv = false;
        this.RS_VER = 'HMAC_SHA256_V1';
        this.RS_DIV = 'RS_DIV';
        this.RS_TRA = '0';
    }
}
