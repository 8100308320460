<table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col"></th>
      <th scope="col">Data</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let keyRead of keyReads; index as i">
      <th scope="row">{{ i + 1 }}</th>
      <td>
        <fa-icon [icon]="faSignInAlt"
          *ngIf="keyRead.type=='O'"
          style="color:#f5333f"></fa-icon>
        <fa-icon [icon]="faSignOutAlt"
          *ngIf="keyRead.type=='I'"
          style="color:#00ce7c"></fa-icon>
      </td>
      <td>{{ keyRead.read_time }}</td>
      <td>{{ keyRead.door_keeper }}</td>
    </tr>
  </tbody>
</table>
