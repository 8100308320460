import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Event_class } from 'src/app/class/Event_class';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { EventSessionContainerService } from 'src/app/services/event-session-container.service';

@Component({
  selector: 'app-session-select',
  templateUrl: './session-select.component.html',
  styleUrls: ['./session-select.component.css']
})
export class SessionSelectComponent implements OnInit {
  @Input() event: Event_class;
  @Output() ended: EventEmitter<string>;

  constructor(public event_sesion_container: EventSessionContainerService) {
    this.ended = new EventEmitter<string>();
  }

  ngOnInit(): void {
  }

  isLate(dateC){
    dateC = new Date(dateC * 1000);
    let now = Date.now();
    //console.log(this.event.num_tickets - this.event.tot_attendants);
    return now > dateC;
  }

  isOpenS(session: Event_session_class){
    let dateO = this.event.open_date * 1000;
    let dateC = this.event.close_date * 1000;
    let now = Date.now();
    //console.log('now:' + now + ' dateO:' + dateO + ' dateC:' + dateC);
    return now > dateO && now < dateC && this.session_has_tickets(session);
  }

  session_has_tickets(session: Event_session_class){
    if(session.limit_by_fu)
      return session.max_fu - session.reserved_fu > 0;
    else
      return session.tickets - session.reserved_tickets > 0;
  }

  register_session(session){
    this.event_sesion_container.event_session = session;
    this.ended.emit('');
  }

}
