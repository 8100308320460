<div class="container-fluid" style="padding-top: 50px; padding-bottom: 100px;">
  <div class="row">
    <div class="col-6 offset-3">
      <div class="form-group">
          <label>nom</label>
          <input type="text" class="form-control" [(ngModel)]="userSeasonTicket.name">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.name">
              {{error | translate}}
          </div>
      </div>
      <div class="form-group">
          <label for="event_nameInput">descripció</label>
          <ckeditor [editor]="Editor"
                  [config]="Editor_config"
                  [(ngModel)]="userSeasonTicket.description"></ckeditor>
      </div>
      <div class="col-12">
        <label for="event_nameInput">tipus</label>
        <div class="alert alert-secondary">
          <div class="row pb-2">
            <div class="col-12">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="typeRadio" id="typeRadio1" value="U" [(ngModel)]="userSeasonTicket.type" checked>
                <label class="form-check-label" for="typeRadio">
                  Preu únic
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="userSeasonTicket.type=='U'">
            <div class="row ml-2">
              <div class="col-12">
                <div class="form-group ml-2">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="userSeasonTicket.allow_i_unique">
                  <label class="form-check-label">
                      permetre registre individual
                  </label>
                </div>
              </div>
              <div class="col-12 mb-4" *ngIf="userSeasonTicket.allow_i_unique">
                <label>preu individual</label>
                <input type="number" class="form-control" [(ngModel)]="userSeasonTicket.price">
                <small>Cost de gestió: {{getComision(userSeasonTicket.price)}}€  |  Restant: {{getRPrice(userSeasonTicket.price)}}€</small>
                <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.price">
                    {{error | translate}}
                </div>
              </div>
              <div class="col-12">
                <div class="form-group ml-2">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="userSeasonTicket.allow_fu_unique">
                  <label class="form-check-label">
                      permetre unions familiars
                  </label>
                </div>
              </div>
              <div class="col-12" *ngIf="userSeasonTicket.allow_fu_unique">
                <label>preu unio familiar</label>
                <input type="number" class="form-control" [(ngModel)]="userSeasonTicket.price_fu">
                <small>Cost de gestió: {{getComision(userSeasonTicket.price_fu)}}€  |  Restant: {{getRPrice(userSeasonTicket.price_fu)}}€</small>
                <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.price_fu">
                    {{error | translate}}
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-12">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="typeRadio" id="typeRadio2" value="S" [(ngModel)]="userSeasonTicket.type">
                <label class="form-check-label" for="typeRadio">
                  Preu per trams
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="userSeasonTicket.type=='S'">
            <div class="row">
              <div class="col-12 ml-4">
                <div class="form-group ml-2">
                  <input class="form-check-input" type="radio" name="allowfui" value="I" [(ngModel)]="allowfui">
                  <label class="form-check-label">
                      registre individual
                  </label>
                </div>
              </div>
              <div class="col-12 ml-4">
                <div class="form-group ml-2">
                  <input class="form-check-input" type="radio" name="allowfui" value="FU" [(ngModel)]="allowfui">
                  <label class="form-check-label">
                      registre unions familiars
                  </label>
                </div>
              </div>
              <div class="col-12 ml-4 pr-4">
                <div class="form-group pl-2 pr-5">
                  <label>preu individual</label>
                  <input type="number" class="form-control" [(ngModel)]="userSeasonTicket.price_individual">
                  <small>Cost de gestió: {{getComision(userSeasonTicket.price_individual)}}€  |  Restant: {{getRPrice(userSeasonTicket.price_individual)}}€</small>
                  <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.price_individual">
                      {{error | translate}}
                  </div>
                </div>
              </div>
              <div class="col-12 ml-4" *ngIf="allowfui=='FU'">
                <div class="form-group ml-2">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="userSeasonTicket.allow_couple">
                  <label class="form-check-label">
                      preu per parelles
                  </label>
                </div>
              </div>
              <div class="col-12 pl-4 pr-4" *ngIf="allowfui=='FU'&&userSeasonTicket.allow_couple">
                <div class="form-group pl-2 pr-2">
                  <label>preu per parella</label>
                  <input type="number" class="form-control" [(ngModel)]="userSeasonTicket.price_couple">
                  <small>Cost de gestió: {{getComision(userSeasonTicket.price_couple)}}€  |  Restant: {{getRPrice(userSeasonTicket.price_couple)}}€</small>

                  <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.price_couple">
                      {{error | translate}}
                  </div>
                </div>
              </div>
              <div class="col-12 ml-4 pr-4" *ngIf="allowfui=='FU'">
                <div class="form-group pl-2 pr-5">
                  <label>màxim inscrits unió familiar</label>
                  <input type="number" class="form-control" [(ngModel)]="userSeasonTicket.max_fu">
                  <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.max_fu">
                      {{error | translate}}
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let section of sections; let i = index">
                <div class="col-12">
                  <div class="alert alert-light">
                    <div class="row">
                      <div class="col-12">
                        <fa-icon [icon]="faTimesCircle" class="float-right" size="lg" (click)="removeSection(i)"></fa-icon>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>descripció</label>
                          <input type="text" class="form-control" [(ngModel)]="section.description">
                          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of sections_error[i].description">
                              {{error | translate}}
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>preu</label>
                          <input type="text" class="form-control" [(ngModel)]="section.price">
                          <small>Cost de gestió: {{getComision(section.price)}}€  |  Restant: {{getRPrice(section.price)}}€</small>
                          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of sections_error[i].price">
                              {{error | translate}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col-12">
                <button type="button" class="btn btn-warning mt-3" style="width: 100%;" (click)="addSection()">afegir tram</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="form-group">
        <label for="datepicker2">data inici validesa</label>
        <input class="form-control" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [(ngModel)]="start_date">
        <owl-date-time #dt1></owl-date-time>
        <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.start_date">
            {{error | translate}}
        </div>
      </div>
      <div class="form-group">
          <label for="datepicker3">data fi validesa</label>
          <input class="form-control" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [(ngModel)]="end_date">
          <owl-date-time #dt2></owl-date-time>
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.end_date">
              {{error | translate}}
          </div>
      </div>
      <div class="form-group">
        <label for="datepicker2">data inici venta</label>
        <input class="form-control" [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3" [(ngModel)]="start_sell">
        <owl-date-time #dt3></owl-date-time>
        <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.start_sell">
            {{error | translate}}
        </div>
      </div>
      <div class="form-group">
          <label for="datepicker3">data fi venta</label>
          <input class="form-control" [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" [(ngModel)]="end_sell">
          <owl-date-time #dt4></owl-date-time>
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of userSeasonTicketError.end_sell">
              {{error | translate}}
          </div>
      </div>
      <button type="button" class="btn btn-primary mt-3" style="width: 100%;" (click)="save()">Guardar</button>
    </div>
  </div>
</div>
<app-back-button link="/user-season-ticket-list"></app-back-button>
