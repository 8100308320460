<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <button (click)="lang='cat'">CAT</button><button (click)="lang='es'">ES</button>
        </div>
        <ng-container *ngIf="lang=='cat'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>POLÍTICA DE PRIVACITAT</h3>
                </div>
                <h4>1. INFORMACIÓ PER A L'USUARI</h4>
                <h5>
                    Qui és el responsable del tractament de les teves dades personals?
                </h5>
                <p>
                    <b>PIQTURE NEW MEDIA S.L</b> és el RESPONSABLE del tractament de les dades personals de l’USUARI i l’informa que aquestes dades es tractaran de conformitat amb el que disposa el Reglament (UE) 2016/679, de 27 d’abril (GDPR), i la Llei Orgànica 3/2018, de 5 de desembre (LOPDGDD).
                </p>
                <h5>
                    Perquè tractem les teves dades personals?
                </h5>
                <p>
                    Per mantenir una relació comercial amb l’usuari. Les operacions previstes per fer el tractament són:
                </p>
                <ul>
                    <li>
                        Remesa de comunicacions comercials publicitàries per e-mail, fax, SMS, MMS, xarxes socials o qualsevol altre mitjà electrònic o físic, present o futur, que permeti efectuar comunicacions comercials. Aquestes comunicacions s’han de fer per mitjà del RESPONSABLE i estan relacionades amb els seus productes i serveis, o amb els seus col·laboradors o proveïdors, amb els quals hagi arribat a algun acord de promoció. En aquest cas, els tercers no tindran mai accés a les dades personals.
                    </li>
                    <li>
                        Efectuar estudis de mercat i anàlisis estadístiques.
                    </li>
                    <li>
                        Tramitar encàrrecs de peticions, donar resposta a les consultes o qualsevol tipus de petició que faci l’USUARI a través de qualsevol de les formes de contacte que es posen a la seva disposició a la pàgina web del RESPONSABLE.
                    </li>
                    <li>
                        Enviar el butlletí informatiu online, sobre novetats, ofertes i promocions en la nostra activitat.
                    </li>
                </ul>
                <h5>
                    Per quin motiu podem tractar les teves dades personals?
                </h5>
                <p>
                    Perquè el tractament està legitimat per l’article 6 del GDPR de la següent manera:
                </p>
                <ul>
                    <li>
                        Amb el consentiment de l’USUARI: enviament de comunicacions comercials i del butlletí informatiu.
                    </li>
                    <li>
                        Per interès legítim del RESPONSABLE: efectuar estudis de mercat, anàlisis estadístiques, etc. i tramitar encàrrecs, sol·licituds, etc. a petició de l’USUARI.
                    </li>
                </ul>
                <h5>
                    Durant quant de temps guardarem les teves dades personals?
                </h5>
                <p>
                    Es conservaran durant no més temps del necessari per mantenir la finalitat del tractament o mentre hi hagi prescripcions legals que en dictaminin la custòdia i quan ja no sigui necessari per a això, se suprimiran amb mesures de seguretat adequades per garantir l’anonimització de les dades o la seva destrucció total.
                </p>
                <h5>
                    A qui facilitem les teves dades personals?
                </h5>
                <p>
                    No està prevista cap comunicació de dades personals a tercers excepte, si fos necessari per al desenvolupament i execució de les finalitats del tractament, als nostres proveïdors de serveis relacionats amb comunicacions, amb els quals el RESPONSABLE ha formalitzat els contractes de confidencialitat i d’encarregat de tractament exigits per la normativa vigent de privacitat.
                </p>
                <h5>
                    Quins són els teus drets?
                </h5>
                <p>
                    Els drets que té l’USUARI són:
                </p>
                <ul>
                    <li>
                        Dret a retirar el consentiment en qualsevol moment.
                    </li>
                    <li>
                        Dret d’accés, rectificació, portabilitat i supressió de les seves dades i de limitació o oposició al seu tractament.
                    </li>
                    <li>
                        Dret a presentar una reclamació davant l’autoritat de control (www.aepd.es) si considera que el tractament no s’ajusta a la normativa vigent.
                    </li>
                </ul>
                <h5>
                    Dades de contacte per exercir els seus drets:
                </h5>
                <p>
                    PIQTURE NEW MEDIA S.L. C/ ENRIC ARDERIU, 1, Altell, 25005 (Lleida). E-mail: acces@mesacces.com
                </p>
            </div>
            <div class="col-12">
                <h4>2. CARÀCTER OBLIGATORI O FACULTATIU DE LA INFORMACIÓ FACILITADA PER L’USUARI</h4>
                <p>
                    Els USUARIS, per mitjà de les caselles corresponents i la introducció de dades en els camps, marcats amb l’asterisc (*) en el formulari de contacte o presentats en formularis de descàrrega, accepten expressament i de manera lliure i inequívoca, que les seves dades són necessàries per a l’atenció de la seva petició, per part del prestador, i que per tant la inclusió de les dades en els camps restants és voluntària. L’USUARI garanteix que les dades personals facilitades al RESPONSABLE són verídiques i es fa responsable de comunicar-ne qualsevol modificació.<br>
                    El RESPONSABLE informa que totes les dades sol·licitades a través del lloc web són obligatòries, ja que són necessàries per a la prestació d’un servei òptim a l’USUARI. En cas que no es facilitin totes les dades, no es garanteix que la informació i serveis facilitats s’ajustin completament a les seves necessitats.
                </p>
            </div>
            <div class="col-12">
                <h4>3. MESURES DE SEGURETAT</h4>
                <p>
                    Que, de conformitat amb el que estableixen les normatives vigents en protecció de dades personals, el RESPONSABLE està complint amb totes les disposicions de les normatives GDPR i LOPDGDD per al tractament de les dades personals de la seva responsabilitat, i manifestament amb els principis descrits a l’article 5 del GDPR, pels quals es tracten de manera lícita, lleial i transparent en relació amb l’interessat i adequades, pertinents i limitades al que és necessari en relació amb les finalitats per a les quals es tracten.<br>
                    El RESPONSABLE garanteix que ha implementat polítiques tècniques i organitzatives apropiades per aplicar les mesures de seguretat que estableixen el GDPR i la LOPDGDD per tal de protegir els drets i llibertats dels USUARIS i els ha comunicat la informació adequada perquè puguin exercir-los.<br>Per a més informació sobre les garanties de privacitat, pots dirigir-te al RESPONSABLE a través de PIQTURE NEW MEDIA S.L. C/ ENRIC ARDERIU, 1, Altell, 25005 (Lleida). E-mail: acces@mesacces.com
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="lang=='es'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>POLÍTICA DE PRIVACIDAD</h3>
                </div>
                <h4>1. INFORMACIÓN AL USUARIO</h4>
                <h5>
                    ¿Quién es el responsable del tratamiento de tus datos personales?
                </h5>
                <p>
                    <b>PIQTURE NEW MEDIA S.L</b> es el RESPONSABLE del tratamiento de los datos personales del USUARIO y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD).
                </p>
                <h5>
                    ¿Para qué tratamos tus datos personales?
                </h5>
                <p>
                    Para mantener una relación comercial con el usuario. Las operaciones previstas para realizar el tratamiento son:
                </p>
                <ul>
                    <li>
                        Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus productos y servicios, o de sus colaboradores o proveedores, con los que este haya alcanzado algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos personales.
                    </li>
                    <li>
                        Realizar estudios de mercado y análisis estadísticos.
                    </li>
                    <li>
                        Tramitar encargos, solicitudes, dar respuesta a las consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las formas de contacto que se ponen a su disposición en la página web del RESPONSABLE.
                    </li>
                    <li>
                        Remitir el boletín informativo online, sobre novedades, ofertas y promociones en nuestra actividad.
                    </li>
                </ul>
                <h5>
                    ¿Por qué motivo podemos tratar tus datos personales?
                </h5>
                <p>
                    Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:
                </p>
                <ul>
                    <li>
                        Con el consentimiento del USUARIO: remisión de comunicaciones comerciales y del boletín informativo.
                    </li>
                    <li>
                        Por interés legítimo del RESPONSABLE: realizar estudios de mercado, análisis estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del USUARIO.
                    </li>
                </ul>
                <h5>
                    ¿Durante cuánto tiempo guardaremos tus datos personales?
                </h5>
                <p>
                    Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen su custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos.
                </p>
                <h5>
                    ¿A quién facilitamos tus datos personales?
                </h5>
                <p>
                    No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario para el desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios relacionados con comunicaciones, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos por la normativa vigente de privacidad.
                </p>
                <h5>
                    ¿Cuáles son tus derechos?
                </h5>
                <p>
                    Los derechos que asisten al USUARIO son:
                </p>
                <ul>
                    <li>
                        Derecho a retirar el consentimiento en cualquier momento.
                    </li>
                    <li>
                        Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.
                    </li>
                    <li>
                        Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.
                    </li>
                </ul>
                <h5>
                    Datos de contacto para ejercer sus derechos:
                </h5>
                <p>
                    PIQTURE NEW MEDIA S.L. C/ ENRIC ARDERIU, 1, Altell, 25005 (Lleida). E-mail: acces@mesacces.com
                </p>
            </div>
            <div class="col-12">
                <h4>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL USUARIO</h4>
                <p>
                    Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en los campos, marcados con un asterisco (*) en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma libre e inequívoca, que sus datos son necesarios para atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos restantes. El USUARIO garantiza que los datos personales facilitados al RESPONSABLE son veraces y se hace responsable de comunicar cualquier modificación de los mismos.<br>
                    El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios, ya que son necesarios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados a sus necesidades.
                </p>
            </div>
            <div class="col-12">
                <h4>3. MEDIDAS DE SEGURIDAD</h4>
                <p>
                    Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el RESPONSABLE está cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente en relación con el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados.<br>
                    El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el GDPR y la LOPDGDD con el fin de proteger los derechos y libertades de los USUARIOS y les ha comunicado la información adecuada para que puedan ejercerlos.<br>Para más información sobre las garantías de privacidad, puedes dirigirte al RESPONSABLE a través de PIQTURE NEW MEDIA S.L. C/ ENRIC ARDERIU, 1, Altell, 25005 (Lleida). E-mail: acces@mesacces.com
                </p>
            </div>
        </ng-container>
    </div>
</div>

