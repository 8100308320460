<div class="form-group">
    <label>nom de la sessió</label>
    <input type="text" class="form-control" [(ngModel)]="event_session.name" >
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_session_error.name">
        {{error | translate}}
    </div>
</div>
<div class="form-group">
    <label for="event_nameInput">descripció de la sessió</label>
    <ckeditor [editor]="Editor" 
            [config]="Editor_config"
            [(ngModel)]="event_session.description"></ckeditor>
</div>
<div class="form-group">
    <label>nº entrades</label>
    <input type="number" class="form-control" [(ngModel)]="event_session.tickets">
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_session_error.tickets">
        {{error | translate}}
    </div>
</div>
<div class="form-group">
    <input class="form-check-input" type="checkbox" [(ngModel)]="event_session.limit_by_fu">
    <label class="form-check-label">
        Limitar per unions familiars
    </label>
</div>
<div class="form-group" *ngIf="event_session.limit_by_fu">
    <label>limit unitats familiars</label>
    <input type="number" class="form-control" [(ngModel)]="event_session.max_fu">
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_session_error.max_fu">
        {{error | translate}}
    </div>
</div>
<div class="form-group">
    <label for="datepicker2">data/hora inici</label>
    <input class="form-control" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [(ngModel)]="event_session.open_date_d">
    <owl-date-time #dt1></owl-date-time>
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_session_error.open_date">
        {{error | translate}}
    </div>
</div>
<div class="form-group" *ngIf="false">
    <label for="datepicker3">data/hora fi</label>
    <input class="form-control" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [(ngModel)]="event_session.close_date_d">
    <owl-date-time #dt2></owl-date-time>
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of event_session_error.close_date">
        {{error | translate}}
    </div>
</div>