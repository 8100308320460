import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Event_class } from 'src/app/class/Event_class';
import { faExternalLinkAlt, faInfoCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faClock, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Title, DomSanitizer } from '@angular/platform-browser';
import * as Constants from 'src/app/modules/constants/constants.module';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { EventSessionContainerService } from 'src/app/services/event-session-container.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.css']
})
export class ViewEventComponent implements OnInit {
  @HostListener('window:scroll', ['$event']) onScroll($event: Event): void {
    this.nav_background = this.getYPosition($event) != 0;
  }
  lang;
  selectedLanguage = 'ca';
  faCalendar = faCalendar;
  faClock = faClock;
  faInfoCircle = faInfoCircle;
  faMapMarkerAlt = faMapMarkerAlt;
  faExternalLinkAlt = faExternalLinkAlt;
  faQuestionCircle = faQuestionCircle;
  faArrowLeft = faArrowLeft;
  selected_session: Event_session_class;
  modechanged: boolean;
  supp_mail: string;
  loaded: boolean;
  private id_event: string;
  public event: Event_class;
  ent_url: string;
  ent_name: string;
  mode: string;
  user_id: string;
  nav_background: boolean;
  over_link: boolean;
  constructor(private route: ActivatedRoute,
              public router: Router,
              private modalService: NgbModal,
              public sanitizer: DomSanitizer,
              private titleService: Title,
              public event_sesion_container: EventSessionContainerService,
              private backend: BackendServiceService,
              private translateService: TranslateService) {
                this.event = new Event_class();
                this.loaded = false;
                this.mode = 'info';
                this.modechanged = false;
                this.ent_url = Constants.ENT_URL;
                this.ent_name = Constants.ENT_NAME;
                this.supp_mail = Constants.SUPP_MAIL;
                
              }

  ngOnInit(): void {
    this.id_event = this.route.snapshot.paramMap.get("id_event");
    
  this.lang = this.route.snapshot.paramMap.get("lang") || 'ca';
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    if(this.route.snapshot.paramMap.get("userseasson")=="US"){
      this.mode='uSeassonTicket'
    }

    let path = this.route.snapshot.url[0].path;
    if(path == 'pay_ok' || path == 'error_pagament'){
      this.loaded = true;

    }
    this.loadUserConf();
    this.translateService.setDefaultLang(this.lang);
                this.translateService.use(this.lang);
  }

  selectLanguage(lang: string) {
    this.translateService.use(lang);
}

  loadUserConf(){
    this.backend.getUserConf(this.id_event).subscribe(data => {
      if(data){
        this.backend.comission_free = data['comission_free'];
        this.backend.commission_low = data['commission_low'];
        this.backend.commission_high = data['commission_high'];
        this.loadEvent();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  loadEvent(){
    this.backend.getEvent(this.id_event).subscribe(data => {
      //console.log(data);
      if(data){
        this.event = Object.assign(new Event_class(),data);
        this.titleService.setTitle(this.event.event_name)
        this.loaded = true;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  register_session(session){
    this.mode = 'register';
    this.modechanged = true;
    this.event_sesion_container.event_session = session;
    //console.log(this.event_sesion_container.event_session);
  }

  register(){
    //this.router.navigate(['/registerattendant/' + this.id_event]);
    this.event.btn_over = false;
    this.event.btn_over2 = false;
    this.event.btn_over3 = false;
    this.mode = 'register';
    this.modechanged = true;
    //console.log("register");
  }

  info(){
    this.mode = 'info';
    this.event.btn_over4 = true;
  }

  isOpenS(session: Event_session_class){
    let dateO = this.event.open_date * 1000;
    let dateC = this.event.close_date * 1000;
    let now = Date.now();
    //console.log('now:' + now + ' dateO:' + dateO + ' dateC:' + dateC);
    return now > dateO && now < dateC && this.session_has_tickets(session);
  }

  session_has_tickets(session: Event_session_class){
    if(session.limit_by_fu)
      return session.max_fu - session.reserved_fu > 0;
    else
      return session.tickets - session.reserved_tickets > 0;
  }

  isOpen(dateO,dateC){
    dateO = new Date(dateO * 1000);
    dateC = new Date(dateC * 1000);
    let now = Date.now();
    //console.log(now > dateO && now < dateC && this.hasTickets());

    return now > dateO && now < dateC && this.hasTickets();
  }

  isEarly(dateO){
    dateO = new Date(dateO * 1000);
    let now = Date.now();
    return now < dateO;
  }

  isLate(dateC){
    dateC = new Date(dateC * 1000);
    let now = Date.now();
    //console.log(this.event.num_tickets - this.event.tot_attendants);
    return now > dateC;
  }

  hasTickets(){
    return this.event.num_tickets - this.event.tot_attendants > 0;
  }

  openHelpModal(content){
    this.modalService.open(content, { scrollable: true, size: 'xl' });
  }

  dateFormat(date){
    date = new Date(date * 1000);
    let result = date.getDate();
    switch(date.getMonth()+1){
      case 1:
        if(this.lang==='es'){result += ' de enero';}else{result += ' de gener';}
      break;
      case 2:
        result += ' de febrer';
      break;
      case 3:
        if(this.lang==='es'){result += ' de marzo';}else{result += ' de març';}
      break;
      case 4:
        if(this.lang==='es'){result += ' de abril';}else{result += ' d\'abril';}
      break;
      case 5:
        if(this.lang==='es'){result += ' de mayo';}else{result += ' de maig';}
      break;
      case 6:
        if(this.lang==='es'){result += ' de junio';}else{result += ' de juny';}
      break;
      case 7:
        if(this.lang==='es'){result += ' de julio';}else{result += ' de juliol';}
      break;
      case 8:
        if(this.lang==='es'){result += ' de agosto';}else{result += ' d\'agost';}
      break;
      case 9:
        if(this.lang==='es'){result += ' de septiembre';}else{result += ' de setembre';}
      break;
      case 10:
        if(this.lang==='es'){result += ' de octubre';}else{result += ' d\'octubre';}
      break;
      case 11:
        if(this.lang==='es'){result += ' de noviembre';}else{result += ' de novembre';}
      break;
      case 12:
        if(this.lang==='es'){result += ' de diciembre';}else{result += ' de desembre';}
      break;
    }
    result += ' ' + date.getFullYear();
    return result;
  }


  timeFormat(date){
    let fDate = new Date(date * 1000);
    return fDate.getHours() + ':' + (fDate.getMinutes()<10?'0':'') + fDate.getMinutes();
  }

  getYPosition(e: Event): number {
    return (e.target as Element).children[0].scrollTop;
  }

  getComision(price){
    if(price <= 0)
      return 0;
    if(price <5)
      return this.backend.commission_low;
    else
      return this.backend.commission_high;
  }
  ordenaPreu(eventos: Event_alternative_price_class[]): number {
    let precioMasBajo = Number.MAX_SAFE_INTEGER;
    for (let evento of eventos) {
      if (evento.price < precioMasBajo) {
        precioMasBajo = evento.price;
      }
    }
    return precioMasBajo;
  }
  

}
