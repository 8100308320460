<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <button (click)="lang='cat'">CAT</button><button (click)="lang='es'">ES</button>
        </div>
        <ng-container *ngIf="lang=='cat'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>PANELL DE CONFIGURACIÓ DE COOKIES</h3>
                </div>
                <p>
                    Des d’aquest panell pot configurar les cookies que el lloc web pot instal·lar en el seu navegador, excepte les cookies tècniques o funcionals que són necessàries per a la navegació i la utilització de les diferents opcions o serveis que s’ofereixen.<br>
                    Les cookies seleccionades indiquen que l’usuari autoritza la instal·lació en el seu navegador i el tractament de dades amb les condicions esmentades en la Política de cookies.<br>
                    L’usuari pot canviar la selecció segons si desitja acceptar o rebutjar la instal·lació de cookies.
                </p>
            </div>
            <div class="col-12 text-center">
                <h5>COOKIES CONTROLADES PER L’EDITOR</h5>
            </div>
            <div class="col-12 text-center">
                <h5>Analitiques</h5>
                <button type="button" class="btn btn-secondary mt-1 mb-1">Desactivar</button>
            </div>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Propietat</th>
                        <th scope="col">Cookie</th>
                        <th scope="col">Finalitat</th>
                        <th scope="col">Plaç</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga</th>
                        <td>ID s'utilitza per identificar els usuaris</td>
                        <td>en 2 anys</td>
                    </tr>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga_&lt;container-id&gt;</th>
                        <td>Conserva l'estat de la sessió.</td>
                        <td>en 2 anys</td>
                    </tr>
                </tbody>
            </table>
            <div class="col-12 text-center">
                <h5>COOKIES DE TERCERS</h5>
            </div>
            <p>
                Els serveis de tercers són aliens al control de l’editor. Els proveïdors poden modificar en qualsevol moment les seves condicions de servei, finalitat i ús de les cookies, etc.
            </p>
            <h5>Proveïdors externs d’aquest lloc web:</h5>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Editor</th>
                        <th scope="col">Política de privacitat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Google Analytics</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4>COM GESTIONAR LES COOKIES DES DEL NAVEGADOR</h4>
            <div class="col-12">
                <h5>Eliminar les cookies del dispositiu </h5>
                <p>
                    Les cookies que ja es troben en un dispositiu es poden eliminar esborrant l’historial del navegador, de manera que se suprimeixen les cookies de tots els llocs web visitats.<br>No obstant això, també es pot perdre part de la informació desada (per exemple, les dades d’inici de sessió o les preferències de lloc web).
                </p>
            </div>
            <div class="col-12">
                <h5>Gestionar les cookies específiques del lloc </h5>
                <p>
                    Per tenir un control més precís de les cookies específiques de cada lloc, els usuaris poden ajustar la seva configuració de privacitat i cookies al navegador.
                </p>
            </div>
            <div class="col-12">
                <h5>Bloquejar les cookies </h5>
                <p>
                    Encara que la majoria dels navegadors moderns es poden configurar per evitar que s’instal·lin cookies en els dispositius, això pot obligar a ajustar manualment determinades preferències cada vegada que es visiti un lloc o pàgina. A més, alguns serveis i característiques poden no funcionar correctament (per exemple, els inicis de sessió amb perfil).
                </p>
            </div>
            <h4>COM ELIMINAR LES COOKIES DELS NAVEGADORS MÉS COMUNS</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <td>Chrome</td>
                        <td>
                            <a href="http://support.google.com/chrome/answer/95647?hl=es">
                                http://support.google.com/chrome/answer/95647?hl=es
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Internet Explorer. Versió 11</td>
                        <td>
                            <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                                https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Firefox. Versió 65.0.1</td>
                        <td>
                            <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies">
                                https://www.mozilla.org/es-ES/privacy/websites/#cookies
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Safari Versió 5.1</td>
                        <td>
                            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                                https://support.apple.com/es-es/guide/safari/sfri11471/mac
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Opera</td>
                        <td>
                            <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">
                                https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="lang=='es'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>PANEL DE CONFIGURACIÓN DE COOKIES</h3>
                </div>
                <p>
                    Desde este panel podrá configurar las cookies que el sitio web puede instalar en su navegador, excepto las cookies técnicas o funcionales que son necesarias para la navegación y la utilización de las diferentes opciones o servicios que se ofrecen.<br>
                    Las cookies seleccionadas indican que el usuario autoriza la instalación en su navegador y el tratamiento de datos bajo las condiciones reflejadas en la Política de cookies.<br>
                    El usuario puede marcar o desmarcar el selector según se desee aceptar o rechazar la instalación de cookies.
                </p>
            </div>
            <div class="col-12 text-center">
                <h5>COOKIES CONTROLADAS POR EL EDITOR</h5>
            </div>
            <div class="col-12 text-center">
                <h5>Analíticas</h5>
                <button type="button" class="btn btn-secondary mt-1 mb-1">Desactivar</button>
            </div>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Propiedad</th>
                        <th scope="col">Cookie</th>
                        <th scope="col">Finalidad</th>
                        <th scope="col">Plazo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga</th>
                        <td>ID utiliza para identificar a los usuarios</td>
                        <td>en 2 años</td>
                    </tr>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga_&lt;container-id&gt;</th>
                        <td>Conserva el estdo de la sesión.</td>
                        <td>en 2 años</td>
                    </tr>
                </tbody>
            </table>
            <div class="col-12 text-center">
                <h5>COOKIES DE TERCEROS</h5>
            </div>
            <p>
                Los servicios de terceros son ajenos al control del editor. Los proveedores pueden modificar en todo momento sus condiciones de servicio, finalidad y utilización de las cookies, etc.
            </p>
            <h5>Proveedores externos de este sitio web:</h5>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Editor</th>
                        <th scope="col">Política de privacidad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Google Analytics</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4>CÓMO GESTIONAR LAS COOKIES DESDE EL NAVEGADOR</h4><br>
            <div class="col-12">
                <h5>Eliminar las cookies del dispositivo </h5>
                <p>
                    Las cookies que ya están en un dispositivo se pueden eliminar borrando el historial del navegador, con lo que se suprimen las cookies de todos los sitios web visitados.<br>Sin embargo, también se puede perder parte de la información guardada (por ejemplo, los datos de inicio de sesión o las preferencias de sitio web).
                </p>
            </div>
            <div class="col-12">
                <h5>Gestionar las cookies específicas del sitio </h5>
                <p>
                    Para tener un control más preciso de las cookies específicas de cada sitio, los usuarios pueden ajustar su configuración de privacidad y cookies en el navegador.
                </p>
            </div>
            <div class="col-12">
                <h5>Bloquear las cookies </h5>
                <p>
                    Aunque la mayoría de los navegadores modernos se pueden configurar para evitar que se instalen cookies en los dispositivos, eso puede obligar al ajuste manual de determinadas preferencias cada vez que se visite un sitio o página. Además, algunos servicios y características pueden no funcionar correctamente (por ejemplo, los inicios de sesión con perfil).
                </p>
            </div>
            <h4>CÓMO ELIMINAR LAS COOKIES DE LOS NAVEGADORES MÁS COMUNES</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <td>Chrome</td>
                        <td>
                            <a href="http://support.google.com/chrome/answer/95647?hl=es">
                                http://support.google.com/chrome/answer/95647?hl=es
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Internet Explorer. Versió 11</td>
                        <td>
                            <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                                https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Firefox. Versió 65.0.1</td>
                        <td>
                            <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies">
                                https://www.mozilla.org/es-ES/privacy/websites/#cookies
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Safari Versió 5.1</td>
                        <td>
                            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                                https://support.apple.com/es-es/guide/safari/sfri11471/mac
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Opera</td>
                        <td>
                            <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">
                                https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>

