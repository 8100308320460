<select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm mr-4 rounded focus:ring-lime-500 focus:border-lime-500 w-fit inline-block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500"
  [(ngModel)]="selectedType" *ngIf="event.register_familiar_union!='T'">
    <option value=' '>{{'Tipus d\'entrada' | translate }}</option>
    <option value='I'>{{'Individual' | translate }}</option>
    <option value='F'>{{'Múltiple' | translate }}</option>
    <option value='Z' *ngIf="event.id_zone&&false">llotja</option>
</select>
<button type="button" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded w-fit inline-block" (click)="confirm()">Confirmar</button>
<select class="form-control" [(ngModel)]="selectedType" *ngIf="event.register_familiar_union=='T'">
    <option value=' '>tipus d'entrada</option>
    <option value='F'>taula</option>
</select>
<p class="my-3 text-xs" *ngIf="selectedType=='I'">
    {{'Aquest tipus d’entrada es refereix a una entrada QR individual i intransferible.' | translate }}
</p>
<p class="my-3 text-xs" *ngIf="selectedType=='F'&&event.register_familiar_union!='T'">
    {{'Aquest tipus d’entrada refereix a un màxim' | translate }} {{event.max_fu_attendants}} {{'persones que rebran les entrades QR individuals i intransferibles en un mateix correu electrònic.' | translate }}
</p>
<p class="my-3 text-xs" *ngIf="selectedType=='F'&&event.register_familiar_union=='T'">
    Taula de màxim {{event.max_fu_attendants}} persones que rebran les entrades QR individuals i intransferibles en un mateix correu electrònic.
</p>
<p class="my-3 text-xs" *ngIf="selectedType=='Z'">
    Aquest tipus d’entrada refereix a la reserva de un mínim de 3 entrades QR i un màxim de 4 entrades QR, totes elles individuals i intransferibles.
</p>

