<div class="container">
    <div class="row">
        <div class="col-sm-6 offset-sm-3 col-xs-12">
            <ng-container *ngIf="deleted">
                <h2 class="mt-3">Reserva anul·lada correctament, gracies.</h2>
            </ng-container>
            <ng-container *ngIf="!deleted">
                <h2 class="mt-3">Confirmes que vols anul·lar la teva reserva per a l'esdeveniment {{event_name}}?</h2>
                <button type="button" class="btn btn-danger mt-3" style="width: 100%;" (click)="confirm()">Si, anul·la la reserva</button>
                <button type="button" class="btn btn-secondary mt-1" style="width: 100%;" [routerLink]="['/esdeveniment/'+id_event]">No, no vull anul·lar-la</button>
            </ng-container>
            
        </div>
    </div>
</div>