<div class="container">
    <div class="col-8 offset-2 text-center">
        <button type="button" class="btn btn-light mb-4 mt-4 d-print-none" style="width: 162px;" (click)="print()">
            <fa-icon [icon]="faPrint"></fa-icon> Imprimeix
        </button>
        <table style="text-align:center;" width="100%" cellpadding="0" cellspacing="0">
            <h1>{{event.event_name}}</h1>
            <br><br><br>
            Benvolgut/da,<br><br>
        
            S’ha completat la reserva de la teva entrada correctament per al esdeveniment {{event.event_name}} que tindrà 
            lloc a {{event.address}} en data i hora: {{dateFormat(event.date)}} - {{timeFormat(event.date)}}.<br>
            Tingues en compte que l'obertura de portes serà a les {{timeFormat(event.door_open_date)}}.
             <br><br>
            Recorda que hauràs d’ensenyar el QR que segueix en el moment d’entrada al recinte, ja sigui des del mòbil o imprimint aquest correu.
            <br><br>
            Gràcies per la teva col·laboració.<br>
            OA2 Produccions
            <ng-container *ngIf="mode=='I'">
                <br><br>Dades inscripció: <br>
                <p>{{attendant.name}} {{attendant.surname}}</p>
                <p>{{attendant.dni}}</p>
                <p>{{attendant.phone}}</p>
                <br><br>
                <div style="width:100%;text-align:center;">
                    <img [src]="ENT_URL + 'qr/' + attendant.entry_key + '.png'" style="max-width:100%;" />
                </div>
            </ng-container>
            <ng-container *ngIf="mode=='F'">
                <ng-container *ngFor="let attendantl of attendants">
                    <br><br>Dades inscripció: <br>
                    <p>{{attendantl.name}} {{attendantl.surname}}</p>
                    <p>{{attendantl.dni}}</p>
                    <br><br>
                    <div style="width:100%;text-align:center;">
                        <img [src]="ENT_URL + 'qr/' + attendantl.entry_key + '.png'" style="max-width:100%;" />
                    </div>
                </ng-container>
            </ng-container>
        </table>
    </div>
</div>