import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { Attendant_error_class } from 'src/app/class/Attendant_error_class';
import { Question_class } from 'src/app/class/Question_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Event_class } from 'src/app/class/Event_class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { faTimesCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import * as Constants from 'src/app/modules/constants/constants.module';
import { EventContainerService } from 'src/app/services/event-container.service';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import { EventSessionContainerService } from 'src/app/services/event-session-container.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';
import { Fields_class } from 'src/app/class/Fields_class';

@Component({
  selector: 'app-register-familiar-union-attendants',
  templateUrl: './register-familiar-union-attendants.component.html',
  styleUrls: ['./register-familiar-union-attendants.component.css']
})
export class RegisterFamiliarUnionAttendantsComponent implements OnInit {
  @Input() questions: Array<Question_class>;
  @Input() event: Event_class;
  @Output() ended: EventEmitter<string>;
  @ViewChild('content') content: ElementRef;
  @ViewChild('alonefu') alonefu: ElementRef;
  @Input() seassonTicket: boolean = false;
  @Input() userSeassonTicket: User_season_ticket_class;
  @Input() fields: Fields_class = new Fields_class();
  min_fu: number = 1;
  alone: boolean;
  faTimesCircle = faTimesCircle;
  faInfoCircle = faInfoCircle;
  faEuroSign = faEuroSign;
  faQuestionCircle = faQuestionCircle;
  max_attendants: number;
  attendants: Array<Attendant_class>;
  attendants_errors: Array<Attendant_error_class>;
  mail: string;
  mail2: string;
  conditionsAccepted: boolean;
  allow:boolean;
  attendant_errors: Attendant_error_class;
  mail_errors: Array<string>;
  phone:string;
  phone_errors: Array<string>;
  general_error: Array<string>;
  gConditionsAccepted: boolean;
  pConditionsAccepted: boolean;
  allow_newsletter: boolean;
  conditions: string;
  checkAttendantP: boolean;
  idAttendantP: string;
  ent_url: string;
  manual: boolean;
  send_mail: boolean;
  show_entry: boolean;
  has_paied: boolean;
  waitServer: boolean = false;
  errorSaving: boolean = false;
  supp_mail: string = Constants.SUPP_MAIL;
  url: string = Constants.ENT_URL;
  key: string = '';
  attendantType: string = 'FU';
  constructor(public backend: BackendServiceService,
              public modalService: NgbModal,
              public eventContainer: EventContainerService,
              public attendantContainer: AttendantContainerService,
              public event_sesion_container: EventSessionContainerService,
              private localLogin: LocalLoginService,
              public router: Router) {
    this.initialitze();
    if(this.backend.manual != undefined)
      this.manual = this.backend.manual;
    else
      this.manual = false;
    if(this.manual){
      this.send_mail = false;
      this.show_entry = true;
      this.has_paied = true;
    }
  }

  ngOnInit(): void {
    this.max_attendants = this.event.max_fu_attendants;
  }

  ngOnChanges(){
    if(this.seassonTicket){
      this.setUserSeassonTicket();
    }
    this.initialitze();
  }

  initialitze(){
    this.ended = new EventEmitter<string>();
    this.attendants = new Array<Attendant_class>();
    this.attendants_errors = new Array<Attendant_error_class>();
    for(let i = 0; i < this.min_fu; i++){
      this.attendants.push(new Attendant_class());
      this.attendants_errors.push(new Attendant_error_class());

    }
    this.mail_errors = new Array<string>();
    this.phone_errors = new Array<string>();
    this.general_error = new Array<string>();
    this.attendant_errors = new Attendant_error_class();
    this.conditionsAccepted = false;
    this.checkAttendantP = false;
    this.pConditionsAccepted = false;
    this.allow = true;
    this.alone = false;
    this.idAttendantP = '';
    this.checkAttendantP = false;
    this.ent_url = Constants.ENT_URL;
  }

  setUserSeassonTicket(){
    let ust: User_season_ticket_class = this.userSeassonTicket;
    if(ust.allow_couple){
      this.min_fu = 2;
    } else {
      this.min_fu = 1;
    }
    this.max_attendants = ust.max_fu;

  }

  addAttendant(){
    //console.log(this.event.available_tickets);
    //console.log(this.backend.role);
    if(this.attendants.length<this.max_attendants && (this.backend.role != undefined || this.attendants.length < this.event.available_tickets)){
      this.attendants.push(new Attendant_class());
      this.attendants_errors.push(new Attendant_error_class());
    }

  }

  pay(rsVersion,rsParams,rsSignature,rsLink) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = rsLink;

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_SignatureVersion';
    mapInput.setAttribute("value", rsVersion);
    mapForm.appendChild(mapInput);

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_MerchantParameters';
    mapInput.setAttribute("value", rsParams);
    mapForm.appendChild(mapInput);

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_Signature';
    mapInput.setAttribute("value", rsSignature);
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  validate(): boolean{
    let result = true;

    this.mail_errors = new Array<string>();
    this.phone_errors = new Array<string>();
    this.attendant_errors = new Attendant_error_class();

    if(!(this.backend.inPerson || this.manual)){
      if(this.isVoid(this.mail)){
        this.phone_errors.push('Es obligatori introduir un correu.');
        result = false;
      }
      if(!this.isMail(this.mail)){
        this.phone_errors.push('El format del correu no es vàlid');
        result = false;
      }
      if(this.mail!=this.mail2){
        this.mail_errors.push('No coincideixen els dos camps de correu.');
        result = false;
      }
    } else {
      if(!this.isMail(this.mail)){
        this.mail = '-';
      }
    }
    if(this.fields.needValidation('phone')){
      if(this.isVoid(this.phone)){
        this.phone_errors.push('Es obligatori introduir un telèfon.');
        result = false;
      }
    } else {
      this.phone = '-';
    }

    if(this.event.conditions!=undefined && this.event.conditions!='')
      if(!this.conditionsAccepted){
        this.attendant_errors.condition.push('Es obligatori acceptar les condicions.');
        result = false;
      }

    if(this.event.general_conditions!=undefined && this.event.general_conditions!='')
      if(!this.gConditionsAccepted){
        this.attendant_errors.general_condition.push('Es obligatori acceptar les condicions.');
        result = false;
      }

    if(!this.pConditionsAccepted){
      this.attendant_errors.pConditions.push('Es obligatori acceptar les condicions de privacitat.');
      result = false;
    }

    for(let i = 0; i < this.attendants.length; i++){
      let attendant: Attendant_class = this.attendants[i];
      let error: Attendant_error_class = new Attendant_error_class();
      if(this.isVoid(attendant.name)){
        error.name.push('Es obligatori introduir un nom.');
        result = false;
      }
      if(this.isVoid(attendant.surname)){
        error.surname.push('Es obligatori introduir un cognom.');
        result = false;
      }
      if(this.fields.needValidation('dni')){
        if(!attendant.nodni){
          if(this.isVoid(attendant.dni)){
            error.dni.push('Es obligatori introduir un DNI.');
            result = false;
          } else if(!this.isValidDNI(attendant.dni)){
            error.dni.push('Es obligatori introduir un DNI vàlid. El format correcte es 12345678A o X1234567A.');
            result = false;
          }
        } else {
          attendant.dni = '-';
        }
      } else {
        attendant.dni = '-';
      }

      this.attendants_errors[i] = error;
    }

    if(this.attendants.length < 2 && result && !this.alone){
      this.alonefuAdvice();
      result = false;
    }

    //console.log(this.attendants_errors);
    return result;
  }

  removeAttendant(list, i){
    list = list.splice(i,1);
  }

  isValidDNI(text:string){
    if(text.length!=9)
      return false;
    let lastChar = "TRWAGMYFPDXBNJZSQVHLCKE";
    let nieValues = {"X":"0","Y":"1","Z":"2"};
    if(nieValues[text[0]]!=undefined)
      text = nieValues[text[0]] + text.substr(1);
    return lastChar[parseInt(text.substr(0,text.length-1))%23] == text.substr(text.length-1);
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  isMail(text){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
  }

  confirm(){
    this.errorSaving = false;
    if(this.validate()){
      this.allow = false;
      for(let i = 0; i < this.attendants.length; i++){
        this.attendants[i].inPerson = this.backend.inPerson;
        this.attendants[i].manual = this.manual;
        if(this.event.has_sessions&&!this.userSeassonTicket)
          this.attendants[i].id_session = this.event_sesion_container.event_session.id;
        if(this.manual){
          this.attendants[i].send_mail = this.send_mail;
          this.attendants[i].has_paied = this.has_paied;
        }
      }
      this.waitServer = true;
      //console.log(this.attendants);
      if(this.seassonTicket){
        this.saveUserSeasonFu();
      } else {
        if(this.manual){
          this.saveFuAdmin();
        } else {
          this.saveFu();
        }
      }
    }
  }

  saveFu(){
    this.backend.saveFamiliarUnion(this.event.id, this.mail, this.phone,
      this.allow_newsletter, this.attendants).subscribe(data => {
      this.waitServer = false;
      //console.log(data);
      if(data['ok']){
        this.key = data['key'];
        if(this.backend.inPerson)
          this.router.navigate(['/list/'+this.event.id]);
        else
          if(this.event.paid&&this.getPrice()>0){
            let rs = data['payment_info'];
            let rsVersion = rs['rsVersion'];
            let rsParams = rs['rsParams'];
            let rsSignature = rs['rsSignature'];
            let rsLink = rs['rsLink'];
            this.pay(rsVersion,rsParams,rsSignature,rsLink);
            this.checkAttendantP = true;
            this.idAttendantP = data['id'];
          }
          else
            this.open();
        this.ended = new EventEmitter<string>();
        this.attendants = new Array<Attendant_class>();
        this.attendants.push(new Attendant_class());
        this.attendants_errors = new Array<Attendant_error_class>();
        this.attendants_errors.push(new Attendant_error_class());
        this.mail_errors = new Array<string>();
        this.phone_errors = new Array<string>();
        this.mail = "";
        this.mail2 = "";
        this.phone = "";
        this.allow = true;
        this.alone = false;
      } else{
        //Controlled error
        if(data['error']=='dni_exists'){
          this.attendants = data['attendants'];
          for(let i = 0; i < this.attendants.length; i++){
            if(this.attendants[i].error == 'dni_exists'){
              this.attendants_errors[i].dni.push('El dni ja es troba registrat.');
            }
          }
        } else if(data['error'] == 'sold_out'){
          this.general_error.push('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
          //console.log('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
        } else if(data['error'] == 'sold_out_fu'){
          this.general_error.push('Ho sentim, no queden reserves disponibles.');
        } else {
          this.errorSaving = true;
        }
        this.allow = true;
      }
    }, error => {
      this.errorSaving = true;
      this.waitServer = false;
      console.log(error);
    });
  }

  saveFuAdmin(){
    this.backend.saveFamiliarUnionAdmin(this.event.id, this.mail, this.phone,
      this.allow_newsletter, this.attendants).subscribe(data => {
      this.waitServer = false;
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data['ok']){
        this.key = data['key'];
        for(let i=0;i<this.attendants.length;i++){
          this.attendants[i].entry_key = data['attendants'][i]['key'];
        }
        if(this.show_entry)
          this.goToTicket();
        else{
          this.attendants = new Array<Attendant_class>();
          this.attendants.push(new Attendant_class());
          this.attendants_errors = new Array<Attendant_error_class>();
          this.attendants_errors.push(new Attendant_error_class());
          this.mail_errors = new Array<string>();
          this.phone_errors = new Array<string>();
          this.mail = "";
          this.mail2 = "";
          this.phone = "";
          this.allow = true;
          this.alone = false;
        }
      } else{
        //Controlled error
        if(data['error']=='dni_exists'){
          this.attendants = data['attendants'];
          for(let i = 0; i < this.attendants.length; i++){
            if(this.attendants[i].error == 'dni_exists'){
              this.attendants_errors[i].dni.push('El dni ja es troba registrat.');
            }
          }
        } else if(data['error'] == 'sold_out'){
          this.general_error.push('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
          console.log('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
        } else {
          this.errorSaving = true;
        }
        this.allow = true;
      }
    }, error => {
      this.errorSaving = true;
      this.waitServer = false;
      console.log(error);
    });
  }

  saveUserSeasonFu(){
    this.attendants.forEach(attendant=>{
      attendant.mail = this.mail;
      attendant.phone = this.phone;
      attendant.selectedUserSeasonTicket = this.userSeassonTicket.id;
    });
    this.backend.saveUserSeasonTicketFu(this.attendants).subscribe(data => {
      this.waitServer = false;
      //console.log(data);
      if(data['ok']){
        if(this.backend.inPerson)
          this.router.navigate(['/list/'+this.event.id]);
        else
          if(this.event.paid){
            let rs = data['payment_info'];
            let rsVersion = rs['rsVersion'];
            let rsParams = rs['rsParams'];
            let rsSignature = rs['rsSignature'];
            let rsLink = rs['rsLink'];
            this.pay(rsVersion,rsParams,rsSignature,rsLink);
            this.checkAttendantP = true;
            this.idAttendantP = data['id'];
          }
          else
            this.open();
        this.ended = new EventEmitter<string>();
        this.attendants = new Array<Attendant_class>();
        this.attendants.push(new Attendant_class());
        this.attendants_errors = new Array<Attendant_error_class>();
        this.attendants_errors.push(new Attendant_error_class());
        this.mail_errors = new Array<string>();
        this.phone_errors = new Array<string>();
        this.mail = "";
        this.mail2 = "";
        this.phone = "";
        this.allow = true;
        this.alone = false;
      } else{
        //Controlled error
        if(data['error']=='dni_exists'){
          this.attendants = data['attendants'];
          for(let i = 0; i < this.attendants.length; i++){
            if(this.attendants[i].error == 'dni_exists'){
              this.attendants_errors[i].dni.push('El dni ja es troba registrat.');
            }
          }
        } else if(data['error'] == 'sold_out'){
          this.general_error.push('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
          //console.log('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
        } else if(data['error'] == 'sold_out_fu'){
          this.general_error.push('Ho sentim, no queden reserves disponibles.');
        } else {
          this.errorSaving = true;
        }
        this.allow = true;
      }
    }, error => {
      this.errorSaving = true;
      this.waitServer = false;
      console.log(error);
    });
  }

  goToTicket(){
    this.eventContainer.event = this.event;
    this.attendantContainer.multiple = true;
    this.attendantContainer.attendants = this.attendants;
    this.router.navigate(['/view-tickets']);
  }

  open() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.ended.emit('');
    }, (reason) => {
      this.ended.emit('');
    });
  }

  alonefuAdvice() {
    this.modalService.open(this.alonefu, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='OK')
        this.alone = true;
        this.confirm();
    }, (reason) => {
    });
  }

  showConditions(content, conditions) {
    this.conditions = conditions;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
    }, (reason) => {

    });
  }

  getPrice(){
    if(this.seassonTicket){
      return this.getUsersSeassonPrice();
    } else{
      return this.getTicketPrice();
    }
  }

  getTicketPrice(){
    let price = 0;
    this.attendants.forEach(attendant => {
      price += this.getAttendantTicketPrice(attendant)*1;
    });
    return price;
  }

  getAttendantTicketPrice(attendant){
    if(attendant.selectedAltPrice == ' '){
      return this.event.price*1;
    } else {
      let price = 0;
      this.event.altPrices.forEach(altPrice => {
        if(altPrice.id == attendant.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  getCommission(){
    if(this.seassonTicket){
      return this.getManagementCost();
    } else{
      return this.getManagementCostTicket();
    }
  }

  getManagementCostTicket(){
    let cost = 0;
    this.attendants.forEach(attendant => {
      let price = this.getAttendantTicketPrice(attendant);
      cost += this.getAttendantManagementCost(price)*1;
    });
    return cost;
  }

  getUsersSeassonPrice(){
    let total: number = 0;
    this.attendants.forEach((attendant, index) => {
      if(this.userSeassonTicket.allow_couple&&index==0){
        total += this.userSeassonTicket.price_couple * 1;
      } else if(!this.userSeassonTicket.allow_couple||index>1) {
        total += this.getAttendantPrice(attendant) * 1;
      }
    });
    return total;
  }

  getAttendantPrice(attendant: Attendant_class){
    let price: number = this.event.price;
    if(attendant.selectedSection!=' '){
      let existSelected = false;
      this.userSeassonTicket.sections.forEach(
        userSeassonTicket => {
          if(userSeassonTicket.id == attendant.selectedSection){
            price = userSeassonTicket.price;
            existSelected = true;
          }
        }
      );
      if(!existSelected){
        attendant.selectedSection = ' ';
      }
    } else{
      price =  this.userSeassonTicket.price_individual;
    }
    return price
  }

  getManagementCost(){
    let total: number = 0;
    this.attendants.forEach((attendant, index) => {
      let price: number;
      if(this.userSeassonTicket.allow_couple&&index==0){
        price = this.userSeassonTicket.price_couple * 1;
        //console.log(price);
        total += this.getAttendantManagementCost(price) * 1;
      } else if(!this.userSeassonTicket.allow_couple||index>1) {
        price = this.getAttendantPrice(attendant) * 1;
        total += this.getAttendantManagementCost(price) * 1;
      }
    });
    return total;
  }

  getAttendantManagementCost(price){
    if(price <= 0)
      return 0;
    if(price <=5)
      return this.backend.commission_low;
    else
      return this.backend.commission_high;
  }
}
