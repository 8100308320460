<div class="my-4">
  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descripció del preu alternatiu</label>
  <input type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500" [(ngModel)]="altPrice.description">
  <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of altPriceError.description">
      {{error | translate}}
  </div>

  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Preu (€)</label>
  <input type="number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm mb-2 rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500" [(ngModel)]="altPrice.price">
  <div class="alert alert-danger mt-1 mb-2" role="alert" *ngFor="let error of altPriceError.price">
      {{error | translate}}
  </div>
  <hr class="my-3">
</div>
