import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { faCalendar, faClock, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope, faList, faEuroSign, faClock as faClockSolid, faUserClock, faPrint, faSyncAlt, faTicketAlt, faUserCheck, faUserTimes, faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-user-seasson-ticket-attendants',
  templateUrl: './list-user-seasson-ticket-attendants.component.html',
  styleUrls: ['./list-user-seasson-ticket-attendants.component.css']
})
export class ListUserSeassonTicketAttendantsComponent implements OnInit {
  idUserSeassonTicket: string;
  userSeassonAttendants: Array<Attendant_class>;
  attendant_name: string;
  selected_attendant: Attendant_class;
  hasFUAttendants: boolean;
  summary: Array<number>;
  saved_mail: string;
  faCalendar = faCalendar;
  faClock = faClock;
  faPrint = faPrint;
  faSyncAlt = faSyncAlt;
  faTrashAlt = faTrashAlt;
  faEnvelope = faEnvelope;
  faList = faList;
  faUserClock = faUserClock;
  faClockSolid = faClockSolid;
  faTicketAlt = faTicketAlt;
  faUserCheck = faUserCheck;
  faUserTimes =faUserTimes;
  faEdit = faEdit;
  faCheck = faCheck;
  faTimes = faTimes;
  faEuroSign = faEuroSign;
  constructor(public backend: BackendServiceService,
              public localLogin: LocalLoginService,
              public modalService: NgbModal,
              public route: ActivatedRoute,
              public router: Router) {
                this.userSeassonAttendants = new Array<Attendant_class>();
                if(this.backend.apikey==undefined){
                  if(this.localLogin.existsData()){
                    this.localLogin.restore_to_service();
                  } else {
                    this.router.navigateByUrl("/");
                  }
                }
  }

  ngOnInit(): void {
    this.idUserSeassonTicket = this.route.snapshot.paramMap.get("id_usta");
    this.getUserSeassonAttendants();
  }

  getUserSeassonAttendants(){
    this.backend.getUserSeassonAttendants(this.idUserSeassonTicket).subscribe(data => {
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.userSeassonAttendants = Object.assign(new Array<Attendant_class>(), data);
        this.setNum();
        this.fuSummary();
        //console.log(this.userSeassonAttendants);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  formatDate(date: string){
    if(date==undefined)
      return '';
    date = date.replace(' ','T');
    let dateF = new Date(date);
    return dateF.getDate() + '/' + (dateF.getMonth()+1) + '/' + dateF.getFullYear() + ' ' + dateF.getHours() + ':' + (dateF.getMinutes()<10?'0':'') + dateF.getMinutes();
  }

  count_fu(fu_id: string){
    return this.userSeassonAttendants.filter(ele => ele.familiar_unit_id == fu_id).length
  }

  sendMail(content, attendant) {
    this.attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='SEND')
        this.sendMailConfirm(attendant);
    }, (reason) => {

    });
  }

  viewPaymentStatus(content, attendant) {
    this.selected_attendant = attendant;
    //console.log(attendant);
    this.modalService.open(content, {size:'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  confirmMarkAsPaied(content, attendant:Attendant_class) {
    this.attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='SEND'){
        this.mark_as_paied(attendant.id);
        this.selected_attendant.payment_status = this.selected_attendant.payment_status!='C'?'C':'';
      }

    }, (reason) => {

    });
  }

  mark_as_paied(id: string){
    this.backend.markAsPaiedUST(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUserSeassonAttendants();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  showDeleteForm(content, attendant: Attendant_class){
    this.attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='SEND')
        this.deleteAttendantConfirm(attendant.id);
    }, (reason) => {

    });
  }

  deleteAttendantConfirm(id){
    this.backend.deleteUstAttendant(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUserSeassonAttendants();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  sendMailConfirm(attendant: Attendant_class){
    this.backend.sendMailUST(attendant).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
  }

  sortSurname(){
    let tmpArray: Array<Attendant_class> = new Array<Attendant_class>();
    this.userSeassonAttendants.forEach((att: Attendant_class)=>{
      if(att.familiar_unit_id == undefined || att.familiar_unit_id == '' || att.first_fu){
        tmpArray.push(att);
      }
    });
    tmpArray.sort((v1: any, v2: any) => v1['surname'] < v2['surname'] ? -1 : v1['surname'] > v2['surname'] ? 1 : 0);
    let sorted: Array<Attendant_class> = new Array<Attendant_class>();
    tmpArray.forEach((att: Attendant_class)=>{
      if(att.familiar_unit_id == undefined || att.familiar_unit_id == ''){
        sorted.push(att);
      } else {
        this.userSeassonAttendants.forEach((att2: Attendant_class)=>{
          if(att2.familiar_unit_id == att.familiar_unit_id){
            sorted.push(att2);
          }
        });
      }
    });
    this.userSeassonAttendants = sorted;
    this.setNum();
  }

  setNum(){
    var count = 1;
    var count2 = 1;
    var lastFU = "";
    var color1 = '#ddd';
    var color2 = '#eee';
    var color = color1;
    var number_fu = 1;
    for(let i = 0; i < this.userSeassonAttendants.length; i++){
      if(this.userSeassonAttendants[i].familiar_unit_id==undefined)
        this.userSeassonAttendants[i].num = count++;
      else{
        if(lastFU!=this.userSeassonAttendants[i].familiar_unit_id){
          color = color==color1?color2:color1;
          this.userSeassonAttendants[i].first_fu = true;
          this.userSeassonAttendants[i].number_fu = number_fu;
          number_fu++;
        } else {
          this.userSeassonAttendants[i].first_fu = false;
        }
        this.userSeassonAttendants[i].color = color;
        this.userSeassonAttendants[i].num = count2++;
        lastFU = this.userSeassonAttendants[i].familiar_unit_id;
      }
    }
    //console.log(this.userSeassonAttendants);
  }

  fuSummary(){
    var lastFU = "";
    var tot = 0;
    this.hasFUAttendants = false;
    this.summary = new Array<number>();
    let amount_paid = 0;
    //console.log(this.userSeassonAttendants);
    for(let i = 0; i < this.userSeassonAttendants.length; i++){
      if(this.userSeassonAttendants[i].familiar_unit_id!=''){
        this.hasFUAttendants = true;
        if(lastFU!=this.userSeassonAttendants[i].familiar_unit_id 
          || i == this.userSeassonAttendants.length-1){
            if(i == this.userSeassonAttendants.length-1){
              amount_paid += this.userSeassonAttendants[i].amount_paid * 1;
            }
          if(this.summary[tot]==undefined)
            this.summary[tot]=0;
          this.summary[tot]++;
          tot = 0;
          this.userSeassonAttendants.forEach((attendant)=>{
            if(attendant.familiar_unit_id==lastFU){
              attendant.amount_paid = amount_paid;
            }
          });
          amount_paid = 0;
        }
        amount_paid += this.userSeassonAttendants[i].amount_paid * 1;
        tot++;
        lastFU = this.userSeassonAttendants[i].familiar_unit_id;
      } else {
        tot = 0;
      }
    }
    if(this.summary[tot]==undefined)
      this.summary[tot]=0;
    this.summary[tot]++;

    //console.log(this.summary);
  }

  saveMail(attendant){
    this.backend.modifyUstMail(attendant.id,attendant.mail, attendant.familiar_unit_id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          attendant.edit_mail = false;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }
}
