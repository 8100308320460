import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Event_class } from 'src/app/class/Event_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-delete-attendant',
  templateUrl: './delete-attendant.component.html',
  styleUrls: ['./delete-attendant.component.css']
})
export class DeleteAttendantComponent implements OnInit {
  ticket: string;
  event_name: string;
  id_event: string;
  deleted: boolean;
  constructor(public route: ActivatedRoute,
              public backend: BackendServiceService,
              private localLogin: LocalLoginService,
              public router: Router) {
                this.deleted = false;
               }

  ngOnInit(): void {
    this.ticket = this.route.snapshot.paramMap.get("ticket");
    this.id_event = this.route.snapshot.paramMap.get("id_event");
    this.event_name = this.route.snapshot.paramMap.get("event_name");
  }

  confirm(){
    this.backend.deleteAttendantByAttendant(this.ticket).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          this.deleted = true;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

}
