<nav class="bg-lime-200">
  <div class="mx-auto">
    <div class="flex h-16 items-center justify-between">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <img class="h-16" src="../../../assets/LOGO.svg" alt="+acces">
        </div>
        <div class="hidden md:block">
          <div class="ml-10 flex items-baseline space-x-4">

            <a routerLink="/user-list" class="bg-white text-stone-900 px-3 py-2 rounded-md text-sm font-medium hover:text-stone-400" aria-current="page">Usuaris</a>

            <a routerLink="/resum" class="text-stone-300 hover:bg-lime-400 hover:text-stone-900 px-3 py-2 rounded-md text-sm font-medium">Comissions</a>

            <a routerLink="/statistics" class="text-stone-300 hover:bg-lime-400 hover:text-stone-900 px-3 py-2 rounded-md text-sm font-medium">Estadístiques</a>
          </div>
        </div>
      </div>
      <div class="hidden md:block px-4">
        <div class="ml-4 flex items-center md:ml-6">
          <!--<button type="button" class="rounded-full bg-stone-800 p-1 text-stone-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-stone-800">
            <span class="sr-only">View notifications</span>
            Heroicon name: outline/bell 
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
          </button>-->

          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div>
              <button type="button" (click)="toggleMenu()"  class="flex max-w-xs items-center rounded-full bg-stone-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-stone-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full" [ngStyle]="{'background-color': user.accent_color}">
                  <span class="font-medium text-white dark:text-gray-300">{{inicial | titlecase}}</span>
              </div>
              </button>
            </div>

            <div *ngIf="menuTop" class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <div class="px-4 py-3 text-sm text-gray-900 dark:text-white border-b">
                <div class="font-bold">
                {{user.name}}
                </div>
              </div> <!-- Active: "bg-stone-100", Not Active: "" -->
              <a routerLink="/conf" class="block px-4 py-2 text-sm text-stone-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Perfil d'Usuari</a>

              <a routerLink="/conf" class="block px-4 py-2 text-sm text-stone-700" role="menuitem" tabindex="-1" id="user-menu-item-1">Configuració</a>

              <a (click)="logout()" class="block px-4 py-2 text-sm text-stone-700 cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-2">Tancar sessió</a>
            </div>
          </div>
        </div>
      </div>
      <div class="-mr-2 flex md:hidden px-4">
        <!-- Mobile menu button -->
        <button type="button" (click)="toggleMMenu()" class="inline-flex items-center justify-center rounded-md bg-stone-800 p-2 text-stone-400 hover:bg-stone-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-stone-800" aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          
          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div *ngIf="menuMovil" id="mobile-menu">
    <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">

      <a routerLink="/user-list" class="bg-white text-stone-900 block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Usuaris i esdeveniments</a>

      <a routerLink="/resum" class="text-stone-300 hover:bg-lime-400 hover:text-stone-900 block px-3 py-2 rounded-md text-base font-medium">Comissions</a>

      <a routerLink="/statistics" class="text-stone-300 hover:bg-lime-400 hover:text-stone-900 block px-3 py-2 rounded-md text-base font-medium">Estadístiques</a>
    </div>
    <div class="border-t border-stone-700 pt-4 pb-3">
      <div class="flex items-center px-5">
        <div class="flex-shrink-0">
          <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full" [ngStyle]="{'background-color': user.accent_color}">
            <span class="font-medium text-gray-600 dark:text-gray-300">{{inicial | titlecase}}</span>
        </div>
        </div>
        <div class="ml-3">
          <div class="text-base font-medium leading-none text-stone-900">{{user.name}}</div>
        </div>
        <button type="button" class="ml-auto flex-shrink-0 rounded-full bg-stone-800 p-1 text-stone-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-stone-800">
          <span class="sr-only">View notifications</span>
          <!-- Heroicon name: outline/bell -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
        </button>
      </div>
      <div class="mt-3 space-y-1 px-2">
        <a routerLink="/conf" class="block rounded-md px-3 py-2 text-base font-medium text-stone-400 hover:bg-lime-400 hover:text-stone-900">Perfil d'Usuari</a>

        <a routerLink="/conf" class="block rounded-md px-3 py-2 text-base font-medium text-stone-400 hover:bg-lime-400 hover:text-stone-900">Configuració</a>

        <a (click)="logout()" class="block rounded-md px-3 py-2 text-base font-medium text-stone-400 hover:bg-lime-400 hover:text-stone-900">Tancar sessió</a>
      </div>
    </div>
  </div>
</nav>



      
    