<ng-container *ngIf="paymentDone">
    <div class="full-screen text-center mt-5 pl-3 pr-3">
        <fa-icon [icon]="faExclamationCircle" style="font-size: 40px; color: #eb8f34;"></fa-icon>
        <div class="container mt-3">
            <div class="col-12">
                <h4>Estàs intentant fer un pagament d’una entrada però ens consta que aquesta entrada ja està pagada. 
                    Si creus que no és així, contacta’ns a <a [href]="'mailto:'+supp_mail">{{supp_mail}}</a>.</h4>
            </div>
        </div>
    </div>
</ng-container>
<app-payment-wait *ngIf="!paymentDone" [idAttendant]="idAttendantP" [check]="checkAttendantP"></app-payment-wait>