import { Component, OnInit } from '@angular/core';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { Event_class } from 'src/app/class/Event_class';
import { EventContainerService } from 'src/app/services/event-container.service';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import * as Constants from 'src/app/modules/constants/constants.module';
import { ActivatedRoute, Router } from '@angular/router';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-view-tickets',
  templateUrl: './view-tickets.component.html',
  styleUrls: ['./view-tickets.component.css']
})
export class ViewTicketsComponent implements OnInit {
  attendant: Attendant_class;
  event: Event_class;
  attendants: Array<Attendant_class>;
  mode: string;
  ENT_URL: string;
  key: string;
  type: string;
  faPrint = faPrint;

  constructor(public eventContainer: EventContainerService,
              public backend: BackendServiceService,
              public router: Router,
              private route: ActivatedRoute,
              private localLogin: LocalLoginService,
              public attendantContainer: AttendantContainerService) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.ENT_URL = Constants.ENT_URL;
    this.mode = this.route.snapshot.paramMap.get("mode");
    this.key = this.route.snapshot.paramMap.get("key");
    let path = this.route.snapshot.url[0].path;
    if(path == 'veure-entrada'){
      this.type = 'E';
    } else if(path == 'veure-abonament'){
      this.type = 'UST';
    } else {
      this.type = 'P';
    }

    if(this.type == 'E'){
      this.getEvent();
      this.getAttendants();
    }

    if(this.type == 'UST'){
      this.getUstAttendants();
    }

    if(this.type == 'P'){
      this.event = this.eventContainer.event;
      if(this.attendantContainer.multiple){
        this.attendants = this.attendantContainer.attendants;
        this.mode = 'F';
      } else {
        this.attendant = this.attendantContainer.attendant;
        this.mode = 'I';
      }
    }
  }

  ngOnInit(): void {
  }

  print(){
    window.print();
  }

  dateFormat(date){
    date = new Date(date * 1000);
    let result = date.getDate();
    switch(date.getMonth()+1){
      case 1:
        result += ' de gener';
      break;
      case 2:
        result += ' de febrer';
      break;
      case 3:
        result += ' de març';
      break;
      case 4:
        result += ' d\'abril';
      break;
      case 5:
        result += ' de maig';
      break;
      case 6:
        result += ' de juny';
      break;
      case 7:
        result += ' de juliol';
      break;
      case 8:
        result += ' d\'agost';
      break;

      case 9:
        result += ' de setembre';
      break;
      case 10:
        result += ' d\'octubre';
      break;
      case 11:
        result += ' de novembre';
      break;
      case 12:
        result += ' de desembre';
      break;
    }
    result += ' ' + date.getFullYear();
    return result;

  }

  timeFormat(date){
    let fDate = new Date(date * 1000);
    return fDate.getHours() + ':' + (fDate.getMinutes()<10?'0':'') + fDate.getMinutes();
  }

  getEvent(){
    this.backend.getEventByAttendantKey(this.key).subscribe(data => {
      //console.log(data);
      if(data){
        this.event = Object.assign(new Event_class(),data);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getAttendants(){
    if(this.mode == 'I'){
      this.getAttendant();
    } else {
      this.getFUAttendants();
    }
  }

  getUstAttendants(){
    if(this.mode == 'I'){
      this.getUstAttendant();
    } else {
      this.getUstFUAttendants();
    }
  }

  getAttendant(){

  }

  getFUAttendants(){

  }

  getUstAttendant(){

  }

  getUstFUAttendants(){

  }
}
