import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent implements OnInit {
  @Input() link: string;
  faArrowLeft = faArrowLeft;
  constructor(public router: Router, public location: Location) { }

  ngOnInit(): void {
  }
  goTo(){
    if(this.link!=undefined&&this.link!='')
      this.router.navigateByUrl(this.link);
    else 
      this.location.back();
  }
}
