import { Component, OnInit, NgZone, Input } from '@angular/core';
import { Subzone_class } from 'src/app/class/Subzone_class';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Event_class } from 'src/app/class/Event_class';
import { Question_class } from 'src/app/class/Question_class';

@Component({
  selector: 'app-register-zone-attendants',
  templateUrl: './register-zone-attendants.component.html',
  styleUrls: ['./register-zone-attendants.component.css']
})
export class RegisterZoneAttendantsComponent implements OnInit {
  @Input() event: Event_class;
  @Input() questions: Question_class;
  subzones: Array<Subzone_class>;
  selectedSubzone: any;
  attendants: Array<Attendant_class>;
  constructor(public backend: BackendServiceService) {
    this.subzones = new Array<Subzone_class>();
    this.selectedSubzone = ' ';
    this.attendants = new Array<Attendant_class>();


  }
  ngOnInit(): void {}

  ngAfterContentInit(){
    this.getZones();
  }

  changedSubzone(){
    this.attendants = new Array<Attendant_class>();
    for(let i = 0; i < this.selectedSubzone.num_seats; i++){
      this.attendants.push(new Attendant_class());
    }
  }

  getZones(){
    this.backend.getSubzones(this.event.id_zone).subscribe(data => {
      //console.log(data);
      if(data){
        this.subzones = Object.assign(new Array, data);
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  confirm(){
    //console.log(this.attendants);
    this.backend.saveZoneAttendants(this.event.id, this.selectedSubzone.id,this.attendants).subscribe(data => {
      //console.log(data);
      if(data['ok']){

      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }
}
