<div class="container-fluid">
  <div class="row mt-2">
    <div class="col-12 d-none d-print-block">
      <img src="/assets/LOGO.svg" style="max-width: 300px;">
    </div>
    <div class="col-12">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" value="M" [(ngModel)]="filterType">
        <label class="form-check-label" for="inlineRadio1">Per mes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" value="R" [(ngModel)]="filterType">
        <label class="form-check-label" for="inlineRadio2">Entre dates</label>
      </div>
    </div>
    <div class="form-group ml-3 d-print-none" *ngIf="backend.role == 'R'">
      <label for="inputState">Usuari</label>
      <select id="inputState" class="form-control" [(ngModel)]="user_name" (change)="getYearsWithEvents()">
        <ng-container *ngFor="let user of users">
          <option *ngIf="user.role == 'A'" [value]="user.user_name">{{user.name}}</option>
        </ng-container>
      </select>
    </div>
    <ng-container *ngIf="filterType=='R'">
      <div class="form-group ml-3 print-none">
        <label>data inici</label>
        <input class="form-control" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" [(ngModel)]="startDate">
        <owl-date-time #dt></owl-date-time>
      </div>
      <div class="form-group ml-3 print-none">
        <label>data fi</label>
        <input class="form-control" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [(ngModel)]="endDate">
        <owl-date-time #dt1></owl-date-time>
      </div>
    </ng-container>
    <ng-container *ngIf="filterType=='M'">
      <div class="form-group col-md-2 d-print-none">
        <label for="inputState">Any</label>
        <select id="inputState" class="form-control" [(ngModel)]="year" (change)="getMonthsWithEvents()">
          <option *ngFor="let year1 of years" [value]="year1">{{year1}}</option>
        </select>
      </div>
      <div class="form-group col-md-2 d-print-none">
        <label for="inputState">Mes</label>
        <select id="inputState" class="form-control" [(ngModel)]="month">
          <option *ngFor="let month1 of months" [value]="month1">{{month1}}</option>
        </select>
      </div>
    </ng-container>
    
    
    <div class="form-group col-md-4 d-print-none">
      <input type="button" class="btn btn-primary mt-4" value="Mostrar" (click)="getSummary()">
      <button type="button" class="btn btn-light mt-4 ml-2" (click)="print()"><fa-icon [icon]="faPrint"></fa-icon> Imprimeix</button>
      <button type="button" class="btn btn-light mt-4 ml-2" (click)="downloadCsv()"><fa-icon [icon]="faFileCsv"></fa-icon> CSV</button>
    </div>
    <div class="col-12">
      <table class="table" *ngIf="eventsSummarys.length>0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">
              Esdeveniment
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Nom de l'esdeveniment. Aquest és el que apareix tant a la pàgina d'inscripció com als correus electrònics."
                popoverTitle="Esdeveniment"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Registrats vàlids
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Sumatori de tots els usuaris que s'han registrat a través de la plataforma, tant si han pagat a través del TPV com si s'ha validat manualment el pagament a través de l'apartat de gestió de la plataforma."
                popoverTitle="Registrats vàlids"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Registrats pagats TPV
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Sumatori de tots els usuaris registrats a través de la plataforma que han completat el pagament fent ús del TPV"
                popoverTitle="Registrats pagats TPV"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Preu entrada
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Preu o preus alternatius corresponents a l'esdeveniment"
                popoverTitle="Preu entrada"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Total entrades
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Sumatori del total de registrats vàlids pel preu de l'entrada. Aquest preu inclou tant els diners del TPV com els rebuts en metàl·lic / TPV físic per part de l'entitat"
                popoverTitle="Total entrades"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Total entrades TPV
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="left" popoverClass="popover-class"
                ngbPopover="Sumatori del total de registrats pagats per TPV multiplicat pel preu de l'esdeveniment. Aquest import coincideix amb el rebut a través del TPV bancari"
                popoverTitle="Total entrades TPV"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Preu comissió
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="left" popoverClass="popover-class"
                ngbPopover=" Import de la comissió en funció del preu de l'esdeveniment, seguint els criteris de tarifació vigents"
                popoverTitle="Preu comissió"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Total comissions
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="left" popoverClass="popover-class"
                ngbPopover="Sumatori de multiplicar el nombre de registrats vàlids (hagin pagat o no a través del TPV) pel preu de la comissió"
                popoverTitle="Total comissions"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let eventSummary of eventsSummarys; index as i">
            <tr>
              <th scope="row">{{i+1}}</th>
              <td>{{eventSummary.event_name}} <br> <small>{{eventSummary.user_name}}</small></td>
              <td>{{eventSummary.paid=='Y'?eventSummary.normal_price.tot_pagats:eventSummary.normal_price.tot_inscrits}}</td>
              <td>{{eventSummary.normal_price.tot_pagats_plataforma}}</td>
              <td>{{eventSummary.price==0?'Gratuït':eventSummary.price + '€'}}</td>
              <td>{{eventSummary.normal_price.tot_pagats==0?'-':(eventSummary.normal_price.tot_pagats*eventSummary.price | number : '1.2-2') + '€'}}</td>
              <td>{{eventSummary.normal_price.tot_pagats_plataforma==0?'-':(eventSummary.normal_price.tot_pagats_plataforma*eventSummary.price | number : '1.2-2') + '€'}}</td>
              <td>{{eventSummary.normal_price.commission==0?'-':(eventSummary.normal_price.commission | number : '1.2-2') + '€'}}</td>
              <td>{{eventSummary.normal_price.total_commissions==0?'-':(eventSummary.normal_price.total_commissions | number : '1.2-2') + '€'}}</td>
            </tr>
            <tr *ngFor="let altPrice of eventSummary.alternative_prices">
              <td></td>
              <td></td>
              <td>{{altPrice.price==0?altPrice.tot_inscrits:altPrice.tot_pagats}}</td>
              <td>{{altPrice.tot_pagats_plataforma}}</td>
              <td>{{altPrice.price==0?'Gratuït':altPrice.price + '€'}}</td>
              <td>{{altPrice.tot_pagats==0?'-':(altPrice.tot_pagats*altPrice.price | number : '1.2-2') + '€'}}</td>
              <td>{{altPrice.tot_pagats_plataforma==0?'-':(altPrice.tot_pagats_plataforma*altPrice.price | number : '1.2-2') + '€'}}</td>
              <td>{{altPrice.commission==0?'-':(altPrice.commission | number : '1.2-2') + '€'}}</td>
              <td>{{altPrice.total_commissions==0?'-':(altPrice.total_commissions | number : '1.2-2') + '€'}}</td>
            </tr>
          </ng-container>
          <tr class="border-c">
            <th scope="row">TOTALS</th>
            <td></td>
            <td>{{this.totals.tot_pagats}}</td>
            <td>{{this.totals.tot_pagats_plataforma}}</td>
            <td></td>
            <td>{{this.totals.preu_total_pagats | number : '1.2-2'}}€</td>
            <td>{{this.totals.preu_total_pagats_plataforma | number : '1.2-2'}}€</td>
            <td></td>
            <td>{{this.totals.total_commissions | number : '1.2-2'}}€</td>
          </tr>
        </tbody>
      </table>
      <br><br>
      <h1 *ngIf="ustSummarys.length>0" >Abonaments</h1>
      <table class="table" *ngIf="ustSummarys.length>0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">
              Abonament
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Nom de l'esdeveniment. Aquest és el que apareix tant a la pàgina d'inscripció com als correus electrònics."
                popoverTitle="Esdeveniment"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Registrats vàlids
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Sumatori de tots els usuaris que s'han registrat a través de la plataforma, tant si han pagat a través del TPV com si s'ha validat manualment el pagament a través de l'apartat de gestió de la plataforma."
                popoverTitle="Registrats vàlids"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Registrats pagats TPV
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Sumatori de tots els usuaris registrats a través de la plataforma que han completat el pagament fent ús del TPV"
                popoverTitle="Registrats pagats TPV"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Preu entrada
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Preu o preus alternatius corresponents a l'esdeveniment"
                popoverTitle="Preu entrada"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Total entrades
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="right" popoverClass="popover-class"
                ngbPopover="Sumatori del total de registrats vàlids pel preu de l'entrada. Aquest preu inclou tant els diners del TPV com els rebuts en metàl·lic / TPV físic per part de l'entitat"
                popoverTitle="Total entrades"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Total entrades TPV
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="left" popoverClass="popover-class"
                ngbPopover="Sumatori del total de registrats pagats per TPV multiplicat pel preu de l'esdeveniment. Aquest import coincideix amb el rebut a través del TPV bancari"
                popoverTitle="Total entrades TPV"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Preu comissió
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="left" popoverClass="popover-class"
                ngbPopover=" Import de la comissió en funció del preu de l'esdeveniment, seguint els criteris de tarifació vigents"
                popoverTitle="Preu comissió"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
            <th scope="col">
              Total comissions
              <button type="button" class="btn btn-white btn-help d-print-none"
                placement="left" popoverClass="popover-class"
                ngbPopover="Sumatori de multiplicar el nombre de registrats vàlids (hagin pagat o no a través del TPV) pel preu de la comissió"
                popoverTitle="Total comissions"
                [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let ustSummary of ustSummarys; index as i">
            <tr>
              <th scope="row">{{i+1}}</th>
              <td colspan="9">{{ustSummary.name}}</td>
            </tr>
            <tr *ngFor="let section of ustSummary.sections">
              <td></td>
              <td>{{section.description}}</td>
              <td>{{section.valid}}</td>
              <td>{{section.platform_paid}}</td>
              <td>{{section.price | number : '1.2-2'}}€</td>
              <ng-container *ngIf="section.is_couple">
                <td>{{(section.valid/2)*section.price | number : '1.2-2'}}€</td>
                <td>{{(section.platform_paid/2)*section.price | number : '1.2-2'}}€</td>
                <td>{{getCommission(section.price) | number : '1.2-2'}}€</td>
                <td>{{section.valid*getCommission(section.price) | number : '1.2-2'}}€</td>
              </ng-container>
              <ng-container *ngIf="!section.is_couple">
                <td>{{section.valid*section.price | number : '1.2-2'}}€</td>
                <td>{{section.platform_paid*section.price | number : '1.2-2'}}€</td>
                <td>{{getCommission(section.price) | number : '1.2-2'}}€</td>
                <td>{{section.valid*getCommission(section.price) | number : '1.2-2'}}€</td>
              </ng-container>
            </tr>
          </ng-container>
          <tr class="border-c">
            <th scope="row">TOTALS</th>
            <td></td>
            <td>{{totalsUst.tot_pagats}}</td>
            <td>{{totalsUst.tot_pagats_plataforma}}</td>
            <td></td>
            <td>{{totalsUst.preu_total_pagats | number : '1.2-2'}}€</td>
            <td>{{totalsUst.preu_total_pagats_plataforma | number : '1.2-2'}}€</td>
            <td></td>
            <td>{{totalsUst.total_commissions | number : '1.2-2'}}€</td>
          </tr>
        </tbody>
      </table>
      <br><br>
      <ng-container *ngIf="eventsSummarys.length>0||ustSummarys.length>0">
        <h1>Resum</h1>
        <table class="table border-c">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">
                Registrats vàlids
                <button type="button" class="btn btn-white btn-help d-print-none"
                  placement="right" popoverClass="popover-class"
                  ngbPopover="Sumatori de tots els usuaris que s'han registrat a través de la plataforma, tant si han pagat a través del TPV com si s'ha validat manualment el pagament a través de l'apartat de gestió de la plataforma."
                  popoverTitle="Registrats vàlids"
                  [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
              </th>
              <th scope="col">
                Registrats pagats TPV
                <button type="button" class="btn btn-white btn-help d-print-none"
                  placement="right" popoverClass="popover-class"
                  ngbPopover="Sumatori de tots els usuaris registrats a través de la plataforma que han completat el pagament fent ús del TPV"
                  popoverTitle="Registrats pagats TPV"
                  [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
              </th>
              <th scope="col"></th>
              <th scope="col">
                Total entrades
                <button type="button" class="btn btn-white btn-help d-print-none"
                  placement="right" popoverClass="popover-class"
                  ngbPopover="Sumatori del total de registrats vàlids pel preu de l'entrada. Aquest preu inclou tant els diners del TPV com els rebuts en metàl·lic / TPV físic per part de l'entitat"
                  popoverTitle="Total entrades"
                  [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
              </th>
              <th scope="col">
                Total entrades TPV
                <button type="button" class="btn btn-white btn-help d-print-none"
                  placement="left" popoverClass="popover-class"
                  ngbPopover="Sumatori del total de registrats pagats per TPV multiplicat pel preu de l'esdeveniment. Aquest import coincideix amb el rebut a través del TPV bancari"
                  popoverTitle="Total entrades TPV"
                  [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
              </th>
              <th scope="col"></th>
              <th scope="col">
                Total comissions
                <button type="button" class="btn btn-white btn-help d-print-none"
                  placement="left" popoverClass="popover-class"
                  ngbPopover="Sumatori de multiplicar el nombre de registrats vàlids (hagin pagat o no a través del TPV) pel preu de la comissió"
                  popoverTitle="Total comissions"
                  [openDelay]="300" triggers="mouseenter:mouseleave">?</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">TOTALS</th>
              <td></td>
              <td>{{totalsUst.tot_pagats+this.totals.tot_pagats}}</td>
              <td>{{totalsUst.tot_pagats_plataforma+this.totals.tot_pagats_plataforma}}</td>
              <td></td>
              <td>{{(totalsUst.preu_total_pagats+this.totals.preu_total_pagats) | number : '1.2-2'}}€</td>
              <td>{{(totalsUst.preu_total_pagats_plataforma+this.totals.preu_total_pagats_plataforma) | number : '1.2-2'}}€</td>
              <td></td>
              <td>{{(totalsUst.total_commissions+this.totals.total_commissions) | number : '1.2-2'}}€</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>