import { Component, OnInit } from '@angular/core';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import * as Constants from 'src/app/modules/constants/constants.module';
import { Router } from '@angular/router';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  link_export_mails: string;
  constructor(public backend: BackendServiceService,
                private localLogin: LocalLoginService,
                public router: Router) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.link_export_mails = Constants.ENT_URL + '/data/list_mails?api_key=' +
      this.backend.apikey;
   }

  ngOnInit(): void {
  }

}
