<section class="">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img class="h-16" src="assets/LOGO.svg" alt="logo">   
        </a>
        <div class="w-full bg-white rounded shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Inicia la sessió
                </h1>
                <form class="space-y-4 md:space-y-6" action="#">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{'Usuari' | translate}}</label>
                        <input [(ngModel)]="user_name" [ngModelOptions]="{standalone: true}" name="user_name" id="user_name" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-lime-600 focus:border-lime-300 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Escriu el teu usuari" required="">
                    </div>
                    <div>
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{'Contrassenya' | translate}}</label>
                        <input type="password" name="user_password" id="user_password" [(ngModel)]="user_password" [ngModelOptions]="{standalone: true}" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-lime-600 focus:border-lime-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                    </div>
                    
                    <div class="alert alert-c mt-2 text-red-400" role="alert" *ngIf="error!=''">
                      {{error | translate}}
                    </div>
                    <div class="alert alert-c mt-2" role="alert" *ngIf="localLogin.closedInvalidSession">
                      Sessió tancada
                    </div>
                    <button type="submit" (click)="login()" class="w-full text-stone-900 bg-lime-200 hover:bg-lime-300 focus:ring-4 focus:outline-none focus:ring-lime-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-lime-300 dark:hover:bg-lime-700 dark:focus:ring-lime-800">Entrar</button>
                    <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                        No recordes les claus? <a href="mailto:acces@mesaces.com" class="font-medium text-lime-300 hover:underline dark:text-lime-500">Escriu-nos!</a>
                    </p>
                </form>
            </div>
        </div>
    </div>
  </section>