import { Component, OnInit, Input } from '@angular/core';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Event_class } from 'src/app/class/Event_class';
import { faCalendar, faClock, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope, faList, faEuroSign, faClock as faClockSolid, faUserClock, faPrint, faSyncAlt, faTicketAlt, faUserCheck, faUserTimes, faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';
import { faCircle, faCheckCircle, faSquare, faCheckSquare} from '@fortawesome/free-regular-svg-icons';
import { EventContainerService } from 'src/app/services/event-container.service';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { Question_class } from 'src/app/class/Question_class';

import * as XLSX from 'xlsx';
interface ResponseData {
  [key: string]: any; // O defineix les propietats específiques que esperes
  id?: string; // La propietat id serà opcional
}

@Component({
  selector: 'app-event-admin',
  templateUrl: './event-admin.component.html',
  styleUrls: ['./event-admin.component.css']
})
export class EventAdminComponent implements OnInit {
  loading:boolean=true;
  acabat=false;
  iniciCaptura=false;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  event: Event_class;
  count: number;
  attendants: Array<Attendant_class> = null;
  id_event: string;
  door_code: string;
  delete_attendant_name: string;
  role: string;
  name_user: string;
  hasFUAttendants: boolean;
  userSeassonAttendants: Array<Attendant_class>;
  saved_name: string = '';
  saved_surname: string = '';
  saved_dni: string = '';
  saved_mail: string;
  saved_phone: string;
  selected_attendant: Attendant_class;
  faCalendar = faCalendar;
  faClock = faClock;
  faPrint = faPrint;
  faSyncAlt = faSyncAlt;
  faTrashAlt = faTrashAlt;
  faEnvelope = faEnvelope;
  faList = faList;
  faUserClock = faUserClock;
  faClockSolid = faClockSolid;
  faTicketAlt = faTicketAlt;
  faUserCheck = faUserCheck;
  faUserTimes =faUserTimes;
  faEdit = faEdit;
  faCheck = faCheck;
  faTimes = faTimes;
  faEuroSign = faEuroSign;
  summary: Array<number>;
  id_attendant:string;
  visible:boolean = false;
  questions = [];
  attendantsS: Attendant_class[] = null;
  fileName: string;
  fileNameM: string;

  constructor(public backend: BackendServiceService,
              public router: Router,
              public eventContainer: EventContainerService,
              public attendantContainer: AttendantContainerService,
              public modalService: NgbModal,
              private localLogin: LocalLoginService,
              public route: ActivatedRoute) {
    this.attendants = new Array<Attendant_class>();
    this.userSeassonAttendants = new Array<Attendant_class>();
    this.event = this.eventContainer.get();
    this.name_user = this.backend.name_user;
    this.id_event = this.route.snapshot.paramMap.get("id_event");
    this.role = this.backend.role;
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.getUserSeassonTicketAttendants();
    if(this.backend.role=='U'){
      this.door_code = backend.door_code;
      this.getAttendants();
    }
    else
      this.getAttendantsAdmin();
      
    //this.eventContainer.set(new Event_class());
  }

  ngOnInit(): void {
    this.loading=true;
   this.attendants=null;
   this.fileName = 'Excel_Individuals_'+this.event.event_name+'.xlsx';
   this.fileNameM = 'Excel_Multiples_'+this.event.event_name+'.xlsx';
  }

  print(){
    window.print();
  }

  update(){
    this.getUserSeassonTicketAttendants();
    if(this.backend.role=='U')
      this.getAttendants();
    else
      this.getAttendantsAdmin();
  }

  countAttendants() {
    if (this.event.paid) {
      let count: number = 0;
      if (this.attendants != null) {
        if (Array.isArray(this.attendants)) { // verificar si es un array
          this.attendants.forEach((attendant: Attendant_class) => {
            if (attendant.payment_status == 'C' || this.checkFUPayment(attendant.familiar_unit_id)) {
              count++;
            }
          });
          console.log(count);
          return count;
        }
      }
    }else{
      let count: number = 0;
      if (this.attendants != null) {
        if (Array.isArray(this.attendants)) { // verificar si es un array
          this.attendants.forEach((attendant: Attendant_class) => {
            
              count++;
            
          });
          console.log(count);
          return count;
        }
      }

    }
    return 0; // si `this.attendants` es nulo o no es un array, devuelve 0
  }
  
  

  checkFUPayment(fu: string): boolean{
    let result = false;
    this.attendants.forEach((attendant: Attendant_class)=>{
      if(attendant.familiar_unit_id != '' && attendant.familiar_unit_id != undefined){
        if(attendant.familiar_unit_id==fu && attendant.payment_status == 'C'){
          result = true;
        }
      }
    });
    return result;
  }

  getAttendedAttendantP(){
    return (this.countAttAttendants()/this.countAttendants())*100;
  }

  getAttendantP(){
    return (this.countAttendants()/(this.event.has_sessions?this.event.sum_sessions_tickets:this.event.num_tickets))*100;
  }
  

  countAttAttendants(){
    let i = 0;
      if (this.attendants != null) {
    this.attendants.forEach((attendant)=>{
      if(attendant.has_attended == 'Y'){
        i++;
      }
    });
    return i;
  }
  }

  saveName(attendant){
    this.backend.modifyAttendantName(attendant.id,attendant.name, attendant.familiar_unit_id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          attendant.edit_name = false;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }
  
  saveSurname(attendant){
    this.backend.modifyAttendantSurname(attendant.id,attendant.surname, attendant.familiar_unit_id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          attendant.edit_surname = false;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  saveDni(attendant){
    this.backend.modifyAttendantDni(attendant.id,attendant.dni, attendant.familiar_unit_id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          attendant.edit_dni = false;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  saveMail(attendant){
    this.backend.modifyAttendantMail(attendant.id,attendant.mail, attendant.familiar_unit_id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          attendant.edit_mail = false;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  savePhone(attendant){
    this.backend.modifyAttendantPhone(attendant.id,attendant.phone, attendant.familiar_unit_id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok'])
          attendant.edit_phone = false;
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getAttendants(){
    this.backend.getAttendants(this.id_event).subscribe(data => {
      
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.attendants = Object.assign(new Array, data);
        
        // Sort the attendants based on the time_register property
        this.attendants.sort((a, b) => {
            return new Date(a.time_register).getTime() - new Date(b.time_register).getTime();
        });
    
        this.setNum();
        this.fuSummary();
        if(this.attendants == data){
            this.loading = false;
        }
        for(let i = 0; i < this.attendants.length; i++)
            this.attendants[i]['edit_mail'] = false;
    }
    
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getUserSeassonTicketAttendants(){
    this.backend.getUserSeassonTicketAttendants(this.id_event).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.userSeassonAttendants = Object.assign(new Array, data);
        //console.log(this.userSeassonAttendants);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  open(content, attendant) {
    this.delete_attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='DELETE')
        this.deleteAttendantConfirm(attendant.id);
    }, (reason) => {

    });
  }

  open2(content, attendant) {
    this.delete_attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='SEND')
        this.sendMailConfirm(attendant);
    }, (reason) => {

    });
  }

  view_payment_status(content, attendant) {
    this.selected_attendant = attendant;
    this.modalService.open(content, {size:'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  viewKeyReads(content, attendant) {
    this.selected_attendant = attendant;
    this.modalService.open(content, {size:'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  confirm_mark_as_paied(content, attendant:Attendant_class) {
    this.delete_attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='SEND'){
        this.mark_as_paied(attendant.id);
        this.selected_attendant.payment_status = this.selected_attendant.payment_status!='C'?'C':'';
      }

    }, (reason) => {

    });
  }

  confirMarkAsAttended(content, attendant:Attendant_class) {
    this.delete_attendant_name = attendant.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='SEND'){
        this.addKeyRead(attendant.id);
        this.selected_attendant.last_read_type =
          this.selected_attendant.last_read_type=='I'?'O':'I';
          this.update();
      }

    }, (reason) => {

    });
  }

  mark_as_paied(id: string){
    this.backend.markAsPaied(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getAttendants();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  count_fu(fu_id: string){
    return this.attendants.filter(ele => ele.familiar_unit_id == fu_id).length
  }

  markAsAttended(id: string){
    this.backend.markAsAttended(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getAttendants();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  addKeyRead(id: string){
    this.backend.addKeyRead(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getAttendants();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  viewTickets(attendant){
    this.eventContainer.event = this.event;
    this.attendantContainer.attendant = attendant;
    this.router.navigate(['/view-tickets']);
  }

  inPersonRegister(){
    this.backend.inPerson = true;
    this.backend.manual = false;
    this.router.navigate(['/registerattendant/'+this.event.id]);
  }

  manualRegister(){
    this.backend.inPerson = false;
    this.backend.manual = true;
    this.router.navigate(['/registerattendant/'+this.event.id]);
  }

  deleteAttendantConfirm(id){
    this.backend.deleteAttendant(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(this.role=='U')
          this.getAttendants();
        else
          this.getAttendantsAdmin();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  session_has_attendants(id){
    let has_attendants = false;
    this.attendants.forEach(attendant => {
      if(attendant.familiar_unit_id==undefined&&attendant.id_session==id)
        has_attendants = true;
    });
    return has_attendants;
  }

  session_has_fu(id){
    let has_fu = false;
    this.attendants.forEach(attendant => {
      if(attendant.familiar_unit_id!=undefined&&attendant.id_session==id)
        has_fu = true;
    });
    return has_fu;
  }

  sendMailConfirm(attendant: Attendant_class){
    this.backend.sendMail(attendant).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
  }

  getAttendantsAdmin(){
    this.backend.getAttendantsAdmin(this.id_event).subscribe(data => {
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.attendants = Object.assign(new Array, data);
        this.setNum();
        this.fuSummary();
        if(this.attendants==data){
        this.loading=false;
        }
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  formatDate(date: string){
    if(date==undefined)
      return '';
    date = date.replace(' ','T');
    let dateF = new Date(date);
    return dateF.getDate() + '/' + (dateF.getMonth()+1) + '/' + dateF.getFullYear() + ' ' + dateF.getHours() + ':' + (dateF.getMinutes()<10?'0':'') + dateF.getMinutes();
  }

  setNum(){
    var count = 1;
    var count2 = 1;
    var lastFU = "";
    var color1 = '#f5f5f4';
    var color2 = '#fff';
    var color = color1;
    var number_fu = 1;
    for(let i = 0; i < this.attendants.length; i++){
      if(this.attendants[i].familiar_unit_id==undefined)
        this.attendants[i].num = count++;
      else{
        if(lastFU!=this.attendants[i].familiar_unit_id){
          color = color==color1?color2:color1;
          this.attendants[i].first_fu = true;
          this.attendants[i].number_fu = number_fu;
          number_fu++;
        } else {
          this.attendants[i].first_fu = false;
        }
        this.attendants[i].color = color;
        this.attendants[i].num = count2++;
        lastFU = this.attendants[i].familiar_unit_id;
      }
    }
    if(this.event.has_sessions){
      this.event.sessions.forEach((session)=>{
        let tot = 1;
        let totFU = 1;
        let lastFU = '';
        let fuNum = 0;
        color = color1;
        this.attendants.forEach((attendant)=>{
          if(attendant.id_session==session.id){
            attendant.first_fu = false;
            if(attendant.familiar_unit_id==undefined||attendant.familiar_unit_id==''){
              attendant.num = tot;
              tot += 1;
            } else {
              if(attendant.familiar_unit_id!=lastFU){
                attendant.count_fu = this.count_fu(attendant.familiar_unit_id);
                fuNum += 1;
                attendant.first_fu = true;
                lastFU = attendant.familiar_unit_id;
                color = color==color1?color2:color1;
              }
              attendant.color = color;
              attendant.number_fu = fuNum;
              attendant.num = totFU;
              totFU += 1;
            }
          }
        });
      });
    }
    //console.log(this.attendants);
  }

  fuSummary(){
    var lastFU = "";
    var tot = 0;
    this.hasFUAttendants = false;
    this.summary = new Array<number>();
    for(let i = 0; i < this.attendants.length; i++){
      if(this.attendants[i].familiar_unit_id!=undefined){
        this.hasFUAttendants = true;
        if(lastFU!=this.attendants[i].familiar_unit_id){
          if(this.summary[tot]==undefined)
            this.summary[tot]=0;
          this.summary[tot]++;
          tot = 0;
        }
        tot++;
        lastFU = this.attendants[i].familiar_unit_id;
      }
    }
    if(this.summary[tot]==undefined)
      this.summary[tot]=0;
    this.summary[tot]++;

    this.event.sessions.forEach(
      (session) => {
        session.summary = new Array<number>();
        this.attendants.forEach((attendant)=>{
          if(attendant.id_session == session.id){
            if(attendant.first_fu){
              if(session.summary[attendant.count_fu]==undefined){
                session.summary[attendant.count_fu] = 0;
              }
              session.summary[attendant.count_fu]++;
            }
          }
        })
      }
    );
    //console.log(this.summary);
  }

  sortSurname(){
    this.attendants.sort((v1: any, v2: any) => v1['surname'] < v2['surname'] ? -1 : v1['surname'] > v2['surname'] ? 1 : 0);
  }

  sortSurnameUst(){
    this.userSeassonAttendants.sort((v1: any, v2: any) => v1['surname'] < v2['surname'] ? -1 : v1['surname'] > v2['surname'] ? 1 : 0);
  }

  sortRegisterDate(){
    this.attendants.sort((v1: any, v2: any) => v1['id'] < v2['id'] ? -1 : v1['id'] > v2['id'] ? 1 : 0);
  }

  dateFormat(date){
    date = new Date(date * 1000);
    let result = date.getDate();
    switch(date.getMonth()+1){
      case 1:
        result += ' de gener';
      break;
      case 2:
        result += ' de febrer';
      break;
      case 3:
        result += ' de març';
      break;
      case 4:
        result += ' d\'abril';
      break;
      case 5:
        result += ' de maig';
      break;
      case 6:
        result += ' de juny';
      break;
      case 7:
        result += ' de juliol';
      break;
      case 8:
        result += ' d\'agost';
      break;

      case 9:
        result += ' de setembre';
      break;
      case 10:
        result += ' d\'octubre';
      break;
      case 11:
        result += ' de novembre';
      break;
      case 12:
        result += ' de desembre';
      break;
    }
    result += ' ' + date.getFullYear();
    return result;

  }

  timeFormat(date){
    let fDate = new Date(date * 1000);
    return fDate.getHours() + ':' + (fDate.getMinutes()<10?'0':'') + fDate.getMinutes();
  }

  getTotalFUAttendantsSession(idSession: number){
    let total = 0;
    this.attendants.forEach((attendant: Attendant_class)=>{
      if(attendant.id_session == idSession){
        if(attendant.familiar_unit_id!=undefined && attendant.familiar_unit_id != ''){
          total += 1;
        }
      }
    });
    return total;
  }

  getTotalAttendantsSession(idSession: number){
    let total = 0;
    this.attendants.forEach((attendant: Attendant_class)=>{
      if(attendant.id_session == idSession){
        if(attendant.familiar_unit_id==undefined || attendant.familiar_unit_id == ''){
          total += 1;
        }
      }
    });
    return total;
  }

  getTotalFUAttendantsSessionP(session: Event_session_class){
    let total = this.getTotalFUAttendantsSession(session.id);
    return (total/session.tickets) * 100;
  }

  getTotalAttendantsSessionP(session: Event_session_class){
    let total = this.getTotalAttendantsSession(session.id);
    return (total/session.tickets) * 100;
  }

  getTotalAttendantsFU(){
    let total = 0;
    console.log(this.attendants)
      if (this.attendants != null) {
    this.attendants.forEach((attendant)=>{
      if(attendant.familiar_unit_id!=undefined&&attendant.familiar_unit_id!=''){
        total += 1;
      }
    });
    return total;}
  }

  getTotalAttendants(){
    let total = 0;
    console.log(this.attendants)
      if (this.attendants != null) {
    this.attendants.forEach((attendant)=>{
      if(attendant.familiar_unit_id==undefined||attendant.familiar_unit_id==''){
        total += 1;
      }
    });
    return total;}
  }
  transformPrice(id){
    if(id!=undefined){
    
    for(let i = 0; i <= this.event.altPrices.length; i++){
      if(this.event.altPrices[i].id===id){        
        return ''+this.event.altPrices[i].description+' - '+this.event.altPrices[i].price+'€';
      }}
    }
    
  }
  acortar(text){
    return text.substr(0,15);
  }

  
  async transformRes() {
    this.iniciCaptura = true;
  
    // Ordenar els attendants per id
    this.attendantsS = this.attendants.sort((v1: Attendant_class, v2: Attendant_class) => v1.id < v2.id ? -1 : v1.id > v2.id ? 1 : 0);
  
    try {
      // Obtenir les respostes per cada attendant i mapejar-les correctament
      const responses = await Promise.all(this.attendantsS.map(async (attendant: Attendant_class) => {
        try {
          const data: ResponseData = await this.backend.getQuestionWithResponses(attendant.id).toPromise();
          data.id = attendant.id; // Assegurar que la resposta té el mateix id que l'attendant
          return data;
        } catch (error) {
          console.log(`Error amb l'usuari ${attendant.id}:`, error);
          // Retorna un objecte amb l'id i un array buit per mantenir l'ordre
          return { id: attendant.id, questions: this.event.questions.map(q => ({ response: [{ text: '-' }] })) };
        }
      }));
  
      // Filtrar les respostes que no tenen errors
      this.questions = responses.map(response => {
        if (!response.error) {
          return response;
        } else {
          return {
            id: response.id,
            questions: this.event.questions.map(q => ({ response: [{ text: '-' }] }))
          };
        }
      });
  
      // Ordenar les respostes per id per assegurar la correlació
      this.ordenarRespostes();
      console.log('Resultat ordenat:', this.questions);
    } catch (error) {
      console.log('Error general:', error);
    }
  }
  
  ordenarRespostes() {
    this.questions = this.questions.sort((v1: any, v2: any) => v1.id < v2.id ? -1 : v1.id > v2.id ? 1 : 0);
    console.log('Resultat ordenat:', this.questions);
    
    setTimeout(() => this.acabat = true, 3000);
    setTimeout(() => this.iniciCaptura = false, 3100);
  }


  
  
  

getKeyByPosition(obj: any, position = 0): any {
  return Object.keys(obj)[position];
}

getExcel():void{

  /* pass here the table id */
  if(document.getElementById('excel-table')){
  let element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Resum');

  /* save to file */  
  XLSX.writeFile(wb, this.fileName);}

}
getExcelM():void{

  /* pass here the table id */
  if(document.getElementById('excel-table-m')){
  let element = document.getElementById('excel-table-m');
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Resum');

  /* save to file */  
  XLSX.writeFile(wb, this.fileNameM);}

}
clipboard_copy(text: string){
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = text;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}
}

