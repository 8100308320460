import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Panel */
import { LoginComponent } from './components/panel/login/login.component';
import { CreateModifyEventComponent } from './components/panel/create-modify-event/create-modify-event.component';
import { EventsListComponent } from './components/panel/events-list/events-list.component';
import { ConfigurationComponent }  from './components/panel/configuration/configuration.component'
import { EventAdminComponent } from './components/panel/event-admin/event-admin.component';
import { ViewResponsesComponent } from './components/panel/view-responses/view-responses.component';
import { CreateZoneComponent } from './components/panel/create-zone/create-zone.component';
import { ZoneListComponent } from './components/panel/zone-list/zone-list.component';
import { UserListComponent } from './components/panel/user-list/user-list.component';
import { CreateUserComponent } from './components/panel/create-user/create-user.component';
import { ViewTicketsComponent } from './components/panel/view-tickets/view-tickets.component';
import { DeleteAttendantComponent } from './components/panel/delete-attendant/delete-attendant.component';
import { StatisticsComponent } from './components/panel/statistics/statistics.component';
import { NewsletterComponent } from './components/panel/newsletter/newsletter.component';
import { SessionsComponent } from './components/panel/sessions/sessions.component';

/* View event */
import { PayComponent } from './components/view-register/pay/pay.component';
import { PrivacyPolicyComponent } from './components/view-register/view/privacy-policy/privacy-policy.component';
import { LegalAdviceComponent } from './components/view-register/view/legal-advice/legal-advice.component';
import { CookiePoliticComponent } from './components/view-register/view/cookie-politic/cookie-politic.component';
import { CookieConfigComponent } from './components/view-register/view/cookie-config/cookie-config.component';
import { ViewEventComponent } from './components/view-register/view/view-event/view-event.component';
import { RegisterAttendantComponent } from './components/view-register/register/ticket/register-attendant/register-attendant.component';

/* View lists */
import { ListEventsFromUserComponent } from './components/view-register/list-events-from-user/list-events-from-user.component';
import { CreateModifyUserSeasonTicketComponent } from './components/panel/create-modify-user-season-ticket/create-modify-user-season-ticket.component';
import { ListUserSeasonTicketComponent } from './components/panel/list-user-season-ticket/list-user-season-ticket.component';
import { ListUserSeassonTicketAttendantsComponent } from './components/panel/list-user-seasson-ticket-attendants/list-user-seasson-ticket-attendants.component';
import { SummaryComponent } from './components/panel/summary/summary.component';
import { MeRegisterComponent } from './components/view-register/register/multi-event/me-register/me-register.component';
import { ViewTicketsMeComponent } from './components/panel/view-tickets-me/view-tickets-me.component';
import { ViewTicketsMeIComponent } from './components/panel/view-tickets-me-i/view-tickets-me-i.component';

const routes: Routes = [
  { path: 'events-list', component: EventsListComponent },
  { path: 'me-register', component: MeRegisterComponent },
  { path: 'cookie-config', component: CookieConfigComponent },
  { path: 'newsletter', component: NewsletterComponent },
  { path: 'politica-cookies', component: CookiePoliticComponent },
  { path: 'politica-privacitat', component: PrivacyPolicyComponent },
  { path: 'avis-legal', component: LegalAdviceComponent },
  { path: 'pay_ok', component: ViewEventComponent},
  { path: 'error_pagament/:id_event', component: ViewEventComponent},
  { path: 'baixa/:id_event/:event_name/:ticket', component: DeleteAttendantComponent },
  { path: 'user-list', component: UserListComponent },
  { path: 'user-season-ticket-list', component: ListUserSeasonTicketComponent },
  { path: 'view-tickets', component: ViewTicketsComponent },
  { path: 'view-tickets-me', component: ViewTicketsMeComponent },
  { path: 'view-tickets-me-i', component: ViewTicketsMeIComponent },
  { path: 'veure-entrada/:mode/:key', component: ViewTicketsComponent },
  { path: 'veure-abonament/:mode/:key', component: ViewTicketsComponent },
  { path: 'create-user', component: CreateUserComponent },
  { path: 'create-user-season-ticket', component: CreateModifyUserSeasonTicketComponent },
  { path: 'create-zone', component: CreateZoneComponent },
  { path: 'zone-list', component: ZoneListComponent },
  { path: 'list', component: EventAdminComponent },
  { path: 'pagar/:mode/:id_attendant', component: PayComponent},
  { path: 'pagar_abonament/:mode/:id_attendant', component: PayComponent},
  { path: 'vresp/:id_attendant', component: ViewResponsesComponent},
  { path: 'list/:id_event', component: EventAdminComponent },
  { path: 'listUSTA/:id_usta', component: ListUserSeassonTicketAttendantsComponent },
  { path: 'event', component: CreateModifyEventComponent },
  { path: 'conf', component: ConfigurationComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'resum', component: SummaryComponent },
  { path: 'event/:event', component: CreateModifyEventComponent },
  //{ path: 'event-admin/:event', component: }
  { path: 'esdeveniment/:id_event', component: ViewEventComponent },
  { path: 'esdeveniment/:id_event/:lang', component: ViewEventComponent },
  { path: 'evv/:id_event/:user_id/:userseasson', component: ViewEventComponent },
  { path: 'registerattendant/:id_event', component: RegisterAttendantComponent },
  { path: 'events_list/:user_id', component: ListEventsFromUserComponent},
  { path: 'sessions', component: SessionsComponent},
  { path: '', component: LoginComponent },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
