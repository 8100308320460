<div class="grid w-full bg-lime-200 h-screen place-items-center" [ngClass]="{'fade-out': loaded}"><span class="justify-center text-center">{{'Carregant esdeveniment' | translate}} <div role="status">
  <br><svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-white animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
  </svg>
  
</div></span></div>

<nav class="bg-white text-stone-800 fixed w-full">
  <div class="mx-auto">
    <div class="flex h-16 items-center justify-between">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <img class="h-12 py-2 px-4" src="../../../assets/logo-mini.svg" alt="+acces">
        </div>
        
      </div>
      <div class="px-4">
        <div class="ml-4 flex items-center md:ml-6">
          <!--<button type="button" class="rounded-full bg-stone-800 p-1 text-stone-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-stone-800">
            <span class="sr-only">View notifications</span>
            Heroicon name: outline/bell 
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
          </button>-->

          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div>
              <button type="button" *ngIf="!event.has_sessions&&mode=='info'" class="text-stone-900 text-xs py-2 px-4 rounded" [ngClass]="isOpen(event.open_date,event.close_date)?'bg-lime-200 hover:bg-lime-300':'bg-stone-400'" (click)="isOpen(event.open_date,event.close_date)&&register()"><span *ngIf="event.paid">{{'Comprar ' | translate}}</span><span *ngIf="!event.paid">{{'Reservar ' | translate}}</span>{{'entrada' | translate}}</button>
              <button type="button" *ngIf="mode!='info'" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded" (click)="info()">{{'Tornar' | translate}}</button>
            </div>

            
          </div>
        </div>
      </div>
      
    </div>
  </div>  
</nav>
<header class="bg-white shadow pt-16">
  <div class="mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <h1 class="text-4xl tracking-tight text-stone-900"><strong>{{event.event_name}}</strong></h1>
    
      <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block text-lime-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg> {{dateFormat(event.date)}}
      </span>
      <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block text-lime-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg> {{timeFormat(event.date)}}h
      </span><br>
      <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block text-lime-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
            
          {{ event.address }}
      </span>
      <a class="text-lime-400" [href]="event.map_link" target="_blank">{{'Com arribar-hi?' | translate}}</a>
  
  </div>

</header>

<app-user-seasson-ticket *ngIf="mode=='uSeassonTicket'" [event]="event"></app-user-seasson-ticket>
<app-register-attendant *ngIf="mode=='register'" (ended)="mode='info'"></app-register-attendant>
<app-payment-wait [check]="false" [payment_status]="'C'" *ngIf="router.url.substring(0,19)=='/pay_ok/'&&!modechanged"></app-payment-wait>
<app-payment-wait [check]="false" [payment_status]="'F'" *ngIf="router.url.substring(0,16)=='/error_pagament/'&&!modechanged"></app-payment-wait>


<div class="">
  <div class="grid grid-cols-1 lg:grid-cols-6 gap-4 sm:gap-6 lg:gap-8 p-4 sm:p-6 lg:p-8">
    <div class="lg:col-span-2 overflow-hidden rounded h-fit shadow"><div class="h-8 p-2 overflow-hidden text-xs text-stone-900 bg-lime-200 uppercase">{{event.event_name}}</div><img class="object-cover w-full" [src]="event.image" title="{{event.event_name}}" alt="{{event.event_name}}" /></div>
    <div class="lg:col-span-4 ">
      <div class="bg-white shadow rounded overflow-hidden p-8"><h1 class="font-bold text-xl mb-2">{{event.event_name}}</h1>
      <div class="text-stone-500 text-sm uppercase">{{'Organitza' | translate}} <a [href]="event.organizer_link" class="hover:text-lime-300 font-bold" target="_blank">{{event.organizers | uppercase}}</a></div>
      <span class="uppercase text-lime-400 tracking-widest text-xs py-4 inline-block">+info</span>
      <div class="text-sm text-justify text-stone-900 py-4 border-y border-gray-100" [innerHtml]="event.event_description"></div>
      <div class="text-xs py-4">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block text-lime-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg> {{dateFormat(event.date)}}
        </span>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block text-lime-400">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg> {{timeFormat(event.date)}}h
        </span><br>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block text-lime-400">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
              
            {{ event.address }}
        </span>
        <a class="text-lime-400" [href]="event.map_link" target="_blank">{{'Com arribar-hi?' | translate}}</a>
  
      </div>
      <div class="text-sm text-stone-900 py-4 border-t border-gray-100 text-end">
          
          <ng-container *ngIf="event.paid">
            <p class="text-2xl">
                
                <ng-container *ngIf="event.altPrices.length==0"><span class="text-stone-400">{{'Preu' | translate }}</span> {{event.price*1}}€ </ng-container>
                <ng-container *ngIf="event.altPrices.length>0"><span class="text-stone-400">{{'Des de' | translate }}</span> {{ordenaPreu(event.altPrices)*1}}€</ng-container>
                <ng-container *ngIf="event.price<=0&&event.altPrices.length<=0">{{'Gratuïta' | translate}}</ng-container>
            </p>
            <ng-container *ngIf="event.paid&&event.show_commission&&event.management_cost!=undefined&&getComision(event.price)>0">
              <p class="text-stone-400 text-xs ">{{'Inclou despeses de gestió' | translate}}: {{getComision(event.price)}} €</p>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!event.paid">
            <p class="text-2xl">{{'Reserva gratuïta' | translate}}</p>
          </ng-container>

          <button type="button" class="text-stone-900 text-xs py-2 px-4 rounded mt-4"
              [ngClass]="isOpen(event.open_date,event.close_date)?'bg-lime-200 hover:bg-lime-300':'bg-stone-400'"
              *ngIf="!event.has_sessions"
              (click)="isOpen(event.open_date,event.close_date)&&register()"><span *ngIf="event.paid">{{'Comprar ' | translate}}</span><span *ngIf="!event.paid">{{'Reservar ' | translate}}</span>{{'entrada' | translate}}</button>
          <br>
          <small class="text-stone-600" *ngIf="isEarly(event.open_date)">{{'Disponible a partir de' | translate}} {{ timeFormat(event.open_date)}} de {{ dateFormat(event.open_date)}}</small>
          <small class="text-stone-600" *ngIf="isLate(event.close_date)">{{'Termini finalitzat' | translate}} ({{dateFormat(event.close_date)}})</small>
          <small class="text-red-400" *ngIf="!hasTickets()&&!isLate(event.close_date)">{{'Reserves exhaurides' | translate}}</small>
          <span>
              <!--Entrades disponibles: {{event.num_tickets-event.tot_attendants}}/{{event.num_tickets}}&nbsp;<fa-icon [icon]="faInfoCircle"></fa-icon><br>-->
             
              
              
              <ng-container *ngIf="event.has_sessions">
                  <span class="important-text-s2 color-red" *ngIf="event.multiple">
                    {{'Aforament actual' | translate}} {{ event.count_in }}/{{ event.has_sessions?event.sum_sessions_tickets:event.num_tickets }}
                  </span>
                  <ng-container *ngFor="let session of event.sessions">
                      <button type="button" class="btn mt-2" style="color: #FFF; width: 100%;"
                      [ngStyle]="{'background-color': isOpenS(session)?event.accent_color:'#ccc'}"
                      (click)="isOpenS(session)&&register_session(session)">{{session.name}}</button>
                      <small class="text-center" *ngIf="!session_has_tickets(session)&&!isLate(event.close_date)">Reserves exhaurides</small>
                      <ng-container *ngIf="event.multiple">
                        {{'Entrades venudes' | translate}} {{ session.reserved_tickets }}/{{ session.tickets }}
                      </ng-container>
                  </ng-container>
              </ng-container>
          </span>

          <div *ngIf="event.register_notes!=''" class="bg-stone-100 text-xs p-4 rounded mt-4 text-justify">
            <span class="uppercase text-lime-400 tracking-widest text-xs pb-4 inline-block">{{'Condicions' | translate}}</span><br><br>
            
            {{event.register_notes}}</div>
      </div>
      </div>
      <div *ngIf="event.information!=''" class="bg-white text-justify shadow rounded overflow-hidden p-8 mt-4 text-xs text-stone-400" [innerHtml]="event.information">

      </div>
    </div>
  </div>
  

</div>

<app-cookie-banner></app-cookie-banner>



<button (click)="openHelpModal(help)" type="button"
class="fixed z-90 bottom-4 right-4 text-stone-900 bg-lime-300 hover:bg-lime-400 focus:ring-4 focus:outline-none focus:ring-lime-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-lime-400 dark:text-lime-400 dark:hover:text-white dark:focus:ring-lime-800">
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
</svg>

</button>

<ng-template #help let-modal>
  <div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
      <div class="grid relative w-full place-items-center h-screen">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit p-8">
            <h4 class="text-3xl tracking-tight text-stone-900 pb-4">{{'Ajuda' | translate}}</h4>
            <div class="text-xs"><app-help-page></app-help-page></div>
            

            <button type="button" class="absolute top-8 right-8 px-2 py-2 rounded bg-lime-200 hover:bg-lime-300" (click)="modal.close('')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              
            </button>
          </div>
      </div>
  </div>
</ng-template>