import { Field_class } from "./Field_class";

export class Fields_class{
    fields: Array<Field_class> = new Array<Field_class>();
    needValidation(field_name: string){
        if(!this.isEnabled(field_name)){
            return false;
        }
        let result = true;
        this.fields.forEach((field: Field_class)=>{
            if(field.name = field_name){
                result = field.validate;
            }
        });
        return result;
    }

    isEnabled(field_name: string){
        let result = true;
        this.fields.forEach((field: Field_class)=>{
            if(field.name = field_name){
                result = field.enabled;
            }
        });
        return result;
    }
}