import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event_class } from 'src/app/class/Event_class';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';

@Component({
  selector: 'app-user-seasson-ticket-select',
  templateUrl: './user-seasson-ticket-select.component.html',
  styleUrls: ['./user-seasson-ticket-select.component.css']
})
export class UserSeassonTicketSelectComponent implements OnInit {
  @Input() event: Event_class;
  @Input() id_user_seasson_ticket: string;
  @Output() id_user_seasson_ticketChange: EventEmitter<string>;
  selectedType: string;
  constructor() {
    this.selectedType = ' ';
    this.id_user_seasson_ticketChange = new EventEmitter<string>();
  }

  ngOnInit(): void {
  }

  confirm(){
    this.id_user_seasson_ticketChange.emit(this.selectedType);
  }

  timestampToDate(unix_timestamp: number){
    return new Date(unix_timestamp * 1000);
  }

  printDate(unix_timestamp: number){
    let date = this.timestampToDate(unix_timestamp);
    let formatedDate = date.getDate() + '/';
    formatedDate += (date.getMonth()+1) + '/';
    formatedDate += date.getFullYear()
    return formatedDate;
  }
  
  isOpen(userSeasonTicket: User_season_ticket_class){
    let dateO = userSeasonTicket.start_sell * 1000;
    let dateC = userSeasonTicket.end_sell * 1000;
    let now = Date.now();
    //console.log('now:' + now + ' dateO:' + dateO + ' dateC:' + dateC);
    return now > dateO && now < dateC;
  }
}
