
<div class="container">
    <div class="row">
        <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xs-12">
            <select class="form-control select-c" (change)="confirm()"
            [(ngModel)]="selectedType" *ngIf="event.register_familiar_union!='T'">
                <option value=' '>tipus d'abonament</option>
                <option *ngFor="let userSeassonTicket of event.userSeassonTickets" 
                [value]='userSeassonTicket.id' [disabled]="!isOpen(userSeassonTicket)?true:null">{{userSeassonTicket.name}}</option>
            </select>
            <ng-container *ngIf="selectedType!=' '">
                <ng-container *ngFor="let userSeassonTicket of event.userSeassonTickets">
                    <ng-container *ngIf="userSeassonTicket.id==selectedType">
                      <p class="mt-3" [innerHTML]="userSeassonTicket.description">
                      </p>
                      <h5>Periode</h5>
                      <strong>Data inici: </strong> {{printDate(userSeassonTicket.start_date)}}
                      <br>
                      <strong>Data fi: </strong> {{printDate(userSeassonTicket.end_date)}}
                    </ng-container>

                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
