export class User_season_ticket_error_class{
  name: Array<string>;
  price: Array<string>;
  price_couple: Array<string>;
  price_individual: Array<string>;
  price_fu: Array<string>;
  start_date: Array<string>;
  end_date: Array<string>;
  start_sell: Array<string>;
  end_sell: Array<string>;
  max_fu: Array<string>;
  constructor(){
    this.name = new Array<string>();
    this.price = new Array<string>();
    this.price_couple = new Array<string>();
    this.price_individual = new Array<string>();
    this.price_fu = new Array<string>();
    this.start_date = new Array<string>();
    this.end_date = new Array<string>();
    this.start_sell = new Array<string>();
    this.end_sell = new Array<string>();
  }
}
