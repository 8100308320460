import { Injectable } from '@angular/core';
import { Event_session_class } from '../class/Event_session_class';

@Injectable({
  providedIn: 'root'
})
export class EventSessionContainerService {
  event_session: Event_session_class;
  constructor() { }
}
