import { Event_alternative_price_class } from './Event_alternative_price_class';
import { Event_session_class } from './Event_session_class';
import { Question_class } from './Question_class';
import { User_season_ticket_class } from './User_season_ticket_class';
export class Event_class {
    apikey: string;
    id: string;
    user_name: string;
    name: string;
    num_tickets: number;
    sum_sessions_tickets: number;
    available_tickets: number;
    tot_attendants: number;
    tot_attendants_att: number;
    count_in: number;
    register_notes: string;
    organizers: string;
    address: string;
    map_link: string;
    organizer_link: string;
    date: number;
    image: string;
    image_zone: string;
    open_date: number;
    close_date: number;
    door_open_date: number;
    paid: boolean;
    price: number;
    management_cost: number;
    max_fu_attendants: number;
    tot_fu_attendants: number;
    accent_color: string;
    background: string;
    door_code: string;
    event_name: string;
    event_description: string;
    conditions: string;
    general_conditions: string;
    information: string;
    online_event_link: string;
    online_event_instructions: string;
    questions: Array<Question_class>;
    id_zone: string;
    register_individial: string;
    register_familiar_union: string;
    register_zones: string;
    archived: string;
    has_sessions: boolean;
    allow_individual_reg: boolean;
    allow_fu_reg: boolean;
    show_commission: boolean;
    btn_over: boolean;
    btn_over2: boolean;
    btn_over3: boolean;
    btn_over4: boolean;
    capacity: number;
    mcFromDate: Date;
    mcToDate: Date;
    mer_assist: boolean = false;
    sessions: Array<Event_session_class>;
    multiple: boolean;
    hasUserSeasonTicket: boolean;
    userSeassonTickets: Array<User_season_ticket_class>;
    have_alternative_price: boolean;
		altPrices: Array<Event_alternative_price_class>;
    selectedAltPrice: string = ' ';
    constructor(){
        this.questions = new Array<Question_class>();
        this.sessions = new Array<Event_session_class>();
        this.userSeassonTickets = new Array<User_season_ticket_class>();
		    this.altPrices = new Array<Event_alternative_price_class>();
        this.image = "";
        this.event_name = "";
        this.event_description ="";
        this.background = "";
        this.online_event_instructions = "";
        this.paid = false;
        this.has_sessions = false;
        this.show_commission = true;
        this.price = 0;
        this.management_cost = 0;
        this.allow_fu_reg = true;
        this.allow_individual_reg = true;
        this.btn_over4 = true;
    }
}
