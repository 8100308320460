import { Attendant_read_class } from './Attendant_read_class';
import { Payment_status_class } from './Payment_status_class';
import { Question_class } from './Question_class';

export class Attendant_class{
    id: string;
    id_event: string;
    name: string;
    entname?:string;
    surname: string;
    num: number;
    dni: string;
    nodni: boolean;
    mail: string;
    mail2: string;
    phone: string;
    color: string;
    entry_key: string;
    has_attended: string;
    inPerson: boolean;
    familiar_unit_id: string;
    time_attendance: string;
    time_register: string;
    door_keeper: string;
    error: string;
    edit_name: boolean = false;
    edit_surname: boolean = false;
    edit_dni: boolean = false;
    edit_mail: boolean;
    edit_phone: boolean;
    payment_status: string;
    allow_newsletter: boolean;
    questions: Array<Question_class>;
    manual: boolean;
    send_mail: boolean = false;
    show_entry: boolean = true;
    has_paied: boolean = true;
    id_session: number;
    apikey: string;
    first_fu: boolean;
    number_fu: number;
    count_fu: number;
    selectedUserSeasonTicket: string;
    selectedSection: string;
    selectedAltPrice: string;
    payment_status_list: Array<Payment_status_class>;
    key_reads: Array<Attendant_read_class>;
    last_read_type: string;
    has_key_reads: boolean;
    amount_paid: number;
    id_events_sessions: Array<any> = new Array<any>();
    constructor(){
        this.name = '';
        this.surname  = '';
        this.dni = '';
        this.mail = '';
        this.mail2 = '';
        this.selectedSection = ' ';
        this.selectedUserSeasonTicket = ' ';
        this.selectedAltPrice = ' ';
        this.edit_mail = false;
        this.edit_phone = false;
        this.allow_newsletter = false;
        this.payment_status_list = new Array<Payment_status_class>();
        this.key_reads = new Array<Attendant_read_class>();
        this.last_read_type = '';
    }
}
