import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Event_class } from 'src/app/class/Event_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import * as Constants from 'src/app/modules/constants/constants.module';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-list-events-from-user',
  templateUrl: './list-events-from-user.component.html',
  styleUrls: ['./list-events-from-user.component.css']
})
export class ListEventsFromUserComponent implements OnInit {
  events: Array<Event_class>;
  user_id: string;
  custom_color: string;
  constructor(public backend: BackendServiceService,
              public route: ActivatedRoute,
              public router: Router,
              public localLogin: LocalLoginService,
              public sanitizer: DomSanitizer) {
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    this.backend.getUserEvents(this.user_id).subscribe(data => {
          if(data['error']=='login_error'){
            this.backend.logout().subscribe(data=> {
              this.localLogin.remove(true);
              this.router.navigateByUrl("/");
            })
          }

          if(data){
            this.events = Object.assign(new Array<Event_class>(),data['events']);
            this.custom_color = data['custom_color'];
            //console.log(this.events);
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
  }

  getENT_URL(){
    return Constants.ENT_URL
  }

  getImageURL(event: Event_class){
    return 'url(\'' + this.getENT_URL() + 'images/'+event.id+'.jpg\')';
  }

  goToUserSeassonTicket(){
    this.router.navigate(['/evv', this.events[0].id, this.user_id, "US"]);
  }

  ngOnInit(): void {
  }

  dateFormat(date){
    date = new Date(date * 1000);
    let result = date.getDate();
    switch(date.getMonth()+1){
      case 1:
        result += ' gen.';
      break;
      case 2:
        result += ' feb.';
      break;
      case 3:
        result += ' mar.';
      break;
      case 4:
        result += ' abr.';
      break;
      case 5:
        result += ' mai.';
      break;
      case 6:
        result += ' jun.';
      break;
      case 7:
        result += ' jul.';
      break;
      case 8:
        result += ' ago.';
      break;

      case 9:
        result += ' set.';
      break;
      case 10:
        result += ' oct.';
      break;
      case 11:
        result += ' nov.';
      break;
      case 12:
        result += ' des.';
      break;
    }
    return result;
  }

  timeFormat(date){
    let fDate = new Date(date * 1000);
    return fDate.getHours() + ':' + (fDate.getMinutes()<10?'0':'') + fDate.getMinutes();
  }

}
