<app-payment-wait [idAttendant]="idAttendantP" [check]="checkAttendantP"></app-payment-wait>
<div class="form-group">
    <label>Correu</label>
    <input type="text" class="form-control" [(ngModel)]="mail" appBlockCopyPaste>
</div>
<div class="form-group">
    <label>Verificació correu</label>
    <input type="text" class="form-control" [(ngModel)]="mail2" appBlockCopyPaste>
</div>
<div class="alert alert-danger" role="alert" *ngFor="let mail_error of mail_errors">
    {{mail_error}}
</div>
<div class="alert alert-secondary" *ngIf="mail_errors.length>0" role="alert">
    <fa-icon [icon]="faInfoCircle" class="ml-1"></fa-icon> <small>Si el teu correu és correcte i et
        continua sortint el missatge d'error, si us plau obre el link de l'esdeveniment en un altre navegador.</small>
</div>
<div class="form-group">
    <label>Telèfon</label>
    <input type="text" class="form-control" [(ngModel)]="phone">
</div>
<div class="alert alert-danger" role="alert" *ngFor="let phone_error of phone_errors">
    {{phone_error}}
</div>
<hr>
<div *ngFor="let attendant of attendants; index as o" >
    <div class="float-right" *ngIf="o>=min_fu">
        <fa-icon class="close" [icon]="faTimesCircle" size="sm" (click)="removeAttendant(attendants,o)"></fa-icon>
    </div>
    <h4>Membre {{o+1}}</h4>
    <app-form-attendant [attendant]="attendant" [questions]="questions" mode="F"
      [seassonTicket]="seassonTicket&&((userSeassonTicket.allow_couple&&o>1)||(!userSeassonTicket.allow_couple))"
      [userSeassonTicket]="userSeassonTicket" [index]="o" [altPrices]="event.altPrices"
      [attendant_errors]="attendants_errors[o]">
    </app-form-attendant>
</div>
<button type="button" *ngIf="this.attendants.length<this.max_attendants && (this.backend.role != undefined || this.attendants.length < this.event.available_tickets)"
        class="btn btn-primary mt-3" style="width: 100%;" (click)="addAttendant()">Afegir un altre membre</button>

<ng-container *ngIf="event.general_conditions!=undefined&&event.general_conditions!=''">
    <div class="input-group mt-1">
        <div class="input-group-prepend">
            <div class="input-group-text">
                <input type="checkbox" [(ngModel)]="gConditionsAccepted">
            </div>
        </div>
        <label class="form-control check-group" style="height: unset;">He llegit i accepto les condicions que puc llegir <b><a (click)="showConditions(condition, event.general_conditions)">aqui</a></b></label>
    </div>
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of attendant_errors.general_condition">
        {{error | translate}}
    </div>
</ng-container>

<ng-container *ngIf="event.conditions!=undefined&&event.conditions!=''">
    <div class="input-group mt-1">
        <div class="input-group-prepend">
            <div class="input-group-text">
                <input type="checkbox" [(ngModel)]="conditionsAccepted">
            </div>
        </div>
        <label class="form-control check-group" style="height: unset;">He llegit i accepto les condicions que puc llegir <b><a (click)="showConditions(condition,event.conditions)">aqui</a></b></label>
    </div>
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of attendant_errors.condition">
        {{error | translate}}
    </div>
</ng-container>

<ng-container *ngIf="true">
    <div class="input-group mt-1">
        <div class="input-group-prepend">
            <div class="input-group-text">
                <input type="checkbox" [(ngModel)]="pConditionsAccepted">
            </div>
        </div>
        <label class="form-control check-group" style="height: unset;">{{'He llegit i accepto les condicions de privacitat que puc llegir' | translate }}<b><a [ngStyle]="{color: event.accent_color}" [href]="ent_url+'politica-privacitat'" target="_blank">aquí</a></b></label>
    </div>
    <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of attendant_errors.pConditions">
        {{error | translate}}
    </div>
</ng-container>

<ng-container *ngIf="true">
    <div class="input-group mt-1">
        <!--<div class="input-group-prepend">
            <div class="input-group-text">
                <input type="checkbox" [(ngModel)]="allow_newsletter">
            </div>
        </div>
        <label class="form-control check-group-2" style="height: unset;">Accepto rebre publicitat comercial per part de {{event.name}}.</label>-->
    </div>
</ng-container>
<ng-container *ngIf="manual">
    <div class="row mt-3">
        <div class="col-4 mt-1">
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="send_mail">
                <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="true"> Si
                </label>
                <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="false"> No
                </label>
            </div>
        </div>
        <div class="col-8 mt-1">
            <label class="col-form-label">
                Enviar correu amb l'entrada?
            </label>
        </div>
        <div class="col-4 mt-1">
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="show_entry">
                <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="true"> Si
                </label>
                <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="false"> No
                </label>
            </div>
        </div>
        <div class="col-8 mt-1">
            <label class="col-form-label">
                Mostrar entrada per pantalla?
            </label>
        </div>
        <div class="col-4 mt-1">
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="has_paied">
                <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="true"> Si
                </label>
                <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="false"> No
                </label>
            </div>
        </div>
        <div class="col-8 mt-1">
            <label class="col-form-label">
                Marcar com a pagat?
            </label>
        </div>
    </div>
</ng-container>
<hr>
<ng-container *ngFor="let event of events; index as index">
    <ng-container *ngIf="event.archived=='F'">
        <ng-container *ngIf="!event.has_sessions">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="event.mer_assist" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    {{event.event_name}}
                </label>
            </div>
            <ng-container *ngIf="event.mer_assist&&event.have_alternative_price; index as index">
                <ng-container *ngFor="let attendant of attendants">
                    <span>{{attendant.name + ' ' + attendant.surname + ' - ' + attendant.dni}}</span>
                    <div class="form-check">
                        <input class="form-check-input" [value]="' '" type="radio" [name]="'altPriceRadio'+index" [(ngModel)]="event.selectedAltPrice">
                        <label class="form-check-label">
                        Sense descompte - {{event.price}}€
                        </label>
                    </div>
                    <ng-container *ngFor="let altPrice of event.altPrices">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" [value]="altPrice.id" [name]="'altPriceRadio'+index" [(ngModel)]="event.selectedAltPrice">
                            <label class="form-check-label">
                                {{altPrice.description}} - {{altPrice.price}}€<br>
                            </label>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="min-width: 46px;">Preu total:</span>
                            </div>
                            <span class="form-control text-right" style="font-weight: bold; font-size: 22px;">
                                {{getPrice(event)}}
                            </span>
                            <div class="input-group-append">
                                <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <small *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                            Inclou {{event.management_cost*1}} € de despeses de gestió
                        </small>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="event.has_sessions">
            <div class="jumbotron p-1 mb-0">
            {{event.event_name}}<br>
            <ng-container *ngFor="let session of event.sessions; index as index2">
                <div class="form-check ml-3">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="session.mer_assist" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                        {{session.name}}
                    </label>
                    <ng-container *ngIf="session.mer_assist&&event.have_alternative_price">
                        <ng-container *ngFor="let attendant of attendants">
                            <br>
                            <span>{{attendant.name + ' ' + attendant.surname + ' - ' + attendant.dni}}</span>
                            <div class="ml-3">
                                <div class="form-check">
                                    <input class="form-check-input" [value]="' '" type="radio" [name]="'e' + index + 's' + index2 + 'altPriceRadio0'" [(ngModel)]="session.selectedAltPrice">
                                    <label class="form-check-label">
                                    Sense descompte - {{event.price}}€
                                    </label>
                                </div>
                                <ng-container *ngFor="let altPrice of event.altPrices; index as index3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" [value]="altPrice.id" [name]="'e' + index + 's' + index2 + 'altPriceRadio'+index3" [(ngModel)]="session.selectedAltPrice">
                                        <label class="form-check-label">
                                            {{altPrice.description}} - {{altPrice.price}}€<br>
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="min-width: 46px;">Preu total:</span>
                                        </div>
                                        <span class="form-control text-right" style="font-weight: bold; font-size: 22px;">
                                            {{getPriceS(event, session)}}
                                        </span>
                                        <div class="input-group-append">
                                            <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <small *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                                        Inclou {{event.management_cost*1}} € de despeses de gestió
                                    </small>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            </div>
        </ng-container>
        <!-- PREU -->
        <ng-container *ngIf="event.paid">
            <hr>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="min-width: 46px;">Preu total:</span>
                        </div>
                        <span class="form-control text-right" style="font-weight: bold; font-size: 22px;">
                            {{getPrice(event)}}
                        </span>
                        <div class="input-group-append">
                            <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <small *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                        Inclou {{event.management_cost*1}} € de despeses de gestió
                    </small>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<h1>PREU TOTAL: {{getTotalPrice()}}</h1>

<div class="alert alert-danger mt-1" role="alert" *ngFor="let error of general_error">
    {{error | translate}}
</div>
<div *ngIf="errorSaving" class="color-red">
    No s'ha pogut registrar, si us plau torni a intentar-ho
</div>
<button type="button" class="btn btn-c mt-3" style="width: 100%;"
  [ngStyle]="{
    'color': event.btn_over?'white':event.accent_color,
    'background-color': event.btn_over?event.accent_color:'white'}"
    (mouseover)="event.btn_over=true"
    (mouseout)="event.btn_over=false"
  *ngIf="!waitServer"
  (click)="confirm()">Reservar</button>
<div *ngIf="waitServer">
    Esperant resposta del servidor. Si us plau, esperi.
</div>
<div class="btn btn-c mt-3 text-center" *ngIf="waitServer">
    <img src="/assets/loader.gif" style="height: 23px;">
</div>

  <ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Reserva completada amb èxit</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <strong>La teva entrada/es s'ha generat correctament</strong><br><br>
        <a class="btn btn-succes btn-block" target="_blank" [href]="url+'bk/index.php/veure/entrada?key='+key+'&type='+attendantType">Mostra les entrades</a>
        <br>
        <strong class="color-red">NOTA IMPORTANT</strong>
        <br>
        Podeu visualitzar la vostra entrada/es fent click al botó "Mostra les entrades".<br><br>
        En breu també rebreu les entrades per correu electrònic. Degut a l'alta demanda aquest pot demorar-se fins a 12 hores.
        Si passat aquest temps no heu rebut el correu, si us plau poseu-vos en contacte 
        <a [href]="'mailto:'+supp_mail"> amb nosaltres 
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
        </a>.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modal.close('')">Tancar</button>
    </div>
</ng-template>

<ng-template #condition let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Condicions</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="conditions">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modal.close('')">Tancar</button>
    </div>
</ng-template>


<ng-template #alonefu let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        Ha seleccionat el registre com a inscripció múltiple i només ha introduït un registre, desitja registrar-se individualment?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Seguir afegint membres</button>
      <button type="button" class="btn btn-danger" (click)="modal.close('OK')">Només sóc jo</button>
    </div>
  </ng-template>
