import { Component, Input, OnInit } from '@angular/core';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';
import { Event_alternative_price_error_class } from 'src/app/class/Event_alternative_price_error_class';

@Component({
  selector: 'app-form-event-alternative-price',
  templateUrl: './form-event-alternative-price.component.html',
  styleUrls: ['./form-event-alternative-price.component.css']
})
export class FormEventAlternativePriceComponent implements OnInit {
  @Input() altPrice: Event_alternative_price_class;
  @Input() altPriceError: Event_alternative_price_error_class;
  constructor() { }

  ngOnInit(): void {
  }

}
