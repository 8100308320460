import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.css']
})
export class HeaderAdminComponent implements OnInit {
  user;
  inicial:string;
  menuTop:boolean=false;
  menuMovil:boolean=false;
  
  constructor(public router: Router,
              private localLogin: LocalLoginService,
              public backend: BackendServiceService) {
                
              }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('mesacces_login'));
    
    this.inicial = this.user.name['0']
    
  }

  toggleMenu(){
    this.menuTop = !this.menuTop
  }
  toggleMMenu(){
    this.menuMovil = !this.menuMovil
  }

  logout(){
    this.backend.logout().subscribe(data => {
      if(data){
        this.localLogin.remove();
        this.router.navigateByUrl("/");
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });;
  }
  getUser(u){
    this.user = u;
    console.log(this.user)
  }
}
