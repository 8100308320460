import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event_class } from '../class/Event_class'
import { Attendant_class } from '../class/Attendant_class'
import * as Constants from '../modules/constants/constants.module';
import { Configuration_class } from '../class/Configuration_class';
import { General_configuration_class } from '../class/General_configuration_class';
import { User_class } from '../class/User_class';
import { Zone_class } from '../class/Zone_class';
import { EventContainerService } from './event-container.service';
import { ZoneContainerService } from './zone-container.service';
import { UserContainerService } from './user-container.service';
import { User_season_ticket_class } from '../class/User_season_ticket_class';

@Injectable({
  providedIn: 'root'
})
export class BackendServiceService {
  apikey: string;
  name_user: string;
  user: string;
  role: string;
  door_code: string;
  accent_color: string;
  inPerson: boolean;
  manual: boolean;
  multiple_creator: boolean;
  comission_free: number;
  commission_low: number;
  commission_high: number;
  private options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  constructor(public httpClient: HttpClient,
    public eventContainer: EventContainerService,
    public userContainer: UserContainerService,
    public zoneContainer: ZoneContainerService) {
      /*this.apikey = '123';
      this.role = 'R';
      this.name_user = 'Carlos Arjona';
      this.accent_color = '#e2211c';*/
     }

  saveEvent(event: Event_class) {
    event.apikey = this.apikey;
    let postData = JSON.stringify(event);

    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'event_create',
                          postData,
                          this.options);
  }

  saveMEvent(event: Event_class) {
    event.apikey = this.apikey;
    let postData = JSON.stringify(event);

    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'multiple_event_create',
                          postData,
                          this.options);
  }

  getQuestionWithResponses(id_attendant: string){
    
    let postData = JSON.stringify(
      {"apikey": this.apikey,
        "id_attendant": id_attendant});
        
      return this.httpClient.post(
                    Constants.SVR_ADDR + 'get_questions_with_responses',
                    postData,
                    this.options);
  }

  getSelectedAltprice(id_attendant: string){
    let postData = JSON.stringify(
      {"apikey": this.apikey,
        "id_attendant": id_attendant});
      return this.httpClient.post(
                    Constants.SVR_ADDR + 'get_selected_alt_price',
                    postData,
                    this.options);
  }

  modifyEvent(event: Event_class) {
    event.apikey = this.apikey;
    let postData = JSON.stringify(event);

    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'event_modify',
                          postData,
                          this.options);
  }

  login(user:string,password:string) {
    this.apikey = undefined;
    this.role = undefined;
    this.door_code = undefined;
    let postData = JSON.stringify(
                  {"user_name": user,
                  "password":password});

    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'login',
                          postData,
                          this.options);
  }

  logout() {
    let postData = JSON.stringify({"apikey": this.apikey});
    this.apikey = undefined;
    this.role = undefined;
    this.door_code = undefined;
    this.eventContainer.set(undefined);
    this.userContainer.set(undefined);
    this.zoneContainer.set(undefined);
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'logout',
                          postData,
                          this.options);
  }

  getConfig() {
    let postData = JSON.stringify({"apikey": this.apikey});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_config',
                          postData,
                          this.options);
  }

  getGConfig(fields: Array<string>) {
    let postData = JSON.stringify({"apikey": this.apikey, "fields": fields});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_general_config',
                          postData,
                          this.options);
  }

  getUserConf(id: string){
    let postData = JSON.stringify({"id": id});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_user_conf',
                          postData,
                          this.options);
  }

  saveConfig(config: Configuration_class){
    config.apikey = this.apikey;
    let postData = JSON.stringify(config);
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'save_config',
                          postData,
                          this.options);
  }

  saveGConfig(config: General_configuration_class){
    let postData = JSON.stringify({"apikey": this.apikey, "fields": config});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'save_general_config',
                          postData,
                          this.options);
  }

  deleteEvent(id){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_event',
                          postData,
                          this.options);
  }

  deleteAttendant(id){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_attendant',
                          postData,
                          this.options);
  }

  deleteUstAttendant(id){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_ust_attendant',
                          postData,
                          this.options);
  }

  deleteAttendantByAttendant(ticket){
    let postData = JSON.stringify({"entry_key": ticket});
    //console.log(postData);
    return this.httpClient.post(
                        Constants.SVR_ADDR + 'cancel_attendant',
                        postData,
                        this.options);
}

  sendMail(attendant: Attendant_class){
    const fu_id = attendant.familiar_unit_id;
    let type = 'I';
    let id = attendant.id;
    if(fu_id!=undefined&&fu_id!=''){
      type = 'F';
      id = fu_id;
    }
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,"type": type});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'send_mail',
                          postData,
                          this.options);
  }

  sendMailUST(attendant: Attendant_class){
    const fu_id = attendant.familiar_unit_id;
    let type = 'I';
    let id = attendant.id;
    if(fu_id!=undefined&&fu_id!=''){
      type = 'F';
      id = fu_id;
    }
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,"type": type});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'send_mail_ust',
                          postData,
                          this.options);
  }

  deleteZone(id){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_zone',
                          postData,
                          this.options);
  }

  getAllEvents(user_name: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_all_events_full',
                          postData,
                          this.options);
  }

  getUserEvents(user_id: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_id": user_id});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_events_user_id',
                          postData,
                          this.options);
  }

  getAllUsers(){
    let postData = JSON.stringify({"apikey": this.apikey});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_all_users',
                          postData,
                          this.options);
  }

  getYearsWithEvents(user_name: string){
    let postData = JSON.stringify({
      "apikey": this.apikey,
      "user_name": user_name
    });
    return this.httpClient.post(
      Constants.ENT_URL + 'bk/statistics/get_years_with_events',
      postData,
      this.options);
  }

  getMonthsWithEvents(user_name: string, year: string){
    let postData = JSON.stringify({
      "apikey": this.apikey,
      "user_name": user_name,
      "year": year
    });
    return this.httpClient.post(
      Constants.ENT_URL + 'bk/statistics/get_months_with_events',
      postData,
      this.options);
  }

  getSummary(user_name: string, year: string, month: string,
        byDateRange: boolean, startDate: number, endDate: number){
    let postData = JSON.stringify({
                                    "apikey": this.apikey,
                                    "user_name": user_name,
                                    "year": year,
                                    "month": month,
                                    "byDateRange": byDateRange,
                                    "startDate": startDate,
                                    "endDate": endDate
                                  });
    //console.log(postData);
    return this.httpClient.post(
                          Constants.ENT_URL + 'bk/statistics/get_tickets_sumary',
                          postData,
                          this.options);
  }

  getUstSummary(user_name: string, year: string, month: string, 
          byDateRange: boolean, startDate: number, endDate: number){
    let postData = JSON.stringify({
                                    "apikey": this.apikey,
                                    "user_name": user_name,
                                    "year": year,
                                    "month": month,
                                    "byDateRange": byDateRange,
                                    "startDate": startDate,
                                    "endDate": endDate
                                  });
    //console.log(postData);
    return this.httpClient.post(
                          Constants.ENT_URL + 'bk/statistics/get_ust_sumary',
                          postData,
                          this.options);
  }

  get_user_stats(user_name: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.ENT_URL + 'bk/statistics/get_user_stats',
                          postData,
                          this.options);
  }

  cloneEvent(id_event: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "id": id_event});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'event_clone',
                          postData,
                          this.options);
  }

  getAllEventsByKey(){
    let postData = JSON.stringify({"apikey": this.apikey});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_all_events_full_key',
                          postData,
                          this.options);
  }

  getUserByKey(){
    let postData = JSON.stringify({"apikey": this.apikey});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_user_by_key',
                          postData,
                          this.options);
  }

  getUsers(a_user_name = ''){
    let postData = JSON.stringify({
      "apikey": this.apikey,
      "a_user_name": a_user_name
    });
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_users',
                          postData,
                          this.options);
  }
  getUserSeassonAttendants(idUserSeassonTicket){
    let postData = JSON.stringify({
      "apikey": this.apikey,
      "idUserSeassonTicket": idUserSeassonTicket
    });
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_user_seasson_ticket_attendants',
                          postData,
                          this.options);
  }

  getUsersSeasonTickets(user_name){
    let postData = JSON.stringify({
      "apikey": this.apikey,
      "user_name": user_name
    });
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_user_season_tickets',
                          postData,
                          this.options);
  }


  getAdminUsers(){
    let postData = JSON.stringify({"apikey": this.apikey});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_admin_users',
                          postData,
                          this.options);
  }

  getEvent(id_event: string){
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_event',
                          '{"id":"'+id_event+'"}',
                          this.options);
  }

  getPaymentStatus(id_attendant: string){
    return this.httpClient.post(
                            Constants.SVR_ADDR + 'get_payment_status',
                            '{"id":"'+id_attendant+'"}',
                            this.options);
  }

  getAttendants(id_event){
    let postData = JSON.stringify({"apikey": this.apikey,"id_event": id_event});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_event_attendants',
                          postData,
                          this.options);
  }

  getUserSeassonTicketAttendants(id_event){
    let postData = JSON.stringify({"apikey": this.apikey,"id_event": id_event});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_user_seasson_tick_attendants',
                          postData,
                          this.options);
  }

  getPaymentInfo(id_attendant){
    let postData = JSON.stringify({"id": id_attendant});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_payment_info',
                          postData,
                          this.options);
  }

  getPaymentInfoFU(id_fu){
    let postData = JSON.stringify({"id": id_fu});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_payment_info_fu',
                          postData,
                          this.options);
  }

  getZones(){
    let postData = JSON.stringify({"apikey": this.apikey});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_zones',
                          postData,
                          this.options);
  }

  getSubzones(id_zone: string){
    let postData = JSON.stringify({id_zone: id_zone});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_subzones',
                          postData,
                          this.options);
  }

  getAttendantsAdmin(id_event){
    let postData = JSON.stringify({"apikey": this.apikey,"id_event":id_event});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_event_attendants_admin',
                          postData,
                          this.options);
  }

  deleteUser(user_name: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_user',
                          postData,
                          this.options);
  }

  deleteUserSeasonTicket(id: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "id": id});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_user_season_ticket',
                          postData,
                          this.options);
  }

  saveZone(zone: Zone_class){
    zone.apikey = this.apikey;
    let postData = JSON.stringify(zone);

    return this.httpClient.post(
                          Constants.SVR_ADDR + 'zone_create',
                          postData,
                          this.options);
  }

  modifyZone(zone: Zone_class){
    zone.apikey = this.apikey;
    let postData = JSON.stringify(zone);

    return this.httpClient.post(
                          Constants.SVR_ADDR + 'zone_modify',
                          postData,
                          this.options);
  }

  saveUser(user: User_class){
    user.apikey = this.apikey;
    let postData = JSON.stringify(user);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_create',
                          postData,
                          this.options);
  }

  modifyUser(user: User_class){
    user.apikey = this.apikey;
    let postData = JSON.stringify(user);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_modify',
                          postData,
                          this.options);
  }

  saveUserSeasonTicket(user: User_season_ticket_class){
    user.apikey = this.apikey;
    let postData = JSON.stringify(user);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_season_ticket_create',
                          postData,
                          this.options);
  }

  saveUserSeasonTicketAttendant(attendant: Attendant_class){
    let postData = JSON.stringify(attendant);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_season_ticket_attendant_create',
                          postData,
                          this.options);
  }

  saveUserSeasonTicketFu(attendant: Array<Attendant_class>){
    let postData = JSON.stringify(attendant);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_season_ticket_fu_create',
                          postData,
                          this.options);
  }

  modifyUserSeasonTicket(user: User_season_ticket_class){
    user.apikey = this.apikey;
    let postData = JSON.stringify(user);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_season_ticket_modify',
                          postData,
                          this.options);
  }

  setArchivedUser(user_name: string, archived: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name,
                                    "archived": archived});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'set_archived_user',
                          postData,
                          this.options);
  }

  setArchivedEvent(id: string, archived: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "archived": archived});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'set_archived_event',
                          postData,
                          this.options);
  }

  modifyAttendantName(id: string, name: string, fu: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "name": name,
                                    "familiar_unit_id": fu
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'modify_attendant_name',
                          postData,
                          this.options);
  }

  modifyAttendantSurname(id: string, surname: string, fu: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "surname": surname,
                                    "familiar_unit_id": fu
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'modify_attendant_surname',
                          postData,
                          this.options);
  }

  modifyAttendantDni(id: string, dni: string, fu: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "dni": dni,
                                    "familiar_unit_id": fu
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'modify_attendant_dni',
                          postData,
                          this.options);
  }

  modifyAttendantMail(id: string, mail: string, fu: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "mail": mail,
                                    "familiar_unit_id": fu
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'modify_attendant_mail',
                          postData,
                          this.options);
  }

  modifyAttendantPhone(id: string, phone: string, fu: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "phone": phone,
                                    "familiar_unit_id": fu
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'modify_attendant_phone',
                          postData,
                          this.options);
  }

  modifyUstMail(id: string, mail: string, fu: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id,
                                    "mail": mail,
                                    "familiar_unit_id": fu
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'modify_ust_mail',
                          postData,
                          this.options);
  }

  addSatUsr(user_name: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'create_sat_user',
                          postData,
                          this.options);
  }

  markPrivacyConditions(){
    let postData = JSON.stringify({"apikey": this.apikey});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'mark_privacy_conditions',
                          postData,
                          this.options);
  }

  markAsAttended(id: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'mark_as_attended',
                          postData,
                          this.options);
  }

  addKeyRead(id: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'add_key_read',
                          postData,
                          this.options);
  }

  markAsPaied(id: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'mark_as_paied',
                          postData,
                          this.options);
  }

  markAsPaiedUST(id: string){
    let postData = JSON.stringify({"apikey": this.apikey,"id": id});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'mark_as_paied_ust',
                          postData,
                          this.options);
  }

  removeSatUsr(user_name: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'delete_sat_user',
                          postData,
                          this.options);
  }

  closeSession(id: number){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "id": id});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'close_session',
                          postData,
                          this.options);
  }

  getSessions(user_name: string){
    let postData = JSON.stringify({"apikey": this.apikey,
                                    "user_name": user_name});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_sessions',
                          postData,
                          this.options);
  }

  saveAttendant(attendant: Attendant_class){
    if(this.apikey!=undefined)
      attendant.apikey = this.apikey;
    let postData = JSON.stringify(attendant);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'attendant_create',
                          postData,
                          this.options);
  }

  saveSeassonTicket(attendant: Attendant_class){
    if(this.apikey!=undefined)
      attendant.apikey = this.apikey;
    let postData = JSON.stringify(attendant);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'user_season_ticket_create',
                          postData,
                          this.options);
  }

  saveAttendantAdmin(attendant: Attendant_class){
    attendant.apikey = this.apikey;
    let postData = JSON.stringify(attendant);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'attendant_create_admin',
                          postData,
                          this.options);
  }

  saveMEAttendantAdmin(attendant: Attendant_class){
    attendant.apikey = this.apikey;
    let postData = JSON.stringify(attendant);
    return this.httpClient.post(
                          Constants.ENT_URL + 'bk/register/create_multiple_event_attendant',
                          postData,
                          this.options);
  }                     

  saveFamiliarUnion(id_event: string, mail: string, phone: string,
                      allow_newsletter: boolean,
                      attendants: Array<Attendant_class>){
    let postData;
    if(this.apikey!=undefined)
      postData = JSON.stringify({id_event: id_event, mail: mail,
        allow_newsletter: allow_newsletter,
        phone: phone, attendants: attendants, apikey: this.apikey});
    else
      postData = JSON.stringify({id_event: id_event, mail: mail,
        allow_newsletter: allow_newsletter,
        phone: phone, attendants: attendants});
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'familiar_union',
                          postData,
                          this.options);
  }

  saveFamiliarUnionAdmin(id_event: string, mail: string, phone: string,
                          allow_newsletter: boolean,
                          attendants: Array<Attendant_class>){
    let postData = JSON.stringify({id_event: id_event, mail: mail,
                                    allow_newsletter: allow_newsletter,
                                    phone: phone, attendants: attendants,
                                    apikey: this.apikey
                                  });
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'familiar_union_admin',
                          postData,
                          this.options);
  }

  getTicketsME(attendants){
    let postData = JSON.stringify({attendants: attendants});
    let cOptions = this.options;
    cOptions['responseType'] = 'text';
    return this.httpClient.post(
                          Constants.ENT_URL + 'bk/tickets/view_me',
                          postData,
                          this.options);
  }

  getTicketsMEFU(attendants){
    let postData = JSON.stringify({attendants: attendants});
    let cOptions = this.options;
    cOptions['responseType'] = 'text';
    return this.httpClient.post(
                          Constants.ENT_URL + 'bk/tickets/view_me_fu',
                          postData,
                          this.options);
  }

  saveMEFamiliarUnionAdmin(id_event: string, mail: string, phone: string,
                          allow_newsletter: boolean,
                          attendants: Array<Attendant_class>){
    let postData = JSON.stringify({id_event: id_event, mail: mail,
                  allow_newsletter: allow_newsletter,
                  phone: phone, attendants: attendants,
                  apikey: this.apikey
                });
    return this.httpClient.post(
        Constants.ENT_URL + 'bk/register/create_multiple_event_fu',
        postData,
        this.options);
  }

  saveZoneAttendants(id_event: string, id_subzone: string,
                      attendants: Array<Attendant_class>){
    let postData = JSON.stringify({id_event: id_event, id_subzone: id_subzone,
                                    attendants: attendants});
    //console.log(postData);
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'attendant_zone',
                          postData,
                          this.options);
  }

  setApiKey(apikey:string){
    this.apikey = apikey;
  }

  getEventByAttendantKey(key: string){
    return this.httpClient.post(
                          Constants.SVR_ADDR + 'get_event_by_attendat_key',
                          '{"key":"'+key+'"}',
                          this.options);
  }


}
