export class Event_error_class{
    event_name: Array<string>;
    num_tickets: Array<string>;
    date: Array<string>;
    open_date: Array<string>;
    close_date: Array<string>;
    door_open_date: Array<string>;
    max_fu_attendants: Array<string>;
    capacity: Array<string>;
    constructor(){
        this.event_name = new Array<string>();
        this.num_tickets = new Array<string>();
        this.date = new Array<string>();
        this.open_date = new Array<string>();
        this.close_date = new Array<string>();
        this.door_open_date = new Array<string>();
        this.max_fu_attendants = new Array<string>();
        this.capacity = new Array<string>();
    }
}
