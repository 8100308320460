<div class="p-8 bg-lime-200 text-stone-800 fixed bottom-0 w-full" *ngIf="this.showBar">
    
        
                <p class="pt-2">
                    {{'Aquest web només instal·larà cookies analítiques i de publicitat comportamental si ho accepteu expressament.' | translate}}<br>
                    {{'Podeu revocar aquest consentiment, obtenir més informació i informar-vos dels vostres drets en la' | translate}} <a target="_blank" [href]="baseURL+'politica-cookies'" class="text-stone-900">{{'Política de cookies' | translate}}</a>
                </p>
            
            
                <button type="button" class="bg-stone-200 hover:bg-stone-300 text-base py-2 px-4 rounded my-4 mr-4" (click)="deactivateCookies()">{{'Cancel·lar' | translate}}</button>
                <button type="button" class="bg-white text-stone-900 hover:bg-lime-300 text-base py-2 px-4 rounded my-4" (click)="activateCookies()">{{'Acceptar' | translate}}</button>
           
        
</div>
