import { Component, OnInit, Input, Output, NgModule } from '@angular/core';
import { Question_class } from 'src/app/class/Question_class';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';
import { Fields_class } from 'src/app/class/Fields_class';

@Component({
  selector: 'app-form-attendant',
  templateUrl: './form-attendant.component.html',
  styleUrls: ['./form-attendant.component.css']
})
export class FormAttendantComponent implements OnInit {
  @Input() questions: Array<Question_class>;
  @Input() attendant: Attendant_class;
  @Input() attendant_errors: Attendant_class;
  @Input() mode: string;
  @Input() seassonTicket: boolean = false;
  @Input() userSeassonTicket: User_season_ticket_class;
  @Input() altPrices: Array<Event_alternative_price_class> = new Array<Event_alternative_price_class>();
  @Input() index: number = 0;
  @Input() fields: Fields_class = new Fields_class();
  faInfoCircle = faInfoCircle;
  constructor() {}

  ngOnInit(): void { }

  ngAfterContentInit(){
    this.setLists()
  }

  setLists(){
    
    this.attendant.questions = [];
    for(let i=0; i<this.questions.length; i++){
      this.questions[i].response.forEach((response)=>{
        if(response.order == 1){
          this.questions[i].response_id = response.id;
        }
      });
      
      this.attendant.questions.push(Object.assign(new Question_class(), this.questions[i]));
    }
    console.log(this.attendant.questions)
    this.attendant.questions = this.attendant.questions.sort((v1: any, v2: any) => v1['id'] < v2['id'] ? -1 : v1['id'] > v2['id'] ? 1 : 0);
    let altP = [];
    this.altPrices.forEach(ap=>{
      altP.push(Object.assign(new Event_alternative_price_class(),ap));
    });
    /*
    if(this.altPrices.length>0){
      this.attendant.selectedAltPrice = this.altPrices[0].id
    }
    */
  }
}
