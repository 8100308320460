import { Subzone_class } from './Subzone_class';
export class Zone_class{
    apikey: string;
    id: string;
    name: string;
    image: string;
    subzones: Array<Subzone_class>;
    constructor(){
        this.name = '';
        this.subzones = new Array<Subzone_class>();
    }
}