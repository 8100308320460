import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faEuroSign, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { Attendant_error_class } from 'src/app/class/Attendant_error_class';
import { Event_class } from 'src/app/class/Event_class';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { EventContainerService } from 'src/app/services/event-container.service';
import { EventSessionContainerService } from 'src/app/services/event-session-container.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import * as Constants from 'src/app/modules/constants/constants.module';
import { UserContainerService } from 'src/app/services/user-container.service';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';
import { User_class } from 'src/app/class/User_class';

@Component({
  selector: 'app-me-attendant',
  templateUrl: './me-attendant.component.html',
  styleUrls: ['./me-attendant.component.css']
})
export class MeAttendantComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  idAttendantP: string;
  public attendant: Attendant_class;
  events: Array<Event_class> = undefined;
  supp_mail: string;
  inPerson: boolean;
  manual: boolean = true;
  send_mail: boolean;
  show_entry: boolean;
  has_paied: boolean;
  attendant_errors: Attendant_error_class;
  attendantType: string = ' ';
  rsVersion: string;
  rsParams: string;
  rsSignature: string;
  rsLink: string;
  checkAttendantP: boolean;
  ent_url: string;
  idUserSeassonTicket: string;
  faEuroSign = faEuroSign;
  faQuestionCircle = faQuestionCircle;
  attendants: Array<Attendant_class> = new Array<Attendant_class>();

  constructor(private route: ActivatedRoute,
              public router: Router,
              public modalService: NgbModal,
              public userContainer: UserContainerService,
              public eventContainer: EventContainerService,
              public event_sesion_container: EventSessionContainerService,
              public attendantContainer: AttendantContainerService,
              private localLogin: LocalLoginService,
              public backend: BackendServiceService) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
      
    this.attendant_errors = new Attendant_error_class();
    this.attendantType = ' ';
    this.idAttendantP = '';
    this.checkAttendantP = false;
    this.supp_mail = Constants.SUPP_MAIL;
    this.ent_url = Constants.ENT_URL;
    if(this.backend.inPerson != undefined)
      this.inPerson = this.backend.inPerson;
    else
      this.inPerson = false;
    if(this.manual){
      this.send_mail = false;
      this.show_entry = true;
      this.has_paied = true;
    }
    this.loadData();
  }

  ngOnChanges(){
    if(this.events==undefined){
      this.loadData();
    }
  }

  

  ngOnInit(): void {
  }

  loadData(){
    this.attendant = new Attendant_class();
    this.attendant.questions = new Array();
    this.attendant.id_event = this.route.snapshot.paramMap.get("id_event");
    if(this.userContainer.user==undefined){
      if(this.backend.user!=undefined&& this.backend.user != ''){
        let u = new User_class();
        u.user_name = this.backend.user;
        this.userContainer.user = u;
      } else {
        this.userContainer.restore();
      }
    } else {
      this.userContainer.save();
    }
    this.getEvents();
  }

  getEvents(){
    // get event list
    this.backend.getAllEvents(this.userContainer.user.user_name).subscribe((data) => {
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }
      if(data){
        this.events = Object.assign(new Array<Event_class>(), data);
        this.events.forEach((event:Event_class)=>{
          event.sessions.forEach((session)=>{
            session.selectedAltPrice = ' ';
          });
        });
        //console.log(this.events);
      }
    });
  }

  saveAttendantAdmin(){
    this.backend.saveMEAttendantAdmin(this.attendant).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok']){
          this.attendant.entry_key = data['key'];
          let id = this.attendant.id_event;
          this.attendant = new Attendant_class();
          this.attendant.id_event = id;
          this.attendants = data['attendantsInfo'];
          //console.log(this.show_entry);
          if(this.show_entry){
            this.goToTicket();
          }
        } else if(!data['ok']&&data['error']=='dni_exists')
          this.attendant_errors.dni.push('El dni ja es troba registrat.');
        else if(data['error'] == 'sold_out')
          this.attendant_errors.general_error.push('Ho sentim, no queden reserves disponibles.');
      }
    }, error => {
      console.log(error);
    });
  }

  confirm(){
    let id_event_session: Array<Object> = new Array<Object>()
    this.events.forEach((event: Event_class)=>{
      if(event.mer_assist){
        id_event_session.push({"id_event": event.id, "id_session": undefined});
      }
      if(event.has_sessions){
        event.sessions.forEach((session: Event_session_class)=>{
          if(session.mer_assist)
            id_event_session.push({"id_event": event.id, "id_session": session.id});
        });
      }
    });
    this.attendant.id_events_sessions = id_event_session;
    //console.log(id_event_session); 

    //this.attendant.questions = this.event.questions;
    this.attendant.inPerson = this.inPerson;
    this.attendant.manual = this.manual;
    this.attendant.send_mail = this.send_mail;
    this.attendant.has_paied = this.has_paied;
    this.show_entry = !this.send_mail;
    this.attendant.show_entry = this.show_entry;
    if(this.attendant.nodni){
      this.attendant.dni = '-';
    }
    //console.log(this.attendant); exit();
    //console.log('%cSeasson ticket','background-color:red;color:white;');
    this.saveAttendantAdmin();
  }


  processType(type){
    //console.log(this.event);
    this.attendantType = type;
  }

  goToTicket(){
    this.attendantContainer.multiple = true;
    this.attendantContainer.attendants = this.attendants;
    //console.log(this.attendants);
    this.router.navigate(['/view-tickets-me-i']);
  }

  validate(): boolean{
    let result = true;
    let attendant: Attendant_class = this.attendant;
    let error: Attendant_error_class = new Attendant_error_class();
    if(this.isVoid(attendant.name)){
      error.name.push('Es obligatori introduir un nom.');
      result = false;
    }
    if(this.isVoid(attendant.surname)){
      error.surname.push('Es obligatori introduir un cognom.');
      result = false;
    }
    if(!attendant.nodni){
      if(this.isVoid(attendant.dni)){
        error.dni.push('Es obligatori introduir un DNI.');
        result = false;
      } else if(!this.isValidDNI(attendant.dni)){
        error.dni.push('Es obligatori introduir un DNI vàlid. El format correcte es 12345678A o X1234567A.');
        result = false;
      }
    } else {
      attendant.dni = '-';
    }
    if(!(this.inPerson||this.manual)){
      if(this.isVoid(attendant.mail)){
        error.mail.push('Es obligatori introduir un correu.');
        result = false;
      }
      if(attendant.mail!=attendant.mail2){
        error.mail.push('No coincideixen els dos camps de correu.');
        result = false;
      }
      if(!this.isMail(attendant.mail)){
        error.mail.push('El format del correu no es vàlid');
        result = false;
      }
    } else {
      if(this.isVoid(attendant.mail)){
        attendant.mail = '-';
      }
    }
    if(this.isVoid(attendant.phone)){
      error.phone.push('Es obligatori introduir un telèfon.');
      result = false;
    }
    this.attendant_errors = error;

    return result;
  }

  isValidDNI(text:string){
    if(text.length!=9)
      return false;
    let lastChar = "TRWAGMYFPDXBNJZSQVHLCKE";
    let nieValues = {"X":"0","Y":"1","Z":"2"};
    if(nieValues[text[0]]!=undefined)
      text = nieValues[text[0]] + text.substr(1);
    return lastChar[parseInt(text.substr(0,text.length-1))%23] == text.substr(text.length-1) ;
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  isMail(text){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
  }
  
  open() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      
    }, (reason) => {
      
    });
  }

  getPrice(event: Event_class){
    if(event.selectedAltPrice==undefined){
      event.selectedAltPrice = ' ';
    }
    if(event.selectedAltPrice == ' '){
      return event.price*1;
    } else {
      let price = 0;
      event.altPrices.forEach(altPrice => {
        if(altPrice.id == event.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  getPriceS(event: Event_class, session: Event_session_class){
    if(session.selectedAltPrice == ' '){
      return event.price*1;
    } else {
      let price = 0;
      event.altPrices.forEach(altPrice => {
        if(altPrice.id == session.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  getTotalPrice(){
    const events: Array<Event_class> = this.events;
    let total: number = 0;
    events.forEach((event: Event_class) => {
      if(event.paid){
        if(event.has_sessions){
          const sessions: Array<Event_session_class> = event.sessions;
          sessions.forEach((session: Event_session_class) => {
            if(session.mer_assist){
              if(event.have_alternative_price){
                const altPrices: Array<Event_alternative_price_class> = event.altPrices;
                const idSelAltPrice: string = session.selectedAltPrice;
                let selAltPrice = false;
                altPrices.forEach((altPrice: Event_alternative_price_class) => {
                  if(altPrice.id==idSelAltPrice){
                    total += parseFloat(altPrice.price.toString());
                    selAltPrice = true;
                  }
                });
                if(!selAltPrice){
                  total += parseFloat(event.price.toString());
                }
              } else {
                total += parseFloat(event.price.toString());
              }
            }
          });
        } else {
          if(event.mer_assist){
            if(event.have_alternative_price){
              const altPrices: Array<Event_alternative_price_class> = event.altPrices;
              const idSelAltPrice: string = event.selectedAltPrice;
              let selAltPrice = false;
              altPrices.forEach((altPrice: Event_alternative_price_class) => {
                if(altPrice.id==idSelAltPrice){
                  total += parseFloat(altPrice.price.toString());
                  selAltPrice = true;
                }
              });
              if(!selAltPrice){
                total += parseFloat(event.price.toString());
              }
            } else {
              total += parseFloat(event.price.toString());
            }
          }
        }
      }
    });
    return total;
  }

}
