<div class="d-print-none" [ngStyle]="{'background-color': accent_color}" style="color: #212529; line-height: 1.2;">
  <div style="background-color: rgba(255,255,255,0.90); padding-bottom: 30px;">
      <div class="container pt-3 pb-3">
          <div class="row">
              <div class="col-12" style="font-size: 20px;">
                Benvingut, <br><strong>{{name_user}}</strong>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col 12">
          <div class="row">
            <div class="col-6">
              <h3>Recintes actius</h3>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-warning float-right" routerLink="/create-zone" style="width: 192px;"><fa-icon [icon]="faPlusSquare"></fa-icon>&nbsp;Recinte</button>
            </div>
          </div>
          <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Llotges</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let zone of zones; index as i">
                <td>
                  {{ zone.name }}
                </td>
                <td>{{ zone.subzones.length }}</td>
                <td>
                  <button type="button" class="btn btn-succes" (click)="edit(zone)">Editar</button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger" [ngStyle]="{'background-color': accent_color}" (click)="open(content,zone)">Eliminar</button>
                </td>
                <td>
                  <button type="button" class="btn btn-warning" (click)="duplicate(zone)">Duplicar</button>
                </td>
              </tr>
              </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Eliminar la llotja <b>{{delete_zone_name}}?</b>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Cancel·lar</button>
      <button type="button" class="btn btn-danger" (click)="modal.close('DELETE')">Eliminar</button>
    </div>
  </ng-template>
