export class User_season_ticket_section_class{
  id: string;
  id_user_season_ticket: string;
  description: string;
  price: number;
  order: number;
  constructor(){
    this.price = 0;
  }
}
