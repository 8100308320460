<div class="container mt-4">
  <div class="row">
    <div class="col-8 offset-2">
      <select class="form-control mb-4" [(ngModel)]="user_name" (change)="getSessions()">
        <option *ngFor="let user of users">{{user.user_name}}</option>
      </select>
    </div>
    <div class="col-12" *ngFor="let session of sessions">
      <div class="alert borderc pb-5" role="alert">
        <h3>
          <fa-icon [icon]="getOSIcon(session.os_id)"></fa-icon>&nbsp;{{session.os_name}}
          &nbsp;&nbsp;&nbsp;
          <fa-icon [icon]="getBrowserIcon(session.browser_id)"></fa-icon>&nbsp;{{session.browser_name}}
        </h3>
        <small><b>Inici de sessió:&nbsp;</b>{{session.creation}}</small>&nbsp;&nbsp;
        <small><b>Últim moviment:&nbsp;</b>{{session.last_move}}</small>&nbsp;&nbsp;
        <small><b>Direcció IP:&nbsp;</b>{{session.ip}}</small>
        <button class="btn btn-succes borderc float-right" (click)="closeSession(session.id)">Tancar</button>
      </div>

    </div>
  </div>
</div>
