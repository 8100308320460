<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <button (click)="lang='cat'">CAT</button><button (click)="lang='es'">ES</button>
        </div>
        <ng-container *ngIf="lang=='cat'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>POLÍTICA DE COOKIES</h3>
                </div>
                <div class="col-12 text-center">
                    <h4>INFORMACIÓ SOBRE COOKIES</h4>
                </div>
                <p>
                    A causa de l’entrada en vigor de la referent modificació de la «Llei de Serveis de la Societat de la Informació» (LSSICE) establerta pel Reial Decret 13/2012, és obligat obtenir el consentiment exprés de l’usuari de totes les pàgines web que utilitzen cookies prescindibles, abans que aquest navegui per elles. 
                </p>
                <div class="col-12 text-center">
                    <h4>QUÈ SÓN LES COOKIES?</h4>
                </div>
                <p>
                    Les cookies i altres tecnologies similars com ara local shared objects, flash cookies o píxels, són eines emprades pels servidors Web per emmagatzemar i recuperar informació sobre els seus visitants, així com per oferir un correcte funcionament del lloc.<br>
                    Mitjançant l’ús d’aquests dispositius es permet al servidor Web recordar algunes dades referents a l’usuari, com les seves preferències per a la visualització de les pàgines d’aquest servidor, nom i contrasenya, productes que més li interessen, etc.
                </p>
                <div class="col-12 text-center">
                    <h4>COOKIES AFECTADES PER LA NORMATIVA I COOKIES EXCEPTUADES</h4>
                </div>
                <p>
                    Segons la directiva de la UE, les cookies que requereixen el consentiment informat per part de l’usuari són les cookies analítiques i les de publicitat i afiliació, i queden exceptuades les de caràcter tècnic i les necessàries per al funcionament del lloc web o la prestació de serveis expressament sol·licitats per l’usuari.
                </p>
                <div class="col-12 text-center">
                    <h4>TIPUS DE COOKIES</h4>
                </div>
                <h5>
                    SEGONS LA FINALITAT 
                </h5>
                <ul>
                    <li>
                        <b>Cookies tècniques i funcionals:</b> són les que permeten a l’usuari navegar a través d’una pàgina web, plataforma o aplicació i utilitzar les diferents opcions o serveis que hi hagi. 
                    </li>
                    <li>
                        <b>Cookies d’anàlisi:</b> són les que permeten al responsable d’aquestes monitoritzar i analitzar el comportament dels usuaris dels llocs web als quals estan vinculats. La informació recollida mitjançant aquest tipus de cookies s’utilitza en el mesurament de l’activitat dels llocs web, aplicació o plataforma i per elaborar perfils de navegació dels usuaris d’aquests llocs, aplicacions i plataformes, per tal d’introduir millores en funció de l’anàlisi de les dades d’ús que fan els usuaris del servei.
                    </li>
                    <li>
                        <b>Cookies publicitàries:</b> són aquelles que permeten gestionar, de la manera més eficaç possible, els espais publicitaris que, si escau, l’editor hagi inclòs en una pàgina web, aplicació o plataforma des de la qual presta el servei sol·licitat segons criteris com el contingut editat o la freqüència en què es mostren els anuncis. 
                    </li>
                    <li>
                        <b>Cookies de publicitat comportamental:</b> recullen informació sobre les preferències i eleccions personals de l’usuari (retargeting) per permetre gestionar, de la manera més eficaç possible, els espais publicitaris que, si escau, l’editor hagi inclòs en una pàgina web, aplicació o plataforma des de la qual presta el servei sol·licitat.
                    </li>
                    <li>
                        <b>Cookies socials:</b> són aquelles que han estat instal·lades per les plataformes de xarxes socials en els serveis per permetre-us compartir contingut amb els vostres amics i xarxes. Les plataformes de mitjans socials tenen la capacitat de rastrejar la vostra activitat en línia fora dels Serveis. Això pot afectar el contingut i els missatges que veieu en altres serveis que visiteu.
                    </li>
                    <li>
                        <b>Cookies d’afiliats:</b> permeten fer un seguiment de les visites procedents d’altres webs, amb les quals el lloc web estableix un contracte d’afiliació (empreses d’afiliació).
                    </li>
                    <li>
                        <b>Cookies de seguretat:</b> emmagatzemen informació xifrada per evitar que les dades que s’hi guarden siguin vulnerables a atacs maliciosos de tercers.
                    </li>
                </ul>
                <h5>
                    SEGONS LA PROPIETAT 
                </h5>
                <ul>
                    <li>
                        <b>Cookies pròpies:</b> són les que s’envien a l’equip terminal de l’usuari des d’un equip o domini gestionat pel propi editor i des del qual es proporciona el servei sol·licitat per l’usuari. 
                    </li>
                    <li>
                        <b>Cookies de tercers:</b> són les que s’envien a l’equip terminal de l’usuari des d’un equip o domini que no és gestionat per l’editor, sinó per una altra entitat que tracta les dades obtingudes mitjançant les cookies.
                    </li>
                </ul>
                <h5>
                    SEGONS EL TERMINI DE CONSERVACIÓ 
                </h5>
                <ul>
                    <li>
                        <b>Cookies de sessió:</b> són un tipus de cookies dissenyades per obtenir i emmagatzemar dades mentre l’usuari accedeix a una pàgina web. 
                    </li>
                    <li>
                        <b>Cookies persistents:</b> són un tipus de cookies en què les dades segueixen emmagatzemades en el terminal i poden ser accedides i tractades durant un període definit pel responsable de la cookie, i que durar des d’uns minuts a diversos anys.
                    </li>
                </ul>
                <div class="col-12 text-center">
                    <h4>TRACTAMENT DE DADES PERSONALS</h4>
                </div>
                <p>
                    RAIBOR ESPECTACLES S.L és el <b>Responsable del tractament</b> de les dades personals de l’<b>Interessat</b> i l’informa que aquestes dades es tractaran de conformitat amb el que estableix el Reglament (UE) 2016/679, de 27 d’abril de 2016 (GDPR), per la qual cosa se li facilita la següent informació del tractament:<br>
                    <b>Finalitats del tractament:</b> segons s’especifica en l’apartat de cookies que s’utilitzen en aquest lloc web.<br>
                    <b>Legitimació del tractament:</b> per consentiment de l’interessat (art. 6.1 GDPR). <br>
                    <b>Criteris de conservació de les dades:</b> segons s’especifica en l’apartat de cookies utilitzades al web.<br>
                    <b>Comunicació de les dades:</b> no es comunicaran les dades a tercers, excepte en cookies propietat de tercers o per obligació legal.<br>
                    <b>Drets que té l’Interessat:</b><br>
                    - Dret a retirar el consentiment en qualsevol moment.<br>- Dret d’accés, rectificació, portabilitat i supressió de les seves dades i de limitació o oposició al seu tractament.<br>- Dret a presentar una reclamació davant l’Autoritat de control (www.aepd.es) si considera que el tractament no s’ajusta a la normativa vigent.<br>
                    <b>Dades de contacte per exercir els seus drets:</b><br>
                    RAIBOR ESPECTACLES S.L. C/ SAVINES, 1 - 25200 CERVERA (Lleida). E-mail: info@oa2produccions.cat
                </p>
            </div>
            <div class="col-12 text-center">
                <h4>COOKIES UTILITZADES EN AQUEST LLOC WEB</h4>
            </div>
            <div class="col-12 text-center">
                <h5>COOKIES CONTROLADES PER L’EDITOR</h5>
            </div>
            <div class="col-12 text-center">
                <h5>Analitiques</h5>
            </div>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Propietat</th>
                        <th scope="col">Cookie</th>
                        <th scope="col">Finalitat</th>
                        <th scope="col">Plaç</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga</th>
                        <td>ID s'utilitza per identificar els usuaris</td>
                        <td>en 2 anys</td>
                    </tr>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga_&lt;container-id&gt;</th>
                        <td>Conserva l'estat de la sessió.</td>
                        <td>en 2 anys</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Els serveis de tercers són aliens al control de l’editor. Els proveïdors poden modificar en qualsevol moment les seves condicions de servei, finalitat i ús de les cookies, etc.
            </p>
            <h5>Proveïdors externs d’aquest lloc web:</h5>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Editor</th>
                        <th scope="col">Política de privacitat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Google Analytics</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4>PANELL DE CONFIGURACIÓ DE COOKIES</h4>
            <p>
                Des d’aquest panell pot configurar les cookies que el lloc web pot instal·lar en el seu navegador, excepte les cookies tècniques o funcionals que són necessàries per a la navegació i la utilització de les diferents opcions o serveis que s’ofereixen.<br>
                <button type="button" class="btn btn-secondary">Accés al panell</button>
            </p>
            <h4>COM GESTIONAR LES COOKIES DES DEL NAVEGADOR</h4>
            <div class="col-12">
                <h5>Eliminar les cookies del dispositiu </h5>
                <p>
                    Les cookies que ja es troben en un dispositiu es poden eliminar esborrant l’historial del navegador, de manera que se suprimeixen les cookies de tots els llocs web visitats.<br>No obstant això, també es pot perdre part de la informació desada (per exemple, les dades d’inici de sessió o les preferències de lloc web).
                </p>
            </div>
            <div class="col-12">
                <h5>Gestionar les cookies específiques del lloc </h5>
                <p>
                    Per tenir un control més precís de les cookies específiques de cada lloc, els usuaris poden ajustar la seva configuració de privacitat i cookies al navegador.
                </p>
            </div>
            <div class="col-12">
                <h5>Bloquejar les cookies </h5>
                <p>
                    Encara que la majoria dels navegadors moderns es poden configurar per evitar que s’instal·lin cookies en els dispositius, això pot obligar a ajustar manualment determinades preferències cada vegada que es visiti un lloc o pàgina. A més, alguns serveis i característiques poden no funcionar correctament (per exemple, els inicis de sessió amb perfil).
                </p>
            </div>
            <h4>COM ELIMINAR LES COOKIES DELS NAVEGADORS MÉS COMUNS</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <td>Chrome</td>
                        <td>
                            <a href="http://support.google.com/chrome/answer/95647?hl=es">
                                http://support.google.com/chrome/answer/95647?hl=es
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Internet Explorer. Versió 11</td>
                        <td>
                            <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                                https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Firefox. Versió 65.0.1</td>
                        <td>
                            <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies">
                                https://www.mozilla.org/es-ES/privacy/websites/#cookies
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Safari Versió 5.1</td>
                        <td>
                            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                                https://support.apple.com/es-es/guide/safari/sfri11471/mac
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Opera</td>
                        <td>
                            <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">
                                https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="lang=='es'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>POLÍTICA DE COOKIES</h3>
                </div>
                <div class="col-12 text-center">
                    <h4>INFORMACIÓN SOBRE COOKIES</h4>
                </div>
                <p>
                    Debido a la entrada en vigor de la referente modificación de la «Ley de Servicios de la Sociedad de la Información» (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de todas las páginas web que usan cookies prescindibles, antes de que este navegue por ellas. 
                </p>
                <div class="col-12 text-center">
                    <h4>¿QUÉ SON LAS COOKIES?</h4>
                </div>
                <p>
                    Las cookies y otras tecnologías similares tales como local shared objects, flash cookies o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer un correcto funcionamiento del sitio.<br>
                    Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le interesan, etc.
                </p>
                <div class="col-12 text-center">
                    <h4>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS</h4>
                </div>
                <p>
                    Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.
                </p>
                <div class="col-12 text-center">
                    <h4>TIPOS DE COOKIES</h4>
                </div>
                <h5>
                    SEGÚN LA FINALIDAD 
                </h5>
                <ul>
                    <li>
                        <b>Cookies técnicas y funcionales: </b>son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.
                    </li>
                    <li>
                        <b>Cookies analíticas:</b> son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.
                    </li>
                    <li>
                        <b>Cookies publicitarias:</b> son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios. 
                    </li>
                    <li>
                        <b>Cookies de publicidad comportamental:</b> recogen información sobre las preferencias y elecciones personales del usuario (retargeting) para permitir la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado.
                    </li>
                    <li>
                        <b>Cookies sociales:</b> son establecidas por las plataformas de redes sociales en los servicios para permitirle compartir contenido con sus amigos y redes. Las plataformas de medios sociales tienen la capacidad de rastrear su actividad en línea fuera de los Servicios. Esto puede afectar al contenido y los mensajes que ve en otros servicios que visita.
                    </li>
                    <li>
                        <b>Cookies de afiliados:</b> permiten hacer un seguimiento de las visitas procedentes de otras webs, con las que el sitio web establece un contrato de afiliación (empresas de afiliación).
                    </li>
                    <li>
                        <b>Cookies de seguridad:</b> almacenan información cifrada para evitar que los datos guardados en ellas sean vulnerables a ataques maliciosos de terceros.
                    </li>
                </ul>
                <h5>
                    SEGÚN LA PROPIEDAD 
                </h5>
                <ul>
                    <li>
                        <b>Cookies propias:</b> son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario. 
                    </li>
                    <li>
                        <b>Cookies de terceros:</b> son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.
                    </li>
                </ul>
                <h5>
                    SEGÚN EL PLAZO DE CONSERVACIÓN 
                </h5>
                <ul>
                    <li>
                        <b>Cookies de sesión:</b> son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. 
                    </li>
                    <li>
                        <b>Cookies persistentes:</b> son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un período definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.
                    </li>
                </ul>
                <div class="col-12 text-center">
                    RAIBOR ESPECTACLES S.L es el <b>Responsable del tratamiento</b> de los datos personales del <b>Interesado</b> y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la siguiente información del tratamiento:
                </div>
                <p>
                    RAIBOR ESPECTACLES S.L és el <b>Responsable del tractament</b> de les dades personals de l’<b>Interessat</b> i l’informa que aquestes dades es tractaran de conformitat amb el que estableix el Reglament (UE) 2016/679, de 27 d’abril de 2016 (GDPR), per la qual cosa se li facilita la següent informació del tractament:<br>
                    <b>Fines del tratamiento:</b> según se especifica en el apartado de cookies que se utilizan en este sitio web.<br>
                    <b>Legitimación del tratamiento:</b> por consentimiento del interesado (art. 6.1 GDPR). <br>
                    <b>Criterios de conservación de los datos:</b> según se especifica en el apartado de cookies utilizadas en la web. <br>
                    <b>Comunicación de los datos:</b> no se comunicarán los datos a terceros, excepto en cookies propiedad de terceros o por obligación legal. <br>
                    <b>Derechos que asisten al Interesado:</b> <br>
                    - Derecho a retirar el consentimiento en cualquier momento. <br>- Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento. <br>- Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente. <br>
                    <b>Datos de contacto para ejercer sus derechos:</b> <br>
                    RAIBOR ESPECTACLES S.L. C/ SAVINES, 1 - 25200 CERVERA (Lleida). E-mail: info@oa2produccions.cat
                </p>
            </div>
            <div class="col-12 text-center">
                <h4>COOKIES UTILIZADAS EN ESTE SITIO WEB</h4>
            </div>
            <div class="col-12 text-center">
                <h5>COOKIES CONTROLADAS POR EL EDITOR</h5>
            </div>
            <div class="col-12 text-center">
                <h5>Analíticas</h5>
            </div>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Propiedad</th>
                        <th scope="col">Cookie</th>
                        <th scope="col">Finalidad</th>
                        <th scope="col">Plazo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga</th>
                        <td>ID utiliza para identificar a los usuarios</td>
                        <td>en 2 años</td>
                    </tr>
                    <tr>
                        <td>oa2produccions.cat</td>
                        <th scope="row">_ga_&lt;container-id&gt;</th>
                        <td>Conserva el estdo de la sesión.</td>
                        <td>en 2 años</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Los servicios de terceros son ajenos al control del editor. Los proveedores pueden modificar en todo momento sus condiciones de servicio, finalidad y utilización de las cookies, etc.
            </p>
            <h5>Proveedores externos de este sitio web:</h5>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Editor</th>
                        <th scope="col">Política de privacidad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Google Analytics</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>
                            <a href="https://privacy.google.com/take-control.html">
                                https://privacy.google.com/take-control.html
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4>PANEL DE CONFIGURACIÓN DE COOKIES</h4>
            <p>
                Desde este panel podrá configurar las cookies que el sitio web puede instalar en su navegador, excepto las cookies técnicas o funcionales que son necesarias para la navegación y la utilización de las diferentes opciones o servicios que se ofrecen.<br>
                <button type="button" class="btn btn-secondary">Acceso al panel</button>
            </p>
            <h4>CÓMO GESTIONAR LAS COOKIES DESDE EL NAVEGADOR</h4><br>
            <div class="col-12">
                <h5>Eliminar las cookies del dispositivo </h5>
                <p>
                    Las cookies que ya están en un dispositivo se pueden eliminar borrando el historial del navegador, con lo que se suprimen las cookies de todos los sitios web visitados.<br>Sin embargo, también se puede perder parte de la información guardada (por ejemplo, los datos de inicio de sesión o las preferencias de sitio web).
                </p>
            </div>
            <div class="col-12">
                <h5>Gestionar las cookies específicas del sitio </h5>
                <p>
                    Para tener un control más preciso de las cookies específicas de cada sitio, los usuarios pueden ajustar su configuración de privacidad y cookies en el navegador.
                </p>
            </div>
            <div class="col-12">
                <h5>Bloquear las cookies </h5>
                <p>
                    Aunque la mayoría de los navegadores modernos se pueden configurar para evitar que se instalen cookies en los dispositivos, eso puede obligar al ajuste manual de determinadas preferencias cada vez que se visite un sitio o página. Además, algunos servicios y características pueden no funcionar correctamente (por ejemplo, los inicios de sesión con perfil).
                </p>
            </div>
            <h4>CÓMO ELIMINAR LAS COOKIES DE LOS NAVEGADORES MÁS COMUNES</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <td>Chrome</td>
                        <td>
                            <a href="http://support.google.com/chrome/answer/95647?hl=es">
                                http://support.google.com/chrome/answer/95647?hl=es
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Internet Explorer. Versió 11</td>
                        <td>
                            <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                                https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Firefox. Versió 65.0.1</td>
                        <td>
                            <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies">
                                https://www.mozilla.org/es-ES/privacy/websites/#cookies
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Safari Versió 5.1</td>
                        <td>
                            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                                https://support.apple.com/es-es/guide/safari/sfri11471/mac
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Opera</td>
                        <td>
                            <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">
                                https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>

