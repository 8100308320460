<h4>Sessions</h4>
<ng-container *ngFor="let session of sessions; index as o">
    <div class="float-right" *ngIf="o>0">
        <fa-icon [icon]="faTimesCircle" size="lg" (click)="removeSession(sessions,o)"></fa-icon>
    </div>
    <h5>Sessió {{o+1}}</h5>
    <app-event-session-form [event_session]="session" [event_session_error]="sessions_errors[o]"></app-event-session-form>
    <hr>
</ng-container>
<button type="button" class="btn btn-primary mt-3" style="width: 100%;" (click)="addSession()">Afegir un altra sessió</button>
