import { Component, Input, OnInit } from '@angular/core';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-payment-wait',
  templateUrl: './payment-wait.component.html',
  styleUrls: ['./payment-wait.component.css']
})
export class PaymentWaitComponent implements OnInit {
  @Input() idAttendant: string;
  @Input() check: boolean;
  @Input() payment_status: string;
  checkCnt: number;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;

  constructor(public backend: BackendServiceService) {
    this.payment_status = '';
    this.check = false;
    this.checkCnt = 0;
    this.checkPaymentStatus(this);
  }

  ngOnInit(): void {
  }

  checkPaymentStatus(obj){
    // 5s(timeout) * 60veces = 300s = 5m
    if(this.check && this.checkCnt < 60){
      this.backend.getPaymentStatus(this.idAttendant).subscribe(data => {
        //console.log(data);
        if(data){
          this.payment_status = data['payment_status'];
        }
        else{
          //Controlled error
        }
      }, error => {
        console.log(error);
      });
      this.checkCnt += 1;
      console.log("Check " + this.checkCnt);
    }
    setTimeout((obj) => obj.checkPaymentStatus(obj),5000,obj);
  }

}
