import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Event_class } from 'src/app/class/Event_class';

@Component({
  selector: 'app-select-attendant-type',
  templateUrl: './select-attendant-type.component.html',
  styleUrls: ['./select-attendant-type.component.css']
})
export class SelectAttendantTypeComponent implements OnInit {
  @Input() event: Event_class = new Event_class();
  @Output() confirmType = new EventEmitter<string>();
  selectedType:string;
  constructor() {
    this.selectedType = ' ';
  }

  ngOnInit(): void {
  }

  confirm(){
    this.event.btn_over = false;
    this.confirmType.emit(this.selectedType);
  }

}
