<div class="seassonPassBar">
  <button type="button" class="btn btn-register"
      [ngStyle]="{
        'color': events[0].btn_over4?'white':events[0].accent_color,
        'background-color': events[0].btn_over4?events[0].accent_color:'white'}"
        (mouseover)="events[0].btn_over4=false"
        (mouseout)="events[0].btn_over4=true"
        *ngIf="events[0].hasUserSeasonTicket"
        (click)="goToUserSeassonTicket()"
        >Accedir als Abonaments</button>
</div>
<div class="p-container mt-4">
  <div class="container container-list mt-4">
    <div class="row">
        <div class="col-12 borderc event-line text-center"
          style="height: unset; padding-top: 5px;"
          *ngIf="events==undefined || events.length<1">
          <h2>No s'han trobat esdeveniments</h2>
        </div>
        <div class="col-12 borderc event-line" *ngFor="let event of events">
            <div class="row data-container">
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 event-image" [ngStyle]="{'background-image': getImageURL(event)}">
                    <div class="over-text">
                        <div class="date-text">
                            {{dateFormat(event.date)}}<br>{{timeFormat(event.date)}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-9 col-lg-6 col-xl-8 event-text">
                    <span class="event-title">
                      {{event.event_name}}
                    </span>
                    <div class="event-info">
                        {{event.address}} <span class="d-none d-sm-inline">|&nbsp;</span><br class="d-inline d-sm-none">
                        <a [href]="event.map_link" target="_blank"
                        [ngStyle]="{
                          'color': event.over_link?event.accent_color:'black'}"
                          (mouseover)="event.over_link=true"
                          (mouseout)="event.over_link=false"
                          target="_blank">
                            Com arribar-hi?
                        </a>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-2 event-buttons">
                    <div class="btn-container row">
                        <!--<div class="color-red" *ngIf="event.multiple">
                          Aforament {{event.count_in}}/{{ event.has_sessions?event.sum_sessions_tickets:event.num_tickets }}
                        </div>-->
                        <div class="price-tag" *ngIf="!event.paid">Esdeveniment gratuït</div>
                        <div class="price-tag" *ngIf="event.paid">De pagament - {{event.price*1}}€</div>
                        <a [routerLink]="['/evv', event.id, user_id, 'T']">
                          <div class="event-btn"
                            [ngStyle]="{'color': event.btn_over?'white':custom_color,
                            'background-color': event.btn_over?custom_color:'white'}"
                            (mouseover)="event.btn_over=true"
                            (mouseout)="event.btn_over=false">
                            VÉS A LA PÀGINA D'INSCRIPCIÓ
                          </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

