import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Session_class } from 'src/app/class/Session_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { faChrome, faFirefoxBrowser, faSafari, faOpera, faWindows, faLinux, faApple, faInternetExplorer, faEdge, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { User_class } from 'src/app/class/User_class';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit {
  sessions: Array<Session_class>;
  users: Array<User_class>;
  user_name: string;
  faChrome = faChrome;
  faFirefoxBrowser = faFirefoxBrowser;
  faSafari = faSafari;
  faOpera = faOpera;
  faWindows = faWindows;
  faLinux = faLinux;
  faApple = faApple;
  faInternetExplorer = faInternetExplorer;
  faEdge = faEdge;
  faWindowMaximize = faWindowMaximize;

  constructor(public backend: BackendServiceService,
              private localLogin: LocalLoginService,
              public router: Router) {
                if(this.backend.apikey==undefined){
                  if(this.localLogin.existsData()){
                    this.localLogin.restore_to_service();
                  } else {
                    this.router.navigateByUrl("/");
                  }
                }
                this.getUsers();
  }

  ngOnInit(): void {
  }

  getSessions(){
    this.backend.getSessions(this.user_name).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.sessions = Object.assign(new Array<Session_class>(), data);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  closeSession(id: number){
    this.backend.closeSession(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getSessions();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getUsers(a_user_name = ''){
    this.backend.getAllUsers().subscribe(data => {
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.users = Object.assign(new Array<User_class>(), data);
        this.getSessions();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }




  getBrowserIcon(id: string){
    if(id=='Firefox'){
      return faFirefoxBrowser;
    } else if(id=='Opera' || id=='OPR'){
      return faOpera;
    } else if(id=='MSIE'){
      return faInternetExplorer;
    } else if(id=='Edg'){
      return faEdge;
    }else if(id=='Chrome'){
      return faChrome;
    } else if(id=='Safari'){
      return faSafari;
    } else {
      return faWindowMaximize;
    }
  }

  getOSIcon(id: string){
    if(id=='Linux'){
      return faLinux;
    } else if(id=='Mac'){
      return faApple;
    } else if(id=='iPhone'){
      return faApple;
    } else if(id=='iPad'){
      return faApple;
    } else if(id=='Droid'){
      return faAndroid;
    } else if(id=='Windows'){
      return faWindows;
    } else {
      return faWindowMaximize;
    }
  }
}
