import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-config',
  templateUrl: './cookie-config.component.html',
  styleUrls: ['./cookie-config.component.css']
})
export class CookieConfigComponent implements OnInit {
  lang: string;
  constructor() { 
    this.lang = 'cat';
  }

  ngOnInit(): void {
  }

}
