import { Component, Input, OnInit } from '@angular/core';
import * as Constants from 'src/app/modules/constants/constants.module';

declare let gtag: Function;

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.css']
})

export class CookieBannerComponent implements OnInit {
  @Input() color: string;
  baseURL: string;
  showBar: boolean;
  constructor() { 
    this.showBar = true;
    this.baseURL = Constants.ENT_URL;
  }

  ngOnInit(): void {
  }

  activateCookies(){
    window['ga-disable-UA-39261361-55'] = false;
    gtag('js', new Date());
    gtag('config', 'UA-39261361-55');
    this.showBar = false;
  }

  deactivateCookies(){
    this.showBar = false;
  }

}
