import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Question_class } from 'src/app/class/Question_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { faCircle, faCheckCircle, faSquare, faCheckSquare} from '@fortawesome/free-regular-svg-icons';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';

@Component({
  selector: 'app-view-responses',
  templateUrl: './view-responses.component.html',
  styleUrls: ['./view-responses.component.css']
})
export class ViewResponsesComponent implements OnInit {
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  id_attendant: string;
  questions: Array<Question_class>;
  altPrice: Event_alternative_price_class;

  constructor(public route: ActivatedRoute,
              public backend: BackendServiceService,
              private localLogin: LocalLoginService,
              public router: Router) {
  }

  ngOnInit(): void {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.id_attendant = this.route.snapshot.paramMap.get("id_attendant");
    this.getResponses();
    this.getSelectedAltPrice();
  }

  getResponses(){
    this.backend.getQuestionWithResponses(this.id_attendant).subscribe(data => {
      console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
        this.localLogin.remove(true);
        this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.questions = Object.assign(new Array(),data);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getSelectedAltPrice(){
    this.backend.getSelectedAltprice(this.id_attendant).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
        this.localLogin.remove(true);
        this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.altPrice = Object.assign(new Event_alternative_price_class,data);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

}
