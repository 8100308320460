import { Injectable } from '@angular/core';
import { Login_class } from '../class/Login_class';
import { BackendServiceService } from './backend-service.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalLoginService {
  closedInvalidSession: boolean;
  constructor(private localStorage: LocalStorageService,
              public backend: BackendServiceService) {
    this.closedInvalidSession = false;
  }
  existsData(){
    return this.localStorage.exists('mesacces_login')
  }
  save(data: Login_class){
    this.localStorage.set('mesacces_login', JSON.stringify(data))
  }
  get(): Login_class{
    return  Object.assign(new Login_class,
              JSON.parse(this.localStorage.get('mesacces_login')));
  }
  remove(closedInvalidSession: boolean = false){
    this.closedInvalidSession = closedInvalidSession;
    this.localStorage.remove('mesacces_login');
  }
  restore_to_service(){
    const data = this.get();
    this.backend.apikey = data.apikey;
    this.backend.role = data.role;
    this.backend.name_user = data.name;
    this.backend.user = data.user_name;
    this.backend.accent_color = data.accent_color
    this.backend.multiple_creator = data.multiple_creator;
    this.backend.comission_free = data.comission_free;
    this.backend.commission_low = data.commission_low;
    this.backend.commission_high = data.commission_high;
  }
}
