
<footer class="p-4 mb-8 bg-white text-stone-500 rounded text-sm shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 mx-4 md:mx-6 lg:mx-8">
    <span class="text-sm text-lime-500 sm:text-center dark:text-lime-400">© <a href="https://www.mesacces.com/" class="hover:underline">+acces™</a> | v{{version.v}}</span>
    
    <ul class="flex flex-wrap items-center mt-3 text-sm text-stone-500 dark:text-stone-400 sm:mt-0">
        <li>
            <a href="https://www.mesacces.com" target="_blank" class="mr-4 hover:underline md:mr-6 ">{{'Sobre la plataforma' | translate }}</a>
        </li>
        <li>
            <a href="https://www.mesacces.com/avis-legal/" target="_blank" class="mr-4 hover:underline md:mr-6">{{'Privadesa' | translate }}</a>
        </li>
        <li>
            <a href="https://www.mesacces.com/contacte" target="_blank" class="hover:underline">{{'Contacta' | translate }}</a>
        </li>
    </ul>
</footer>
