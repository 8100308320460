import { Component, Input, OnInit } from '@angular/core';
import { Payment_status_class } from 'src/app/class/Payment_status_class';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  @Input() payment_status_list: Array<Payment_status_class>;
  faChrome = faChrome;
  faEnvelope = faEnvelope;
  constructor() { 
    this.payment_status_list = new Array<Payment_status_class>();
  }

  ngOnInit(): void {
  }

}
