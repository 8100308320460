import { Component, OnInit } from '@angular/core';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';
import { faPlusSquare, faInfoCircle, faListAlt, faPlusCircle, faMinusCircle, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { Router } from '@angular/router';
import { UserSeasonTicketContainerService } from 'src/app/services/user-season-ticket-container.service';
import { isRoot } from '@angular/compiler-cli/src/ngtsc/file_system';
import { UserContainerService } from 'src/app/services/user-container.service';

@Component({
  selector: 'app-list-user-season-ticket',
  templateUrl: './list-user-season-ticket.component.html',
  styleUrls: ['./list-user-season-ticket.component.css']
})
export class ListUserSeasonTicketComponent implements OnInit {
  userSeasonTickets: Array<User_season_ticket_class>;
  deleteUserSeasonTicketName: string;
  name_user: string;
  accent_color: string;
  role: string;

  faPlusSquare = faPlusSquare;
  faInfoCircle = faInfoCircle;
  faUserPlus = faUserPlus;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faUser = faUser;
  faListAlt = faListAlt;
  constructor(public backend: BackendServiceService,
    public modalService: NgbModal,
    public userSeasonTicketContainer: UserSeasonTicketContainerService,
    public userContainer: UserContainerService,
    public sanitizer: DomSanitizer,
    private localLogin: LocalLoginService,
    public router: Router) {
      if(this.backend.apikey==undefined){
        if(this.localLogin.existsData()){
          this.localLogin.restore_to_service();
        } else {
          this.router.navigateByUrl("/");
        }
      }
      this.role = this.backend.role;
      this.getUsersSeasonTicket();
      this.name_user = this.backend.name_user;
      this.accent_color = this.backend.accent_color;
    }

  ngOnInit(): void {
  }

  isAdminOrRoot(){
    return this.role == 'A' || this.role == 'R';
  }

  edit(userSeasonTicket: User_season_ticket_class){
    this.userSeasonTicketContainer.userSeasonTicket = userSeasonTicket;
    this.router.navigateByUrl("/create-user-season-ticket");
  }

  new(){
    this.userSeasonTicketContainer.userSeasonTicket = undefined;
    this.router.navigateByUrl("/create-user-season-ticket");
  }

  confirmDelete(content, userSeasonTicket: User_season_ticket_class) {
    this.deleteUserSeasonTicketName = userSeasonTicket.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='DELETE')
        this.deleteUserSeasonTicket(userSeasonTicket.id);
    }, (reason) => {

    });
  }

  deleteUserSeasonTicket(id: string){
    this.backend.deleteUserSeasonTicket(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUsersSeasonTicket();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getUsersSeasonTicket(){
    let user_name = this.backend.user;
    if(this.isAdminOrRoot()){
      user_name = this.userContainer.user.user_name;
    }
    this.backend.getUsersSeasonTickets(user_name).subscribe(data => {
      if(data['error']=='login_error'){
          this.backend.logout().subscribe(data=> {
            this.localLogin.remove(true);
            this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.userSeasonTickets = Object.assign(new Array<User_season_ticket_class>(), data);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }


  listAttendants(idUserSeassonTicket: string){
    this.router.navigateByUrl("/listUSTA/" + idUserSeassonTicket);
  }


}
