export class Login_class{
  accent_color: string;
  apikey: string;
  door_code: string;
  name: string;
  privacy_conditions: string;
  role: string;
  user_name: string;
  valid: boolean;
  multiple_creator: boolean;
  comission_free: number;
  commission_low: number;
  commission_high: number;
}
