<div class="container-fluid mt-4 mb-4">
    <div class="row mt-5 mb-1">
        <div class="col-3 text-center">
          <h3 style="cursor: pointer;" (click)="view_user='F'" [ngStyle]="{'color': view_user=='F'?'black':accent_color+'5A'}">Usuaris actius</h3>
        </div>
        <div class="col-3 text-center" style="border-left: black solid 1px;">
          <h3 style="cursor: pointer;" (click)="view_user='T'" [ngStyle]="{'color': view_user=='T'?'black':accent_color+'5A'}">Usuaris arxivats</h3>
        </div>
    </div>
    <div class="row">
        <!--
        <div class="col-2">
            <div class="list-group">
                <ng-container *ngFor="let user of users; let i = index">
                    <a class="list-group-item list-group-item-action"
                        (click)="set_active_user(i)"
                        [ngClass]="{'active': active_user == user.user_name}"
                        *ngIf="user.role=='U'">
                        <div class="row">
                            <div class="col-3">
                                <img class="fluid-img" style="max-width: 15px;" [src]="sanitizer.bypassSecurityTrustUrl(user.logo)" alt="" >
                            </div>
                            <div class="col-9">
                                {{user.name}}
                            </div>
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>-->
        <ng-container *ngFor="let user of users; let i = index">
            <div class="col-6" *ngIf="user.role=='U'&&user.archived==view_user">
                <a (click)="set_active_user(i)">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend text-center">
                            <span class="input-group-text" style="min-width: 46px;">
                                <img class="fluid-img" style="max-height: 16px;" [src]="sanitizer.bypassSecurityTrustUrl(user.logo)" alt="" >
                            </span>
                        </div>
                        <span class="form-control text-center" [ngClass]="{'selected': active_user == user.user_name}">{{user.name}}</span>
                    </div>
                </a>
            </div>
        </ng-container>
        <div class="col-12">&nbsp;</div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                    <img class="fluid-img" style="max-width: 60px;" [src]="sanitizer.bypassSecurityTrustUrl(users[active_user_index]!=undefined?users[active_user_index].logo:'')" alt="" ><br>
                    <h4>{{users[active_user_index]!=undefined?users[active_user_index].name:''}}</h4>
                </div>
                <div class="col-12 mt-3 mb-3">
                    <div class="row">
                        <div class="col-12">
                            <h5>Registrats/tickets</h5>
                            <div class="progress mt-1" style="height:30px">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': registred_tickets_p + '%'}">{{registred_tickets_p|number:'1.0-1'}}%</div>
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (100-registred_tickets_p) + '%'}" style="color: black;background-color: unset;">{{(100-registred_tickets_p)|number:'1.0-1'}}%</div>
                            </div>
                            <h5 class="mt-2">Asistents/Registrats</h5>
                            <div class="progress" style="height:30px">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': attended_registred_p + '%'}">{{attended_registred_p|number:'1.0-1'}}%</div>
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (100-attended_registred_p) + '%'}" style="color: black;background-color: unset;">{{(100-attended_registred_p)|number:'1.0-1'}}%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3 mb-3">
                    <div class="row">
                        <div class="col-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faTicketAlt"></fa-icon></span>
                                </div>
                                <span class="form-control text-center">{{get_num_tickets_count()}}</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faTicketAlt"></fa-icon><fa-icon [icon]="faEuroSign"></fa-icon></span>
                                </div>
                                <span class="form-control text-center">{{get_avg_ticket_price()|number:'1.0-2'}}</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" style="min-width: 46px;">Σ<fa-icon [icon]="faEuroSign"></fa-icon></span>
                                </div>
                                <span class="form-control text-center">{{get_payed_tickets_total_price()}}</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faCalendar"></fa-icon></span>
                                </div>
                                <span class="form-control text-center">{{events.length}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <h4 class="text-center">Individual</h4>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faUser"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_registred_count()}}</span>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faUserClock"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_attended_count()}}</span>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faMoneyBillWave"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_payed_count()}}</span>
                    </div>
                </div>
                <div class="col-4">
                    <h4 class="text-center">Unions familiars</h4>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faUserFriends"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_fu_registred_count()}}</span>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faUserClock"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_fu_attended_count()}}</span>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faMoneyBillWave"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_fu_payed_count()}}</span>
                    </div>
                </div>
                <div class="col-4">
                    <h4 class="text-center">Total</h4>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faUsers"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">
                            {{get_user_fu_registred_count()*1+get_user_registred_count()*1}}
                        </span>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faUserClock"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_fu_attended_count()*1+get_user_attended_count()*1}}</span>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faMoneyBillWave"></fa-icon></span>
                        </div>
                        <span class="form-control text-center">{{get_user_payed_count()*1+get_user_fu_payed_count()}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-5 mb-1">
            <div class="row">
                <div class="col-4 text-center">
                  <h3 style="cursor: pointer;" (click)="view_event='F'" [ngStyle]="{'color': view_event=='F'?'black':accent_color+'5A'}">Esdeveniments actius</h3>
                </div>
                <div class="col-4 text-center" style="border-left: black solid 1px;">
                  <h3 style="cursor: pointer;" (click)="view_event='T'" [ngStyle]="{'color': view_event=='T'?'black':accent_color+'5A'}">Esdeveniments arxivats</h3>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="list-group" *ngIf="events!=undefined">
                <ng-container *ngFor="let event of events; let i = index">
                    <a class="list-group-item list-group-item-action"
                        *ngIf="event.archived==view_event"
                        (click)="set_active_event(i)"
                        [ngClass]="{'active': active_event == event.id}">
                        <b>{{event.event_name}}</b><br>
                        {{dateFormatNum(event.date)}}
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="col-8">
            <ng-container *ngIf="active_event!=undefined&&events!=undefined&&events.length>0">
                <div class="row">
                    <div class="col-12 mt-3 pb-3">
                        <h5 class="text-center">{{events[active_event_index].event_name}}</h5>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faCalendarDay"></fa-icon></span>
                                    </div>
                                    <span class="form-control text-center">{{dateFormat(events[active_event_index].date)}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faTicketAlt"></fa-icon></span>
                                    </div>
                                    <span class="form-control text-center">{{events[active_event_index].num_tickets}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faEuroSign"></fa-icon></span>
                                    </div>
                                    <span class="form-control text-center">{{events[active_event_index].price}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="min-width: 46px;">Σ<fa-icon [icon]="faEuroSign"></fa-icon></span>
                                    </div>
                                    <span class="form-control text-center">{{(events[active_event_index].price*1)*(events[active_event_index].fu_payed_attendant*1+events[active_event_index].payed_attendant*1)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3 mb-3">
                        <div class="row">
                            <div class="col-12">
                                <h5>Registrats/Tickets</h5>
                                <div class="progress mt-1" style="height:30px">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{'width': ev_registred_tickets_p + '%'}">{{ev_registred_tickets_p|number:'1.0-1'}}%</div>
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (100-ev_registred_tickets_p) + '%'}" style="color: black;background-color: unset;">{{(100-ev_registred_tickets_p)|number:'1.0-1'}}%</div>
                                </div>
                                <h5 class="mt-2">Asistents/Registrats</h5>
                                <div class="progress" style="height:30px">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{'width': ev_attended_registred_p + '%'}">{{ev_attended_registred_p|number:'1.0-1'}}%</div>
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (100-ev_attended_registred_p) + '%'}" style="color: black;background-color: unset;">{{(100-ev_attended_registred_p)|number:'1.0-1'}}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <h4 class="text-center">Individual</h4>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faUser"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].registred_count}}</span>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="faUserClock"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].attended_count}}</span>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="faMoneyBillWave"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].payed_attendant}}</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <h4 class="text-center">Unions familiars</h4>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faUserFriends"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].fu_registred_count}}</span>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="faUserClock"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].fu_attended_count}}</span>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="faMoneyBillWave"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].fu_payed_attendant}}</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <h4 class="text-center">Total</h4>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="min-width: 46px;"><fa-icon [icon]="faUsers"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">
                                {{events[active_event_index].fu_registred_count*1+events[active_event_index].registred_count*1}}
                            </span>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="faUserClock"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].fu_attended_count*1+events[active_event_index].attended_count*1}}</span>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="faMoneyBillWave"></fa-icon></span>
                            </div>
                            <span class="form-control text-center">{{events[active_event_index].fu_payed_attendant*1+events[active_event_index].payed_attendant*1}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>
