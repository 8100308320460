import { Injectable } from '@angular/core';
import { Zone_class } from '../class/Zone_class';

@Injectable({
  providedIn: 'root'
})
export class ZoneContainerService {
  zone: Zone_class;
  constructor() { }

  get(){
    return this.zone;
  }

  set(zone: Zone_class){
    this.zone = zone;
  }
}
