import { Attendant_class } from "./Attendant_class";
import { Attendant_me_participates } from "./Attendant_me_participates";
import { Event_class } from "./Event_class";

export class Attendant_me_class extends Attendant_class{
    participates: Array<Attendant_me_participates> = new Array<Attendant_me_participates>();
    constructor(){
        super();
    }

    addEvents(events: Array<Event_class>){
        events.forEach((event: Event_class)=>{
            let amp = new Attendant_me_participates(event.id);
            amp.addSessions(event.sessions);
            this.participates.push(amp);
        });
    }
}