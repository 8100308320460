<div class="container-fluid" style="padding-top: 50px; padding-bottom: 100px;">
  <div class="row">
    <div class="col-6 offset-3">
      <div class="form-group">
          <label>nom</label>
          <input type="text" class="form-control" [(ngModel)]="user.name">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of user_error.name">
              {{error | translate}}
          </div>
      </div>
      <div class="form-group" *ngIf="mode=='E'">
        <label>usuari</label>
        <input type="text" class="form-control" disabled [value]="user.user_name">
     </div>
      <div class="form-group" *ngIf="mode!='E'">
          <label>usuari</label>
          <input type="text" class="form-control" [(ngModel)]="user.user_name">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of user_error.user_name">
              {{error | translate}}
          </div>
      </div>
      <ng-container *ngIf="user.creator_role == 'R'">
        <div class="form-group">
          <label>comisio a partir de 10000 entrades gratuites</label>
          <input type="text" class="form-control" [(ngModel)]="user.commission_free">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of user_error.commission_free">
              {{error | translate}}
          </div>
        </div>
        <div class="form-group">
          <label>comisio entrades per sota o igual a 5€</label>
          <input type="text" class="form-control" [(ngModel)]="user.commission_low">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of user_error.commission_low">
              {{error | translate}}
          </div>
        </div>
        <div class="form-group">
          <label>comisio entrades per damunt de 5€</label>
          <input type="text" class="form-control" [(ngModel)]="user.commission_high">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of user_error.commission_high">
              {{error | translate}}
          </div>
        </div>
      </ng-container>
      <div class="form-group">
          <label>password</label>
          <input type="password" class="form-control" [(ngModel)]="user.password">
          <div class="alert alert-danger mt-1" role="alert" *ngFor="let error of user_error.password">
              {{error | translate}}
          </div>
      </div>
      <div class="form-group">
          <label for="event_nameInput">condicions per inscripció</label>
          <ckeditor [editor]="Editor"
                  [config]="Editor_config"
                  [(ngModel)]="user.information"></ckeditor>
      </div>
      <div class="form-group">
          <label for="event_nameInput">informació legal</label>
          <ckeditor [editor]="Editor"
                  [config]="Editor_config"
                  [(ngModel)]="user.conditions"></ckeditor>
      </div>
      <div class="form-group">
        <label>Logo</label>
        <input #filechooser type="file" class="form-control-file">
      </div>
      <div class="form-group">
        <label for="event_nameInput">color</label>
        <input class="form-control" [(colorPicker)]="user.custom_color"
          [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
          [style.background]="user.custom_color"/>
      </div>
      <div class="form-group">
        <label>imatge fons esdeveniments</label>
        <input #filechooser type="file" class="form-control-file">
      </div>
      <!--<div class="form-group">
        <label>url propia</label>
        <input type="text" class="form-control" [(ngModel)]="user.custom_url">
        <div class="alert alert-info mt-1" role="alert">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          Exemple: Es pujara a https://www.piqture.cat/web/esdeveniments/ al camp ha d'introduir /web/esdeveniments (barra al principi però no al final).
        </div>
      </div>-->
      <ng-container *ngIf="user.creator_role != 'R'">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                  <input type="checkbox" [(ngModel)]="user.multiple_creator">
              </div>
            </div>
            <label class="form-control check-group">
                Usuari de recinte esportiu
            </label>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="user.creator_role == 'R'">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                  <input type="checkbox" [(ngModel)]="user.has_tpv">
              </div>
            </div>
            <label class="form-control check-group">
                Utilitzar TPV propi
            </label>
          </div>
        </div>
        <ng-container *ngIf="user.has_tpv">
          <h3>Redsys</h3>
          <div class="form-group">
            <label>Version</label>
            <input type="text" class="form-control" [(ngModel)]="user.RS_VER">
          </div>
          <div class="form-group">
              <label>Key</label>
              <input type="text" class="form-control" [(ngModel)]="user.RS_KEY">
          </div>
          <div class="form-group">
              <label>Fuc</label>
              <input type="text" class="form-control" [(ngModel)]="user.RS_FUC">
          </div>
          <div class="form-group">
              <label>Terminal</label>
              <input type="text" class="form-control" [(ngModel)]="user.RS_TER">
          </div>
          <div class="form-group">
              <label>URL propia</label>
              <input type="text" class="form-control" [(ngModel)]="user.RS_URL">
          </div>
          <div class="form-group">
              <label>Divisa</label>
              <input type="text" class="form-control" [(ngModel)]="user.RS_DIV">
          </div>
          <div class="form-group">
              <label>Trans</label>
              <input type="text" class="form-control" [(ngModel)]="user.RS_TRA">
          </div>
        </ng-container>
      </ng-container>
      <button type="button" class="btn btn-primary mt-3" style="width: 100%;" (click)="save()">Guardar</button>
    </div>
  </div>
</div>
<app-back-button></app-back-button>
