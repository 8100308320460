<app-payment-wait [idAttendant]="idAttendantP" [check]="checkAttendantP"></app-payment-wait>

<div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2">{{'Correu electrònic' | translate }}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="mail" appBlockCopyPaste>
</div>

<div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2">{{ 'Verifica el correu' | translate }}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="mail2" appBlockCopyPaste>
</div>

<div class="text-red-500 text-xs mt-1" role="alert" *ngFor="let mail_error of mail_errors">
    {{mail_error}}
</div>

<div class="bg-gray-100 border-l-4 border-gray-500 text-gray-700 p-4" *ngIf="mail_errors.length>0" role="alert">
    <div class="flex">
        <div>
            <fa-icon [icon]="faInfoCircle" class="ml-1"></fa-icon>
        </div>
        <div class="ml-2">
            <small>{{'Si el teu correu és correcte i et continua sortint el missatge d\'error, si us plau obre el link de l\'esdeveniment en un altre navegador.' | translate }}</small>
        </div>
    </div>
</div>

<div class="mb-4" *ngIf="fields.isEnabled('phone')">
    <label class="block text-gray-700 text-sm font-bold mb-2">{{'Telèfon' | translate }}</label>
    <input type="text" class="bg-stone-50 border border-stone-300 text-stone-900 text-sm rounded-lg focus:ring-lime-500 focus:border-lime-500 block w-full p-2.5 dark:bg-stone-700 dark:border-stone-600 dark:placeholder-stone-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500" [(ngModel)]="phone">
</div>

<div class="text-red-500 text-xs mt-1" role="alert" *ngFor="let phone_error of phone_errors">
    {{phone_error}}
</div>

<hr class="my-4">

<div *ngFor="let attendant of attendants; index as o">
    <div class="flex justify-between items-center mb-4">
        <h4 class="text-lg font-bold">{{'Registre' | translate }} {{o + 1}}</h4>
        <div *ngIf="o >= min_fu">
            <fa-icon class="text-gray-500 hover:text-red-500 cursor-pointer" [icon]="faTimesCircle" size="sm" (click)="removeAttendant(attendants, o)"></fa-icon>
        </div>
    </div>
    <app-form-attendant
        [attendant]="attendant"
        [questions]="questions"
        mode="F"
        [fields]="fields"
        [seassonTicket]="seassonTicket && ((userSeassonTicket.allow_couple && o > 1) || (!userSeassonTicket.allow_couple))"
        [userSeassonTicket]="userSeassonTicket"
        [index]="o"
        [altPrices]="event.altPrices"
        [attendant_errors]="attendants_errors[o]">
    </app-form-attendant>
</div>
<ng-container *ngIf="event.general_conditions">
    <div class="my-3 inline-block">
        <input
            class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 focus:ring-2"
            type="checkbox"
            [(ngModel)]="gConditionsAccepted">
        <label class="ml-2 text-sm font-medium text-gray-900">
            {{'He llegit i accepto les condicions de privacitat que puc llegir' | translate}}
            <b><a class="text-lime-800 cursor-pointer" (click)="showConditions(condition, event.general_conditions)">aquí</a></b>
        </label>
    </div>
    <div class="text-red-500 text-xs mt-1" *ngFor="let error of attendant_errors.general_condition">
        {{error | translate}}
    </div>
</ng-container>
<button
    type="button"
    *ngIf="attendants.length < max_attendants && (backend.role != undefined || attendants.length < event.available_tickets)"
    class="w-full bg-lime-200 hover:bg-lime-300 text-stone-900 py-2 text-xs px-4 rounded mt-3"
    (click)="addAttendant()">
    {{'Afegir un altre registre' | translate}}
</button>



<ng-container *ngIf="event.conditions">
    <div class="my-3 inline-block">
        <input
            class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 focus:ring-2"
            type="checkbox"
            [(ngModel)]="conditionsAccepted">
        <label class="ml-2 text-sm font-medium text-gray-900">
            {{'He llegit i accepto les condicions de privacitat que puc llegir' | translate}}
            <b><a class="text-red-400 cursor-pointer" (click)="showConditions(condition, event.conditions)">aquí</a></b>
        </label>
    </div>
    <div class="text-red-500 text-xs mt-1" *ngFor="let error of attendant_errors.condition">
        {{error | translate}}
    </div>
</ng-container>

<div class="my-3 inline-block">
    <input
        class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 focus:ring-2"
        type="checkbox"
        [(ngModel)]="pConditionsAccepted">
    <label class="ml-2 text-sm font-medium text-gray-900">
        {{'He llegit i accepto les condicions de privacitat que puc llegir' | translate}}
        <b><a
            class="text-red-400"
            [ngStyle]="{ color: event.accent_color }"
            [href]="ent_url + 'politica-privacitat'"
            target="_blank">aquí</a></b>
    </label>
</div>
<div class="text-red-500 text-xs mt-1" *ngFor="let error of attendant_errors.pConditions">
    {{error | translate}}
</div>

<ng-container *ngIf="manual">
    <div class="mt-3">
        <div class="flex items-center mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4">Enviar correu amb l'entrada?</label>
            <div class="flex items-center">
                <label class="inline-flex items-center mr-4">
                    <input type="radio" class="form-radio text-lime-600" [(ngModel)]="send_mail" [value]="true">
                    <span class="ml-2">Si</span>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" class="form-radio text-lime-600" [(ngModel)]="send_mail" [value]="false">
                    <span class="ml-2">No</span>
                </label>
            </div>
        </div>
        <div class="flex items-center mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4">Mostrar entrada per pantalla?</label>
            <div class="flex items-center">
                <label class="inline-flex items-center mr-4">
                    <input type="radio" class="form-radio text-lime-600" [(ngModel)]="show_entry" [value]="true">
                    <span class="ml-2">Si</span>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" class="form-radio text-lime-600" [(ngModel)]="show_entry" [value]="false">
                    <span class="ml-2">No</span>
                </label>
            </div>
        </div>
        <div class="flex items-center mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2 mr-4">Marcar com a pagat?</label>
            <div class="flex items-center">
                <label class="inline-flex items-center mr-4">
                    <input type="radio" class="form-radio text-lime-600" [(ngModel)]="has_paied" [value]="true">
                    <span class="ml-2">Si</span>
                </label>
                <label class="inline-flex items-center">
                    <input type="radio" class="form-radio text-lime-600" [(ngModel)]="has_paied" [value]="false">
                    <span class="ml-2">No</span>
                </label>
            </div>
        </div>
    </div>
</ng-container>

<hr class="my-4">

<div *ngIf="event.paid">
    <div class="mb-4">
        <div class="flex items-center justify-between">
            <span class="text-gray-700 text-lg">{{'Preu total' | translate}}:</span>
            <span class="font-bold text-2xl">{{getPrice()}} <fa-icon [icon]="faEuroSign"></fa-icon></span>
        </div>
        <small class="text-gray-500" *ngIf="event.show_commission && event.management_cost">
            Inclou {{getCommission()}} € de despeses de gestió
        </small>
    </div>
</div>

<div class="text-red-500 text-xs mt-1" *ngFor="let error of general_error">
    {{error | translate}}
</div>

<div *ngIf="errorSaving" class="text-red-500 mt-2">
    No s'ha pogut registrar, si us plau torni a intentar-ho
</div>

<button
    type="button"
    class="w-full bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded my-3"
    
    (mouseover)="event.btn_over = true"
    (mouseout)="event.btn_over = false"
    *ngIf="!waitServer"
    (click)="confirm()">
    Reservar
</button>

<div *ngIf="waitServer">
    Esperant resposta del servidor. Si us plau, esperi.
</div>

<div class="text-center mt-3" *ngIf="waitServer">
    <img src="/assets/loader.gif" class="h-6 inline-block">
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Reserva completada amb èxit</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <strong>La teva entrada/es s'ha generat correctament</strong><br><br>
        <a
            class="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-center block"
            target="_blank"
            [href]="url + 'bk/index.php/veure/entrada?key=' + key + '&type=' + attendantType">
            Mostra les entrades
        </a>
        <br>
        <strong class="text-red-500">NOTA IMPORTANT</strong><br>
        Podeu visualitzar la vostra entrada/es fent clic al botó "Mostra les entrades".<br><br>
        En breu també rebreu les entrades per correu electrònic. Degut a l'alta demanda aquest pot demorar-se fins a 12 hores.
        Si passat aquest temps no heu rebut el correu, si us plau poseu-vos en contacte
        <a [href]="'mailto:' + supp_mail">amb nosaltres <fa-icon [icon]="faQuestionCircle"></fa-icon></a>.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modal.close('')">Tancar</button>
    </div>
</ng-template>

<ng-template #condition let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Condicions</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="conditions"></div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modal.close('')">Tancar</button>
    </div>
</ng-template>

<ng-template #alonefu let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Ha seleccionat el registre múltiple i només ha introduït les dades d'un membre, desitja registrar-se individualment?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn bg-green-500 text-white" (click)="modal.dismiss()">Seguir afegint membres</button>
        <button type="button" class="btn bg-red-500 text-white" (click)="modal.close('OK')">Només sóc jo</button>
    </div>
</ng-template>
