import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Zone_class } from 'src/app/class/Zone_class';
import { Subzone_class } from 'src/app/class/Subzone_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { ZoneContainerService } from 'src/app/services/zone-container.service';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-create-zone',
  templateUrl: './create-zone.component.html',
  styleUrls: ['./create-zone.component.css']
})
export class CreateZoneComponent implements OnInit {
  zone: Zone_class;
  mode: string;
  @ViewChild('filechooser',{static: false}) fileChooser!: ElementRef;
  constructor(public backend: BackendServiceService,
              public zoneContainer: ZoneContainerService,
              private localLogin: LocalLoginService,
              public router: Router) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
      if(this.zoneContainer.get()!=undefined){
        this.mode = 'M';
        this.zone = this.zoneContainer.get();
        this.zoneContainer.set(undefined);
      } else {
        this.mode = 'C';
        this.zone = new Zone_class();
      }
   }

  ngOnInit(): void {
  }

  ngAfterViewChecked(){
    this.wireUpFileChooser();
  }

  addSubzone(){
    this.zone.subzones.push(new Subzone_class());
  }

  createZone(){
    //console.log(this.zone);
    this.backend.saveZone(this.zone).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
          this.router.navigateByUrl("zone-list");
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  modifyZone(){
    //console.log(this.zone);
    this.backend.modifyZone(this.zone).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
          this.router.navigateByUrl("zone-list");
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  save(){
    if(this.mode == 'C')
      this.createZone();
    else
      this.modifyZone();
  }

  wireUpFileChooser(){
    const elementRef = this.fileChooser.nativeElement as HTMLInputElement;

    elementRef.addEventListener('change', (evt: any) => {
      const selectedFile = evt.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener("loadend", (evt: any) => {
        this.zone.image = evt.target.result;
      });
    });
  }

}
