export class General_configuration_class{
    RS_VER: string;
    RS_KEY: string;
    RS_FUC: string;
    RS_TER: string;
    RS_LNK: string;
    RS_DIV: string;
    RS_TRA: string;
    RS_LOK: string;
    RS_LKO: string;
    RS_MIN: string;
    RES_TI: string;
};