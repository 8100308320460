import { Response_class } from './Response_class';
export class Question_class{
    id: string;
    text: string;
    type: string;
    text_response: string;
    response_id: string;
    response: Array<Response_class>;
    constructor(){
        this.text = '';
        this.type = 'T';
        this.text_response = '';
        this.response = new Array<Response_class>();
    }
}
