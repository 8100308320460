import { Component, Input, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { Event_session_error_class } from 'src/app/class/Event_session_error_class';

@Component({
  selector: 'app-event-session-form',
  templateUrl: './event-session-form.component.html',
  styleUrls: ['./event-session-form.component.css']
})
export class EventSessionFormComponent implements OnInit {
  @Input() event_session: Event_session_class;
  @Input() event_session_error: Event_session_error_class;


  public Editor_config = {
    fontSize: {
      options: [
          9,
          11,
          13,
          'default',
          17,
          19,
          21
      ]
    },
    fontColor: {
      colors: [
          {
              color: 'hsl(0, 0%, 0%)',
              label: 'Black'
          },
          {
              color: 'hsl(0, 0%, 30%)',
              label: 'Dim grey'
          },
          {
              color: 'hsl(0, 0%, 60%)',
              label: 'Grey'
          },
          {
              color: 'hsl(0, 0%, 90%)',
              label: 'Light grey'
          },
          {
              color: 'hsl(0, 0%, 100%)',
              label: 'White',
              hasBorder: true
          },

          // ...
      ]
    },
    toolbar: [ 'fontSize', 'fontColor', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'mediaEmbed' ]
};
public Editor = ClassicEditor;

  constructor() {
    this.event_session = new Event_session_class();
    this.event_session_error = new Event_session_error_class();
  }

  ngOnInit(): void {
  }

}
