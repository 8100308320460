import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Login_class } from 'src/app/class/Login_class';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('privacyPolicyModal') content: ElementRef;
  user_name: string;
  user_password: string;
  faUser = faUser;
  faKey = faKey;
  error: string;

  constructor(private router: Router,
              public localLogin: LocalLoginService,
              public modalService: NgbModal,
              private backend: BackendServiceService) {
                this.error = "";
                if(this.localLogin.existsData()){
                  this.localLogin.restore_to_service();
                  this.navigateToUserHome();
                }
               }

  ngOnInit(): void {

  }

  login(){
    this.error = "";
    this.backend.login(this.user_name,this.user_password).subscribe(data => {
      //console.log(data);
      if(data){

        if(data['valid']){
          this.backend.apikey = data['apikey'];
          this.backend.role = data['role'];
          this.backend.name_user = data['name'];
          this.backend.comission_free = data['comission_free'];
          this.backend.commission_low = data['commission_low'];
          this.backend.commission_high = data['commission_high'];
          this.backend.user = data['user_name'];
          this.backend.accent_color = data['accent_color'];
          this.backend.multiple_creator = data['multiple_creator'];
          const loginData = Object.assign(new Login_class, data);
          this.localLogin.save(loginData);
          //console.log(this.localLogin.get())
          //console.log(data);return;
          if(data['privacy_conditions']=='N')
            this.showPrivacy();
          else
            this.navigateToUserHome();
        } else {
          this.error = "Usuari o contrassenya incorrectes.";
        }
      }
      else{
        //Controlled error
        this.error = "Error del servidor.";
      }
    }, error => {
      console.log(error);
    });

  }

  navigateToUserHome(){
    if(this.backend.role=='A' || this.backend.role == 'R')
      this.router.navigateByUrl("user-list");
    else{
      //this.backend.door_code = data['door_code'];
      this.router.navigateByUrl("events-list");
    }
  }

  accept_privacy(){
    this.backend.markPrivacyConditions().subscribe(data => {
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok']=='Y'){
          if(this.backend.role=='A'){
            this.router.navigateByUrl("user-list");
          }else{
            this.router.navigateByUrl("events-list");
          }
        }
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  showPrivacy() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title', size:'xl', scrollable: true}).result.then((result) => {
      if(result=='SEND')
        this.accept_privacy();
      else
        this.backend.logout();
    }, (reason) => {
    });
  }

}
