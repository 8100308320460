import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-advice',
  templateUrl: './legal-advice.component.html',
  styleUrls: ['./legal-advice.component.css']
})
export class LegalAdviceComponent implements OnInit {
  lang: string;
  constructor() {
    this.lang = 'cat';
   }

  ngOnInit(): void {
  }

}
