import { Injectable } from '@angular/core';
import { User_class } from '../class/User_class';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserContainerService {
  user: User_class = undefined;
  creator_role: string;
  creator_user_name: string;
  constructor(private localStorage: LocalStorageService) { }
  set(user: User_class){
    this.user = user;
  }
  get(){
    return this.user;
  }
  save(){
    this.localStorage.set('mesacces_user_container', JSON.stringify(this.user))
  }
  getS(): User_class{
    return  Object.assign(new User_class,
              JSON.parse(this.localStorage.get('mesacces_user_container')));
  }
  restore(){
    this.user = this.getS();
  }
}
