import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserContainerService } from 'src/app/services/user-container.service';
import { User_class } from 'src/app/class/User_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlusSquare, faInfoCircle, faPlusCircle, faMinusCircle, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  carregant:boolean=true;

  @Output('user') emitirUser = new EventEmitter();


  users: Array<User_class>;
  delete_user_name: string;
  name_user: string;
  creator_user_name: string;
  accent_color: string;
  faPlusSquare = faPlusSquare;
  faInfoCircle = faInfoCircle;
  faUserPlus = faUserPlus;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faUser = faUser;
  user: User_class;
  view: string;
  role: string;
  return_root: boolean;
  constructor(public backend: BackendServiceService,
              public modalService: NgbModal,
              public userContainer: UserContainerService,
              public localStorage: LocalStorageService,
              public sanitizer: DomSanitizer,
              private localLogin: LocalLoginService,
              public router: Router) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.role = this.backend.role;
    this.return_root = false;
    if(this.loadData()){
      this.getBaseUsers(this.creator_user_name);
    } else {
      this.getUsers();
    }
    this.name_user = this.backend.name_user;
    this.creator_user_name = this.backend.user;
    this.accent_color = this.backend.accent_color;
    this.view = 'F';
  }


  ngOnInit(): void {
    this.emitirUser.emit(this.backend.user);
    
  }

  loadData(): boolean{
      if(this.localStorage.exists("mesacces_user_list_nav")){
        let data = JSON.parse(this.localStorage.get("mesacces_user_list_nav"));
        this.role = data.role;
        this.return_root = true;
        this.creator_user_name = data.user_name; 
        return true;
      }
      return false;
  }

  return_to_root_list(){
    this.creator_user_name = this.backend.name_user;
    this.return_root=false;
    this.role='R';
    this.localStorage.remove("mesacces_user_list_nav");
    this.getUsers();
  }

  edit(user){
    this.userContainer.set(user);
    this.userContainer.creator_role = this.role;
    this.userContainer.creator_user_name = this.creator_user_name;
    this.router.navigateByUrl("/create-user");
  }

  new(){
    this.userContainer.set(undefined);
    this.userContainer.creator_role = this.role;
    this.userContainer.creator_user_name = this.creator_user_name;
    this.router.navigateByUrl("/create-user");
  }

  open(content, user: User_class) {
    this.delete_user_name = user.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='DELETE')
        this.deleteUser(user.user_name);
    }, (reason) => {

    });
  }

  satUsr(content, user: User_class) {
    this.user = user;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  deleteUser(user_name){
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    this.backend.deleteUser(user_name).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUsers();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  archive(user_name){
    const archive = this.view == 'T'?'F':'T';
    this.backend.setArchivedUser(user_name,archive).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUsers();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  userIsAdmin(){
    return this.role == 'A';
  }

  userIsRoot(){
    return this.role == 'R';
  }

  getUsers(){
    if(this.userIsAdmin()){
      this.getBaseUsers();
      }
    else
      this.getAdminUsers()
     
  }
  viewUsers(user: User_class) {
    this.carregant=true;
    setTimeout(() => this.viewUsersTimed(user), 500);
  }

  viewUsersTimed(user: User_class){
    this.role = 'A';
    this.return_root = true;
    this.creator_user_name = user.user_name;
    const data = {user_name: user.user_name, role: 'A'};
    this.localStorage.set("mesacces_user_list_nav", JSON.stringify(data));
    this.getBaseUsers(user.user_name);
  }

  getBaseUsers(a_user_name = ''){
    this.backend.getUsers(a_user_name).subscribe(data => {
      if(data['error']=='login_error'){
          this.backend.logout().subscribe(data=> {
            this.localLogin.remove(true);
            this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.users = Object.assign(new Array, data);
        this.carregant=false
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getAdminUsers(){
    this.backend.getAdminUsers().subscribe(data => {
      if(data['error']=='login_error'){
          this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.users = Object.assign(new Array, data);
        this.carregant=false
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  removeSatUsr(user_name: string){
    this.backend.removeSatUsr(user_name).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUsers();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  addSatUsr(user_name: string){
    this.backend.addSatUsr(user_name).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getUsers();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  viewEvents(user: User_class){
    this.carregant=true;
    this.userContainer.set(user);
    this.backend.multiple_creator = user.multiple_creator;
    this.router.navigateByUrl("/events-list");
  }
  acortar(text){
    return text.substr(0,35);
  }
}
