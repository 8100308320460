import { Component, Input, OnInit } from '@angular/core';
import { Attendant_read_class } from 'src/app/class/Attendant_read_class';
import { faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-attendant-reads',
  templateUrl: './attendant-reads.component.html',
  styleUrls: ['./attendant-reads.component.css']
})
export class AttendantReadsComponent implements OnInit {
  @Input() keyReads: Array<Attendant_read_class>;
  faSignInAlt = faSignInAlt;
  faSignOutAlt = faSignOutAlt;

  constructor() { }

  ngOnInit(): void {
  }

}
