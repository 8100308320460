import { Component, OnInit } from '@angular/core';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { Zone_class } from 'src/app/class/Zone_class';
import { ZoneContainerService } from 'src/app/services/zone-container.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.css']
})
export class ZoneListComponent implements OnInit {
  zones: Array<Zone_class>;
  delete_zone_name: string;
  name_user: string;
  accent_color: string;
  faPlusSquare = faPlusSquare;
  constructor(public backend: BackendServiceService,
              private modalService: NgbModal,
              public zoneContainer: ZoneContainerService,
              private localLogin: LocalLoginService,
              public router: Router) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.name_user = this.backend.name_user;
    this.accent_color = this.backend.accent_color;
   }

  ngOnInit(): void {
    this.zones = new Array<Zone_class>();
    let zone = new Zone_class();
    zone.name = "Poliesportiu";
    zone.id = '45';
    /*zone.subzones = [{id: "1", name: "Zona 1", num_seats: 10},
                      {id: "2", name: "Zona 2", num_seats: 15},
                      {id: "3", name: "Zona 3", num_seats: 10}];*/

    //this.zones.push(zone);
    this.getZones();
  }

  edit(zone: Zone_class){
    //console.log(event);
    this.zoneContainer.set(zone);
    this.router.navigate(['/create-zone']);
  }

  open(content, zone: Zone_class) {
    this.delete_zone_name = zone.name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='DELETE')
        this.deleteZone(zone.id);
    }, (reason) => {

    });
  }

  deleteZone(id){
    this.backend.deleteZone(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.getZones();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getZones(){
    this.backend.getZones().subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.zones = Object.assign(new Array, data);
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  duplicate(zone){
    //console.log(this.zone);
    this.backend.saveZone(zone).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
          this.getZones();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

}
