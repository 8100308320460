import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { User_class } from 'src/app/class/User_class'
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { UserContainerService } from 'src/app/services/user-container.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User_error_class } from 'src/app/class/User_error_class';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  @ViewChild('filechooser',{static: false}) fileChooser!: ElementRef;
  user: User_class;
  user_error: User_error_class;
  mode: string;
  faInfoCircle = faInfoCircle;
  public Editor_config = {
    fontSize: {
      options: [
          9,
          11,
          13,
          'default',
          17,
          19,
          21
      ]
    },
    fontColor: {
      colors: [
          {
              color: 'hsl(0, 0%, 0%)',
              label: 'Black'
          },
          {
              color: 'hsl(0, 0%, 30%)',
              label: 'Dim grey'
          },
          {
              color: 'hsl(0, 0%, 60%)',
              label: 'Grey'
          },
          {
              color: 'hsl(0, 0%, 90%)',
              label: 'Light grey'
          },
          {
              color: 'hsl(0, 0%, 100%)',
              label: 'White',
              hasBorder: true
          },

          // ...
      ]
    },
    toolbar: [ 'fontSize', 'fontColor', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'mediaEmbed' ]
};
public Editor = ClassicEditor;
  constructor(public backend: BackendServiceService,
              public userContainer: UserContainerService,
              private localLogin: LocalLoginService,
              public router: Router) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.user_error = new User_error_class();
    if(this.userContainer.get()!=undefined&&this.userContainer.get().user_name!=undefined&&this.userContainer.get().user_name!=''){
      this.mode = 'E';
      this.user = this.userContainer.get();
      //console.log(this.user);
    } else {
      this.mode = 'C';
      this.user = new User_class();
    }
    this.user.creator_role = this.userContainer.creator_role;
    this.user.creator_user = this.userContainer.creator_user_name;
    if(this.user.sat_of==undefined)
      this.user.sat_of=' ';
    console.log(this.user);
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(){
    this.wireUpFileChooser();
  }

  validate(): boolean{
    let result = true;
    this.user_error = new User_error_class();
    if(this.isVoid(this.user.name)){
      this.user_error.name.push("El camp nom es obligatori");
      result = false;
    }
    if(this.isVoid(this.user.user_name)){
      this.user_error.user_name.push("El camp usuari es obligatori");
      result = false;
    }
    if(this.user.creator_role == 'R'){
      if(this.isVoid(this.user.commission_free)){
        this.user_error.commission_free.push("El camp comisio es obligatori");
        result = false;
      }
      if(this.isVoid(this.user.commission_low)){
        this.user_error.commission_low.push("El camp comisio es obligatori");
        result = false;
      }
      if(this.isVoid(this.user.commission_high)){
        this.user_error.commission_high.push("El camp comisio es obligatori");
        result = false;
      }
    }
    if(this.mode == 'C' && this.isVoid(this.user.password)){
      this.user_error.password.push("El camp contrasenya es obligatori");
      result = false;
    }
    return result;
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  save(){
    //console.log(this.user);
    if(this.validate())
      if(this.mode=='C'){
        console.log(this.user);
        this.backend.saveUser(this.user).subscribe(data => {
          //console.log(data);
          if(data['error']=='login_error'){
              this.backend.logout().subscribe(data=> {
              this.localLogin.remove(true);
              this.router.navigateByUrl("/");
            })
          }

          if(data){
              this.router.navigateByUrl("user-list");
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
      } else {
        console.log(this.user);
        this.backend.modifyUser(this.user).subscribe(data => {
          console.log(data);
          if(data['error']=='login_error'){
            this.backend.logout().subscribe(data=> {
            this.localLogin.remove(true);
            this.router.navigateByUrl("/");
            })
          }

          if(data){
              this.router.navigateByUrl("user-list");
          }
          else{
            //Controlled error
          }
        }, error => {
          console.log(error);
        });
      }
  }

  wireUpFileChooser(){
    const elementRef = this.fileChooser.nativeElement as HTMLInputElement;

    elementRef.addEventListener('change', (evt: any) => {
      const selectedFile = evt.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener("loadend", (evt: any) => {
        this.user.logo = evt.target.result;
      });
    });
  }
}
