<div class="container-fluid" style="padding-top: 50px; padding-bottom: 100px;">
    <div class="row">
        
        <div class="col-6 offset-3">
            <div class="row" *ngFor="let question of questions;index as i">
                <div class="col-12 mt-3">
                    <label>{{question.text}}</label>
                </div>
                <div class="col-12" *ngIf="question.type!='T'">
                    <div *ngFor="let response of question.response;index as o">
                        <div class="input-group mt-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <fa-icon [icon]="faCircle" *ngIf="question.type=='R'&&question.response[o]['MARK']!='Y'"></fa-icon>
                                    <fa-icon [icon]="faCheckCircle" *ngIf="question.type=='R'&&question.response[o]['MARK']=='Y'"></fa-icon>
                                    <fa-icon [icon]="faSquare" *ngIf="question.type=='C'&&question.response[o]['MARK']!='Y'"></fa-icon>
                                    <fa-icon [icon]="faCheckSquare" *ngIf="question.type=='C'&&question.response[o]['MARK']=='Y'"></fa-icon>
                                </div>
                            </div>
                            <label class="form-control" style="height: unset;">{{question.response[o].text}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="question.type=='T'">
                    <textarea class="form-control" [(ngModel)]="question.text_response"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
