<div class="container-fluid" style="padding-top: 50px; padding-bottom: 100px;">
    <div class="row">
        <div class="col-6 offset-3">
            <div class="form-group">
                <label>Nom del recinte</label>
                <input type="text" class="form-control" [(ngModel)]="zone.name" required #surname="ngModel">
            </div>
            <div class="form-group">
                <label>Foto</label>
                <input #filechooser type="file" class="form-control-file">
            </div>
            <h4>Llotges</h4>
            <app-create-subzone *ngFor="let subzone of zone.subzones; index as i" [subzone]="subzone" [subzones]="zone.subzones" [position]="i"></app-create-subzone>
            <button type="button" class="btn btn-primary mt-3" style="width: 100%;" (click)="addSubzone()">+</button>
            <button type="button" class="btn btn-primary mt-3" style="width: 100%;" (click)="save()">Guardar</button>
        </div>
    </div>
</div>
<app-back-button></app-back-button>
