<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-12">
            <a [href]="link_export_mails">
                <button type="button" class="btn btn-success btn-block">
                    Descarregar llista de correus (CSV)
                </button>
            </a>
        </div>
    </div>
</div>
