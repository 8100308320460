import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { User_class } from 'src/app/class/User_class';
import { faUser, faUserClock, faUsers, faUserFriends, faTicketAlt, faCalendarDay, faEuroSign, faMoneyBillWave, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { User_events_stats_class } from 'src/app/class/User_events_stats_class';
import { Router } from '@angular/router';
import { LocalLoginService } from 'src/app/services/local-login.service';
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  users: Array<User_class>;
  events: Array<User_events_stats_class>;
  active_user: string;
  active_user_index: number;
  active_event: number;
  active_event_index: number;
  accent_color: string;
  view_user: string;
  view_event: string;
  attended_registred_p: number;
  registred_tickets_p: number;
  ev_attended_registred_p: number;
  ev_registred_tickets_p: number;
  faUser = faUser;
  faUserClock = faUserClock;
  faUsers = faUsers;
  faUserFriends = faUserFriends;
  faTicketAlt = faTicketAlt;
  faCalendarDay = faCalendarDay;
  faEuroSign = faEuroSign;
  faCalendar = faCalendar;
  faMoneyBillWave = faMoneyBillWave;
  constructor(public backend: BackendServiceService,
              public router: Router,
              private localLogin: LocalLoginService,
              public sanitizer: DomSanitizer) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.accent_color = this.backend.accent_color;
    this.view_user = 'F';
    this.view_event = 'F';
    this.events = new Array<User_events_stats_class>();
    this.users = new Array<User_class>();
    this.active_user_index = 0;
    this.attended_registred_p = 0;
    this.load_users();
  }

  ngOnInit(): void {
  }

  set_active_user(index: number){
    this.active_user = this.users[index].user_name;
    this.active_user_index = index;
    this.load_events();
  }

  set_active_event(index: number){
    this.active_event = this.events[index].id;
    this.active_event_index = index;
    this.calculate_percents_ev();
  }

  load_users(){
    this.backend.getUsers().subscribe(data => {
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.users = Object.assign(new Array, data);
        let i = -1;
        this.active_user = undefined;
        if(this.users.length > 0){
          do{
            i++;
            this.active_user = this.users[i].user_name;
            this.active_user_index = i;
            //console.log('i:'+i+'user:'+this.active_user+'role:'+this.users[i].role)
          } while((this.users[i].role != 'U' || this.users[i].archived != 'F') && i < this.users.length);
        }
        //console.log(this.active_user)
        if(this.active_user!=undefined)
          this.load_events();

        //console.log(this.active_user)
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  load_events(){
    this.backend.get_user_stats(this.active_user).subscribe(data => {
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.events = Object.assign(new Array, data);
        if(this.events.length>0){
          this.active_event = this.events[0].id;
          this.active_event_index = 0;
          this.calculate_percents();
          this.calculate_percents_ev();
        }
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  dateFormat(date){
    date = new Date(date * 1000);
    let result = date.getDate();
    switch(date.getMonth()+1){
      case 1:
        result += ' de gener';
      break;
      case 2:
        result += ' de febrer';
      break;
      case 3:
        result += ' de març';
      break;
      case 4:
        result += ' d\'abril';
      break;
      case 5:
        result += ' de maig';
      break;
      case 6:
        result += ' de juny';
      break;
      case 7:
        result += ' de juliol';
      break;
      case 8:
        result += ' d\'agost';
      break;

      case 9:
        result += ' de setembre';
      break;
      case 10:
        result += ' d\'octubre';
      break;
      case 11:
        result += ' de novembre';
      break;
      case 12:
        result += ' de desembre';
      break;
    }
    result += ' ' + date.getFullYear();
    return result;
  }

  dateFormatNum(date){
    date = new Date(date * 1000);
    let result = date.getDate();
    result += '/' + (date.getMonth()+1);
    result += '/' + date.getFullYear();
    return result;
  }

  get_user_registred_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.registred_count*1));
    return result;
  }

  get_user_attended_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.attended_count*1));
    return result;
  }

  get_num_tickets_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.num_tickets*1));
    return result;
  }

  get_avg_ticket_price(){
    let result = 0;
    let count = 0;
    this.events.forEach(element => {
        if(element.price != undefined && element.price != 0){
          count += 1;
          result += (element.price*1);
        }
      }
    );
    return result/count!=NaN?result/count:0;
  }

  get_payed_tickets_total_price(){
    let result = 0;
    this.events.forEach(element =>
        result += ((element.payed_attendant*1) + (element.fu_payed_attendant*1))
                    * element.price
        );
    return result;
  }

  get_user_payed_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.payed_attendant*1));
    return result;
  }

  get_user_fu_registred_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.fu_registred_count*1));
    return result;
  }

  get_user_fu_attended_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.fu_attended_count*1));
    return result;
  }

  get_user_fu_payed_count(){
    let result = 0;
    this.events.forEach(element =>
        result += (element.fu_payed_attendant*1));
    return result;
  }

  calculate_percents(){
    let registred = this.get_user_fu_registred_count()*1
                      + this.get_user_registred_count()*1;
    let attended = this.get_user_attended_count()*1
                      + this.get_user_fu_attended_count()*1;
    this.attended_registred_p = registred==0?0:(attended/registred)*100;

    let tickets = this.get_num_tickets_count();
    this.registred_tickets_p = (registred/tickets)*100;
  }

  calculate_percents_ev(){
    let event = this.events[this.active_event_index];
    let registred = event.registred_count*1 + event.fu_registred_count*1;
    let attended = event.attended_count*1 + event.fu_attended_count*1;
    this.ev_attended_registred_p = registred==0?0:(attended/registred)*100;

    let tickets = event.num_tickets;
    this.ev_registred_tickets_p = (registred/tickets)*100;
  }

}
