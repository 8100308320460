import { Attendant_me_participates_session } from "./Attendant_me_participates_session";
import { Event_session_class } from "./Event_session_class";

export class Attendant_me_participates{
    id_event: string;
    event_has_sessions: boolean = false;
    assists: boolean = false;
    sessions: Array<Attendant_me_participates_session>;
    selectedAltPrice: string;

    constructor(id_event: string = ''){
        this.id_event = id_event;
        this.sessions = new Array<Attendant_me_participates_session>();
        this.selectedAltPrice = '';
    }

    addSessions(sessions: Array<Event_session_class>){
        sessions.forEach((session: Event_session_class) => {
            let s = new Attendant_me_participates_session(session.id);
            this.sessions.push(s);
        });
    }
}