<div class="absolute w-full min-h-full bg-stone-100" *ngIf="needSessionSelect()">
  <div class="row">
    <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xs-12">
      <app-session-select [event]="event"
        (ended)="sessionSelected=true">
      </app-session-select>
    </div>
  </div>
</div>

<div class="absolute w-full min-h-full bg-stone-100" *ngIf="!needSessionSelect()">
    <div class="row">
        <div class=" bg-white p-8 max-w-5xl mx-auto my-8 rounded text-sm">
            <img class="img-fluid" [src]="event.image_zone?event.image_zone:''" />

            <app-payment-wait [idAttendant]="idAttendantP"
              [check]="checkAttendantP"></app-payment-wait>

            <app-select-attendant-type *ngIf="attendantType==' '"
              (confirmType)="processType($event)" [event]="event">
            </app-select-attendant-type>

            <app-register-zone-attendants *ngIf="attendantType=='Z'"
              [questions]="event.questions" [event]="event">
            </app-register-zone-attendants>
            <app-register-familiar-union-attendants *ngIf="attendantType=='F'"
              [questions]="event.questions"
              [seassonTicket]="seassonTicket"
              [fields]="fields"
              [userSeassonTicket]="userSeassonTicket"
              [event]="event" (ended)="ended.emit('')">
            </app-register-familiar-union-attendants>

            <app-form-attendant *ngIf="attendantType=='I'"
              [attendant]="attendant" [questions]="attendant.questions"
              [attendant_errors]="attendant_errors"
              [seassonTicket]="seassonTicket"
              [fields]="fields"
              [userSeassonTicket]="userSeassonTicket"
              [altPrices]="event.altPrices">
            </app-form-attendant>
            
            <ng-container *ngIf="attendantType=='I'&&event.general_conditions!=undefined&&event.general_conditions!=''">
                <div class="my-3 inline-block">
                    <input class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  type="checkbox" [(ngModel)]="gConditionsAccepted">
                    <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{'He llegit i accepto les condicions de compra que puc llegir' | translate }} <b><a class="text-red-400" (click)="showConditions(condition, event.general_conditions)">{{'aqui' | translate}}</a></b></label>
                </div>
                <div class="text-red-500 text-xs mt-1" role="alert" *ngFor="let error of attendant_errors.general_condition">
                    {{error | translate}}
                </div>
            </ng-container>
            <ng-container *ngIf="attendantType=='I'&&event.conditions!=undefined&&event.conditions!=''">
                <div class="my-3 inline-block">
                    
                    <input class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  type="checkbox" [(ngModel)]="conditionsAccepted">
                    <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{'He llegit i accepto les condicions de compra que puc llegir' | translate }} <b><a class="text-red-400" (click)="showConditions(condition, event.conditions)">{{'aqui' | translate}}</a></b></label>
                </div>
                <div class="text-red-500 text-xs mt-1" role="alert" *ngFor="let error of attendant_errors.condition">
                    {{error | translate}}
                </div>
            </ng-container>
            <ng-container *ngIf="attendantType=='I'">
                <div class="my-3 inline-block">
                    
                    <input class="w-4 h-4 text-lime-600 bg-gray-100 border-gray-300 rounded focus:ring-lime-500 dark:focus:ring-lime-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  type="checkbox" [(ngModel)]="pConditionsAccepted">
                    <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{'He llegit i accepto les condicions de privacitat que puc llegir' | translate }} <b><a class="text-red-400" [href]="ent_url+'politica-privacitat'" target="_blank">{{'aqui' | translate}}</a></b></label>
                </div>
                <div class="text-red-500 text-xs mt-1" role="alert" *ngFor="let error of attendant_errors.pConditions">
                    {{error | translate}}
                </div>
            </ng-container>
            <ng-container *ngIf="attendantType=='I'">
                
            </ng-container>
            <ng-container *ngIf="attendantType=='I'&&manual">
                <div class="row mt-3">
                    <div class="col-12 mt-1">
                        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="send_mail">
                            <label ngbButtonLabel class="btn-primary">
                                <input ngbButton type="radio" [value]="true"> Enviar entrada per correu
                            </label>
                            <label ngbButtonLabel class="btn-primary">
                                <input ngbButton type="radio" [value]="false"> Mostrar entrada per pantalla
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="event.paid&&attendantType=='I'&&!seassonTicket">
                <div class="my-3"><hr></div>
                
                    
                        
                        <div class="w-full text-2xl"><span class="text-stone-400">{{'Preu total' | translate}}</span> {{getPrice()}}€</div>
                        <div class="w-full text-stone-400 text-xs" *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                            Inclou {{event.management_cost*1}} € de despeses de gestió                        
                        </div>
                   
                
            </ng-container>
            <ng-container *ngIf="event.paid&&attendantType=='I'&&seassonTicket">
                <div class="my-3"><hr></div>
              
              <div class="w-full text-2xl"><span class="text-stone-400">{{'Preu total' | translate}}</span> {{getUserSeassonPrice()}}€</div>
                        <div class="w-full text-stone-400 text-xs" *ngIf="event.show_commission&&event.management_cost!=undefined&&event.management_cost>0">
                            Inclou {{getManagementCost()}} € de despeses de gestió                        
                        </div>
          </ng-container>
            <ng-container *ngIf="attendantType=='I'">
                <div class="text-red-500 text-xs mt-1" role="alert" *ngFor="let error of attendant_errors.general_error">
                    {{error | translate}}
                </div>
            </ng-container>
            <div *ngIf="errorSaving" class="color-red">
                No s'ha pogut registrar, si us plau torni a intentar-ho
            </div>
            <div class="w-full"><button type="button" *ngIf="attendantType=='I'&&!waitServer" class="bg-lime-200 hover:bg-lime-300 text-stone-900 text-xs py-2 px-4 rounded my-3"
                (click)="confirm()"><span *ngIf="event.paid">{{'Comprar ' | translate}}</span><span *ngIf="!event.paid">{{'Reservar ' | translate}}</span>{{'entrada' | translate}}</button></div>
            
            <div *ngIf="waitServer">
                Esperant resposta del servidor. Si us plau, esperi.
            </div>
            <div class="btn btn-c mt-3 text-center" *ngIf="waitServer">
                <img src="/assets/loader.gif" style="height: 23px;">
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed bg-lime-200 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div class="relative w-full grid place-items-center h-screen">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 grid max-w-3xl h-fit">
                <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                    <h4 class="modal-title" id="modal-basic-title">{{ 'Reserva completada amb èxit' | translate }}</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.close('')" (click)="refresh()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="p-6 space-y-6">
                    
                    
                    <strong class="color-red">{{ 'NOTA IMPORTANT' | translate }}</strong>
                    <br>
                    <small>
                        {{ 'En breu rebreu les entrades per correu electrònic un porcés que pot demorar-se uns minuts. Si passat 30 minuts no heu rebut el correu, si us plau poseu-vos en contacte a acces@mesacces.com' | translate }}.
                    </small>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #condition let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Condicions</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="condition_text">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modal.close('')">Tancar</button>
    </div>
</ng-template>


<app-back-button *ngIf="backend.apikey!=undefined&&backend.role!=undefined&&router.url.substring(0,14) != '/esdeveniment/'"></app-back-button>
