export class User_error_class{
    user_name: Array<string>;
    name: Array<string>;
    password: Array<string>;
    commission_free: Array<string>;
    commission_low: Array<string>;
    commission_high: Array<string>;
    constructor(){
        this.user_name = new Array<string>();
        this.name = new Array<string>();
        this.password = new Array<string>();
        this.commission_free = new Array<string>();
        this.commission_low = new Array<string>();
        this.commission_high = new Array<string>();
    }
}
