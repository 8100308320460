import { Component, OnInit } from '@angular/core';
import { Event_class } from 'src/app/class/Event_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { EventContainerService } from 'src/app/services/event-container.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserContainerService } from 'src/app/services/user-container.service';
import { User_class } from 'src/app/class/User_class';
import { faPlusSquare, faEye, faEyeSlash, faExternalLinkAlt, faFileDownload, faTrash, faEdit, faList, faLink, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { faClone, faCopy } from '@fortawesome/free-regular-svg-icons'
import { DomSanitizer } from '@angular/platform-browser';
import * as Constants from 'src/app/modules/constants/constants.module';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit {
  listEvents: Array<Event_class>;
  public delete_event_name: string;
  user: User_class;
  mode: string;
  accent_color: string;
  name_user: string;
  name_user_list: string;
  show: string;
  user_id: string;
  waitingClone: boolean = false;
  faPlusSquare = faPlusSquare;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faExternalLink = faExternalLinkAlt;
  faFileDownload = faFileDownload;
  faTrash = faTrash;
  faEdit = faEdit;
  faList = faList;
  faLink = faLink;
  faClone = faClone;
  faCopy = faCopy;
  faHourglassHalf = faHourglassHalf;
  constructor(public backend: BackendServiceService,
              private router: Router,
              private modalService: NgbModal,
              public sanitizer: DomSanitizer,
              public userContainer: UserContainerService,
              private localLogin: LocalLoginService,
              private eventContainer: EventContainerService) {
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.accent_color = this.backend.accent_color;
    this.name_user = this.backend.name_user;
    this.show = 'F';
    if(this.userContainer.get()!=undefined){
      this.user = this.userContainer.get();
      this.user_id = this.user.user_id;
      this.getEvents();
      this.name_user_list = this.user.name;
      this.mode = 'A';
    } else {
      this.getEventsByKey();
      this.getUserByKey();
      this.mode = 'U';
    }
    //this.userContainer.set(new User_class());
   }

  ngOnInit(): void {
  }

  clone(event: Event_class){
    this.waitingClone = true;
    this.backend.cloneEvent(event.id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.getEvents();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  editar(event: Event_class){
    //console.log(event);
    this.eventContainer.set(event);
    this.router.navigate(['/event']);
  }

  goToUserSeasonTicket(){
    this.router.navigateByUrl('/user-season-ticket-list');
  }

  goToMultiEventRegister(){
    this.router.navigateByUrl('/me-register');
  }

  new(){
    this.eventContainer.set(undefined);
    this.router.navigateByUrl('/event');
  }

  viewAttendants(event: Event_class){
    this.eventContainer.set(event);
    this.router.navigate(['/list/' + event.id]);
  }

  view(event: Event_class){
    this.router.navigate(['/esdeveniment/' + event.id]);
  }

  dateFormat(date){
    let dateF: Date = new Date(date * 1000);
    let day = dateF.getDate();
    let month = dateF.getMonth() + 1;
    let year = dateF.getFullYear();
    return day + '/' + month + '/' + year.toString().substr(year.toString().length - 2, 2);
  }

  eventLife(date){
    let d = new Date(date * 1000);
    d.setDate((new Date(date * 1000)).getDate() + 30);
    //console.log(d.toLocaleDateString());
    let days = Number.parseInt((((((d.valueOf() - (new Date()).valueOf()) /1000) /60) /60) /24).toFixed());
    //console.log(days);
    return days>0?days:0;
  }

  open(content, event) {
    this.delete_event_name = event.event_name;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='DELETE')
        this.deleteEvent(event.id);
    }, (reason) => {

    });
  }

  deleteEvent(id){
    this.backend.deleteEvent(id).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data['ok']){
        this.getEvents();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getEvents(){
    this.backend.getAllEvents(this.user.user_name).subscribe(data => {
      this.waitingClone = false;
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.listEvents = Object.assign(new Array, data);
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getEventsByKey(){
    this.backend.getAllEventsByKey().subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.listEvents = Object.assign(new Array, data);
        //console.log(this.listEvents);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getUserByKey(){
    this.backend.getUserByKey().subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        this.user = Object.assign(new User_class, data);
        //console.log(this.user);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  archive(id){
    const archive = this.show == 'T'?'F':'T';
    this.backend.setArchivedEvent(id,archive).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(this.mode=='A')
          this.getEvents();
        else
          this.getEventsByKey();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getUrlZip(){
    return Constants.ENT_URL + 'data/get_custom_file?user_name=' + this.user.user_name;
  }

  getIframe(){
    const link = Constants.ENT_URL + "events_list/" + this.user_id;
    return '<iframe id="frame" src="'+link+'" style="border: 0; width: 100%; height: 100%;">ERROR</iframe>';
  }

  open_showiframe(content){
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  getAttendantP(event: Event_class): number{
    return ((event.tot_attendants/(event.has_sessions?event.sum_sessions_tickets:event.num_tickets))*100);
  }

  getCloneState(){
    return this.waitingClone?faHourglassHalf:faClone;
  }

  clipboard_copy(text: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  acortar(text){
    return text.substr(0,50);
  }
}
