<p class="mb-4 font-bold text-sm">
    La reserva d'entrades a través del nostre sistema es realitza en 3 passos:
</p>
<ol class="list-outside list-disc">
    <li>
        Triï el tipus d'entrada que vol reservar o comprar (individual o múltiple).
    </li>
    <li>
        Completi el formulari amb totes les seves dades.
    </li>
    <li>
        En el cas d'un esdeveniment de pagament, efectuï el pagament amb les seves dades
        bancàries. Recordi que la majoria dels bancs requereixen una autorització de 2 passos
        en un temps determinat per poder completar pagaments en línia. Perquè el pagament
        s’efectuï de manera correcta, asseguri's de seguir tots els passos.
    </li>
</ol>
<p>
    Una vegada completat el registre a un esdeveniment, pot descarregar les entrades al moment,
    des de la mateixa pàgina web on ha realitzat la reserva. Igualment, rebrà en els següents
    minuts les entrades en el correu electrònic que ens ha facilitat.
</p>
<br>
<p class="mb-4 font-bold text-sm">
    Si té problemes per completar el formulari de reserva d'entrades:
</p>
<ol class="list-outside list-disc">
    <li>
        Asseguri's d'estar utilitzant un navegador actualitzat com Google Chrome, Firefox,
        Microsoft Edge, etc. No podrà completar el formulari mitjançant Internet Explorer.
    </li>
    <li>
        Davant un avís d'advertiment, comprovi que el seu antivirus li permeti emplenar
        formularis de compra en Internet.
    </li>
    <li>
        Si en completar el seu DNI/NIE rep un error amb el missatge "El DNI ja es troba
        registrat.", busqui un correu nostre a la seva bústia d'entrada. És possible que trobi un
        correu de "Pagament pendent". Segueixi les instruccions d'aquest correu per
        completar la seva reserva de l'esdeveniment.
    </li>
</ol>
<br>
<p class="mb-4 font-bold text-sm">
    Si ha completat el formulari amb èxit, però ha tingut problemes amb el pagament:
</p>
<ol class="list-outside list-disc">
    <li>
        Si rep un correu automàtic de "Pagament pendent" però s'ha efectuat el càrrec en el
        seu compte bancari, escriu-nos un correu a <a class="font-bold text-lime-400" href="mailto:acces@mesacces.com">acces@mesacces.com</a> amb el seu DNI/NIE i
        el nom de l'esdeveniment al qual ha realitzat la reserva. Nosaltres ens encarregarem
        de fer-li arribar les entrades.
    </li>
    <li>
        Si rep un correu automàtic de "Pagament pendent" i no s'ha efectuat el càrrec en el
        seu compte bancari, segueixi les instruccions del correu per completar el pagament
        amb èxit.
    </li>
    <li>
        Si rep un missatge d'error en el procés de pagament, comprovi de nou que totes les
        dades siguin correctes.
    </li>
    <li>
        Si rep un missatge d'error en el procés de pagament, asseguri's que el seu banc no hagi
        bloquejat les compres en línia des de la seva targeta bancària.
    </li>
</ol>
<br>
<p class="mb-4 font-bold text-sm">
    Si ha completat el formulari de reserva i el pagament amb èxit (en el cas dels esdeveniments
    de pagament), però no ha rebut el codi QR amb les entrades:
</p>
<ol class="list-outside list-disc">
    <li>
        En cas d'utilitzar un correu electrònic Hotmail, revisi la seva carpeta de "Correu no
        desitjat".
    </li>
    <li>
        En cas d'utilitzar un correu electrònic Gmail, revisi la seva carpeta de "Promocions".
    </li>
    <li>
        Si s'ha equivocat en facilitar-nos la seva adreça de correu electrònic, enviï'ns un correu
        a <a class="font-bold text-lime-400" href="mailto:acces@mesacces.com">acces@mesacces.com</a> amb el seu DNI/NIE i el nom de l'esdeveniment al qual ha
        realitzat la reserva. Nosaltres ens encarregarem de fer-li arribar les entrades.
    </li>
    <li>
        Si ha rebut el correu amb les entrades, però l'ha esborrat, enviï'ns un correu a
        <a class="font-bold text-lime-400" href="mailto:acces@mesacces.com">acces@mesacces.com</a> amb el seu DNI/NIE i el nom de l'esdeveniment al qual ha
        realitzat la reserva. Nosaltres ens encarregarem de fer-li arribar les entrades.
    </li>
</ol>
<br>
<p>
    Per a qualsevol altre dubte, no dubti a posar-se en contacte amb nosaltres a
    <a class="font-bold text-lime-400" href="mailto:acces@mesacces.com">acces@mesacces.com</a>. Intentarem contestar-li el més aviat possible. El nostre horari d’atenció
    al client és de 09.00 a 15.00 de dilluns a divendres.
</p>