import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Router } from '@angular/router';
import { Configuration_class } from 'src/app/class/Configuration_class';
import { General_configuration_class } from 'src/app/class/General_configuration_class';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalLoginService } from 'src/app/services/local-login.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
  @ViewChild('filechooser',{static: false}) fileChooser!: ElementRef;
  config: Configuration_class;
  gconfig: General_configuration_class;
  saveConfigOK: Boolean;
  saveGConfigOK: Boolean;
  public Editor_config = {
      fontSize: {
        options: [
            9,
            11,
            13,
            'default',
            17,
            19,
            21
        ]
      },
      fontColor: {
        colors: [
            {
                color: 'hsl(0, 0%, 0%)',
                label: 'Black'
            },
            {
                color: 'hsl(0, 0%, 30%)',
                label: 'Dim grey'
            },
            {
                color: 'hsl(0, 0%, 60%)',
                label: 'Grey'
            },
            {
                color: 'hsl(0, 0%, 90%)',
                label: 'Light grey'
            },
            {
                color: 'hsl(0, 0%, 100%)',
                label: 'White',
                hasBorder: true
            },

            // ...
        ]
      },
      toolbar: [ 'fontSize', 'fontColor', 'bold', 'italic', 'bulletedList', 'numberedList', 'link', 'mediaEmbed' ]
  };
  public Editor = ClassicEditor;
  constructor(public backend: BackendServiceService,
              private localLogin: LocalLoginService,
              public router: Router) {
    this.config = new Configuration_class();
    this.gconfig = new General_configuration_class();
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.getConfig();
    this.getGConfig();
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(){
    this.wireUpFileChooser();
  }

  getGConfig(){
    let fields = [
      'RS_VER',
      'RS_KEY',
      'RS_FUC',
      'RS_TER',
      'RS_LNK',
      'RS_LOK',
      'RS_LKO',
      'RS_DIV',
      'RS_MIN',
      'RS_TRA',
      'RES_TI'
    ];
    this.backend.getGConfig(fields).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.gconfig = Object.assign(new General_configuration_class(), data);
      //console.log(this.gconfig);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  getConfig(){
    this.backend.getConfig().subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.config = Object.assign(new Configuration_class(), data);
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  save(){
    this.saveConfigOK = false;
    this.saveGConfigOK = false;
    this.saveConfig();
    this.saveGConfig();
  }

  checkSave(){
    if(this.saveConfigOK&&this.saveGConfigOK)
      this.router.navigateByUrl("user-list");
  }

  saveConfig(){
    this.backend.saveConfig(this.config).subscribe(data => {
      //console.log(data);
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        if(data['ok'])
          this.saveConfigOK = true;
        this.checkSave();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  saveGConfig(){
    console.log(this.gconfig);
    this.backend.saveGConfig(this.gconfig).subscribe(data => {
      //console.log(data); return 0;
      if(data['error']=='login_error'){
this.backend.logout().subscribe(data=> {
this.localLogin.remove(true);
this.router.navigateByUrl("/");
})
}

      if(data){
        this.saveGConfigOK = true;
        this.checkSave();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  wireUpFileChooser(){
    const elementRef = this.fileChooser.nativeElement as HTMLInputElement;

    elementRef.addEventListener('change', (evt: any) => {
      const selectedFile = evt.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener("loadend", (evt: any) => {
        this.config.image = evt.target.result;
      });
    });
  }

}
