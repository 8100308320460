import { Component, OnInit, Input } from '@angular/core';
import { Subzone_class } from 'src/app/class/Subzone_class';

@Component({
  selector: 'app-create-subzone',
  templateUrl: './create-subzone.component.html',
  styleUrls: ['./create-subzone.component.css']
})
export class CreateSubzoneComponent implements OnInit {
  @Input() subzone: Subzone_class;
  @Input() subzones: Array<Subzone_class>;
  @Input() position: number;

  constructor() { }

  ngOnInit(): void {
  }

  deleteSubzone(){
    this.subzones = this.subzones.splice(this.position,1);
  }
}
