import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Event_class } from 'src/app/class/Event_class';
import { Attendant_error_class } from 'src/app/class/Attendant_error_class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventContainerService } from 'src/app/services/event-container.service';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import * as Constants from 'src/app/modules/constants/constants.module';
import { EventSessionContainerService } from 'src/app/services/event-session-container.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';
import { Fields_class } from 'src/app/class/Fields_class';
import { Field_class } from 'src/app/class/Field_class';

@Component({
  selector: 'app-register-attendant',
  templateUrl: './register-attendant.component.html',
  styleUrls: ['./register-attendant.component.css']
})
export class RegisterAttendantComponent implements OnInit {
  @Input() seassonTicket: boolean = false;
  @Input() userSeassonTicket: User_season_ticket_class;
  @Output() ended: EventEmitter<string>;
  @ViewChild('content') content: ElementRef;
  idAttendantP: string;
  public attendant: Attendant_class;
  public event: Event_class;
  private id_event: string;
  supp_mail: string;
  inPerson: boolean;
  manual: boolean;
  send_mail: boolean;
  show_entry: boolean;
  has_paied: boolean;
  sessionSelected: boolean;
  conditionsAccepted: boolean;
  gConditionsAccepted: boolean;
  condition_text: string;
  attendant_errors: Attendant_error_class;
  attendantType: string = ' ';
  rsVersion: string;
  rsParams: string;
  rsSignature: string;
  rsLink: string;
  checkAttendantP: boolean;
  pConditionsAccepted: boolean;
  ent_url: string;
  idUserSeassonTicket: string;
  key: string;
  waitServer: boolean = false;
  errorSaving: boolean = false;
  url: string = Constants.ENT_URL;
  fields: Fields_class = new Fields_class();
  faEuroSign = faEuroSign;
  faQuestionCircle = faQuestionCircle;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public modalService: NgbModal,
              public eventContainer: EventContainerService,
              public event_sesion_container: EventSessionContainerService,
              public attendantContainer: AttendantContainerService,
              private localLogin: LocalLoginService,
              public backend: BackendServiceService) {
    if(router.url.substring(0,19) == '/registerattendant/')
      if(this.backend.apikey==undefined){
        if(this.localLogin.existsData()){
          this.localLogin.restore_to_service();
        } else {
          this.router.navigateByUrl("/");
        }
      }
    this.ended = new EventEmitter<string>();
    this.sessionSelected = false;
    this.event = new Event_class();
    this.attendant_errors = new Attendant_error_class();
    this.attendantType = ' ';
    this.idAttendantP = '';
    this.conditionsAccepted = false;
    this.checkAttendantP = false;
    this.pConditionsAccepted = false;
    this.supp_mail = Constants.SUPP_MAIL;
    this.ent_url = Constants.ENT_URL;
    if(this.backend.inPerson != undefined)
      this.inPerson = this.backend.inPerson;
    else
      this.inPerson = false;
    if(this.backend.manual != undefined)
      this.manual = this.backend.manual;
    else
      this.manual = false;
    if(this.manual){
      this.send_mail = false;
      this.show_entry = true;
      this.has_paied = true;
    }
  }

  ngOnChanges(){
    this.setAttendantType();
    if(this.attendant!=undefined){
      this.attendant.selectedUserSeasonTicket = this.userSeassonTicket.id;
    }
  }

  ngOnInit(): void {
    this.attendant = new Attendant_class();
    if(this.userSeassonTicket!=undefined){
      this.attendant.selectedUserSeasonTicket = this.userSeassonTicket.id;
    }
    this.id_event = this.route.snapshot.paramMap.get("id_event");
    this.attendant.id_event = this.route.snapshot.paramMap.get("id_event");
    this.backend.getEvent(this.id_event).subscribe(data => {
      //console.log(data);
      if(data){
        this.event = Object.assign(new Event_class(),data);
        this.attendant.questions = this.event.questions;
        this.setAttendantType();
        //Chapuza ethernum
        if(this.id_event == '4464'||this.id_event == '4494'){
          let field = new Field_class();
          field.name = 'dni';
          field.enabled = false;
          field.validate = false;
          this.fields.fields.push(field);
          let field2 = new Field_class();
          field2.name = 'phone';
          field2.enabled = false;
          field2.validate = false;
          this.fields.fields.push(field2);
        }
      }
    }, error => {
      console.log(error);
    });
  }

  setAttendantType(){
    if(this.seassonTicket){
      this.attendantType = this.userSeassonTicket.allow_i?'I':'F';
      if(this.userSeassonTicket.allow_fu){
        this.event.max_fu_attendants = this.userSeassonTicket.max_fu;
      }
    } else {
      if(this.count_register_modes()>1){
        this.attendantType = ' ';
      } else {
        this.attendantType = this.get_allowed_mode();
      }
    }
  }

  count_register_modes(){
    let count = 0;
    if(this.event.allow_fu_reg)
      count++;
    if(this.event.allow_individual_reg)
      count++;
    return count;
  }

  get_allowed_mode(){
    if(this.event.allow_fu_reg)
      return 'F';
    if(this.event.allow_individual_reg)
      return 'I';
  }

  pay(rsVersion,rsParams,rsSignature,rsLink) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = rsLink;

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_SignatureVersion';
    mapInput.setAttribute("value", rsVersion);
    mapForm.appendChild(mapInput);

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_MerchantParameters';
    mapInput.setAttribute("value", rsParams);
    mapForm.appendChild(mapInput);

    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = 'Ds_Signature';
    mapInput.setAttribute("value", rsSignature);
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  saveAttendantAdmin(){
    this.backend.saveAttendantAdmin(this.attendant).subscribe(data => {
      this.waitServer = false;
      //console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data){
        if(data['ok']){
          this.key = data['key'];
          this.attendant.entry_key = data['key'];
          if(this.show_entry)
            this.goToTicket();
          else{
            let id = this.attendant.id_event;
            this.attendant = new Attendant_class();
            this.attendant.id_event = id;
          }
        } else if(!data['ok']&&data['error']=='dni_exists'){
          this.attendant_errors.dni.push('El dni ja es troba registrat.');
        } else if(data['error'] == 'sold_out'){
          this.attendant_errors.general_error.push('Ho sentim, no queden reserves disponibles.');
        } else {
          this.errorSaving = true;
        }
      } else {
        this.errorSaving = true;
      }
    }, error => {
      this.errorSaving = true;
      this.waitServer = false;
      console.log(error);
    });
  }

  saveAttendant(){
    this.backend.saveAttendant(this.attendant).subscribe(data => {
      this.waitServer = false;
      //console.log(data);
      if(data){
        if(data['ok']){
          this.key = data['key'];
          if(this.inPerson){
            let id = this.attendant.id_event;
            this.attendant = new Attendant_class();
            this.attendant.id_event = id;
          } else {
            if(this.event.paid&&this.getPrice()>0){
              let rs = data['payment_info'];
              this.rsVersion = rs['rsVersion'];
              this.rsParams = rs['rsParams'];
              this.rsSignature = rs['rsSignature'];
              this.rsLink = rs['rsLink'];
              this.pay(this.rsVersion,this.rsParams,this.rsSignature,this.rsLink);
              this.idAttendantP = data['id'];
              this.checkAttendantP = true;
            } else {
              this.open();
              let id = this.attendant.id_event;
              this.attendant = new Attendant_class();
              this.attendant.id_event = id;
            }
          }
        } else if(!data['ok']&&data['error']=='dni_exists'){
          this.attendant_errors.dni.push('El dni ja es troba registrat.');
        } else if(data['error'] == 'sold_out'){
          this.attendant_errors.general_error.push('Ho sentim, no queden reserves disponibles.');
        } else {
          this.errorSaving = true;
        }
      } else {
        this.errorSaving = true;
      }
    }, error => {
      this.errorSaving = true;
      this.waitServer = false;
      console.log(error);
    });
  }

  saveSeassonTicket(){
    this.backend.saveUserSeasonTicketAttendant(this.attendant).subscribe(data => {
      this.waitServer = false;
      //console.log(data); return;
      if(data){
        if(data['ok']){
          let rs = data['payment_info'];
          this.rsVersion = rs['rsVersion'];
          this.rsParams = rs['rsParams'];
          this.rsSignature = rs['rsSignature'];
          this.rsLink = rs['rsLink'];
          this.pay(this.rsVersion,this.rsParams,this.rsSignature,this.rsLink);
          this.idAttendantP = data['id'];
          this.checkAttendantP = true;
        } else if(!data['ok']&&data['error']=='dni_exists')
          this.attendant_errors.dni.push('El dni ja es troba registrat.');
      }
    }, error => {
      this.waitServer = false;
      console.log(error);
    });
  }

  confirm(){
    this.errorSaving = false;
    //console.log(this.attendant); exit();
    //this.attendant.questions = this.event.questions;
    this.attendant.questions.length=0;
    this.attendant.inPerson = this.inPerson;
    this.attendant.manual = this.manual;
    this.attendant.send_mail = this.send_mail;
    this.attendant.has_paied = this.has_paied;
    if(this.event.has_sessions&&!this.seassonTicket)
      this.attendant.id_session = this.event_sesion_container.event_session.id;
    this.show_entry = !this.send_mail;
    this.attendant.show_entry = this.show_entry;
    //console.log(this.attendant); exit();
    //console.log('%cSeasson ticket','background-color:red;color:white;');
    if(this.validate()){
      this.waitServer = true;
      //console.log('%cSeasson ticket','background-color:red;color:white;');
      //console.log(this.attendant);
      if(this.seassonTicket){
        this.saveSeassonTicket();
      } else {
        if(this.manual){
          this.saveAttendantAdmin();
        } else {
          this.saveAttendant();
        }
      }
    }
  }
  

  goToTicket(){
    this.eventContainer.event = this.event;
    this.attendantContainer.multiple = false;
    this.attendantContainer.attendant = this.attendant;
    this.router.navigate(['/view-tickets']);
  }

  processType(type){
    //console.log(this.event);
    this.attendantType = type;
  }

  validate(): boolean{
    let result = true;
    let attendant: Attendant_class = this.attendant;
    let error: Attendant_error_class = new Attendant_error_class();
    if(this.isVoid(attendant.name)){
      error.name.push('Es obligatori introduir un nom.');
      result = false;
    }
    if(this.isVoid(attendant.surname)){
      error.surname.push('Es obligatori introduir un cognom.');
      result = false;
    }
    if(this.fields.needValidation('dni')){
      if(!attendant.nodni){
        if(this.isVoid(attendant.dni)){
          error.dni.push('Es obligatori introduir un DNI.');
          result = false;
        } else if(!this.isValidDNI(attendant.dni)){
          error.dni.push('Es obligatori introduir un DNI vàlid. El format correcte es 12345678A o X1234567A.');
          result = false;
        }
      } else {
        attendant.dni = '-';
      }
    } else {

      attendant.dni = '-';
    }
    
    if(!(this.inPerson||this.manual)){
      if(this.isVoid(attendant.mail)){
        error.mail.push('Es obligatori introduir un correu.');
        result = false;
      }
      if(attendant.mail!=attendant.mail2){
        error.mail.push('No coincideixen els dos camps de correu.');
        result = false;
      }
      if(!this.isMail(attendant.mail)){
        error.mail.push('El format del correu no es vàlid');
        result = false;
      }
    } else {
      if(this.isVoid(attendant.mail)){
        attendant.mail = '-';
      }
    }
    if(this.fields.needValidation('phone')){
      if(this.isVoid(attendant.phone)){
        error.phone.push('Es obligatori introduir un telèfon.');
        result = false;
      }
    } else {
      attendant.phone = '-';
    }
    if(this.event.conditions!=undefined && this.event.conditions!='')
      if(!this.conditionsAccepted){
        error.condition.push('Es obligatori acceptar les condicions.');
        result = false;
      }
    if(this.event.general_conditions!=undefined && this.event.general_conditions!='')
      if(!this.gConditionsAccepted){
        error.general_condition.push('Es obligatori acceptar les condicions.');
        result = false;
      }
    if(!this.pConditionsAccepted){
      error.pConditions.push('Es obligatori acceptar les condicions de privacitat.');
      result = false;
    }
    this.attendant_errors = error;

    return result;
  }

  isValidDNI(text:string){
    if(text.length!=9)
      return false;
    let lastChar = "TRWAGMYFPDXBNJZSQVHLCKE";
    let nieValues = {"X":"0","Y":"1","Z":"2"};
    if(nieValues[text[0]]!=undefined)
      text = nieValues[text[0]] + text.substr(1);
    return lastChar[parseInt(text.substr(0,text.length-1))%23] == text.substr(text.length-1) ;
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  isMail(text){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
  }
  open() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.ended.emit('');
    }, (reason) => {
      this.ended.emit('');
    });
  }
  showConditions(content, text) {
    this.condition_text = text;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
    }, (reason) => {

    });
  }
  getUserSeassonPrice(){
    let price: number = this.event.price;
    if(this.attendant.selectedSection!=' '){
      let existSelected = false;
      this.userSeassonTicket.sections.forEach(
        userSeassonTicket => {
          if(userSeassonTicket.id == this.attendant.selectedSection){
            price = userSeassonTicket.price;
            existSelected = true;
          }
        }
      );
      if(!existSelected){
        this.attendant.selectedSection = ' ';
      }
    } else{
      price =  this.userSeassonTicket.price_individual;
    }
    return price;
  }
  getManagementCost(){
    const price = this.getUserSeassonPrice();
    if(price <= 0)
      return 0;
    if(price <=5)
      return this.backend.commission_low;
    else
      return this.backend.commission_high;
  }

  getPrice(){
    if(this.attendant.selectedAltPrice == ' '){
      return this.event.price*1;
    } else {
      let price = 0;
      this.event.altPrices.forEach(altPrice => {
        if(altPrice.id == this.attendant.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  needSessionSelect(){
    if(this.event_sesion_container.event_session!=undefined&&
        this.id_event!=this.event_sesion_container.event_session.id_event){
      this.event_sesion_container.event_session = undefined;
    }
    return this.event.has_sessions&&!this.seassonTicket&&!this.sessionSelected
      &&this.event_sesion_container.event_session == undefined;
  }
  refresh(): void {
    window.location.reload();
}

}
