import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Attendant_error_class } from 'src/app/class/Attendant_error_class';
import { Question_class } from 'src/app/class/Question_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { Event_class } from 'src/app/class/Event_class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { faTimesCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import * as Constants from 'src/app/modules/constants/constants.module';
import { EventContainerService } from 'src/app/services/event-container.service';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import { EventSessionContainerService } from 'src/app/services/event-session-container.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { User_season_ticket_class } from 'src/app/class/User_season_ticket_class';
import { UserContainerService } from 'src/app/services/user-container.service';
import { Event_session_class } from 'src/app/class/Event_session_class';
import { Event_alternative_price_class } from 'src/app/class/Event_alternative_price_class';
import { Attendant_me_class } from 'src/app/class/Attendant_me_class';
import { Attendant_me_participates } from 'src/app/class/Attendant_me_participates';

@Component({
  selector: 'app-me-familiar-union',
  templateUrl: './me-familiar-union.component.html',
  styleUrls: ['./me-familiar-union.component.css']
})
export class MeFamiliarUnionComponent implements OnInit {
  @Input() questions: Array<Question_class>;
  @Input() event: Event_class;
  @Output() ended: EventEmitter<string>;
  @ViewChild('content') content: ElementRef;
  @ViewChild('alonefu') alonefu: ElementRef;
  @Input() seassonTicket: boolean = false;
  @Input() userSeassonTicket: User_season_ticket_class;
  events: Array<Event_class> = new Array<Event_class>();
  min_fu: number = 1;
  alone: boolean;
  faTimesCircle = faTimesCircle;
  faInfoCircle = faInfoCircle;
  faEuroSign = faEuroSign;
  faQuestionCircle = faQuestionCircle;
  max_attendants: number;
  attendants: Array<Attendant_me_class>;
  attendants_errors: Array<Attendant_error_class>;
  mail: string;
  mail2: string;
  entname:string;
  conditionsAccepted: boolean;
  allow:boolean;
  attendant_errors: Attendant_error_class;
  mail_errors: Array<string>;
  phone:string;
  phone_errors: Array<string>;
  general_error: Array<string>;
  gConditionsAccepted: boolean;
  pConditionsAccepted: boolean;
  allow_newsletter: boolean;
  conditions: string;
  checkAttendantP: boolean;
  idAttendantP: string;
  ent_url: string;
  manual: boolean;
  send_mail: boolean = false;
  show_entry: boolean = true;
  has_paied: boolean = true;
  waitServer: boolean = false;
  errorSaving: boolean = false;
  supp_mail: string = Constants.SUPP_MAIL;
  url: string = Constants.ENT_URL;
  key: string = '';
  attendantType: string = 'FU';
  constructor(public backend: BackendServiceService,
              public userContainer: UserContainerService,
              public modalService: NgbModal,
              public eventContainer: EventContainerService,
              public attendantContainer: AttendantContainerService,
              public event_sesion_container: EventSessionContainerService,
              private localLogin: LocalLoginService,
              public router: Router) {
    this.initialitze();
    if(this.backend.manual != undefined)
      this.manual = this.backend.manual;
    else
      this.manual = false;
    if(this.manual){
      this.send_mail = false;
      this.show_entry = true;
      this.has_paied = true;
    }

    this.event = this.generateEvent();
  }

  ngOnInit(): void {
    this.max_attendants = this.event.max_fu_attendants;
    this.getEvents();
  }

  ngOnChanges(){
    this.initialitze();
  }

  initialitze(){
    this.questions = new Array<Question_class>();
    this.ended = new EventEmitter<string>();
    this.attendants = new Array<Attendant_me_class>();
    this.attendants_errors = new Array<Attendant_error_class>();
    for(let i = 0; i < this.min_fu; i++){
      this.attendants.push(new Attendant_me_class());
      this.attendants_errors.push(new Attendant_error_class());

    }
    this.mail_errors = new Array<string>();
    this.phone_errors = new Array<string>();
    this.general_error = new Array<string>();
    this.attendant_errors = new Attendant_error_class();
    this.conditionsAccepted = false;
    this.checkAttendantP = false;
    this.pConditionsAccepted = false;
    this.allow = true;
    this.alone = false;
    this.idAttendantP = '';
    this.checkAttendantP = false;
    this.ent_url = Constants.ENT_URL;
  }

  getEvents(){
    // get event list
    this.backend.getAllEvents(this.userContainer.user.user_name).subscribe((data) => {
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }
      if(data){
        this.events = Object.assign(new Array<Event_class>(), data);
        this.events.forEach((event:Event_class)=>{
          event.sessions.forEach((session)=>{
            session.selectedAltPrice = ' ';
          });
        });
        //console.log(this.events);
      }
    });
  }

  generateEvent(){
    let event = new Event_class();
    event.max_fu_attendants  = 20;
    return event;
  }

  addAttendant(){
    //console.log(this.event.available_tickets);
    //console.log(this.backend.role);
    if(this.attendants.length<this.max_attendants && (this.backend.role != undefined || this.attendants.length < this.event.available_tickets)){
      this.attendants.push(new Attendant_me_class());
      this.attendants_errors.push(new Attendant_error_class());
    }

  }

  validate(): boolean{
    let result = true;

    this.mail_errors = new Array<string>();
    this.phone_errors = new Array<string>();
    this.attendant_errors = new Attendant_error_class();

    if(!(this.backend.inPerson || this.manual)){
      if(this.isVoid(this.mail)){
        this.phone_errors.push('Es obligatori introduir un correu.');
        result = false;
      }
      if(!this.isMail(this.mail)){
        this.phone_errors.push('El format del correu no es vàlid');
        result = false;
      }
      if(this.mail!=this.mail2){
        this.mail_errors.push('No coincideixen els dos camps de correu.');
        result = false;
      }
    } else {
      if(!this.isMail(this.mail)){
        this.mail = '-';
      }
    }
    if(this.isVoid(this.phone)){
      this.phone_errors.push('Es obligatori introduir un telèfon.');
      result = false;
    }

    if(this.event.conditions!=undefined && this.event.conditions!='')
      if(!this.conditionsAccepted){
        this.attendant_errors.condition.push('Es obligatori acceptar les condicions.');
        result = false;
      }

    if(this.event.general_conditions!=undefined && this.event.general_conditions!='')
      if(!this.gConditionsAccepted){
        this.attendant_errors.general_condition.push('Es obligatori acceptar les condicions.');
        result = false;
      }

    if(!this.pConditionsAccepted){
      this.attendant_errors.pConditions.push('Es obligatori acceptar les condicions de privacitat.');
      result = false;
    }

    for(let i = 0; i < this.attendants.length; i++){
      let attendant: Attendant_me_class = this.attendants[i];
      let error: Attendant_error_class = new Attendant_error_class();
      if(this.isVoid(attendant.name)){
        error.name.push('Es obligatori introduir un nom.');
        result = false;
      }
      if(this.isVoid(attendant.surname)){
        error.surname.push('Es obligatori introduir un cognom.');
        result = false;
      }
      if(!attendant.nodni){
        if(this.isVoid(attendant.dni)){
          error.dni.push('Es obligatori introduir un DNI.');
          result = false;
        } else if(!this.isValidDNI(attendant.dni)){
          error.dni.push('Es obligatori introduir un DNI vàlid. El format correcte es 12345678A o X1234567A.');
          result = false;
        }
      } else {
        attendant.dni = '-';
      }

      this.attendants_errors[i] = error;
    }

    if(this.attendants.length < 2 && result && !this.alone){
      this.alonefuAdvice();
      result = false;
    }

    //console.log(this.attendants_errors);
    return result;
  }

  removeAttendant(list, i){
    list = list.splice(i,1);
  }

  isValidDNI(text:string){
    if(text.length!=9)
      return false;
    let lastChar = "TRWAGMYFPDXBNJZSQVHLCKE";
    let nieValues = {"X":"0","Y":"1","Z":"2"};
    if(nieValues[text[0]]!=undefined)
      text = nieValues[text[0]] + text.substr(1);
    return lastChar[parseInt(text.substr(0,text.length-1))%23] == text.substr(text.length-1);
  }

  isVoid(text){
    return text == '' || text == undefined;
  }

  isMail(text){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
  }

  confirm(){
    this.errorSaving = false;
    if(this.validate()){
      this.allow = false;
      for(let i = 0; i < this.attendants.length; i++){
        this.attendants[i].inPerson = this.backend.inPerson;
        this.attendants[i].manual = this.manual;
        if(this.event.has_sessions&&!this.userSeassonTicket)
          this.attendants[i].id_session = this.event_sesion_container.event_session.id;
        if(this.manual){
          this.attendants[i].send_mail = this.send_mail;
          this.attendants[i].has_paied = this.has_paied;
        }
      }
      this.waitServer = true;
      //console.log(this.attendants);
      this.saveFuAdmin();
    }
  }

  saveFuAdmin(){
    this.assingIds();
    //console.log(this.attendants);
    this.backend.saveMEFamiliarUnionAdmin(this.event.id, this.mail, this.phone,
      this.allow_newsletter, this.attendants).subscribe(data => {
      this.waitServer = false;
      console.log(data);
      if(data['error']=='login_error'){
        this.backend.logout().subscribe(data=> {
          this.localLogin.remove(true);
          this.router.navigateByUrl("/");
        })
      }

      if(data['ok']){
        this.key = data['key'];
        for(let i=0;i<this.attendants.length;i++){
          this.attendants[i].entry_key = data['attendants'][i]['key'];
        }
        if(this.show_entry)
          this.goToTicket();
        else{
          this.attendants = new Array<Attendant_me_class>();
          this.attendants.push(new Attendant_me_class());
          this.attendants_errors = new Array<Attendant_error_class>();
          this.attendants_errors.push(new Attendant_error_class());
          this.mail_errors = new Array<string>();
          this.phone_errors = new Array<string>();
          this.mail = "";
          this.mail2 = "";
          this.phone = "";
          this.allow = true;
          this.alone = false;
        }
      } else{
        //Controlled error
        if(data['error']=='dni_exists'){
          this.attendants = data['attendants'];
          for(let i = 0; i < this.attendants.length; i++){
            if(this.attendants[i].error == 'dni_exists'){
              this.attendants_errors[i].dni.push('El dni ja es troba registrat.');
            }
          }
        } else if(data['error'] == 'sold_out'){
          this.general_error.push('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
          console.log('Ho sentim, sol queden ' + data['tickets'] + ' reserves disponibles.');
        } else {
          this.errorSaving = true;
        }
        this.allow = true;
      }
    }, error => {
      this.errorSaving = true;
      this.waitServer = false;
      console.log(error);
    });
  }

  goToTicket(){
    this.eventContainer.event = this.event;
    this.attendantContainer.multiple = true;
    this.attendantContainer.attendants = this.attendants;
    //console.log(this.attendants);
    this.router.navigate(['/view-tickets-me']);
  }

  open() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.ended.emit('');
    }, (reason) => {
      this.ended.emit('');
    });
  }

  alonefuAdvice() {
    this.modalService.open(this.alonefu, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result=='OK')
        this.alone = true;
        this.confirm();
    }, (reason) => {
    });
  }

  showConditions(content, conditions) {
    this.conditions = conditions;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
    }, (reason) => {

    });
  }

  getAttendantTicketPrice(attendant){
    if(attendant.selectedAltPrice == ' '){
      return this.event.price*1;
    } else {
      let price = 0;
      this.event.altPrices.forEach(altPrice => {
        if(altPrice.id == attendant.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  getCommission(){
    if(this.seassonTicket){
      return this.getManagementCost();
    } else{
      return this.getManagementCostTicket();
    }
  }

  getManagementCostTicket(){
    let cost = 0;
    this.attendants.forEach(attendant => {
      let price = this.getAttendantTicketPrice(attendant);
      cost += this.getAttendantManagementCost(price)*1;
    });
    return cost;
  }

  getAttendantPrice(attendant: Attendant_me_class){
    let price: number = this.event.price;
    if(attendant.selectedSection!=' '){
      let existSelected = false;
      this.userSeassonTicket.sections.forEach(
        userSeassonTicket => {
          if(userSeassonTicket.id == attendant.selectedSection){
            price = userSeassonTicket.price;
            existSelected = true;
          }
        }
      );
      if(!existSelected){
        attendant.selectedSection = ' ';
      }
    } else{
      price =  this.userSeassonTicket.price_individual;
    }
    return price
  }

  getManagementCost(){
    let total: number = 0;
    this.attendants.forEach((attendant, index) => {
      let price: number;
      if(this.userSeassonTicket.allow_couple&&index==0){
        price = this.userSeassonTicket.price_couple * 1;
        //console.log(price);
        total += this.getAttendantManagementCost(price) * 1;
      } else if(!this.userSeassonTicket.allow_couple||index>1) {
        price = this.getAttendantPrice(attendant) * 1;
        total += this.getAttendantManagementCost(price) * 1;
      }
    });
    return total;
  }

  getAttendantManagementCost(price){
    if(price <= 0)
      return 0;
    if(price <=5)
      return this.backend.commission_low;
    else
      return this.backend.commission_high;
  }

  getPrice(event: Event_class){
    if(event.selectedAltPrice==undefined){
      event.selectedAltPrice = ' ';
    }
    if(event.selectedAltPrice == ' '){
      return event.price*1;
    } else {
      let price = 0;
      event.altPrices.forEach(altPrice => {
        if(altPrice.id == event.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  getPriceS(event: Event_class, session: Event_session_class){
    if(session.selectedAltPrice == ' '){
      return event.price*1;
    } else {
      let price = 0;
      event.altPrices.forEach(altPrice => {
        if(altPrice.id == session.selectedAltPrice){
          price = altPrice.price;
        }
      });
      return price*1;
    }
  }

  getTotalPrice(){
    const events: Array<Event_class> = this.events;
    let total: number = 0;
    events.forEach((event: Event_class) => {
      if(event.paid){
        if(event.has_sessions){
          const sessions: Array<Event_session_class> = event.sessions;
          sessions.forEach((session: Event_session_class) => {
            if(session.mer_assist){
              if(event.have_alternative_price){
                const altPrices: Array<Event_alternative_price_class> = event.altPrices;
                const idSelAltPrice: string = session.selectedAltPrice;
                let selAltPrice = false;
                altPrices.forEach((altPrice: Event_alternative_price_class) => {
                  if(altPrice.id==idSelAltPrice){
                    total += parseFloat(altPrice.price.toString());
                    selAltPrice = true;
                  }
                });
                if(!selAltPrice){
                  total += parseFloat(event.price.toString());
                }
              } else {
                total += parseFloat(event.price.toString());
              }
            }
          });
        } else {
          if(event.mer_assist){
            if(event.have_alternative_price){
              const altPrices: Array<Event_alternative_price_class> = event.altPrices;
              const idSelAltPrice: string = event.selectedAltPrice;
              let selAltPrice = false;
              altPrices.forEach((altPrice: Event_alternative_price_class) => {
                if(altPrice.id==idSelAltPrice){
                  total += parseFloat(altPrice.price.toString());
                  selAltPrice = true;
                }
              });
              if(!selAltPrice){
                total += parseFloat(event.price.toString());
              }
            } else {
              total += parseFloat(event.price.toString());
            }
          }
        }
      }
    });
    return total;
  }
  assingIds(){
    for(let i=0; i<this.attendants.length; i++){
      let attendant: Attendant_me_class = this.attendants[i];
      attendant.participates = new Array<Attendant_me_participates>();
      attendant.send_mail = this.send_mail;
      attendant.show_entry = this.show_entry;
      attendant.has_paied = this.has_paied;
    }
    this.events.forEach((event: Event_class)=>{
      if(event.mer_assist){
        this.attendants.forEach((attendant: Attendant_me_class)=>{
          let participate = new Attendant_me_participates();
          participate.id_event = event.id;
          attendant.participates.push(participate);
        });
      }
    }); 
  }
}
