import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Commissions_class } from 'src/app/class/Commissions_class';
import { Events_summary_class } from 'src/app/class/Events_summary_class';
import { Event_summary_price_block_class } from 'src/app/class/Event_summary_price_block_class';
import { Summary_ust_class } from 'src/app/class/Summary_ust_class';
import { User_class } from 'src/app/class/User_class';
import { BackendServiceService } from 'src/app/services/backend-service.service';
import { LocalLoginService } from 'src/app/services/local-login.service';
import { faPrint, faFileCsv } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  eventsSummarys: Array<Events_summary_class>;
  ustSummarys: Array<Summary_ust_class>;
  users: Array<User_class>;
  commissions: Commissions_class;
  year: string;
  month: string;
  years: Array<string>;
  months: Array<string>;
  filterType: string = 'M';
  startDate: Date = new Date();
  endDate: Date = new Date();
  user_name: string;
  totals: Event_summary_price_block_class;
  totalsUst: Event_summary_price_block_class;
  faPrint = faPrint;
  faFileCsv = faFileCsv;
  constructor(public backend: BackendServiceService,
              public router: Router,
              private localLogin: LocalLoginService,) {
    this.eventsSummarys = new Array<Events_summary_class>();
    this.ustSummarys = new Array<Summary_ust_class>();
    this.totals = new Event_summary_price_block_class();
    this.totalsUst = new Event_summary_price_block_class();
    if(this.backend.role != 'R'){
      this.getYearsWithEvents();
    } else {
      this.getUserNames();
    }
    if(this.backend.apikey==undefined){
      if(this.localLogin.existsData()){
        this.localLogin.restore_to_service();
      } else {
        this.router.navigateByUrl("/");
      }
    }
  }

  ngOnInit(): void {
  }

  getCommission(price){
    if(price <= 0)
      return 0;
    if(price <5)
      return this.commissions.commission_low*1;
    else
      return this.commissions.commission_high*1;
  }

  getTotalCommission(total, price){
    let commission = this.getCommission(price);
    return total * commission;
  }

  calculateUstTotals(){
    let ustT = new Event_summary_price_block_class();
    this.ustSummarys.forEach((ustS)=>{
      ustS.sections.forEach((section)=>{
        ustT.tot_pagats += section.valid*1;
        ustT.tot_pagats_plataforma += section.platform_paid*1;
        const commission = this.getCommission(section.price);
        if(section.description == "Membres d'una parella"){
          ustT.preu_total_pagats += ((section.valid/2) * section.price);
          ustT.preu_total_pagats_plataforma += ((section.platform_paid/2) * section.price);
          ustT.total_commissions += section.valid * commission;
        } else {
          ustT.preu_total_pagats += ((section.valid*1) * section.price);
          ustT.preu_total_pagats_plataforma += ((section.platform_paid*1) * section.price);
          ustT.total_commissions += section.valid * commission;
        }
      });
    });
    this.totalsUst = ustT;
  }

  calculateCommissions(){
    this.eventsSummarys.forEach((eSummary) => {
      if(eSummary.paid!='Y'){
        eSummary.price = 0;
      }
      eSummary.normal_price.commission = this.getCommission(eSummary.price);
      //console.log(eSummary.normal_price.commission);
      eSummary.normal_price.total_commissions =
              eSummary.normal_price.commission
              * eSummary.normal_price.tot_pagats;
      if(eSummary.have_alternative_price=='Y'){
        eSummary.alternative_prices.forEach((altPSum)=>{
          altPSum.commission = this.getCommission(altPSum.price);
          altPSum.total_commissions =
              altPSum.commission
                * altPSum.tot_pagats;
        });
      }
    });
    this.calculateTotals();
  }

  getYearsWithEvents(){
    this.backend.getYearsWithEvents(this.user_name).subscribe(
      data=>{
        this.years = Object.assign(new Array<string>(), data['years']);
        this.year = this.years[0];
        this.getMonthsWithEvents();
      },
      error=>{
        console.error(error)
      }
    );
  }

  getMonthsWithEvents(){
    this.backend.getMonthsWithEvents(this.user_name, this.year).subscribe(
      data=>{
        this.checkLogin(data);
        this.months = Object.assign(new Array<string>(), data['months']);
        this.month = this.months[0];
      },
      error=>{
        console.error(error)
      }
    );
  }

  getSummary(){
    const byDateRange = this.filterType == 'R';
    const startDate = Math.round(this.startDate.getTime()/1000);
    const endDate = Math.round(this.endDate.getTime()/1000);
    this.backend.getSummary(this.user_name, this.year,this.month,
            byDateRange, startDate, endDate).subscribe(
      data=>{
        this.checkLogin(data);
        this.commissions = Object.assign(new Commissions_class(), data['commissions']);
        this.eventsSummarys = Object.assign(new Array<Events_summary_class>(), data['events']);
        this.calculateCommissions();
        this.getUstSummary();
        //console.log(this.eventsSummarys);
      },
      error=>{
        console.error(error)
      }
    );
  }

  getUstSummary(){
    const byDateRange = this.filterType == 'R';
    const startDate = Math.round(this.startDate.getTime()/1000);
    const endDate = Math.round(this.endDate.getTime()/1000);
    this.backend.getUstSummary(this.user_name, this.year,this.month,
            byDateRange, startDate, endDate).subscribe(
      data=>{
        this.checkLogin(data);
        this.ustSummarys = Object.assign(new Array<Summary_ust_class>(), data);
        this.calculateUstTotals();
        //console.log(this.ustSummarys);
      },
      error=>{
        console.error(error)
      }
    );
  }

  getUserNames(){
    this.backend.getAllUsers().subscribe(data => {
      this.checkLogin(data);
      if(data){
        this.users = Object.assign(new Array<User_class>(), data);
        this.user_name = this.getFirstUserName();
        this.getYearsWithEvents();
      }
      else{
        //Controlled error
      }
    }, error => {
      console.log(error);
    });
  }

  checkLogin(data){
    if(data['error']=='login_error'){
      this.backend.logout().subscribe(data=> {
        this.localLogin.remove(true);
        this.router.navigateByUrl("/");
      })
    }
  }

  getFirstUserName(){
    let name = '';
    let i = 0;
    let user: User_class;
    do{
      user = this.users[i];
      name = user.user_name;
      i++;
    } while(user.role != 'A' && i < this.users.length)
    return name;
  }

  print(){
    window.print();
  }

  calculateTotals(){
    let totalValids: number = 0;
    let totalValidsTPV: number = 0;
    let preuTotalValids: number = 0;
    let preuTotalValidsTPV: number = 0;
    let totalCommission: number = 0;
    this.eventsSummarys.forEach((summary)=>{
      totalValids += (summary.paid=='Y'?summary.normal_price.tot_pagats*1: summary.normal_price.tot_inscrits*1);
      totalValidsTPV += (summary.normal_price.tot_pagats_plataforma*1);
      preuTotalValids += (summary.paid=='Y'?summary.normal_price.tot_pagats * summary.price:0);
      preuTotalValidsTPV += (summary.paid=='Y'?summary.normal_price.tot_pagats_plataforma * summary.price:0);
      totalCommission += summary.normal_price.total_commissions;
      if(summary.alternative_prices!=undefined){
        summary.alternative_prices.forEach((altP)=>{
          totalValids += (altP.price!=0?altP.tot_pagats*1:altP.tot_inscrits*1);
          totalValidsTPV += (altP.tot_pagats_plataforma*1);
          preuTotalValids += (altP.price!=0?altP.tot_pagats * altP.price:0);
          preuTotalValidsTPV += (altP.price!=0?altP.tot_pagats_plataforma * altP.price:0);
          totalCommission += altP.total_commissions;
        });
      }
    });
    this.totals.tot_pagats = totalValids;
    this.totals.tot_pagats_plataforma = totalValidsTPV;
    this.totals.preu_total_pagats = preuTotalValids;
    this.totals.preu_total_pagats_plataforma = preuTotalValidsTPV;
    this.totals.total_commissions = totalCommission;
  }
  downloadCsv(){
    
  }
}
