<div class="d-print-none" [ngStyle]="{'background-color': accent_color}" style="color: #212529; line-height: 1.2;">
  <div style="border-bottom: 4px solid black; padding-bottom: 30px;">
      <div class="container-fluid pt-3 pl-3 pb-3">
          <div class="row">
              <div class="col-12" style="font-size: 25px;">
                Benvingut, <strong>{{name_user}}</strong>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="container-fluid mt-3 mb-5 pb-5">
    <div class="row">
        <div class="col 12">
            <div class="row">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-warning float-right" (click)="new()" style="width: 192px;"><fa-icon [icon]="faPlusSquare"></fa-icon>&nbsp;Abonament</button>
              </div>
            </div>
            <ng-container *ngFor="let userSeasonTicket of userSeasonTickets; index as i">
              <div class="row mt-3" style="border-top: 4px solid black; height: 100px;">
                  <div class="col-9" style="height: 100px;">
                    <div style="margin-top: 19px;">
                      <h2 style="margin-bottom: 0px;">{{ userSeasonTicket.name }}</h2>
                    </div>
                  </div>
                  <div class="col-3" style="height: 100px;">
                    <div style="padding-left: 15px; line-height: 40px; background-color: rgb(50,55,61); height: 40px; margin-top:30px; margin-right: -15px; color: white;">
                      <div style="text-align: left;">
                        <fa-icon [icon]="faInfoCircle" (click)="edit(userSeasonTicket)" style="cursor: pointer;font-size: 20px; color: #15D790;" class="ml-3"></fa-icon>
                        <fa-icon [icon]="faListAlt" (click)="listAttendants(userSeasonTicket.id)" style="cursor: pointer;font-size: 20px; color: #42bbc9;" class="ml-3"></fa-icon>
                        <fa-icon [icon]="faMinusCircle" (click)="confirmDelete(content,userSeasonTicket)" style="cursor: pointer;font-size: 20px; color: rgb(208,77,45);" class="ml-3"></fa-icon>
                      </div>
                    </div>
                  </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmar</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Eliminar l'abonament <b>{{deleteUserSeasonTicketName}}?</b>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-succes" (click)="modal.dismiss()">Cancel·lar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('DELETE')">Eliminar</button>
  </div>
</ng-template>

<app-back-button link="/events-list"></app-back-button>
