<table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-4 mb-4">
    <thead>
      <tr>
        <th class="px-3 py-2">Intent</th>
        <th class="px-3 py-2">Plataforma</th>
        <th class="px-3 py-2">Data i hora</th>
        <th class="px-3 py-2">Codi del banc</th>
        <th class="px-3 py-2">Informació retornada del banc</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let payment_status of payment_status_list; index as i" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th scope="row" class="px-3 py-2">{{i+1}}</th>
        <td class="px-3 py-2">
          <fa-icon [icon]="faChrome" *ngIf="i==0"></fa-icon>
          <fa-icon [icon]="faEnvelope" *ngIf="i!=0"></fa-icon>
        </td>
        <td class="px-3 py-2">{{payment_status.moment}}</td>
        <td class="px-3 py-2">{{payment_status.id_response!=''?payment_status.id_response:'ND'}}</td>
        <td class="px-3 py-2">{{payment_status.message!=''?payment_status.message:'Operación no completada por parte del cliente'}}</td>
      </tr>
    </tbody>
  </table>