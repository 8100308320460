import { Component, OnInit } from '@angular/core';
import { Attendant_class } from 'src/app/class/Attendant_class';
import { AttendantContainerService } from 'src/app/services/attendant-container.service';
import { BackendServiceService } from 'src/app/services/backend-service.service';

@Component({
  selector: 'app-view-tickets-me',
  templateUrl: './view-tickets-me.component.html',
  styleUrls: ['./view-tickets-me.component.css']
})
export class ViewTicketsMeComponent implements OnInit {
  tickets: string = "";
  attendants: Array<Attendant_class> = new Array<Attendant_class>();
  constructor(public backend: BackendServiceService,
              public attendantContainer: AttendantContainerService) { }

  ngOnInit(): void {
    this.attendants = this.attendantContainer.attendants;
    this.getTickets();
  }

  getTickets(){
    this.backend.getTicketsMEFU(this.attendants).subscribe((data: string) => {
      //console.log(data);
      this.tickets = data;
    });
  }

}
