<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <button (click)="lang='cat'">CAT</button><button (click)="lang='es'">ES</button>
        </div>
        <ng-container *ngIf="lang=='cat'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>LLEI DELS SERVEIS DE LA SOCIETAT DE LA INFORMACIÓ (LSSI)</h3>
                </div>
                <p>
                    PIQTURE NEW MEDIA S.L, responsable del lloc web, d’ara endavant el RESPONSABLE, posa a disposició dels usuaris aquest document, amb el qual pretén fer complir les obligacions que estableix la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i de Comerç Electrònic (LSSICE), BOE N.º 166, així com informar tots els usuaris del lloc web sobre quines són les condicions d’ús.<br>
                    Qualsevol persona que accedeixi a aquest lloc web assumeix el paper d’usuari i es compromet a l’observança i compliment rigorós de les disposicions que s’hi estableixen, així com a qualsevol altra disposició legal s’hi apliqui. <br>
                    PIQTURE NEW MEDIA S.L es reserva el dret de modificar qualsevol tipus d’informació que pugui aparèixer al lloc web, sense l’obligació de preavisar o assabentar els usuaris d’aquestes obligacions, atès que es considera suficient la publicació al lloc web de PIQTURE NEW MEDIA S.L.
                </p>
                <h4>1. DADES IDENTIFICADORES</h4>
                <p>
                    Nom del domini: mesacces.com<br>Nom comercial: <br>Denominació social: PIQTURE NEW MEDIA S.L<br>NIF: B25794744<br>Domicili social: C\ ENRIC ARDERIU 1, Altell, 25005, Lleida<br>Telèfon: 873991392<br>e-mail: acces@mesacces.com<br>
                </p>
            </div>
            <div class="col-12">
                <h4>2. DRETS DE PROPIETAT INTEL·LECTUAL I INDUSTRIAL</h4>
                <p>
                    El lloc web, incloent a títol enunciatiu però no limitatiu la seva programació, edició, compilació i altres elements necessaris per al seu funcionament, els dissenys, logotips, text i/o gràfics, són propietat del RESPONSABLE o, si és el cas, disposa de llicència o autorització expressa per part dels autors. Tots els continguts del lloc web es troben degudament protegits per la normativa de propietat intel·lectual i industrial, així com inscrits en els registres públics corresponents. <br>
                    Independentment de la finalitat per a la qual fossin destinats, la reproducció total o parcial, ús, explotació, distribució i comercialització, requereix en tot cas l’autorització escrita prèvia per part del RESPONSABLE. Qualsevol ús no autoritzat prèviament es considera un incompliment greu dels drets de propietat intel·lectual o industrial de l’autor. <br>
                    Els dissenys, logotips, text i/o gràfics aliens al RESPONSABLE i que puguin aparèixer al lloc web, pertanyen als seus respectius propietaris, els quals són responsables de qualsevol possible controvèrsia que se’n pugui desprendre. El RESPONSABLE autoritza expressament que tercers puguin redirigir directament als continguts concrets del lloc web, i en tot cas redirigir al lloc web principal de mesacces.com. <br>
                    El RESPONSABLE reconeix a favor dels seus titulars els corresponents drets de propietat intel·lectual i industrial, sense implicar el seu sol esment o aparició al lloc web l’existència de drets o responsabilitat sobre aquests, ni el suport, patrocini o recomanació per part del RESPONSABLE.<br><br>
                    Per a efectuar qualsevol tipus d’observació pel que fa a possibles incompliments dels drets de propietat intel·lectual o industrial, així com sobre qualsevol dels continguts del lloc web, podeu fer-ho a través del correu electrònic nfo@mesacces.com.
                </p>
            </div>
            <div class="col-12">
                <h4>3. EXEMPCIÓ DE RESPONSABILITATS</h4>
                <p>
                    El RESPONSABLE s’eximeix de qualsevol tipus de responsabilitat derivada de la informació publicada al seu lloc web en cas que aquesta informació hagi estat manipulada o introduïda per un tercer aliè al RESPONSABLE.<br>
                    <b>Ús de Cookies</b><br>
                    Pot ser que aquest lloc web utilitzi cookies tècniques (petits arxius d’informació que el servidor envia a l’ordinador de qui accedeix a la pàgina) per dur a terme determinades funcions que es consideren imprescindibles per al correcte funcionament i visualització del web. Les cookies utilitzades tenen, en qualsevol cas, caràcter temporal, amb l’única finalitat de fer que la navegació sigui més eficient, i desapareixen en finalitzar la sessió de l’usuari. En cap cas, aquestes cookies no proporcionen per si mateixes dades de caràcter personal i no s’utilitzaran per a recollir dades d’aquest tipus. <br>
                    Mitjançant l’ús de cookies també és possible que el servidor on es troba la web reconegui el navegador que utilitza l’usuari, amb la finalitat que la navegació sigui més senzilla, tot permetent, per exemple, l’accés dels usuaris que s’hagin registrat prèviament a les àrees, serveis, promocions o concursos reservats exclusivament a ells sense haver de registrar-se a cada visita. També es poden utilitzar per a mesurar l’audiència, paràmetres de trànsit, controlar el progrés i el nombre d’entrades, etc. En aquests casos, les cookies són prescindibles tècnicament però beneficioses per a l’usuari. Aquest lloc web no instal·larà cookies prescindibles sense el consentiment previ de l’usuari.
                    L’usuari té la possibilitat de configurar el seu navegador per a ser alertat de la recepció de cookies i per impedir-ne la instal·lació al seu equip. Si us plau, consulteu les instruccions del vostre navegador per a ampliar aquesta informació.<br>
                    <b>Política d’enllaços</b><br>
                    Des del lloc web, és possible que es redirigeixi a continguts de tercers llocs web. Atès que el RESPONSABLE no pot controlar sempre els continguts introduïts per tercers en els seus llocs web respectius, no assumeix cap tipus de responsabilitat respecte d’aquests continguts. En qualsevol cas, retirarà immediatament qualsevol contingut que pugui transgredir la legislació nacional o internacional, la moral o l’ordre públic, tot retirant immediatament la redirecció a aquest lloc web i assabentant les autoritats competents del contingut en qüestió. <br>
                    El RESPONSABLE no es fa responsable de la informació i continguts emmagatzemats, a títol enunciatiu però no limitatiu, a fòrums, xats, generadors de blogs, comentaris, xarxes socials o qualsevol altre mitjà que permeti a tercers publicar continguts de manera independent en la pàgina web del RESPONSABLE. Això no obstant, i en compliment del que estableixen als articles 11 i 16 de la LSSICE, es posa a disposició de tots els usuaris, autoritats i forces de seguretat que col·laboren de manera activa en la retirada o, si escau, bloqueig de tots els continguts que puguin afectar o transgredir la legislació nacional o internacional, els drets de tercers o la moral i l’ordre públic. En cas que l’usuari consideri que el lloc web conté material que pugui ser susceptible d’aquesta classificació, li preguem que ho notifiqui immediatament a l’administrador del lloc web. <br>
                    Aquest lloc web s’ha revisat i provat perquè funcioni correctament. En principi, es pot garantir que funcionarà correctament els 365 dies de l’any i les 24 hores al dia. Això no obstant, el RESPONSABLE no descarta la possibilitat que es produeixin errors de programació, o que hi tinguin lloc causes de força major, catàstrofes naturals, vagues o circumstàncies similars que facin impossible l’accés a la pàgina web.<br>
                    <b>Adreces IP</b><br>
                    Els servidors del lloc web podran detectar automàticament l’adreça IP i el nom del domini utilitzats per l’usuari. Una adreça IP és un número assignat automàticament a un ordinador així que es connecta a Internet. Tota aquesta informació queda registrada en un fitxer d’activitat del servidor que permet processar les dades posteriorment per tal d’obtenir mesuratges únicament estadístics que permetin conèixer el nombre d’impressions de pàgines, el nombre de visites efectuades als servidors web, l’ordre de visites, el punt d’accés, etc.<br>
                </p>
            </div>
            <div class="col-12">
                <h4>4. LLEI APLICABLE I JURISDICCIÓ</h4>
                <p>
                    Per a la resolució de totes les controvèrsies o qüestions relacionades amb aquest lloc web o de les activitats que s’hi duen a terme, s’aplicarà la legislació espanyola, a la qual se sotmeten expressament les parts, els quals tindran competència suficient per a la resolució de tots els conflictes derivats o relacionats amb el seu ús els Jutjats i Tribunals més propers a CERVERA.
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="lang=='es'">
            <div class="col-12">
                <div class="col-12 text-center">
                    <h3>LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (LSSI)</h3>
                </div>
                <p>
                    PIQTURE NEW MEDIA S.L, responsable del sitio web, en adelante RESPONSABLE, pone a disposición de los usuarios el presente documento, con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), BOE N.º 166, así como informar a todos los usuarios del sitio web respecto a cuáles son las condiciones de uso.<br>
                    Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualquier otra disposición legal que fuera de aplicación. <br>
                    PIQTURE NEW MEDIA S.L se reserva el derecho de modificar cualquier tipo de información que pudiera aparecer en el sitio web, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendiéndose como suficiente la publicación en el sitio web de PIQTURE NEW MEDIA S.L.
                </p>
                <h4>1. DATOS IDENTIFICATIVOS</h4>
                <p>
                    Nombre de dominio: mesacces.com<br>Nombre comercial: <br>Denominación social: PIQTURE NEW MEDIA S.L<br>NIF: B25794744<br>Domicilio social: C\ ENRIC ARDERIU 1, Altell, 25005, Lleida<br>Teléfono: 873991392<br>e-mail: acces@mesacces.com<br>Inscrita en el Registro Mercantil de Lleida, el 09/03/2012.
                </p>
            </div>
            <div class="col-12">
                <h4>2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h4>
                <p>
                    El sitio web, incluyendo a título enunciativo pero no limitativo su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos, son propiedad del RESPONSABLE o, si es el caso, dispone de licencia o autorización expresa por parte de los autores. Todos los contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad intelectual e industrial, así como inscritos en los registros públicos correspondientes. <br>
                    Independientemente de la finalidad para la que fueran destinados, la reproducción total o parcial, uso, explotación, distribución y comercialización, requiere en todo caso la autorización escrita previa por parte del RESPONSABLE. Cualquier uso no autorizado previamente se considera un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor. <br>
                    Los diseños, logotipos, texto y/o gráficos ajenos al RESPONSABLE y que pudieran aparecer en el sitio web, pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible controversia que pudiera suscitarse respecto a los mismos. El RESPONSABLE autoriza expresamente a que terceros puedan redirigir directamente a los contenidos concretos del sitio web, y en todo caso redirigir al sitio web principal de mesacces.com. <br>
                    El RESPONSABLE reconoce a favor de sus titulares los correspondientes derechos de propiedad intelectual e industrial, no implicando su sola mención o aparición en el sitio web la existencia de derechos o responsabilidad alguna sobre los mismos, como tampoco respaldo, patrocinio o recomendación por parte del mismo.<br><br>
                    Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede hacerlo a través del correo electrónico nfo@mesacces.com.
                </p>
            </div>
            <div class="col-12">
                <h4>3. EXENCIÓN DE RESPONSABILIDADES</h4>
                <p>
                    El RESPONSABLE se exime de cualquier tipo de responsabilidad derivada de la información publicada en su sitio web siempre que esta información haya sido manipulada o introducida por un tercero ajeno al mismo.<br>
                    <b>Uso de Cookies</b><br>
                    Este sitio web puede utilizar cookies técnicas (pequeños archivos de información que el servidor envía al ordenador de quien accede a la página) para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualización del sitio. Las cookies utilizadas tienen, en todo caso, carácter temporal, con la única finalidad de hacer más eficaz la navegación, y desaparecen al terminar la sesión del usuario. En ningún caso, estas cookies proporcionan por sí mismas datos de carácter personal y no se utilizarán para la recogida de los mismos. <br>
                    Mediante el uso de cookies también es posible que el servidor donde se encuentra la web reconozca el navegador utilizado por el usuario con la finalidad de que la navegación sea más sencilla, permitiendo, por ejemplo, el acceso de los usuarios que se hayan registrado previamente a las áreas, servicios, promociones o concursos reservados exclusivamente a ellos sin tener que registrarse en cada visita. También se pueden utilizar para medir la audiencia, parámetros de tráfico, controlar el progreso y número de entradas, etc., siendo en estos casos cookies prescindibles técnicamente, pero beneficiosas para el usuario. Este sitio web no instalará cookies prescindibles sin el consentimiento previo del usuario.
                    El usuario tiene la posibilidad de configurar su navegador para ser alertado de la recepción de cookies y para impedir su instalación en su equipo. Por favor, consulte las instrucciones de su navegador para ampliar esta información.<br>
                    <b>Política de enlaces</b><br>
                    Desde el sitio web, es posible que se redirija a contenidos de terceros sitios web. Dado que el RESPONSABLE no puede controlar siempre los contenidos introducidos por terceros en sus respectivos sitios web, no asume ningún tipo de responsabilidad respecto a dichos contenidos. En todo caso, procederá a la retirada inmediata de cualquier contenido que pudiera contravenir la legislación nacional o internacional, la moral o el orden público, procediendo a la retirada inmediata de la redirección a dicho sitio web, poniendo en conocimiento de las autoridades competentes el contenido en cuestión. <br>
                    El RESPONSABLE no se hace responsable de la información y contenidos almacenados, a título enunciativo pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio que permita a terceros publicar contenidos de forma independiente en la página web del RESPONSABLE. Sin embargo, y en cumplimiento de lo dispuesto en los artículos 11 y 16 de la LSSICE, se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad, colaborando de forma activa en la retirada o, en su caso, bloqueo de todos aquellos contenidos que puedan afectar o contravenir la legislación nacional o internacional, los derechos de terceros o la moral y el orden público. En caso de que el usuario considere que existe en el sitio web algún contenido que pudiera ser susceptible de esta clasificación, se ruega lo notifique de forma inmediata al administrador del sitio web. <br>
                    Este sitio web se ha revisado y probado para que funcione correctamente. En principio, puede garantizarse el correcto funcionamiento los 365 días del año, 24 horas al día. Sin embargo, el RESPONSABLE no descarta la posibilidad de que existan ciertos errores de programación, o que acontezcan causas de fuerza mayor, catástrofes naturales, huelgas o circunstancias semejantes que hagan imposible el acceso a la página web.
                    <b>Direcciones IP</b><br>
                    Los servidores del sitio web podrán detectar de manera automática la dirección IP y el nombre de dominio utilizados por el usuario. Una dirección IP es un número asignado automáticamente a un ordenador cuando este se conecta a Internet. Toda esta información se registra en un fichero de actividad del servidor que permite el posterior procesamiento de los datos con el fin de obtener mediciones únicamente estadísticas que permitan conocer el número de impresiones de páginas, el número de visitas realizadas a los servidores web, el orden de visitas, el punto de acceso, etc.
                </p>
            </div>
            <div class="col-12">
                <h4>4. LEY APLICABLE Y JURISDICCIÓN</h4>
                <p>
                    Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales más cercanos a CERVERA.
                </p>
            </div>
        </ng-container>
    </div>
</div>

