import { Injectable } from '@angular/core';
import { Attendant_class } from '../class/Attendant_class';

@Injectable({
  providedIn: 'root'
})
export class AttendantContainerService {
  attendant: Attendant_class;
  attendants: Array<Attendant_class>;
  multiple: boolean;
  constructor() {
    this.multiple = false;
   }
}
